/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import {
  AddEditSponsorshipPayload,
  AddEditSponsorshipResponse,
  DeleteSponsorshipPayload,
  DeleteSponsorshipResponse,
  ParentSponsorshipResponse,
  SponsorshipItemPayload,
  SponsorshipItemResponse,
  UpdateSponsorshipStatusPayload,
  UpdateSponsorshipStatusResponse,
} from 'shared/src/interfaces/sponsorship.interface';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SponsorshipService {
  rootURL = environment.rootApiUrl;
  endPointUrl = API_ENDPOINTS.sponsorship;
  constructor(private http: HttpClient) {}

  ////////// load sponsorship items list /////////
  loadSponsorshipList(payload: SponsorshipItemPayload) {
    let url = `${this.rootURL}${this.endPointUrl.default}`
      .replace('{pageSize}', (payload?.pageSize ?? 20).toString())
      .replace('{pageNumber}', (payload?.pageNumber ?? 1).toString())
      .replace('{orderBy}', (payload?.orderBy ?? 'Date').toString())
      .replace('{order}', (payload?.order ?? 'desc').toString());
    if (payload?.query) {
      url += `&query=${payload?.query}`;
    }
    return this.http
      .get<SponsorshipItemResponse>(url)
      .pipe(map((e: SponsorshipItemResponse) => e?.body));
  }

  ////////// get all enabled sponsorship item with parent id 0 /////////
  enabledSponsorshipItems(id:number) {
    const url = `${this.rootURL}${this.endPointUrl.enabledParent}`.replace(
      '{id}',
      (id ?? '').toString()
    );
    return this.http
      .get<ParentSponsorshipResponse>(url)
      .pipe(map((e: ParentSponsorshipResponse) => e));
  }

  /////////////// add sponsorship /////////////
  addSponsorship(payload: AddEditSponsorshipPayload) {
    const url = `${this.rootURL}${this.endPointUrl.add}`;
    return this.http
      .post<AddEditSponsorshipResponse>(url, payload)
      .pipe(map((e: AddEditSponsorshipResponse) => e));
  }

  ////////////// sponsorship detail ////////////////
  sponsorshipDetail(id: number) {
    const url = `${this.rootURL}${this.endPointUrl.sponsorshipById}`.replace(
      '{id}',
      (id ?? '').toString()
    );
    return this.http
      .get<AddEditSponsorshipResponse>(url)
      .pipe(map((e: AddEditSponsorshipResponse) => e?.body));
  }

  /////////////// edit sponsorship /////////////
  updateSponsorship(payload: AddEditSponsorshipPayload, id: number) {
    const url = `${this.rootURL}${this.endPointUrl.sponsorshipById}`.replace(
      '{id}',
      (id ?? '').toString()
    );
    return this.http
      .put<AddEditSponsorshipResponse>(url, payload)
      .pipe(map((e: AddEditSponsorshipResponse) => e));
  }

  ////////// delete Sponsorship/////////
  deleteSponsorship(payload: DeleteSponsorshipPayload) {
    const url = `${this.rootURL}${this.endPointUrl.delete}`;
    return this.http
      .delete<DeleteSponsorshipResponse>(url, { body: payload })
      .pipe(map((e: DeleteSponsorshipResponse) => e));
  }

  ////////// update sponsorship status/////////
  updateSponsorshipStatus(payload: UpdateSponsorshipStatusPayload) {
    const url = `${this.rootURL}${this.endPointUrl.status}`;
    return this.http
      .patch<UpdateSponsorshipStatusResponse>(url, payload)
      .pipe(map((e: UpdateSponsorshipStatusResponse) => e));
  }
}
