import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FooterLinks } from './footerLinks';
@Component({
  selector: 'panjab-digi-lib-footer',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  constructor(private translate: TranslateService) {}
  getArrayItems = (start: number, end: number) => {
    let op = [start];
    while (end > start) op.push(++start);
    return op;
  };
  getCorrespondingLinks = (
    type: 'explore' | 'aboutUs' | 'pdl' | 'services' | 'generalInfo'
  ) => {
    return FooterLinks[type];
  };
  getAllHeaders = (): any => {
    return Object.keys(FooterLinks);
  };
}
