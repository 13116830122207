import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CommonListingComponent } from '../../common-listing/common-listing.component';
import { RouterLink, RouterOutlet } from '@angular/router';

@Component({
  selector: 'panjab-digi-lib-my-library',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CommonListingComponent,
    RouterLink,
    RouterOutlet,
  ],
  templateUrl: './my-library.component.html',
  styleUrl: './my-library.component.scss',
})
export class MyLibraryComponent {
  dataItems = null;
  researchTopics = [
    {
      id: 1,
      name: 'Rural Rehabiliation in East Punjab',
      total: 80,
      items: [
        {
          thumbnail: 'assets/images/list-thumb1.jpg',
        },
        {
          thumbnail: 'assets/images/list-thumb1.jpg',
        },
        {
          thumbnail: 'assets/images/list-thumb1.jpg',
        },
      ],
    },
    {
      id: 2,
      name: 'Janam Patri Guru Nanak Dev Ji',
      total: 34,
      items: [
        {
          thumbnail: 'assets/images/list-thumb1.jpg',
        },
        {
          thumbnail: 'assets/images/list-thumb1.jpg',
        },
        {
          thumbnail: 'assets/images/list-thumb1.jpg',
        },
        {
          thumbnail: 'assets/images/list-thumb1.jpg',
        },
        {
          thumbnail: 'assets/images/list-thumb1.jpg',
        },
        {
          thumbnail: 'assets/images/list-thumb1.jpg',
        },
      ],
    },
  ];
  constructor() {
    this.dataItems = this.getDataCorrespondingToCategory({categoryName: 'Books'})
  }
  getDataCorrespondingToCategory = (currentKey: any): any => {
    const data = [];
    const types = ['Manuscripts', 'Books', 'Magzines'];
    const stars = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
    for (let i = 0; i < 100; i++) {
      data.push({
        id: 1,
        type:
          currentKey.id === 0
            ? types[Math.ceil(Math.random() * 3) - 1]
            : currentKey.categoryName,
        rating: stars[Math.ceil(Math.random() * 10) - 1],
        name: 'Item ' + Math.ceil(Math.random() * 38374),
        script: 'Gurmukhi',
        pages: Math.ceil(Math.random() * 10000),
        thumbnail: 'assets/images/list-thumb1.jpg',
        author: 'Author ' + Math.ceil(Math.random() * 38374),
        subject: 'Subject ' + Math.ceil(Math.random() * 38374),
        description:
          'Following declaration of independence of India and its simultaneous partition, there was a transfer of population on the basis of religion from the asdasda'.substring(
            0,
            150
          ),
      });
    }
    return { data, total: data.length + Math.ceil(Math.random() * 500) };
  };
}
