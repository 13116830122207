import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertService } from '../../../../services/website/alert.service';
import { Observable } from 'rxjs';
import { IAlert } from '../../../../interfaces/alert.interface';

@Component({
  selector: 'panjab-digi-lib-alerts',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.scss',
})
export class AlertsComponent implements OnInit {
  alert$: Observable<IAlert> | undefined;

  constructor(private alertService: AlertService) {}

  ngOnInit(): void { 
    this.alert$ = this.alertService.onAlert();
  }

  close() {
    this.alertService.clear();
  }
}
