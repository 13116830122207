/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from 'shared/src/environments/environment';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { map, Observable } from 'rxjs';
import { Volunteer, VolunteerRequests } from 'shared/src/interfaces/volunteer.interface';

@Injectable({
  providedIn: 'root'
})
export class VolunteerService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.masterForm.language;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadVolunteers(payload: GenericListingPayload): Observable<{volunteers: Volunteer[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.volunteer.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }

  getVolunteerById(id: number): Observable<{volunteer: Volunteer[]}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.volunteer.default}`, {params: {ids: id}}).pipe(map((e: any) => e.body));
  }
  
  addVolunteers(payload: Volunteer) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.volunteer.default}`, payload).pipe(map((e: any) => e));
  }
  
  editVolunteers(payload: Volunteer, volunteerId: number) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.volunteer.default}/${volunteerId}`, payload).pipe(map((e: any) => e));
  }

  updateVolunteersStatus(volunteerIDs: number[], status: boolean) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.volunteer.updateVolunteerStatus}`, {ids: volunteerIDs, status}).pipe(map((e: any) => e));
  }
  
  downloadVolunteers() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.volunteer.downloadVolunteers}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }  

  loadVolunteersRequest(payload: GenericListingPayload): Observable<{requestsList: VolunteerRequests[], totalRecords: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.webUserMgmt.volunteerReq.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }

  updateVolunteerRequestStatus(id: number, status: number) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.webUserMgmt.volunteerReq.updateStatus}`, { id, status }).pipe(map((e: any) => e));
  }

}
