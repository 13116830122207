import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { MetadataPerCategory, MetadataOfCategory, SaveAllocationPayload, SaveAccessPayload } from "shared/src/interfaces/master-form.interface";

export const LoadMetadataPerCategory = createAction(
    '[MetadataPerCategory] Load Metadata Per Category'
)

export const LoadMetadataPerCategorySuccess = createAction(
    '[MetadataPerCategory] Load Metadata Per Category Successfully',
    props<{categories: MetadataPerCategory[]}>()
)

export const LoadMetadataPerCategoryError = createAction(
    '[MetadataPerCategory] Load Metadata Per Category Error',
    props<{message: string}>()
)

export const LoadMetadataOfCategory = createAction(
    '[MetadataPerCategory] Load Metadata for single Category',
    props<{payload: GenericListingPayload, categoryID: number}>(),
)

export const LoadMetadataOfCategorySuccess = createAction(
    '[MetadataPerCategory] Load Metadata for single Category Successfully',
    props<{metadata: MetadataOfCategory[], totalCount: number, categoryID: number}>()
)

export const LoadMetadataOfCategoryError = createAction(
    '[MetadataPerCategory] Load Metadata for single Category Error',
    props<{message: string}>()
)

export const LoadAllocatedMetadataOfCategory = createAction(
    '[MetadataPerCategory] Load Allocated Metadata for single Category',
    props<{payload: GenericListingPayload, categoryID: number}>(),
)

export const LoadAllocatedMetadataOfCategorySuccess = createAction(
    '[MetadataPerCategory] Load Allocated Metadata for single Category Successfully',
    props<{metadata: MetadataOfCategory[], totalCount: number, categoryID: number}>()
)

export const LoadAllocatedMetadataOfCategoryError = createAction(
    '[MetadataPerCategory] Load Allocated Metadata for single Category Error',
    props<{message: string}>()
)

export const SaveAllocations = createAction(
    '[MetadataPerCategory] Save Allocations',
    props<{payload: SaveAllocationPayload, categoryID: number}>(),
)

export const SaveAllocationsSuccess = createAction(
    '[MetadataPerCategory] Save Allocations Successfully',
    props<{message: string, categoryID: number}>()
)

export const SaveAllocationsError = createAction(
    '[MetadataPerCategory] Save Allocations Error',
    props<{message: string}>()
)

export const SaveAccess = createAction(
    '[MetadataPerCategory] Save Access',
    props<{payload: SaveAccessPayload[], categoryID: number}>(),
)

export const SaveAccessSuccess = createAction(
    '[MetadataPerCategory] Save Access Successfully',
    props<{message: string, categoryID: number}>()
)

export const SaveAccessError = createAction(
    '[MetadataPerCategory] Save Access Error',
    props<{message: string}>()
)
