/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { Adopter } from "shared/src/interfaces/fundraising.interface";

export const LoadAdoptedBooks = createAction(
    '[Adopter] Load Adopted books',
    props<{payload: GenericListingPayload}>(),
)

export const LoadAdoptedBooksSuccess = createAction(
    '[Adopter] Load Adopted books Successfully',
    props<{adoptedBooksList: Adopter[], totalRecords: number}>()
)

export const LoadAdoptedBooksError = createAction(
    '[Adopter] Load Adopted books Error',
    props<{message: string}>()
)