<div class="modal__style">
  <button type="button" class="btn close btn__close" aria-label="Close" (click)="decline()">
    <img
      src="assets/images/icons/cross-close-icon.svg"
      alt="Close"
      class="img-fluid"
    />
  </button>
  <div class="modal-header justify-content-center" *ngIf="title">
    <h4 class="modal-title">{{ title }}</h4>
  </div>
  <div class="modal-body pt-0 pb-4">
    {{ message }}
    <ng-template #modalContent></ng-template>
  </div>
  <div class="modal-footer justify-content-center gap-2">
    <button type="button" class="btn btn-default btn__secondary" (click)="dismiss()">{{ btnCancelText }}</button>
    <button type="button" [disabled]="isLoading" class="btn btn-primary btn__primary" (click)="onSubmit()">{{ btnOkText }}</button>
  </div>
</div>
  