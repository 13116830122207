import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromServices from "../reducers/services.reducer";

export const selectServiceState =
  createFeatureSelector<fromServices.State>('services');

export const getServices = createSelector(
    selectServiceState,
    fromServices.selectAll
);

export const getTotalRecords = createSelector(
  selectServiceState,
  (state) => state.totalRecords
);

export const getServiceByID = (props: {id: string}) => createSelector(
  selectServiceState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectServiceState,
  (state) => state.payload
)

export const getThumbnails = createSelector(
  selectServiceState,
  (state) => state.thumbnails
)

export const onSave = createSelector(
  selectServiceState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);