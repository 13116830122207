/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import {
  addEditPublisherPayload,
  deletePublisherPayload,
  getPublisherPayload,
  mergePublisherPayload,
  Publisher,
  updatePublisherStatusPayload,
} from 'shared/src/interfaces/publisher.interface';
export const LoadPublishers = createAction(
  '[Publisher] Load Publisher List',
  props<{ payload: getPublisherPayload }>()
);

export const LoadPublisherSuccessfully = createAction(
  '[Publisher] Load Publisher Successfully',
  props<{ publisherList: Publisher[]; totalRecords: number }>()
);

export const LoadPublisherError = createAction(
  '[Publisher] Load Publisher Error',
  props<{ message: string }>()
);

export const AddPublisher = createAction(
  '[Publisher] Add Publisher to List',
  props<{ payload: addEditPublisherPayload }>()
);

export const AddPublisherSuccessfully = createAction(
  '[Publisher] Publisher Added Successfully',
  props<{ publisher: Publisher; message: string }>()
);

export const AddPublisherError = createAction(
  '[Publisher] Publisher Added Error',
  props<{ message: string }>()
);

export const UpdatePublisher = createAction(
  '[Publisher] Edit Publisher in List',
  props<{ payload: addEditPublisherPayload,id:number }>()
);

export const UpdatePublisherSuccessfully = createAction(
  '[Publisher] Publisher Updated Successfully',
  props<{ publisher: Publisher; message: string }>()
);

export const UpdatePublisherError = createAction(
  '[Publisher] Publisher Updated Error',
  props<{ message: string }>()
);

export const UpdatePublisherStatus = createAction(
    '[Publisher] Update Publisher Status in List',
    props<{ payload: updatePublisherStatusPayload }>()
  );
  
  export const UpdatePublisherStatusSuccessfully = createAction(
    '[Publisher] Publisher Status Updated Successfully',
    props<{ publisher: Publisher[]; message: string }>()
  );
  
  export const UpdatePublisherStatusError = createAction(
    '[Publisher] Publisher Status Updated Error',
    props<{ message: string }>()
  );

  export const MergePublisher = createAction(
    '[Publisher] Merge Publisher',
    props<{ payload: mergePublisherPayload }>()
  );
  
  export const MergePublisherSuccessfully = createAction(
    '[Publisher] Merge Publisher Successfully',
    props<{ message: string }>()
  );
  
  export const MergePublisherError = createAction(
    '[Publisher] Publisher Status Updated Error',
    props<{ message: string }>()
  );

  export const DeletePublisher= createAction(
    '[Publisher] Delete Status from List',
    props<{ payload: deletePublisherPayload }>()
  );
  
  export const DeletePublisherSuccessfully = createAction(
    '[Publisher] Publisher Deleted Successfully',
    props<{ message: string }>()
  );
  
  export const DeletePublisherError = createAction(
    '[Publisher] Publisher Status Updated Error',
    props<{ message: string }>()
  );