<section class="innerpage-banner">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
                    <a class="breadcrumb-link font-regular" href="#">
                        <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
                        Home
                    </a> 
                    <span class="chevron-arrow font-regular">
                        <img src="assets/images/icons/arrow-right.svg">
                    </span>
                    <a class="breadcrumb-link font-regular" href="#">
                        Categories
                    </a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="page-title">
                    <h1 class="heading-dark semibold mb-0">All Categories</h1>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="categories section-padding ">
    <div class="container">
        <div class="row">
            
            <div class="col-12">
                <div class="categories-in-grid">
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName1.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers1.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category1.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName2.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                         {{'pdl-website.categoryNumbers2.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category2.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0">{{'pdl-website.categoryName3.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers3.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category3.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0">{{'pdl-website.categoryName4.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers4.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category4.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0">{{'pdl-website.categoryName5.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers5.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category5.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0">{{'pdl-website.categoryName6.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers6.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category6.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0">{{'pdl-website.categoryName7.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers7.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category7.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName8.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers8.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category8.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName9.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers9.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category9.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName10.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers10.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category10.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName11.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers11.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category11.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName12.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers12.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category12.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName13.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers13.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category13.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName14.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers14.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category14.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName15.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers15.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category15.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName16.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers16.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category16.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName17.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers17.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category17.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName18.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers18.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category18.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName19.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers19.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category19.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName20.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers20.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category20.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName21.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers21.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category21.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName22.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers22.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category22.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName23.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers23.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category23.jpg">
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{'pdl-website.categoryName24.categoryText' | translate }}</h6>
                                    <span class="number-of-users mb-0">
                                        {{'pdl-website.categoryNumbers24.numbersText' | translate }}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img src="assets/images/category24.jpg">
                                </div>
                            </a>
                        </div>

                    </div>
                </div>


            </div>
            
        </div>
    </div>
</section>