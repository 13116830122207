<section class="most-popular-section section-padding">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="heading-style semibold text-center mb-lg-40">{{'pdl-website.mostPopular.sectionTitle' | translate }}</h2>
            </div>
            <div class="col-12">
                <div class="most-popular-slider">
                    <ngx-slick-carousel class="carousel" 
                                        #slickModal="slick-carousel" 
                                        [config]="slideConfig" 
                                        (init)="slickInit($event)">
                        @for (slide of slides; track slide.img) {
                            <div ngxSlickItem class="slide most-popular-slide me-4">
                                    <img class="mostpopular-slide-bg" src="{{ slide.img }}" alt="" width="100%">
                            </div>
                        }
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </div>
</section>


