import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from 'shared/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminDownloadService {
  rootUrl = '';
  baseUrl = API_ENDPOINTS.homePage.download;
  constructor(private http: HttpClient) {
    this.rootUrl = environment.rootApiUrl;

  }


  /////////// admin download //////////
  download(payload: any) {
    const url = `${this.rootUrl}${this.baseUrl.downloadList}`;
    return this.http.post(url, payload?.data);
  }

  /////////// Get Accession Info //////////
  getAccessionInfo(accessionNumber:string) {
    const url = `${this.rootUrl}${this.baseUrl.getAccessionInfo}`.replace(
      '{accessionNumber}',
      accessionNumber
    );
    return this.http.get(url);
  }

  /////////// get trending list on admin homepage //////////
  getDownloadList(data: any) {
    const url = `${this.rootUrl}${this.baseUrl.loadDownLoadList}`
      .replace('{pageSize}', data?.pageSize)
      .replace('{pageNumber}', data?.pageNumber);
    return this.http.get(url);
  }
 ////////////// save download form after selected image /////////////
  addTemplateImage(formData:any) {
    const url = `${this.rootUrl}${this.baseUrl.addTemplateImage}`;
    return this.http.put(url, formData);
  }

  /////////// get selected image from download list detail ///////////
  selectedDownloadImageDetail(id: string) {
    const url = `${this.rootUrl}${this.baseUrl.selectedDownloadImageDetail}`.replace(
      '{id}',
      id
    );
    return this.http.get(url);
  }
  
  /////////// edit admin download //////////
  editDownload(payload: any) {
    const url = `${this.rootUrl}${this.baseUrl.editDownloadItem}`.replace(
      '{id}',
      payload?.id
    );
    return this.http.put(url, payload?.data);
  }

  /////////// get template image based on template type //////////
  getTemplateImage(templateType:string) {
    const url = `${this.rootUrl}${this.baseUrl.templateImages}`
      .replace('{templateType}', templateType);
    return this.http.get(url);
  }

  /////////// activate template based on template type //////////
  activateTemplate(payload: any) {
    const url = `${this.rootUrl}${this.baseUrl.activateTemplate}`;
    return this.http.patch(url, payload);
  }

  /////////// activate template based on template type //////////
  updateDownloadItemStatus(payload: any) {
    const url = `${this.rootUrl}${this.baseUrl.changeStatus}`;
    return this.http.patch(url, payload);
  }

    /////////// reorder download items //////////
  sortDownloadItem(id: string, toOrder: number) {
   const url = `${this.rootUrl}${this.baseUrl.sort}`.replace('{id}',id);
  return this.http.put(url, {toOrder});
  }

}
