export const WEB_API_ENDPOINTS = {
    homePage: {
        getBanners: 'app/banner',
        getTrendingKeywords: 'app/trending',
        getCategories: 'app/category',
        getDownloads: 'app/downloads',
        videos : 'app/videos?type=homepage',
        search : 'app/suggestion?query={query}&categoryId={categoryId}'

    },
    downloads: {
        allDownloads : 'app/allDownloads?pageNumber={pageNumber}&pageSize={pageSize}&sortBy={sortBy}'
    },
    videos : {
       video : 'app/video/{id}',
       videos : 'app/videos?orderBy={orderBy}&type=all'
    },
    user: {
        donations: 'user/donations',
        getAdoptedBooks: 'user/adoptedBooks'
    },
    volunteer: {
        validateReq: 'volunteer/validate',
        addReq: 'volunteer/add-request'
    },
    search : {
        default : 'app/search',
        filters : 'app/filters',
        searchCollection : 'app/searchCollection',
        save :'app/saveSearch',
        update : 'app/updateSavedSearch/{id}',
        delete: 'app/deleteSearch/{id}'
    }

}