import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, tap, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { HomeDataService } from 'shared/src/services/admin/home/home-data.service';
import { AppState, CategoriesSelector, ServiceActions, ServicesSelector } from '@panjab-digi-lib/shared';
import { MasterFormsService } from 'shared/src/services/admin/master-form/master-forms.service';
import { Update } from '@ngrx/entity';
import { Service } from 'shared/src/interfaces/services.interface';

@Injectable()
export class ServicesEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private homeDataService: HomeDataService,
        private masterFormService: MasterFormsService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ServiceActions.LoadServices),
            exhaustMap((action) => {
                
                return this.masterFormService.loadServices(action.payload).pipe(
                    map((response) => {
                        return ServiceActions.LoadServicesSuccess({ services: response.services, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(ServiceActions.LoadServicesError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );
    
    AddService$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ServiceActions.AddService),
            exhaustMap((action) => {
                
                return this.masterFormService.addService(action.formData).pipe(
                    map((response) => {

                        return ServiceActions.AddServiceSuccess({service: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(ServiceActions.AddServiceError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );
    
    EditService$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ServiceActions.EditService),
            exhaustMap((action) => {
                
                return this.masterFormService.editService(action.formData, action.serviceID).pipe(
                    map((response) => {

                        this.router.navigateByUrl('/master-forms/manage/pdl-services');
                        return ServiceActions.EditServiceSuccess({service: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(ServiceActions.EditServiceError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );
    
    UpdateServiceStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ServiceActions.UpdateServicesStatus),
            exhaustMap((action) => {
                
                return this.masterFormService.updateServiceStatus(action.ServiceIDs, action.status).pipe(
                    map((response: any) => {
                        const serviceUpdates: Update<Service>[] = action.ServiceIDs.map((id) => {  
                            return {
                                id, changes: {
                                    Status: action.status
                                }
                            }
                        })
                        
                        return ServiceActions.UpdateServicesStatusSuccess({serviceUpdates, message: response.message});
                    }),
                    catchError((error: any) => of(ServiceActions.UpdateServicesStatusError({message: error?.error?.error?.message ? error?.error?.error?.message : error?.message}))
                    ))
            })
        )
    );
    
    DeleteServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ServiceActions.DeleteServices),
            exhaustMap((action) => {
                
                return this.masterFormService.deleteService(action.ServiceIDs).pipe(
                    map((response: any) => {
                        
                        return ServiceActions.DeleteServicesSuccess({ServiceIDs: action.ServiceIDs, message: response.message});
                    }),
                    catchError((error: any) => of(ServiceActions.DeleteServicesError({message: error?.error?.error?.message ? error?.error?.error?.message : error?.message}))
                    ))
            })
        )
    );

    AddDeleteServiceSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ServiceActions.AddServiceSuccess, ServiceActions.DeleteServicesSuccess),
            switchMap((action) => combineLatest([
                    of(action.type),
                    this.store.select(ServicesSelector.getListingPayload).pipe(take(1))
                ])
            ),
            map(([type, payload]) => {
                if(type == '[Service] Add Service Success') {
                    this.router.navigateByUrl('/master-forms/manage/pdl-services');
                }
                return ServiceActions.LoadServices({payload});
            })
        )
    );

}