/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { environment } from 'shared/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AddEditEditorPayload, AddEditEditorResponse, DeleteEditorPayload, DeleteEditorResponse, GetEditorPayload, GetEditorResponse, MergeEditorPayload, MergeEditorsResponse, UpdateEditorStatusPayload, UpdateEditorStatusResponse } from 'shared/src/interfaces/editor.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditorService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.masterForm.editor;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }
    ////////// load Editors /////////
    loadEditors(payload: GetEditorPayload) {
      let url = `${this.rootURL}${this.endPointUrl.default}`;
      url = url
        .replace('{sortBy}', payload?.sortBy ?? 'name')
        .replace('{orderBy}', payload?.orderBy ?? 'asc');
        if(payload?.pageSize) {
        url += `&pageSize=${payload?.pageSize}`
        }
        if(payload?.pageNumber) {
        url += `&pageNumber=${payload?.pageNumber}`
        }
        if(payload?.query) {
        url += `&query=${payload?.query}`
        }
      return this.http
        .get<GetEditorResponse>(url)
        .pipe(map((e: GetEditorResponse) => e?.body));
    }
  
    ////////////// Editor detail ////////////////
    editorDetail(id:number) {
      const url = `${this.rootURL}${this.endPointUrl.editorById}`.replace('{id}', (id ?? '').toString());
      return this.http.get<AddEditEditorResponse>(url)
      .pipe(map((e: AddEditEditorResponse) => e?.body));
    }
  
    ////////// add Editor /////////
    addEditor(payload: AddEditEditorPayload) {
      const url = `${this.rootURL}${this.endPointUrl.editor}`;
      return this.http
        .post<AddEditEditorResponse>(url, payload)
        .pipe(map((e: AddEditEditorResponse) => e));
    }
  
    ////////// update Editor /////////
    updateEditor(payload: AddEditEditorPayload,id:number) {
      let url = `${this.rootURL}${this.endPointUrl.editorById}`;
      url = url.replace('{id}', (id ?? '').toString());
      return this.http
        .put<AddEditEditorResponse>(url, payload)
        .pipe(map((e: AddEditEditorResponse) => e));
    }
  
    ////////// update Editor status/////////
    updateEditorStatus(payload: UpdateEditorStatusPayload) {
      const url = `${this.rootURL}${this.endPointUrl.editor}`;
      return this.http
        .patch<UpdateEditorStatusResponse>(url, payload)
        .pipe(map((e: UpdateEditorStatusResponse) => e));
    }
  
    ////////// delete Editor/////////
    deleteEditor(payload: DeleteEditorPayload) {
      const url = `${this.rootURL}${this.endPointUrl.editor}`;
      return this.http
        .delete<DeleteEditorResponse>(url, { body: payload })
        .pipe(map((e: DeleteEditorResponse) => e));
    }
  
    ////////// merge Editors /////////
    mergeEditors(payload: MergeEditorPayload) {
      const url = `${this.rootURL}${this.endPointUrl.merge}`;
      return this.http
        .put<MergeEditorsResponse>(url, payload)
        .pipe(map((e: MergeEditorsResponse) => e));
    }
}
