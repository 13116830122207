import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageService } from '../StaticPageService';
import { TranslateModule } from '@ngx-translate/core';
import { StaticPageHeadComponent } from '../staticPageHead/staticPageHead.component';
import { SafeHtmlPipe } from 'shared/src/pipes/safe-html.pipe';

@Component({
  selector: 'panjab-digi-lib-interlibrary',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    StaticPageHeadComponent,
    SafeHtmlPipe,
  ],
  templateUrl: './interlibrary.component.html',
  styleUrl: './interlibrary.component.scss',
})
export class InterlibraryComponent {
  content = '';

  constructor(private staticPageService: StaticPageService) {}
  ngOnInit(): void {
    this.staticPageService
      .getStaticContent('en', 'interlibrary')
      .subscribe((data: any) => {
        this.content = data.body.Content;
      });
  }
}
