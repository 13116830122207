<panjab-digi-lib-static-page-head [pageName]="'pdl-website.policies.titleText' | translate" pagePath="/policy">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {

    <h1 class="heading mb-3">Panjab Digital Library Policies</h1>
    <p class="text">
      Concrete policies grounded in material facts are an essential foundation of any serious project.This section
      contains polices that govern the implementation and use of the digitization project, from the data capturing to
      the archiving stage.Please note that technological changes may necessitate changes to PDL policies at any time
      without prior notice. Pursuant to our Terms and Conditions it is your responsibility to keep up-to-date with and
      adhere to the policies posted here.
    </p>

    <h1 class="heading mb-3">Access Policy</h1>
    <p class="text">
      Due to licensing restrictions, access to some of the electronic resources available through the Panjab Digital
      Library is limited to the participating or member institutions/individuals. No initial login is necessary to enter
      the site and begin searching for items. However, when you attempt to link to one of the restricted resources, you
      will be prompted to enter your PDL username and password. In such cases, you will have to register yourself with
      PDL, which is free.
    </p>

    <h1 class="heading mb-3">Usage Restrictions</h1>
    <p class="text">
      The downloading of entire manuscript, book, newspaper, or any material on Panjab Digital Library in a systematic
      fashion is strictly prohibited. Furthermore, attempts to download the entire database are also prohibited. It is
      prohibited to make print or electronic copies for redistribution to non-subscriber individuals and institutions.
      Web activity may be monitored by Panjab Digital Library staff or individual publishers and violation of the
      licensing agreement could result in cancellation of access to the service or legal action.
    </p>

    <h1 class="heading mb-3">PDL Gift Policy</h1>
    <p class="text">
      PDL greatly appreciates Gifts-in-Kind, as they help significantly in broadening the depth and scope of our
      project. These gifts may include books, manuscripts, journals, newspapers, artwork, historical documents,
      hardware, software and DVDs and tapes.
    </p>
    <p class="text">This policy statement covers Gift-in-Kind donations to the general collection and does not apply to
      the Rare Books or Archives collections. For donations to Rare Books or Archives, please contact the librarian
      directly at <a href="mailto:librarian@panjabdigilib.org" target="_blank"
        class="email cursor-pointer text">librarian&#64;panjabdigilib.org</a>.</p>
    <p class="text">Gifts of material become property of the PDL and all gifts are considered to be unrestricted. The
      PDL will consider your gift for addition to the digital collection, but all gifts are accepted with the
      understanding that they become the property of the PDL and will be used in a way that will best serve the needs of
      the PDL and our users. Due to limited space, the PDL will be selective of what is to be added to the digital
      collection. The PDL will add only those items to its collection which is needed to support the teaching, research,
      and service programs of PDL. The material must also meet copyright policy guidelines. The useful material gifts
      are out-of-print publications; soft copies of new publications are highly appreciated.</p>
    <p class="text">Examples of donated materials unsuitable for inclusion into the PDL collection are: foreign language
      materials which do not support the PDL Content Selection Policy or research needs, and unauthorized copies. Gifts
      that will require a commitment of funds, special facilities or equipment must be approved by the librarian.</p>
    <p class="text">Donated materials must first be reviewed by a Library Liaison. If the material is located far from
      the PDL office, arrangements for viewing the materials or reviewing a list of the materials offered as donations
      should be made between the donor and the liaison. Donations not reviewed by a Library Liaison cannot be accepted.
    </p>
    <h1 class="heading mb-3">
      Document Submission Policy
    </h1>
    <p class="text">The purpose of the PDL archive is to make documents visible, accessible, harvestable, searchable,
      and useable by any potential user with access to the Internet. Searching and archiving at PDL is totally free for
      any user. The only requirement is that users wishing to read a complete document need to register in order to
      obtain a user id in the system. Librarians, libraries, research institutes, organizations, and individual
      researchers involved in Sikh/Panjab history and related fields are encouraged to make use of and contribute to the
      archive.</p>
    <p class="text">PDL is currently accepting published or unpublished manuscripts, books, pictures, articles, theses,
      newspapers, periodicals and audio/video files. The criteria for acceptance is that the documents are relevant to
      research on Sikhs and Panjab and that they are in the form of a finished document that is ready to be entered into
      a process. Documents may include: preprints, postprints, conference papers, conference posters, presentations,
      books, book chapters, reports, theses, and newspaper and magazine articles.</p>
    <p class="text">Submitted documents will be placed into the submission buffer, where they may be approved by the PDL
      Staff, rejected, or returned to the author for modifications in the metadata or if there are problems with the
      electronic file. Documents in the submission buffer are manually reviewed to confirm they fit our policy before
      adding them to PDL. PDL staff controls the metadata quality of the document and are allowed to make changes if the
      metadata are incorrect. Generally five business days is the allowable time period between when a paper is
      deposited to PDL and when it becomes accessible to other users.</p>
    <p class="text">Authors submitting documents are responsible for confirming there aren't any restrictions on the
      electronic distribution of the submitted document. Copyright issues will be the authors' responsibility. PDL is
      not responsible for contents submitted by the authors.</p>


    <h1 class="heading mb-3">Languages</h1>
    <p class="text">PDL accepts books in only five languages at this time: English, Panjabi, Hindi, Urdu and Persian.
      Books in other languages will eventually become part of our index as the program advances and we work to make our
      collection and hosting service more comprehensive and search software more complete.</p>

    <h1 class="heading mb-3">Book Content</h1>
    <p class="text">Books that you submit may not include illegal content.</p>

    <h1 class="heading mb-3">Deposit Agreement</h1>
    <h1 class="subheading mb-3">For work being deposited by its own author:</h1>
    <p class="text">In self-archiving this collection of files and associated bibliographic metadata, I grant PDL the
      right to store them and make them permanently available publicly for free on-line. I declare that this material is
      my own intellectual property and I understand that PDL does not assume any responsibility if there is any breach
      of copyright in distributing these files or metadata. All authors are urged to prominently assert their copyright
      on the title page of their work.</p>
    <h1 class="subheading mb-3">For work being deposited by someone other than its author:</h1>
    <p class="text">I hereby declare that the collection of files and associated bibliographic metadata that I am
      archiving at PDL is in the public domain. If this is not the case, I accept full responsibility for any breach of
      copyright that distributing these files or metadata may entail.</p>

    <h1 class="heading mb-3">PDL Digital Image Storage Policy</h1>
    <p class="text">This proposal will continue to be expanded and updated under the guidance of the PDL Back up Working
      Group. The anticipated changes will include information about the general practice of digital archiving and cost
      analysis of digital archiving. Changes to the general practices concerning the use of PDL images are not
      anticipated at this time. All participants will be notified in writing of any changes before they take effect.
    </p>
    <p class="text">The structure of PDL will be based on a model of distributed images and centralized metadata. In
      other words, digital images owned or created by an organization are stored on a server maintained by that
      organization. There will be many servers, in many different locations, which will store images for PDL projects.
      The images will be distributed in multiple locations. Metadata created to describe those digital images will be
      stored on the server maintained by the PDL. The metadata, stored by the PDL, will be kept in one centralized union
      catalog so many collections, held by many organizations, can all be searched simultaneously.</p>
    <p class="text">But the PDL would also like to store digital images in a centralized location. The intent of the
      Panjab Digital Library storing master (high resolution) images on the PDL server is strictly to assist with data
      migration and upgrades. It also allows the PDL to offer a copy of the images to the owning institution for the
      purposes of disaster recovery. The PDL IBM server is currently stored and maintained by the Nanakshahi Trust in
      SAS Nagar, Panjab. The data on this server is backed-up regularly and the back-up information is stored off-site
      on multiple Tape Drives and DVDs. The Nanakshahi Trust will not use any of the PDL or project images for their own
      purposes unless given express permission.</p>
    <p class="text">One of the goals of the PDL is to ensure that the digital information created for and by its
      projects is accessible over a long period of time. Therefore the PDL is offering the service of storing master
      images that will be maintained by the PDL, or an affiliated organization. The Master images will be migrated to
      new media as appropriate and the data will be refreshed in order to insure its usability in the future.</p>
    <p class="text">If the PDL should dissolve or come to exist in a form significantly altered from the original
      vision, the master images will be transferred to an appropriate organization with similar goals and interests as
      the PDL. All assets of the PDL will be disbursed as stated in the bylaws of the organization. Master images stored
      with the PDL will not be used in any way by the PDL without consent from rights holder.</p>
    <h1 class="subheading mb-3">Disclaimer</h1>
    <p class="text">The PDL is/will not be responsible for storage or retrieval failures of the digital archive.</p>

    <h1 class="heading mb-3">Backup Policy</h1>
    <p class="text">PDL endeavors to both digitally preserve and provide access to a wide range of heritage material.
      This requires that the media used for storage purposes demonstrates reliability and is easily accessible.
      Generally, these requirements can only be met by employing multiple formats and media.</p>
    <p class="text">After reviewing <a class="email cursor-pointer text"
        href="/assets/documents/PDL Backup Working Group.doc">PDL Backup Working Group report</a>, PDL formulated a Data
      Back-up Policy with all of
      these factors in mind . We created a multipurpose mechanism that will safe guard the material PDL holds, provide
      quick and easy access, as well as ensure that human errors, disasters and technological advances do not undo the
      PDL efforts.</p>
    <p class="text">To this end, PDL back-ups are kept in more than one media. One copy of backup is always online. For
      offline backups PDL employs DVDs (three copies), LTO4 Tapes (2), IBM Storage Servers (1) and SATA Hard disks (1),
      to store its data, apart from the online storage server. Copies of these backup articles are further stored at two
      separate locations to avoid complete and instant loss in case of a calamity or war. PDL Adopted this policy
      recently, earlier all backup was on two DVDs and one Hard Disk.</p>

    <h1 class="heading mb-3">Copyright Policy</h1>
    <p class="text">The PDL has tried to include only those books and other print material which is beyond the purview
      of copyright. However, notwithstanding the above stated, in the instance that any evidence to the contrary is
      observed, it can be brought to the notice of PDL Copyright Working Group at <a
        href="mailto:info@panjabdigilib.org" target="_blank"
        class="email cursor-pointer text">info&#64;panjabdigilib.org</a>. The concerned
      author or publisher shall, in writing, substantiate his/her claim to the working group and request the elimination
      of such content from the Web site. If the claim is confirmed, the content will be removed within 48 hours.</p>
    }
  </div>
</section>
