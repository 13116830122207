<section class="innerpage-banner" style="background: none;">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
          <a class="breadcrumb-link font-regular" href="/user/my-library">
            My Research Topic
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular" href="#">
            Rural Rehabiliation in East Punjab
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="container">
  <div class=" mt-3 d-flex align-items-center justify-content-between mb-4">
    <div class="d-flex align-items-center col-gap-12">
      <span class="heading bold">Rural Rehabiliation in East Punjab</span><span class="editbtn cursor-pointer"
        [routerLink]="'edit'">Edit</span>
    </div>
    <div class="deletebtn">
      <img class="cursor-pointer" [routerLink]="'delete'" src="/assets/images/icons/delete-icon.svg" />
    </div>
  </div>
  <div class="d-flex flex-wrap collection_overview_wrapper mb-4">
    <div class="d-flex">
      <img src="/assets/images/icons/pdl-guestbook-ico.svg" /> 3 Magzines
    </div>
    <div class="d-flex">
      <img src="/assets/images/icons/newspaper.svg" /> 3 Newspapers
    </div>
    <div class="d-flex">
      <img src="/assets/images/icons/book.svg" /> 3 Books
    </div>
    <div class="d-flex">
      <img src="/assets/images/icons/manuscript.svg" /> 3 Books
    </div>
  </div>
  <p class="text mb-4">The immense wealth of art and heritage collected, stored and preserved in archives, museums and
    libraries, gains significance only if it is made easily accessible to the public. It can be said then that spreading
    awareness about art and heritage, The immense wealth of art and heritage collected, stored and preserved in
    archives, museums and libraries, gains significance only if it is made easily accessible to the public. It can be
    said then that spreading awareness about art and heritage, The immense wealth of art and heritage collected, stored
    and preserved in archives, museums and libraries, gains significance only if it is made easily accessible to the
    public. It can be said then that spreading .&nbsp;&nbsp;&nbsp;&nbsp;<span class="editbtn cursor-pointer"
      [routerLink]="'edit'">Edit</span>
  </p>
  <div class="list_wrapper">
    <!-- // loop on this div -->
    <div class="list_item_wrapper">
      <div class="list_item d-flex mb-1">
        <img class="img" src="/assets/images/rr-book.jpg" />
        <div class="content flex-grow-1">
          <div class="semibold mb-2 d-flex justify-content-between "><span>Rural Rehabilitation in East Punjab and
              farmers and the struggles </span>
            <div class="likeandOptionWrapper p-absolute d-flex col-gap-12 flex-wrap">
              <a href="/user/my-library/assign-literature" class="cursor-pointer"> <img
                  src="/assets/images/icons/drag-button-green.svg" aria-label="options" /></a>
              <span class="cursor-pointer"> <img src="/assets/images/icons/heart-green.svg"
                  aria-label="dislike" /></span>
            </div>
          </div>
          <div class="d-flex flex-wrap collection_overview_wrapper mb-4">
            <div class="d-flex">
              <img src="/assets/images/icons/book.svg" /> Books
            </div>
            <div class="d-flex">
              <img src="/assets/images/icons/pen-ico.svg" /> SODHI NARAYAN SINGH
            </div>
            <div class="d-flex">
              <img src="/assets/images/icons/calendar-ico.svg" /> 21 JUNE 2023
            </div>
          </div>
          <div class="hyper-text mb-2">
            35 Pages Bookmarks
          </div>
          <div class="notes_dpdwn">
            My Notes <span class="total_text">(Total 4 Notes)</span>&nbsp;&nbsp;
            <img class="cursor-pointer" (click)="toggleShowData()"
              [src]="showData ?  '/assets/images/icons/chevron-up.svg': '/assets/images/icons/chevron-down.svg'" />
          </div>
        </div>


      </div>
      @if (showData) {
      <div class="expanded_notes_wrapper">
        <div class="noteItem mb-2">
          <div class="d-flex col-gap-12 align-items-center"><span class="semibold">Page 4</span><button
              class="view_btn">Reader View</button></div>
          <div class="note">“ Progressively incentivize cooperative systems through technically sound functionalities.
            The
            credibly productivities seamless data.Humor in early Punjabi drama is very rare and very often obscene,
            vulgar
            and loaded with double engendered words.”</div>
        </div>
      </div>

      }
    </div>
  </div>

</section>
<router-outlet></router-outlet>
