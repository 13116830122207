import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromBanners from "../reducers/banners.reducer";

export const selectBannersState =
  createFeatureSelector<fromBanners.State>('banners');

export const getBanners = createSelector(
  selectBannersState,
  fromBanners.selectAll
);

export const getBannerByID = (props: { id: number }) => createSelector(
  selectBannersState,
  (state) => state.entities[props.id]
)

export const isLoading = createSelector(
  selectBannersState,
  (state) => state.isLoading
);

export const totalBanners = createSelector(
  selectBannersState,
  (state) => state.totalBanners
);

export const selectError = createSelector(
  selectBannersState,
  (state) => state.errorMessage
);

export const selectSuccess = createSelector(
  selectBannersState,
  (state) => state.successMessage
);

export const isSaving = createSelector(
  selectBannersState,
  (state) => [state.isSaving, state.isError, state.errorMessage],
);