import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState, Update } from "@ngrx/entity";
import { MetadataPerCategoryActions } from '../actions';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { MetadataPerCategory, MetadataOfCategory } from "shared/src/interfaces/master-form.interface";

export interface State extends EntityState<any> {
    payload: GenericListingPayload,
    metadataOfCategory: MetadataOfCategory[],
    totalRecords: number,
    isLoading: boolean;
    loadingForSingleCategory?: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<MetadataPerCategory>({
    selectId: (e) => e.CategoryID
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: false,
    loadingForSingleCategory: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(MetadataPerCategoryActions.LoadMetadataPerCategory, (state, action) =>
    ({
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(MetadataPerCategoryActions.LoadMetadataPerCategorySuccess, (state, action) =>
    ({
        ...adapter.setAll(action.categories, state),
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(MetadataPerCategoryActions.LoadMetadataPerCategoryError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(MetadataPerCategoryActions.LoadMetadataOfCategory, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        loadingForSingleCategory: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(MetadataPerCategoryActions.LoadMetadataOfCategorySuccess, (state, action) =>
    ({
        ...state,
        metadataOfCategory: action.metadata,
        totalRecords: action.totalCount,
        loadingForSingleCategory: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(MetadataPerCategoryActions.LoadMetadataOfCategoryError, (state, action) =>
    ({
        ...state,
        loadingForSingleCategory: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(MetadataPerCategoryActions.SaveAllocations, (state, action) =>
    ({
        ...state,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(MetadataPerCategoryActions.SaveAllocationsSuccess, (state, action) =>
    ({
        ...state,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(MetadataPerCategoryActions.SaveAllocationsError, (state, action) =>
    ({
        ...state,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(MetadataPerCategoryActions.SaveAccess, (state, action) =>
    ({
        ...state,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(MetadataPerCategoryActions.SaveAccessSuccess, (state, action) =>
    ({
        ...state,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(MetadataPerCategoryActions.SaveAccessError, (state, action) =>
    ({
        ...state,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();