import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromViewDonation from "../reducers/view-donation.reducer";

export const selectViewDonationState =
  createFeatureSelector<fromViewDonation.State>('viewDonation');

export const getDonations = createSelector(
    selectViewDonationState,
    fromViewDonation.selectAll
);

export const getTotalRecords = createSelector(
  selectViewDonationState,
  (state) => state.totalRecords
);

export const getListingPayload = createSelector(
  selectViewDonationState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectViewDonationState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectViewDonationState,
  (state) => state.isLoading,
);