import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'panjab-digi-lib-list-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './list-modal.component.html',
  styleUrl: './list-modal.component.scss',
})
export class ListModalComponent {
  @Input() type: any;
  @Input() listData: any[] = [];
  @Output() onClose = new EventEmitter<any>();

  getlistData = () => {
    console.log('lsit', JSON.stringify(this.listData), this.type);
    return this.listData;
  };
  getNthChunk = (chunkCount: number, chunkSize: number): any => {
    return this.listData?.slice(
      (chunkCount - 1) * chunkSize,
      chunkCount * chunkSize
    );
  };

  getTotalChunks = (chunkSize: number) => {
    return Array(Math.ceil(this.listData.length / chunkSize)).fill(1);
  };

  closeClickHandler = () => {
    this.onClose.emit()
  }
}
