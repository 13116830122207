import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'panjab-digi-lib-spinner-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner-button.component.html',
  styleUrl: './spinner-button.component.scss',
})
export class SpinnerButtonComponent {
  @Input() name = '';
}
