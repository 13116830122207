import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalDividerComponent } from '../../../divider/global-divider.component';

@Component({
  selector: 'panjab-digi-lib-exhibition-details',
  standalone: true,
  imports: [CommonModule, GlobalDividerComponent],
  templateUrl: './exhibition-details.component.html',
  styleUrl: './exhibition-details.component.scss',
})
export class ExhibitionDetailsComponent {}
