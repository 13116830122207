/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { AdoptedByService } from 'shared/src/services/admin/Fundraising/adopted-by.service';
import { AdoptedBooksActions } from '../actions';

@Injectable()
export class AdoptedBooksEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private adoptedByService: AdoptedByService
    ) {
    }
    LoadAdoptedBooks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdoptedBooksActions.LoadAdoptedBooks),
            exhaustMap((action) => {

                return this.adoptedByService.getAdoptedBooks(action.payload).pipe(
                    map((response) => {
                        return AdoptedBooksActions.LoadAdoptedBooksSuccess({ adoptedBooksList: response.adoptedBooksList, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(AdoptedBooksActions.LoadAdoptedBooksError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
}