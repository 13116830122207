/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminVideoGalleryActions } from '../actions';
import {
  catchError,
  exhaustMap,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AdminVideoGallerySelector, AppState } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';
import { VideoGalleryService } from 'shared/src/services/admin/video-gallery/video-gallery.service';

@Injectable()
export class AdminVideoGalleryEffects {
  constructor(
    private actions$: Actions,
    private service: VideoGalleryService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  //////////////// get video gallery list ////////////
  LoadVideoGalleryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminVideoGalleryActions.LoadVideoGallery),
      exhaustMap((action) => {
        return this.service.loadVideoGallery(action?.payload).pipe(
          map((response) => {
            return AdminVideoGalleryActions.LoadVideoGallerySuccessfully({
              videoList: response?.videoList,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              AdminVideoGalleryActions.LoadVideoGalleryError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// add Video to Gallery ////////////
  AddVideoGallery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminVideoGalleryActions.AddVideo),
      exhaustMap((action) => {
        return this.service.addVideoGallery(action?.payload).pipe(
          map((response) => {
            return AdminVideoGalleryActions.AddVideoSuccessfully({
              video: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminVideoGalleryActions.AddVideoError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Video  ////////////
  UpdateVideoGallery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminVideoGalleryActions.UpdateVideo),
      exhaustMap((action) => {
        return this.service
          .updateVideoGallery(action?.payload, action?.id)
          .pipe(
            map((response) => {
              return AdminVideoGalleryActions.UpdateVideoSuccessfully({
                video: response?.body,
                message: response?.message,
              });
            }),
            catchError((error) =>
              of(
                AdminVideoGalleryActions.UpdateVideoError({
                  message: error?.error?.error?.message,
                })
              )
            )
          );
      })
    )
  );

  //////////////// update Video status////////////
  UpdateVideoGalleryStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminVideoGalleryActions.UpdateVideoStatus),
      exhaustMap((action) => {
        return this.service.updateVideoStatus(action?.payload).pipe(
          map((response) => {
            return AdminVideoGalleryActions.UpdateVideoStatusSuccessfully({
              video: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminVideoGalleryActions.UpdateVideoStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  ///////////////// delete Video ///////////////////////
  DeleteVideoGallery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminVideoGalleryActions.DeleteVideo),
      exhaustMap((action) =>
        this.service.deleteVideo(action?.payload).pipe(
          map((response) => {
            return AdminVideoGalleryActions.DeleteVideoSuccessfully({
              message: response?.message,
            });
          }),
          catchError((res) =>
            of(
              AdminVideoGalleryActions.DeleteVideoError({
                message: res.error?.error?.message,
              })
            )
          )
        )
      )
    )
  );

  ///////////////// update homepage Video ///////////////////////
  UpdateTemplateVideo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminVideoGalleryActions.UpdateTemplateVideo),
      exhaustMap((action) =>
        this.service.updateHomepageVideo(action?.payload).pipe(
          map((response) => {
            return AdminVideoGalleryActions.UpdateTemplateVideoSuccessfully({
              message: response?.message,
            });
          }),
          catchError((res) =>
            of(
              AdminVideoGalleryActions.UpdateTemplateVideoError({
                message: res.error?.error?.message,
              })
            )
          )
        )
      )
    )
  );

  ///////////////////// redirect after adding/deleting Video /////////////////////
  AddDeleteVideoGallerySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminVideoGalleryActions.AddVideoSuccessfully,
        AdminVideoGalleryActions.DeleteVideoSuccessfully
      ),
      withLatestFrom(
        this.store.pipe(select(AdminVideoGallerySelector.loadVideoGalleryList))
      ),
      switchMap(([action, state]) => {
        this.router.navigateByUrl('/video-gallery/video-gallery');
        const pageNumber =
          action.type === AdminVideoGalleryActions.AddVideoSuccessfully.type
            ? 1
            : state?.pageNumber;
        const payload = { pageNumber: pageNumber, pageSize: state?.pageSize,videoType : 'all' };
        return of(AdminVideoGalleryActions.LoadVideoGallery({ payload }));
      })
    )
  );
}
