import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAdoptPrice from '../reducers/admin-adopt-price.reducer';

export const selectState =
  createFeatureSelector<fromAdoptPrice.State>('adoptPrice');

export const loadCategoriesWithPriceList = createSelector(selectState, (state) => ({
  categoryList: fromAdoptPrice.selectAll(state),
  totalRecords: state.totalRecords,
}));

export const getAdoptPriceById = (id: number) => createSelector(
  selectState,
  (state) => state.entities[id]
)

export const onSave = createSelector(
  selectState,
  (state) => [state.isError, state.errorMessage, state.successMessage,state.isLoading]
);