/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminTranslatorActions } from '../actions';
import { catchError, exhaustMap, map, of, switchMap, withLatestFrom } from 'rxjs';
import { TranslatorService } from 'shared/src/services/admin/master-form/translator.service';
import { Store, select } from '@ngrx/store';
import { AdminTranslatorSelector, AppState } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';


@Injectable()
export class AdminTranslatorEffects {
  constructor(private actions$: Actions, private service: TranslatorService, private store: Store<AppState>, private router: Router,) {}

  //////////////// get all Translators ////////////
  LoadTranslatorList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminTranslatorActions.LoadTranslators),
      exhaustMap((action) => {
        return this.service.loadTranslators(action?.payload).pipe(
          map((response) => {
            return AdminTranslatorActions.LoadTranslatorSuccessfully({
              translatorList: response?.translatorList,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              AdminTranslatorActions.LoadTranslatorError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// add Translator ////////////
  AddTranslator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminTranslatorActions.AddTranslator),
      exhaustMap((action) => {
        return this.service.addTranslator(action?.payload).pipe(
          map((response) => {
            return AdminTranslatorActions.AddTranslatorSuccessfully({
              translator: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminTranslatorActions.AddTranslatorError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Translator ////////////
  UpdateTranslator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminTranslatorActions.UpdateTranslator),
      exhaustMap((action) => {
        return this.service.updateTranslator(action?.payload,action?.id).pipe(
          map((response) => {
            return AdminTranslatorActions.UpdateTranslatorSuccessfully({
              translator: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminTranslatorActions.UpdateTranslatorError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Translator status////////////
  UpdateTranslatorStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminTranslatorActions.UpdateTranslatorStatus),
      exhaustMap((action) => {
        return this.service.updateTranslatorStatus(action?.payload).pipe(
          map((response) => {
            return AdminTranslatorActions.UpdateTranslatorStatusSuccessfully({
              translator: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminTranslatorActions.UpdateTranslatorStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  ///////////////// delete Translator ///////////////////////
  DeleteTranslator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminTranslatorActions.DeleteTranslator),
      exhaustMap((action) =>
        this.service.deleteTranslator(action?.payload).pipe(
          map((response) => {
            return AdminTranslatorActions.DeleteTranslatorSuccessfully({
              message: response?.message,
            });
          }),
          catchError((res) =>
            of(
              AdminTranslatorActions.DeleteTranslatorError({
                message: res.error?.error?.message,
              })
            )
          )
        )
      )
    )
  );

//////////////// add Translator ////////////
  MergeTranslator$ = createEffect(() =>
        this.actions$.pipe(
          ofType(AdminTranslatorActions.MergeTranslator),
          exhaustMap((action) => {
            return this.service.mergeTranslators(action?.payload).pipe(
              map((response) => {
                return AdminTranslatorActions.MergeTranslatorSuccessfully({
                  message: response?.message,
                });
              }),
              catchError((error) =>
                of(
                  AdminTranslatorActions.AddTranslatorError({
                    message: error?.error?.error?.message,
                  })
                )
              )
            );
          })
        )
      );

 ///////////////////// redirect after adding/deleting/merging Translator /////////////////////     
  AddDeleteMergeTranslatorSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminTranslatorActions.AddTranslatorSuccessfully, AdminTranslatorActions.DeleteTranslatorSuccessfully,AdminTranslatorActions.MergeTranslatorSuccessfully),
      withLatestFrom(this.store.pipe(select(AdminTranslatorSelector.loadTranslatorList))),
      switchMap(([action, state]) => {
        this.router.navigateByUrl('/master-forms/manage/translators');
        const pageNumber = (action.type === AdminTranslatorActions.AddTranslatorSuccessfully.type || action.type === AdminTranslatorActions.MergeTranslatorSuccessfully.type) ? 1 : state?.pageNumber;
        const payload = { pageNumber: pageNumber, pageSize: state?.pageSize, sortBy: state?.sortBy,orderBy: state?.orderBy };
        return of(AdminTranslatorActions.LoadTranslators({payload}));
      })
    )
  )
}
