/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import {
  addEditTranslatorPayload,
  deleteTranslatorPayload,
  getTranslatorPayload,
  mergeTranslatorPayload,
  Translator,
  updateTranslatorStatusPayload,
} from 'shared/src/interfaces/translator.interface';
export const LoadTranslators = createAction(
  '[Translator] Load Translator List',
  props<{ payload: getTranslatorPayload }>()
);

export const LoadTranslatorSuccessfully = createAction(
  '[Translator] Load Translator Successfully',
  props<{ translatorList: Translator[]; totalRecords: number }>()
);

export const LoadTranslatorError = createAction(
  '[Translator] Load Translator Error',
  props<{ message: string }>()
);

export const AddTranslator = createAction(
  '[Translator] Add Translator to List',
  props<{ payload: addEditTranslatorPayload }>()
);

export const AddTranslatorSuccessfully = createAction(
  '[Translator] Translator Added Successfully',
  props<{ translator: Translator; message: string }>()
);

export const AddTranslatorError = createAction(
  '[Translator] Translator Added Error',
  props<{ message: string }>()
);

export const UpdateTranslator = createAction(
  '[Translator] Edit Translator in List',
  props<{ payload: addEditTranslatorPayload,id:number }>()
);

export const UpdateTranslatorSuccessfully = createAction(
  '[Translator] Translator Updated Successfully',
  props<{ translator: Translator; message: string }>()
);

export const UpdateTranslatorError = createAction(
  '[Translator] Translator Updated Error',
  props<{ message: string }>()
);

export const UpdateTranslatorStatus = createAction(
    '[Translator] Update Translator Status in List',
    props<{ payload: updateTranslatorStatusPayload }>()
  );
  
  export const UpdateTranslatorStatusSuccessfully = createAction(
    '[Translator] Translator Status Updated Successfully',
    props<{ translator: Translator[]; message: string }>()
  );
  
  export const UpdateTranslatorStatusError = createAction(
    '[Translator] Translator Status Updated Error',
    props<{ message: string }>()
  );

  export const MergeTranslator = createAction(
    '[Translator] Merge Translator',
    props<{ payload: mergeTranslatorPayload }>()
  );
  
  export const MergeTranslatorSuccessfully = createAction(
    '[Translator] Merge Translator Successfully',
    props<{ message: string }>()
  );
  
  export const MergeTranslatorError = createAction(
    '[Translator] Translator Status Updated Error',
    props<{ message: string }>()
  );

  export const DeleteTranslator= createAction(
    '[Translator] Delete Status from List',
    props<{ payload: deleteTranslatorPayload }>()
  );
  
  export const DeleteTranslatorSuccessfully = createAction(
    '[Translator] Translator Deleted Successfully',
    props<{ message: string }>()
  );
  
  export const DeleteTranslatorError = createAction(
    '[Translator] Translator Status Updated Error',
    props<{ message: string }>()
  );