<panjab-digi-lib-static-page-head [pageName]="'pdl-website.footerforms.titleText' | translate" pagePath="/forms">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <div class="d-flex justify-content-center text mb-3">Following is the list of available forms which you can download
      to obtain service from PDL.</div>
    <div class="table-responsive mt-5 ">
      <table class="static_table table">
        <thead class="text">
          <tr class="text">
            <th>Name</th>
            <th>Category</th>
            <th>Download</th>
          </tr>
        </thead>

        <tbody>
          <!-- row 1 -->
          <tr class="text">
            <td>Document Adoption</td>
            <td>Manuscripts</td>
            <td>
              <div class="centered-btn d-flex align-items-center align-items-center p-6-12">
                <a href="/assets/documents/Adopt a book form.doc"
                  class="btn pdl-btn f-12 d-flex align-items-center">download</a>
              </div>
            </td>
          </tr>

          <!-- row 2 -->
          <tr class="text">
            <td>Digitization Request</td>
            <td>Books</td>
            <td>
              <div class="centered-btn d-flex align-items-center align-items-center p-6-12">
                <a href="/assets/documents/Digitization Request - Books.dot"
                  class="btn pdl-btn f-12 d-flex align-items-center">download</a>
              </div>
            </td>
          </tr>

          <!-- row 3 -->
          <tr class="text">
            <td>Digitization Request</td>
            <td>Newspapers</td>
            <td>
              <div class="centered-btn d-flex align-items-center align-items-center p-6-12">
                <a href="/assets/documents/Digitization Request - Newspapers.dot"
                  class="btn pdl-btn f-12 d-flex align-items-center">download</a>
              </div>
            </td>
          </tr>

          <!-- row 4 -->
          <tr class="text">
            <td>Digitization Request</td>
            <td>Manuscripts</td>
            <td>
              <div class="centered-btn d-flex align-items-center align-items-center p-6-12">
                <a href="/assets/documents/Digitization Request - Manuscripts.dot"
                  class="btn pdl-btn f-12 d-flex align-items-center">download</a>
              </div>
            </td>
          </tr>
          <!-- row 5 -->
          <tr class="text">
            <td>Digitization Training Form</td>
            <td>Manuscripts</td>
            <td>
              <div class="centered-btn d-flex align-items-center align-items-center p-6-12">
                <a href="/assets/documents/Digitization Training Form.dot"
                  class="btn pdl-btn f-12 d-flex align-items-center">download</a>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>

    }
  </div>
</section>
