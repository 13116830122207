import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState, Update } from "@ngrx/entity";
import { DonorActions } from '../actions';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { Donor } from "shared/src/interfaces/web-management.interface";

export interface State extends EntityState<any> {
    payload: GenericListingPayload,
    totalRecords: number,
    isLoading?: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<Donor>({
    selectId: (e) => e.DonID
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(DonorActions.LoadDonorList, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(DonorActions.LoadDonorListSuccess, (state, action) =>
    ({
        ...adapter.setAll(action.donors, state),
        totalRecords: action.totalRecords,
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(DonorActions.LoadDonorListError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(DonorActions.AddDonation, (state) => ({
      ...state,
      isSaving: true,
      isLoading: false,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })),
    on(DonorActions.AddDonationSuccess, (state, action) =>
      ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action?.message
      })
    ),
    on(DonorActions.AddDonationError, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
      };
    }),
    on(DonorActions.EditDonation, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(DonorActions.EditDonationSuccess, (state, action) =>
    ({
        ...adapter.updateOne({ id: action.Donation.DonID, changes: action.Donation }, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(DonorActions.EditDonationError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(DonorActions.DeleteDonation, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(DonorActions.DeleteDonationSuccess, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(DonorActions.DeleteDonationError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();