import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IAlert } from '../../../../shared/src/interfaces/alert.interface'

const DURATION = 7000;

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alertSubject = new Subject<IAlert>();

  onAlert(): Observable<IAlert> {
      return this.alertSubject.asObservable();
  }

  success(message: string, autoClear: boolean=true) {
    this.alertSubject.next({ type: 'success', message });
    if(autoClear) {
      this.autoClear();
    }
  }

  error(message: string, autoClear: boolean=true) {
    this.alertSubject.next({ type: 'error', message });
    if(autoClear) {
      this.autoClear();
    }
  }

  warning(message: string, autoClear: boolean=true) {
    this.alertSubject.next({ type: 'warning', message });
    if(autoClear) {
      this.autoClear();
    }
  }

  clear() {
      this.alertSubject.next({ type: '', message: '' });
  }

  private autoClear() {
    setTimeout(() => {
      this.clear();
    }, DURATION);
  }
}
