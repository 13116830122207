<section class="collections section-padding">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="heading-style semibold text-center mb-lg-40">{{'pdl-website.collectionsSection.sectionTitle' | translate }}</h2>
            </div>
            <div class="col-12 hidden-after-767">
                <div class="collections-in-grid">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="collection-card">
                                <div class="block-img">
                                    <img src="assets/images/collection1.png">
                                </div>
                                <div class="block-text">
                                    <h5 class="medium-font"> {{'pdl-website.collectionsTitle1.titleText' | translate }}</h5>
                                    <span class="number-of-docs">
                                        <img src="assets/images/icons/folder-icon.svg"> {{'pdl-website.numberofDocs.docsText' | translate }}
                                    </span>
                                    <p class="description-text">
                                        {{'pdl-website.collectionsDescription.descriptionText' | translate }}
                                    </p>
                                    <div class="line-btn-block">
                                        <a href="#" class="btn pdl-btn line-btn medium-font">{{'pdl-website.collectionsButton.buttonText' | translate }}</a>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="collection-card">
                                <div class="block-img">
                                    <img src="assets/images/collection1.png">
                                </div>
                                <div class="block-text">
                                    <h5 class="medium-font"> {{'pdl-website.collectionsTitle2.titleText' | translate }}</h5>
                                    <span class="number-of-docs">
                                        <img src="assets/images/icons/folder-icon.svg"> {{'pdl-website.numberofDocs.docsText' | translate }}
                                    </span>
                                    <p class="description-text">
                                        {{'pdl-website.collectionsDescription.descriptionText' | translate }}
                                    </p>
                                    <div class="line-btn-block">
                                        <a href="#" class="btn pdl-btn line-btn medium-font">{{'pdl-website.collectionsButton.buttonText' | translate }}</a>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="collection-card">
                                <div class="block-img">
                                    <img src="assets/images/collection1.png">
                                </div>
                                <div class="block-text">
                                    <h5 class="medium-font"> {{'pdl-website.collectionsTitle3.titleText' | translate }}</h5>
                                    <span class="number-of-docs">
                                        <img src="assets/images/icons/folder-icon.svg"> {{'pdl-website.numberofDocs.docsText' | translate }}
                                    </span>
                                    <p class="description-text">
                                        {{'pdl-website.collectionsDescription.descriptionText' | translate }}
                                    </p>
                                    <div class="line-btn-block">
                                        <a href="#" class="btn pdl-btn line-btn medium-font">{{'pdl-website.collectionsButton.buttonText' | translate }}</a>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="col-12 visible-below-767">
                <div class="collections-in-slider">
                    <ngx-slick-carousel class="carousel" 
                                        #slickModal="slick-carousel" 
                                        [config]="slideConfig" 
                                        (init)="slickInit($event)">
                        @for (slide of slides; track slide.img) {
                            <div ngxSlickItem class="slide collection-slide pe-4">
                                <div class="collection-card">

                                    <div class="block-img">
                                        <img class="collection-img" src="{{ slide.img }}" alt="">
                                    </div>
                                    <div class="block-text">
                                        <h5 class="medium-font"> {{slide.heading}}</h5>
                                        <span class="number-of-docs">
                                            <img src="assets/images/icons/folder-icon.svg"> {{slide.number}}
                                        </span>
                                        <p class="description-text">
                                            {{slide.description}}
                                        </p>
                                        <div class="line-btn-block">
                                            <a href="#" class="btn pdl-btn line-btn medium-font">{{slide.button}}</a>
                                        </div>
                                    </div>




                                </div> 
                            </div>
                        }
                    </ngx-slick-carousel>
                </div>
            </div>


            





            <div class="col-12">
                <div class="centered-btn d-flex align-items-center justify-content-center">
                    <a href="#" class="btn pdl-btn">{{'pdl-website.collectionsmainButton.buttonText' | translate }}</a>
                </div>
            </div>
            
        </div>
    </div>
</section>