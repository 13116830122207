import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromWebTrendingKeywords from "../../reducers/website/web-trending-keywords.reducer";

export const selectKeywordsState =
  createFeatureSelector<fromWebTrendingKeywords.State>('webKeywords');

export const getKeywords = createSelector(
    selectKeywordsState,
    fromWebTrendingKeywords.selectAll
);

export const isLoading = createSelector(
  selectKeywordsState,
  (state) => state.isLoading
);

export const isSaving = createSelector(
    selectKeywordsState,
    (state) => [state.isSaving, state.isError, state.errorMessage],
);

export const getTrendingKeywords = createSelector(
    selectKeywordsState,
    (state) => state.trendingKeywords
);

export const getHistoricalTrending = createSelector(
    selectKeywordsState,
    (state) => state.historicKeywords
);