import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromSubject from "../reducers/subject.reducer";

export const selectSubjectState =
  createFeatureSelector<fromSubject.State>('subject');

export const getSubject = createSelector(
    selectSubjectState,
    fromSubject.selectAll
);

export const getParentSubjects = createSelector(
    selectSubjectState,
    (state) => state.parentSubjects
);

export const getTotalRecords = createSelector(
  selectSubjectState,
  (state) => state.totalRecords
);

export const getSubjectByID = (props: {id: string}) => createSelector(
  selectSubjectState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectSubjectState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectSubjectState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectSubjectState,
  (state) => state.isLoading,
);