import { createSelector } from '@ngrx/store';

const selectLogin = (state: any) => state.auth;

export const selectToken = createSelector(
    selectLogin,
    (state) => state.token
);

export const selectError = createSelector(
    selectLogin,
    (state) => state.error
);

export const selectIsLoading = createSelector(
    selectLogin,
    (state) => state.isLoading
);

export const selectWebToken = createSelector(
    selectLogin,
    (state) => state.webToken
);

export const selectWebUser = createSelector(
    selectLogin,
    (state) => state.user
);