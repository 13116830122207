import { createReducer, on } from '@ngrx/store';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {CategoryActions} from "../actions/index"
import { CategoryResponse, getCategoriesPayload } from 'shared/src/interfaces/category.interface';

export interface State extends EntityState<any> {
  homeCategories: CategoryResponse[],
  payload: getCategoriesPayload,
  totalRecords: number,
  viewType: string,
  preCodeExists: boolean,
  isLoading: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
}

export const adapter = createEntityAdapter<CategoryResponse>({
  selectId: (e) => e.categoryID
});

export const initialState = adapter.getInitialState( {
  homeCategories: [] as CategoryResponse[],
  viewType: 'AllCategories',
  totalRecords: 0,
  preCodeExists: false,
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  successMessage: ''
});

export const reducer = createReducer(
  initialState,
  on(CategoryActions.LoadCategories,(state, action) =>
    ({
      ...state,
      payload: action.payload,
      isLoading: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(CategoryActions.LoadCategoriesSuccess,(state, action) =>
    ({
      ...adapter.setAll(action.categories, state),
      homeCategories: action.categories.filter((category) => category.ShowOnHome).sort((cat1,cat2) => cat1.HomeCategoriesOrder-cat2.HomeCategoriesOrder),
      totalRecords: action.totalRecords,
      isLoading: false,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(CategoryActions.LoadCategoriesError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
      successMessage: '',
    })
  ),
  on(CategoryActions.ToggleCategoriesViewType,(state, action) =>
    ({
      ...state,
      viewType: action.viewType,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(CategoryActions.UpdateCategoriesHomeStatus,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(CategoryActions.UpdateCategoriesHomeStatusSuccess,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action.message
    })
  ),
  on(CategoryActions.UpdateCategoriesHomeStatusError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
  on(CategoryActions.ReorderCategories,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(CategoryActions.ReorderCategoriesSuccess,(state, action) =>
     ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
      })
  ),
  on(CategoryActions.ReorderCategoriesError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
  on(CategoryActions.AddCategory,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(CategoryActions.AddCategorySuccess,(state, action) =>
     ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
      })
  ),
  on(CategoryActions.AddCategoryError,(state, action) => 
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
  on(CategoryActions.EditCategory,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(CategoryActions.EditCategorySuccess,(state, action) =>
     ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
      })
  ),
  on(CategoryActions.EditCategoryError,(state, action) => 
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
  on(CategoryActions.UpdateCategoriesStatus,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(CategoryActions.UpdateCategoriesStatusSuccess,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action.message
    })
  ),
  on(CategoryActions.UpdateCategoriesStatusError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
  on(CategoryActions.CheckPreCode,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(CategoryActions.CheckPreCodeSuccess,(state, action) =>
    ({
      ...state,
      preCodeExists: action.preCodeExists,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: ''
    })
  ),
  on(CategoryActions.CheckPreCodeError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
    })
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();