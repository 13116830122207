/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState, Update } from "@ngrx/entity";
import { DepartmentActions } from '../actions';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { Department } from "shared/src/interfaces/master-form.interface";

export interface State extends EntityState<any> {
    payload: GenericListingPayload,
    totalRecords: number,
    isLoading?: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<Department>({
    selectId: (e) => e.DeptID
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(DepartmentActions.LoadDepartment, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(DepartmentActions.LoadDepartmentSuccess, (state, action) =>
    ({
        ...adapter.setAll(action.departments, state),
        totalRecords: action.totalRecords,
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(DepartmentActions.LoadDepartmentError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(DepartmentActions.UpdateDepartmentStatus, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(DepartmentActions.UpdateDepartmentStatusSuccess, (state, action) =>
    ({
        ...adapter.updateMany(action.departmentUpdates, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(DepartmentActions.UpdateDepartmentStatusError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),

    on(DepartmentActions.AddDepartment, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(DepartmentActions.AddDepartmentSuccess, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(DepartmentActions.AddDepartmentError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(DepartmentActions.EditDepartment, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(DepartmentActions.EditDepartmentSuccess, (state, action) =>
    ({
        ...adapter.updateOne({ id: action.department.DeptID, changes: action.department }, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(DepartmentActions.EditDepartmentError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(DepartmentActions.DeleteDepartment, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(DepartmentActions.DeleteDepartmentSuccess, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(DepartmentActions.DeleteDepartmentError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();