<!-- deactivate or delete account modal-->
<ng-template #deleteDeactivateModal let-modal>
    <form [formGroup]="deleteDeactivateForm" class="deleteDeactivateForm mt-5">
      <!-- show alert -->
      <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
      <!-- /show alert -->
      <hr />
      <div class="text-start my-4 form-check">
        <input type="radio" formControlName="deldeactivateAcc" id="deactivateAcc" class="form-check-input" value="deactivateAcc" aria-label="Deactivate Account"> 
        <label for="deactivateAcc" class="form-check-label ms-2"> {{'pdl-website.forms.labels.deleteDeactivateForm.deactivateAcc' | translate }}</label>
        <div class="fw-bold mt-1 ms-2">{{'pdl-website.forms.labels.deleteDeactivateForm.deactivateAccTitle' | translate }}</div>
        <div class="ms-2">{{'pdl-website.forms.labels.deleteDeactivateForm.deactivateAccText' | translate }}</div>
      </div>
      <hr />
      <div class="text-start my-4 form-check">
        <input type="radio" formControlName="deldeactivateAcc" id="deleteAcc" class="form-check-input" value="deleteAcc" aria-label="Delete Account"> 
        <label for="deleteAcc" class="form-check-label ms-2"> {{'pdl-website.forms.labels.deleteDeactivateForm.deleteAcc' | translate }}</label>
        <div class="fw-bold mt-1 ms-2">{{'pdl-website.forms.labels.deleteDeactivateForm.deleteAccTitle' | translate }}</div>
        <div class="ms-2">{{'pdl-website.forms.labels.deleteDeactivateForm.deleteAccText' | translate }}</div>
        <!-- delete all my record confirmation -->
        @if(deleteDeactivateForm.controls['deldeactivateAcc'].value === 'deleteAcc') {
          <div class="ms-2 mt-2">{{'pdl-website.forms.labels.deleteDeactivateForm.delDataConfirmText' | translate}}</div>
          <div class="text-start my-3 ps-2 form-check d-flex data__confirmation">
            <div class="d-inline-flex me-4">
              <input type="radio" formControlName="delDataConfirm" id="keepWithMyName" class="form-check-input" value="1" aria-label="Delete Data Confirmation"> 
              <label for="keepWithMyName" class="form-check-label ms-2 align-self-center"> {{'pdl-website.forms.labels.deleteDeactivateForm.keepWithMyName' | translate }}</label>
            </div>
            <div class="d-inline-flex">
              <input type="radio" formControlName="delDataConfirm" id="keepAnonymous" class="form-check-input" value="2" aria-label="Delete Data Confirmation"> 
            <label for="keepAnonymous" class="form-check-label ms-2 align-self-center"> {{'pdl-website.forms.labels.deleteDeactivateForm.keepAnonymous' | translate }}</label>
            </div>
          </div>
        }
      </div>
    </form>
</ng-template>
<!-- /deactivate or delete account modal -->
