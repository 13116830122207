<panjab-digi-lib-static-page-head pageName="Exhbitions" pagePath="/exhibitions"></panjab-digi-lib-static-page-head>
<div class="top_section container">
    <div class="img_div">
        <div class="block-img">
            <img src="assets/images/exhibition1.jpg">
        </div>
    </div>
    <div class="info_div">
        <div class="exhibition-card">
            <div class="block-text ps-4 pe-4">
                <h5 class="medium-font"> {{'pdl-website.exhibitionTitle1.titleText' | translate }}
                </h5>
                <div class="small-tags">
                    <span class="small-tag">
                        <img src="assets/images/icons/calendar-ico.svg">
                        {{'pdl-website.exhibitionDate1.dateText' | translate }}
                    </span>
                    <span class="small-tag">
                        <img src="assets/images/icons/location-ico.svg">
                        {{'pdl-website.exhibitionLocation1.locationText' | translate }}
                    </span>
                </div>
                <p class="description-text">
                    {{'pdl-website.exhibitionDescription1.descriptionText' | translate }}
                </p>
                <div class="d-flex dates_and_place justify-content-between">
                    <div class="item">
                        <span class="heading">
                            Dates
                        </span>
                        <div class="item-info">
                            <span>07 July 2023 - 08 July 2023</span>
                            <span>10:00 am - 6:00 pm</span>
                        </div>
                    </div>
                    <div class="item">
                        <span class="heading">
                            Dates
                        </span>
                        <div class="item-info">
                            <span>07 July 2023 - 08 July 2023</span>
                            <span>10:00 am - 6:00 pm</span>
                        </div>
                    </div>
                </div>
                <div class="pdl-btns-row d-flex align-items-center justify-content-start flex-wrap">
                    <a href="#"
                        class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start">{{'pdl-website.exhibitionDirection.buttonText'
                        | translate }} <img src="assets/images/icons/directions-ico.svg"></a>
                    <a href="#"
                        class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start">
                        {{'pdl-website.exhibitionVisit.buttonText' | translate }} <img
                            src="assets/images/icons/visit-ico.svg"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-center my-2 desktopOnly">
    <img src="assets/images/icons/divider.svg" alt="">
</div>
<section class="exhibitions section-padding">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="heading-style semibold text-center mb-4">Upcoming
                    {{'pdl-website.exhibitionsSection.sectionTitle' | translate }}</h2>
                <p class="title-description text-center mb-lg-40 pdl-grey">
                    {{'pdl-website.exhibitionsDescription.descriptionText' | translate }}
                </p>
            </div>
            <div class="col-12">
                <div class="exhibitions-in-grid">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
                            <div class="exhibition-card">
                                <div class="block-img">
                                    <img src="assets/images/exhibition1.jpg">
                                </div>
                                <div class="block-text ps-4 pe-4">
                                    <h5 class="medium-font"> {{'pdl-website.exhibitionTitle1.titleText' | translate }}
                                    </h5>
                                    <div class="small-tags">
                                        <span class="small-tag">
                                            <img src="assets/images/icons/calendar-ico.svg">
                                            {{'pdl-website.exhibitionDate1.dateText' | translate }}
                                        </span>
                                        <span class="small-tag">
                                            <img src="assets/images/icons/location-ico.svg">
                                            {{'pdl-website.exhibitionLocation1.locationText' | translate }}
                                        </span>
                                    </div>
                                    <p class="description-text">
                                        {{'pdl-website.exhibitionDescription1.descriptionText' | translate }}
                                    </p>
                                    <div class="pdl-btns-row d-flex align-items-center justify-content-start flex-wrap">
                                        <a href="#"
                                            class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start">{{'pdl-website.exhibitionDirection.buttonText'
                                            | translate }} <img src="assets/images/icons/directions-ico.svg"></a>
                                        <a href="#"
                                            class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start">
                                            {{'pdl-website.exhibitionVisit.buttonText' | translate }} <img
                                                src="assets/images/icons/visit-ico.svg"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
                            <div class="exhibition-card">
                                <div class="block-img">
                                    <img src="assets/images/exhibition2.jpg">
                                </div>
                                <div class="block-text ps-4 pe-4">
                                    <h5 class="medium-font"> {{'pdl-website.exhibitionTitle2.titleText' | translate }}
                                    </h5>
                                    <div class="small-tags">
                                        <span class="small-tag">
                                            <img src="assets/images/icons/calendar-ico.svg">
                                            {{'pdl-website.exhibitionDate2.dateText' | translate }}
                                        </span>
                                        <span class="small-tag">
                                            <img src="assets/images/icons/location-ico.svg">
                                            {{'pdl-website.exhibitionLocation2.locationText' | translate }}
                                        </span>
                                    </div>
                                    <p class="description-text">
                                        {{'pdl-website.exhibitionDescription2.descriptionText' | translate }}
                                    </p>
                                    <div class="pdl-btns-row d-flex align-items-center justify-content-start flex-wrap">
                                        <a href="#"
                                            class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start">
                                            {{'pdl-website.exhibitionDirection.buttonText' | translate }} <img
                                                src="assets/images/icons/directions-ico.svg"></a>
                                        <a href="#"
                                            class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start">
                                            {{'pdl-website.exhibitionVisit.buttonText' | translate }} <img
                                                src="assets/images/icons/visit-ico.svg"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="centered-btn d-flex align-items-center justify-content-center">
                    <a href="#" class="btn pdl-btn">{{'pdl-website.exhibitionsmainButton.buttonText' | translate }}</a>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- past exhibtions -->

<section class="exhibitions section-padding">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="heading-style semibold text-center mb-4">Past
                    {{'pdl-website.exhibitionsSection.sectionTitle' | translate }}</h2>
                <p class="title-description text-center mb-lg-40 pdl-grey">
                    Digital collections that will be made available free of cost
                </p>
            </div>
            <div class="col-12">
                <div class="d-flex no-scrollbar past_item_wrapper">
                    <!-- // item  -->
                    <div class="past_item cursor-pointer">
                        <img class="img" src="/assets/images/current-projects.png" alt="">
                        <div class="past_item_desc d-flex flex-column">
                            <div class="d-flex mt-4 date align-items-center">
                                <img src="/assets/images/icons/calendar-ico.svg" alt="">
                                <span class="day px-1">saturday . 29 january 2022</span>
                            </div>
                            <p class="semibold">
                                Colonial and later state governments
                            </p>
                            <p class="desc">The desire to lend a hand to those less fortunate is one of the most
                                persistent
                                motivations leading Americans to join clubs and form associations. From the late
                                eighteenth
                                century onward.</p>
                        </div>
                    </div>
                    <!-- // item  -->
                    <div class="past_item cursor-pointer">
                        <img class="img" src="/assets/images/current-projects.png" alt="">
                        <div class="past_item_desc d-flex flex-column">
                            <div class="d-flex mt-4 date align-items-center">
                                <img src="/assets/images/icons/calendar-ico.svg" alt="">
                                <span class="day px-1">saturday . 29 january 2022</span>
                            </div>
                            <p class="semibold">
                                Colonial and later state governments
                            </p>
                            <p class="desc">The desire to lend a hand to those less fortunate is one of the most
                                persistent
                                motivations leading Americans to join clubs and form associations. From the late
                                eighteenth
                                century onward.</p>
                        </div>
                    </div>
                    <!-- // item  -->
                    <div class="past_item cursor-pointer">
                        <img class="img" src="/assets/images/current-projects.png" alt="">
                        <div class="past_item_desc d-flex flex-column">
                            <div class="d-flex mt-4 date align-items-center">
                                <img src="/assets/images/icons/calendar-ico.svg" alt="">
                                <span class="day px-1">saturday . 29 january 2022</span>
                            </div>
                            <p class="semibold">
                                Colonial and later state governments
                            </p>
                            <p class="desc">The desire to lend a hand to those less fortunate is one of the most
                                persistent
                                motivations leading Americans to join clubs and form associations. From the late
                                eighteenth
                                century onward.</p>
                        </div>
                    </div>
                    <!-- // item  -->
                    <div class="past_item cursor-pointer">
                        <img class="img" src="/assets/images/current-projects.png" alt="">
                        <div class="past_item_desc d-flex flex-column">
                            <div class="d-flex mt-4 date align-items-center">
                                <img src="/assets/images/icons/calendar-ico.svg" alt="">
                                <span class="day px-1">saturday . 29 january 2022</span>
                            </div>
                            <p class="semibold">
                                Colonial and later state governments
                            </p>
                            <p class="desc">The desire to lend a hand to those less fortunate is one of the most
                                persistent
                                motivations leading Americans to join clubs and form associations. From the late
                                eighteenth
                                century onward.</p>
                        </div>
                    </div>
                    <!-- // item  -->
                    <div class="past_item cursor-pointer">
                        <img class="img" src="/assets/images/current-projects.png" alt="">
                        <div class="past_item_desc d-flex flex-column">
                            <div class="d-flex mt-4 date align-items-center">
                                <img src="/assets/images/icons/calendar-ico.svg" alt="">
                                <span class="day px-1">saturday . 29 january 2022</span>
                            </div>
                            <p class="semibold">
                                Colonial and later state governments
                            </p>
                            <p class="desc">The desire to lend a hand to those less fortunate is one of the most
                                persistent
                                motivations leading Americans to join clubs and form associations. From the late
                                eighteenth
                                century onward.</p>
                        </div>
                    </div>
                    <!-- // item  -->
                    <div class="past_item cursor-pointer">
                        <img class="img" src="/assets/images/current-projects.png" alt="">
                        <div class="past_item_desc d-flex flex-column">
                            <div class="d-flex mt-4 date align-items-center">
                                <img src="/assets/images/icons/calendar-ico.svg" alt="">
                                <span class="day px-1">saturday . 29 january 2022</span>
                            </div>
                            <p class="semibold">
                                Colonial and later state governments
                            </p>
                            <p class="desc">The desire to lend a hand to those less fortunate is one of the most
                                persistent
                                motivations leading Americans to join clubs and form associations. From the late
                                eighteenth
                                century onward.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="centered-btn d-flex align-items-center justify-content-center">
                <a href="#" class="btn pdl-btn">{{'pdl-website.exhibitionsmainButton.buttonText' | translate }}</a>
            </div>
        </div>
    </div>
</section>
<div class="d-flex justify-content-center mb-2 mt-2 desktopOnly">
    <img src="assets/images/icons/divider.svg" alt="">
</div>

<div class="d-flex justify-content-around gap-24 container flex-wrap mb-5 mt-5">
    <div class="contact_item">
        <img src="assets/images/icons/header-menu/library-icon.png" />
        <span class="heading">Ask for an Exhibition</span>
        <p class="text">Inspire change: Volunteer your time and be a catalyst for transformation.</p>
    </div>
    <div class="contact_item">
        <img src="assets/images/icons/phone.svg" />
        <span class="heading">Contact Us</span>
        <p class="text">Gift the power of words: Donate a book and empower someone.</p>
    </div>
    <div class="contact_item">
        <img src="assets/images/icons/share.svg" />
        <span class="heading">Share your experience</span>
        <p class="text">Gift the power of words: Donate a book and empower someone.</p>
    </div>
</div>
