<div class="d-flex flex-wrap mb-5" [ngClass]="">
  @if (listType === 'card') {
  <div class="cardWrapper mt-3 no-scrollbar">
    @for (item of data?.data; track item) {
    <div class="card">
      <div class="image">
        <img class="thumbnail" [src]="item.thumbnail" [alt]="item.id" />
        @if (showLikeAndMoreOptions) {
        <div class="likeandOptionWrapper p-absolute d-flex justify-content-between">
          <a href="/user/my-library/assign-literature" class="cursor-pointer"> <img src="/assets/images/icons/drag-button.svg" aria-label="options" /></a>
          <span class="cursor-pointer"> <img src="/assets/images/icons/heart-white.svg" aria-label="dislike" /></span>
        </div>
        }
        <div class="d-flex justify-content-between p-absolute align-items-end">
          <span>{{item.type}}</span>
          <div class="stars">
            @for (star of getRating(item.rating); track star) {
            @if (star === 1) {
            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
            }
            @if (star === 0.5) {
            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
            }
            @if (star === 0) {
            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
            }
            }
          </div>
        </div>
      </div>
      <div class="meta-data mt-2 ">
        <div class="name">{{item.name}}</div>
        <div class="d-flex justify-content-between mt-3 script-pages"><span>{{item.script}}</span>
          <span>{{item.pages}} PAGES</span>
        </div>
      </div>
    </div>
    }
  </div>
  }
</div>

@if (listType === 'list') {
<div class="listWrapper no-scrollbar">
  @for (item of data.data; track item; let i = $index;) {
  <div class="list" [ngClass]="{'odd' : (i+1) % 2}">
    <div class="image">
      <img class="thumbnail" [src]="item.thumbnail" [alt]="item.id" />
    </div>
    <div class="meta-data">
      <div class="d-flex flex-wrap col-gap-12">
        <div class="meta-btn">
          <span>
            {{item.author}}
          </span>
          <span>
            {{item.subject}}
          </span>
          <span>
            {{item.pages}} Pages
          </span>
        </div>
      </div>
      <div class="stars">
        @for (star of getRating(item.rating); track star) {
        @if (star === 1) {
        <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
        }
        @if (star === 0.5) {
        <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
        }
        @if (star === 0) {
        <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
        }
        }
      </div>
      <div class="name mt-3">{{item.name}}</div>
      <div class="desc">{{item.description}}...</div>
    </div>
  </div>
  }

</div>
}
