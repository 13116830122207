/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { PermissionActions } from '../actions/index';
import { PermissionService } from 'shared/src/services/staff/permission/permission.service';

@Injectable()
export class PermissionEffects {
  constructor(
    private actions$: Actions,
    private service: PermissionService
  ) {}

  ///////////////// check permissions of user is logged in //////////////////
  LoadPermissions$ = createEffect(() =>
    this.actions$.pipe(
        ofType(PermissionActions.UserPermission),
        exhaustMap(() => {
          return this.service.loadPermissions().pipe(
            map((response) => {
              return PermissionActions.UserPermissionSuccess({
                permissions: response?.body,
              });
            }),
            catchError((error) => {
              return of(
                PermissionActions.UserPermissionError({
                  message: error?.error?.error?.message,
                })
              );
            })
          );
        })
      )
  );
}
