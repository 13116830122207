import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState, Update } from "@ngrx/entity";
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { AssignedTask } from 'shared/src/interfaces/staff/document.interface';
import { AssignedTaskActions } from 'shared/src/store/actions';

export interface State extends EntityState<any> {
    payload: GenericListingPayload,
    totalRecords: number,
    isLoading: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<AssignedTask>({
    selectId: (e) => e.PSID
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: true,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(AssignedTaskActions.LoadAssignedTaskList, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(AssignedTaskActions.LoadAssignedTaskListSuccess, (state, action) =>
    ({
        ...adapter.setAll(action.taskList, state),
        totalRecords: action.totalRecords,
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(AssignedTaskActions.LoadAssignedTaskListError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(AssignedTaskActions.SubmitAssignedTask, (state, action) =>
    ({
        ...state,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(AssignedTaskActions.SubmitAssignedTaskSuccess, (state, action) =>
    ({
        ...adapter.updateOne({ id: action.task.PSID, changes: action.task }, state),
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(AssignedTaskActions.SubmitAssignedTaskError, (state, action) =>
    ({
        ...state,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();