/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { Permission } from 'shared/src/interfaces/permission.interface';
export const UserPermission = createAction(
  '[Permission] Load User Permission',
);

export const UserPermissionsLoaded = createAction(
  '[Permissions] Permissions Loaded'
);

export const UserPermissionSuccess = createAction(
  '[Process] Load User Successfully',
  props<{permissions: Permission[]}>()
);

export const UserPermissionError = createAction(
  '[Process] Load User Error',
  props<{message: string}>()
)