import { createAction, props } from '@ngrx/store';

export const LoadAllTrendingKeywords = createAction(
    '[All Trending Keywords] Load All Trending Keywords List',
    props<{payload: {pageSize:number,pageNumber: number}}>(),
);

export const LoadAllTrendingKeywordsSuccessfully = createAction(
    '[All Trending Keywords] Load All Trending Keywords Successfully',
    props<{ trendingList: [],totalRecords: number }>()
);

export const LoadAllTrendingKeywordsError = createAction(
    '[All Trending Keywords] Load All Trending Keywords Error',
    props<{ message: string }>()
);

export const UpdateKeywordShowOnHomePageStatus = createAction(
    '[Trending] Show/Hide keyword on homepage',
    props<{payload: {id:Array<number>,status: boolean}}>()
);

export const UpdateKeywordShowOnHomePageSuccessfully = createAction(
    '[Trending] Update keyword Status Success',
    props<{ trendingItem: any,message: string }>()
);

export const UpdateKeywordShowOnHomePageStatusError = createAction(
    '[Trending] Update keyword Status Error',
    props<{ message: string }>()
);

export const GetKeywordDetail = createAction(
    '[Keyword Detail] Get Keyword Detail',
    props<{payload: {id:number}}>()
);

export const GetKeywordDetailSuccessfully = createAction(
    '[Keyword Detail] Get Keyword Detail Success',
    props<{ keywordDetail: any,message: string }>()
);

export const GetKeywordDetailError = createAction(
    '[Keyword Detail] Get Keyword Detail Error',
    props<{ message: string }>()
);

export const MergeKeywords = createAction(
    '[Trending] Merge Keywords',
    props<{payload: {data:Array<any>}}>()
);

export const MergeKeywordsSuccessfully = createAction(
    '[Trending] Merge Keywords Success',
    props<{ keyword: any,message: string }>()
);

export const MergeKeywordsStatusError = createAction(
    '[Trending] Merge Keywords Status Error',
    props<{ message: string }>()
);

