import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { LocalStorageService } from "shared/src/services/common/local-storage.service";
import { AuthenticationService } from "shared/src/services/admin/authentication/authentication.service";

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

  const router: Router = inject(Router);
  const localStorage = inject(LocalStorageService);
  const authService = inject(AuthenticationService);
  const tokenExpired = authService.isTokenExpired('webToken');
  const authenticatedRoutes = ['/profile','/adopted-books'];
  const HOME_PAGE = '/';
  const LOGIN_PAGE = '/login-user';
  if(!tokenExpired) {
    const token = localStorage.get('webToken');
    const negateUrls = ['/login-user', '/register-user', '/verify-user', '/recover-password', '/forgot-username', '/reset-password'];
    if (token && token.length) {
      if (negateUrls.includes(state.url)) {
        router.navigateByUrl(HOME_PAGE);
        return false;
      }
      return true;
    }
  } else if(tokenExpired) {
    const routeFound = authenticatedRoutes.find(url => state.url.indexOf(url) !== -1);
    if(routeFound) {
      router.navigateByUrl(LOGIN_PAGE);
      return false;
    }
    return true;
  }
  router.navigate(['/login-user']);
  return false;
}