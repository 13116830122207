<div
  class="d-flex flex-column flex-shrink-0 px-2 dashboard__sidebar"
  [class]="isExpanded ? 'is__expanded' : 'is__collapsed'"
>
  <div class="d-flex flex-row px-2 py-4 align-items-center">
    <a
      [routerLink]="'/'"
      href="javascript:void(0)"
      class="align-items-center mb-md-0 text-decoration-none logo_image"
    >
      <i class="fa-solid fa-square"></i>
      @if (isExpanded) { Panjab Digital Library } @else { PDL }
    </a>
    <button
      class="btn toggle__expand"
      (click)="toggleExpand()"
      [class.is__flipped]="isExpanded"
    >
      @if (isExpanded) {
      <img src="assets/images/icons/toggle-left-icon.svg" alt="" />
      } @else {
      <img src="assets/images/icons/toggle-right.svg" alt="" />
      }
    </button>
    <hr />
  </div>
  <div class="sidebar__menu__wrapper">
    @for (parentMenu of navMenu; let i = $index; track parentMenu.name) {
    @if(parentMenu && parentMenu.children.length > 0) {
    <div class="accordion" id="accordionList{{ i }}">
      <h4 class="accordion__category">{{ parentMenu.name | translate }}</h4>
      @if(parentMenu.children.length > 0) { @for (firstChildMenu of
      parentMenu.children;let j = $index; track firstChildMenu.name) {
      @if(firstChildMenu.children.length === 0) {
      <div class="accordion-item first__child">
        <h2 class="accordion-header">
          <button
            class="accordion-button"
            [class.collapsed]="!isMenuActive(firstChildMenu.path, false)"
            [routerLink]="firstChildMenu.url"
            type="button"
            (click)="setMenuTitle(firstChildMenu.name)"
          >
            <img
              class="icon-left icon-inactive"
              [src]="firstChildMenu.icon[0]"
              alt="Dashboard"
            /><img
              class="icon-left icon-active"
              [src]="firstChildMenu.icon[1]"
              alt="icon"
            />
            <span>{{ firstChildMenu.name | translate }}</span>
          </button>
        </h2>
      </div>
      } @else {
      <div class="accordion-item">
        <h2 class="accordion-header" id="heading{{ i }}{{ j }}">
          <button
            class="accordion-button"
            [class.collapsed]="!isMenuActive(firstChildMenu.path, false)"
            [routerLink]="firstChildMenu.url"
            (click)="setMenuTitle(firstChildMenu.name)"
            type="button"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse' + i + j"
            [attr.aria-expanded]="
              !isMenuActive(firstChildMenu.path, false) ? false : true
            "
            [attr.aria-controls]="'collapse' + i + j"
          >
            <img
              class="icon-left icon-inactive"
              [src]="firstChildMenu.icon[0]"
              alt="Dashboard"
            /><img
              class="icon-left icon-active"
              [src]="firstChildMenu.icon[1]"
              alt="icon"
            />
            <span>{{ firstChildMenu.name | translate }}</span>
          </button>
        </h2>
        @if (isExpanded) {
        <div
          id="collapse{{ i }}{{ j }}"
          class="accordion-collapse collapse"
          [class.show]="isMenuActive(firstChildMenu.path, false)"
          [attr.aria-labelledby]="'heading' + i + j"
          [attr.data-bs-parent]="'#accordionList' + i"
        >
          <div class="accordion-body has__child">
            <div
              class="accordion nested__accordion pdl"
              id="sub-accordionList{{ j }}"
            >
              @for (secondChildMenu of firstChildMenu.children;let k = $index;
              track secondChildMenu.name) { @if(secondChildMenu.children.length
              === 0) {
              <div class="accordion-item first__child">
                <h2 class="accordion-header">
                  <button
                    [class.active]="isMenuActive(secondChildMenu.path, false)"
                    [routerLink]="secondChildMenu.url"
                    class="accordion-button"
                    type="button"
                  >
                    <span>{{ secondChildMenu.name | translate }}</span>
                  </button>
                </h2>
              </div>
              } @else {
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  id="sub-heading{{ i }}{{ j }}{{ k }}"
                >
                  <button
                    [class.active]="isMenuActive(secondChildMenu.path, false)"
                    [class.collapsed]="
                      !isMenuActive(secondChildMenu.path, false)
                    "
                    [routerLink]="secondChildMenu.url"
                    class="accordion-button has__child"
                    type="button"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#sub-collapse' + i + j + k"
                    [attr.aria-expanded]="
                      !isMenuActive(secondChildMenu.path, false) ? false : true
                    "
                    [attr.aria-controls]="'#sub-collapse' + i + j + k"
                  >
                    <span>{{ secondChildMenu.name | translate }}</span>
                  </button>
                </h2>
                <div
                  id="sub-collapse{{ i }}{{ j }}{{ k }}"
                  class="accordion-collapse collapse"
                  [class.show]="isMenuActive(secondChildMenu.path, false)"
                  [attr.aria-labelledby]="'sub-heading' + i + j + k"
                  [attr.data-bs-parent]="'#sub-accordionList' + j"
                >
                  <div class="accordion-body">
                    <div class="accordion nested__accordion reports">
                      @for (thirdChildMenu of secondChildMenu.children;let l =
                      $index; track thirdChildMenu.name) { @if
                      (thirdChildMenu.children.length === 0) {
                      <div class="accordion-item first__child">
                        <h2 class="accordion-header">
                          <button
                            [class.active]="
                              isMenuActive(thirdChildMenu.path, false)
                            "
                            [routerLink]="thirdChildMenu.url"
                            class="accordion-button"
                            type="button"
                          >
                            <span>{{ thirdChildMenu.name | translate }}</span>
                          </button>
                        </h2>
                      </div>
                      } }
                    </div>
                  </div>
                </div>
              </div>
              } }
            </div>
          </div>
        </div>
        }
      </div>
      } } }
    </div>
    } }
  </div>
</div>
