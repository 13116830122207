import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'panjab-digi-lib-adopt-book-form',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './adopt-book-form.component.html',
  styleUrl: './adopt-book-form.component.scss',
})
export class AdoptBookFormComponent {
  mobileFullCartView = true;
  dummyArr = [1,2,3,4,5,6,7,8,9]
  toggleCartView = () => (this.mobileFullCartView = !this.mobileFullCartView);
}
