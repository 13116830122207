import { ElementRef, Input, OnInit } from "@angular/core";
import { Directive } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[highlightText]',
  standalone: true
})
export class HighlightTextDirective implements OnInit {
  @Input('highlightText') phrase = '';
  constructor(private el: ElementRef) { }

  ngOnInit() {
    setTimeout(() => {
      let html = this.el.nativeElement.innerHTML;
  
      const re = new RegExp(this.phrase, 'ig');
      html = html.replace(re, `<span class="highlight">${this.phrase}</span>`);
  
      this.el.nativeElement.innerHTML = html;
    }, 10);
  }
}