import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromVolunteer from "../reducers/admin-volunteer.reducer";

export const selectVolunteerState =
  createFeatureSelector<fromVolunteer.State>('volunteerList');

export const getVolunteers = createSelector(
    selectVolunteerState,
    fromVolunteer.selectAll
);

export const getTotalRecords = createSelector(
  selectVolunteerState,
  (state) => state.totalRecords
);

export const getVolunteerByID = (props: {id: string}) => createSelector(
  selectVolunteerState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectVolunteerState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectVolunteerState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);