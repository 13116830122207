/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { APP_CONSTANTS } from 'apps/panjab-digi-lib/src/common/constants/app.constants';
import {
  AppState,
  WebVideosActions,
  WebVideosSelector,
  environment,
} from '@panjab-digi-lib/shared';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Video } from 'shared/src/interfaces/video-gallery.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'panjab-digi-lib-video-gallery',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbPopover],
  templateUrl: './video-gallery.component.html',
  styleUrl: './video-gallery.component.scss',
})
export class VideoGalleryComponent implements OnInit, OnDestroy {
  fromRecords = 1;
  toRecords = 10;
  totalRecords = 100;
  orderBy: string = 'VideoOrder';
  pageNumber = 1;
  pages: any;
  totalNoOfPages = 10;
  pageSize = APP_CONSTANTS.pageSize;
  defaultPaginationSize = APP_CONSTANTS.defaultPaginationSize;
  baseUrl = environment.rootApiPublicUrl;
  onSaveSelector$: Subscription | undefined;
  videoList!: Video[];
  constructor(
    private store: Store<AppState>,
    private router : Router
  ) {}

  ngOnInit(): void {
    this.loadAllVideos(this.pageNumber);
  }

  ///////////// get video for gallery /////////////////

  loadAllVideos(pageNumber: number) {
    const payload = this.getPayload(pageNumber);
    this.store.dispatch(WebVideosActions.LoadWebVideos({ payload }));
    this.onSaveSelector$ = this.store
      .select(WebVideosSelector.getVideos)
      .subscribe((response) => {
        this.videoList = response?.videoList;
        this.totalRecords = response?.totalRecords || 0;
        this.totalNoOfPages = Math.ceil(this.totalRecords / this.pageSize);
        this.fromRecords =
          pageNumber === 1 ? 1 : (pageNumber - 1) * this.pageSize + 1;
        this.toRecords =
          (pageNumber - 1) * this.pageSize + this.videoList?.length;
        if (this.onSaveSelector$) {
          if (!this.onSaveSelector$.closed) {
            this.loadPagination(pageNumber);
          }
          this.onSaveSelector$.unsubscribe();
        }
      });
  }

  /////////////////// payload which need to send in request ////////////////////
  getPayload(pageNumber: number) {
    return {
      pageNumber: pageNumber,
      pageSize: this.pageSize,
      orderBy: this.orderBy,
      type: 'all',
    };
  }

  ///////// sort videos based on default, title alphabetically or recently added  ///////

  sortVideos(by: string) {
    if (this.orderBy === by) {
      return;
    }
    this.orderBy = by;
    this.loadAllVideos(this.pageNumber);
  }
  ////////////// calculate pagination ///////////////
  loadPagination(pageNumber: number) {
    this.pages = [];
    this.pageNumber = pageNumber;

    // Show all the pagination elements if there are less than 8 pages total
    if (this.totalNoOfPages <= this.defaultPaginationSize) {
      for (let p = 1; p <= this.totalNoOfPages; p++) {
        this.pages.push(p);
      }
    } else {
      this.getPaginationStartAndEnd();
    }
  }

  getPaginationStartAndEnd() {
    let start = this.pageNumber - 1;
    let end = this.pageNumber + 1;
    if (this.pageNumber === 1) {
      end += 3;
    } else if (this.pageNumber === 2) {
      end += 2;
    } else if (
      this.pageNumber === 3 ||
      this.pageNumber === this.totalNoOfPages - 3
    ) {
      end += 1;
    }
    if (this.pageNumber === this.totalNoOfPages) {
      start -= 3;
    } else if (this.pageNumber === this.totalNoOfPages - 1) {
      start -= 2;
    } else if (
      this.pageNumber === 4 ||
      this.pageNumber === this.totalNoOfPages - 2
    ) {
      start -= 1;
    }
    this.createPaginationWithStartAndEnd(start, end);
  }

  createPaginationWithStartAndEnd(start: number, end: number) {
    if (this.pageNumber >= 3) {
      this.pages.push(1);
      if (this.pageNumber > 4) {
        this.pages.push('...');
      }
    }
    if (start === 0) start += 1;
    for (let p = start; p <= end; p++) {
      if (p > this.totalNoOfPages || p === -1) {
        continue;
      }
      this.pages.push(p);
    }
    if (this.pageNumber < this.totalNoOfPages - 1) {
      if (this.pageNumber < this.totalNoOfPages - 3) {
        this.pages.push('...');
      }
      this.pages.push(this.totalNoOfPages);
    }
  }

  /////////////////// redirect to video player ///////////////

  redirectToVideo(videoId: number) {
    this.router.navigate([`/video/${videoId}`])
  }

  ngOnDestroy(): void {
    this.onSaveSelector$?.unsubscribe();
  }
}
