/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
// import { Volunteer } from 'shared/src/interfaces/volunteer.interface';
import { AppState, VolunteerActions, AdminVolunteerSelector } from '@panjab-digi-lib/shared';
import { VolunteerService } from 'shared/src/services/admin/volunteer/volunteer.service';

@Injectable()
export class VolunteerEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private volunteerService: VolunteerService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadVolunteer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VolunteerActions.LoadVolunteer),
            exhaustMap((action) => {

                return this.volunteerService.loadVolunteers(action.payload).pipe(
                    map((response) => {
                        return VolunteerActions.LoadVolunteerSuccess({ volunteerList: response.volunteers, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(VolunteerActions.LoadVolunteerError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
    
    UpdateVolunteerStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VolunteerActions.UpdateVolunteerStatus),
            exhaustMap((action) => {

                return this.volunteerService.updateVolunteersStatus(action.volunteerIDs, action.status).pipe(
                    map((response: any) => {
                        const volunteerUpdates = action.volunteerIDs.map((id) => {
                            return {
                                id, changes: {
                                    vSetStatus : action.status
                                }
                            }
                        })

                        return VolunteerActions.UpdateVolunteerStatusSuccess({ volunteerUpdates, message: response.message });
                    }),
                    catchError((error: any) => of(VolunteerActions.UpdateVolunteerStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    AddVolunteer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VolunteerActions.AddVolunteer),
            exhaustMap((action) => {

                return this.volunteerService.addVolunteers(action.data).pipe(
                    map((response) => {

                        return VolunteerActions.AddVolunteerSuccess({ volunteer: response.body, message: response.message });
                    }),
                    catchError((error: any) => of(VolunteerActions.AddVolunteerError({ message: error?.error?.error?.message || this.defaultErrMsg }))))
            })
        )
    );

    EditVolunteer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VolunteerActions.EditVolunteer),
            exhaustMap((action) => {
                
                return this.volunteerService.editVolunteers(action.data, action.id).pipe(
                    map((response) => {

                        this.router.navigateByUrl('/pdl-employees/volunteer');
                        return VolunteerActions.EditVolunteerSuccess({volunteer: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(VolunteerActions.EditVolunteerError({message: error?.error?.error?.message || this.defaultErrMsg}))))
            })
        )
    );
    
    AddDeleteVolunteerSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VolunteerActions.AddVolunteerSuccess),
            switchMap((action) => combineLatest([
                    of(action.type),
                    this.store.select(AdminVolunteerSelector.getListingPayload).pipe(take(1))
                ])
            ),
            map(([type, payload]) => {
                this.router.navigateByUrl('/pdl-employees/volunteer');
                return VolunteerActions.LoadVolunteer({ payload });
            })
        )
    );
}