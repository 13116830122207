import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthSelector, BreadcrumbsComponent } from '@panjab-digi-lib/shared';
import { SocialLoginComponent } from '../common/social-login/social-login.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState, AuthActions } from '@panjab-digi-lib/shared';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { AlertService } from 'shared/src/services/website/alert.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { SocialUser } from '@abacritt/angularx-social-login';
import { UserService as WebUserService } from '../../../common/services/user.service';
import { ConfirmationModalService } from 'shared/src/services/confirmation-modal/confirmation-modal.service';
@Component({
  selector: 'panjab-digi-lib-login-user',
  standalone: true,
  imports: [CommonModule, TranslateModule, SocialLoginComponent, BreadcrumbsComponent, RouterLink, ReactiveFormsModule, NgbTooltipModule, AlertsComponent],
  templateUrl: './login-user.component.html',
  styleUrl: './login-user.component.scss'
})
export class LoginUserComponent implements OnInit, OnDestroy {
  pageTitle = 'Login';
  loginUserForm!: FormGroup;
  subscription = new Subscription();
  token = '';
  loggedInUser!: any;
  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorageService,
    private alertService: AlertService,
    private userService: WebUserService,
    private confirmationModalService: ConfirmationModalService
  ) {}

  ngOnInit() {
    this.initLoginForm();
    this.subscription.add(
      this.store.select(AuthSelector.selectWebToken).subscribe((token: string) => {
        if(token) {
          this.router.navigate(['/']);
        }
      })
    );
    this.subscription.add(
      this.store.select(AuthSelector.selectError).subscribe((error: any) => {
        if (error && error.message) {
          if(error?.message?.indexOf('Your account is deactivated') !== -1) {
            this.confirmationModalService.confirm(
              this.translate.instant('pdl-website.forms.labels.reactivateAccConfirm.title'), 
              this.translate.instant('pdl-website.forms.labels.reactivateAccConfirm.desc'), 
              this.translate.instant('pdl-shared.buttonText.yes'), 
              this.translate.instant('pdl-shared.buttonText.no'), 
              'md').then((confirmed) => {
              if(confirmed) {
                if(error.type && error.type == 'social_login') {
                  // social login
                  const payloads = {...error.user};
                  this.userService.handleSocialLogin(payloads, true);
                } else {
                  // standard login
                  const payloads = {
                    username: this.loginUserForm.controls['username'].value,
                    password: this.loginUserForm.controls['password'].value,
                    login: true
                  }
                  this.store.dispatch(AuthActions.webLogin(payloads));
                }
              }
              this.store.dispatch(AuthActions.resetState());
            });
          } else {
            this.alertService.error(error?.message);
          }
        }
      })
    );
    if(this.localStorage.get('webToken')) {
      this.router.navigate(['/']);
    }
    // set username as placeholder value for forgot username email url
    this.route.queryParams.subscribe(params => {
        if(params['user']) {
          this.loginUserForm.controls['username'].setValue(params['user']);
        }
      }
    );
  }

  initLoginForm() {
    this.loginUserForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  signInWithFB(): void {
    this.userService.handleFBSignIn();
  }

  handleSocialLogin(user: SocialUser): void {
    this.userService.handleSocialLogin(user);
  }

  onSubmit() {
    this.alertService.clear();
    this.loginUserForm.markAllAsTouched();
    if(this.loginUserForm.valid) {
      const payloads = {
        username: this.loginUserForm.controls['username'].value,
        password: this.loginUserForm.controls['password'].value
      }
      this.store.dispatch(AuthActions.webLogin(payloads));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
