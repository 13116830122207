import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WebDownloadActions } from '../actions';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { DownloadsService } from 'shared/src/services/website/downloads/downloads.service';

@Injectable()
export class WebDownloadsEffects {

  constructor(
    private actions$: Actions,
    private downloadService: DownloadsService
  ) {
  }
  LoadWebDownloads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebDownloadActions.LoadWebDownloads),
      exhaustMap(action =>
        this.downloadService.loadAllDownloads(action?.payload).pipe(
          map((response: any) => {
            return WebDownloadActions.LoadWebDownloadsSuccess({
              downloads: response?.body?.downloadList,
              totalRecords : response?.body?.totalRecords
            });
          }),
          catchError((error: any) => of(WebDownloadActions.LoadWebDownloadsError(error))))
      )
    )
  );
}