import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CommonAdvanceSearchComponent } from './search/common-advance-search.component';

@Component({
  selector: 'panjab-digi-lib-common-listing',
  standalone: true,
  imports: [CommonModule, TranslateModule, CommonAdvanceSearchComponent],
  templateUrl: './common-listing.component.html',
  styleUrl: './common-listing.component.scss',
})
export class CommonListingComponent {
  @Input() data: any = '';
  @Input() listType: any = '';
  @Input() showLikeAndMoreOptions = false;

  getRating = (rating: number) => {
    const fullStars = Math.floor(rating);
    const isHalf = rating - fullStars > 0;
    const noStars = 5 - fullStars - (isHalf ? 1 : 0);
    const op = Array(fullStars).fill(1);
    const zeroStars = Array(noStars).fill(0);
    if (isHalf) {
      op.push(0.5);
    }

    return [...op, ...zeroStars];
  };
}
