import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastService } from 'shared/src/services/toast/toast.service'
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'panjab-digi-lib-toast',
  standalone: true,
  imports: [CommonModule, NgbToastModule],
  template: `
  @for (toast of toastService.toasts; track toast) {
    <ngb-toast
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (hidden)="toastService.remove(toast)"
    >
    {{toast.message}}
    </ngb-toast>
  }
`,
  host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  constructor(public toastService: ToastService) { }

  ngOnDestroy(): void {
    this.toastService.clear();
  }
}
