import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromWebUser from "../../reducers/web-user.reducer";

export const selectUserState =
  createFeatureSelector<fromWebUser.State>('webUser');

export const selectError = createSelector(
  selectUserState,
  (state) => state.error
);

export const isLoading = createSelector(
  selectUserState,
  (state) => state.isLoading
);

export const selectSuccess = createSelector(
  selectUserState,
  (state) => state.message,
);
