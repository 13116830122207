import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, tap, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { CategoryResponse, GenericReponse } from "shared/src/interfaces/category.interface";
import { HomeDataService } from 'shared/src/services/admin/home/home-data.service';
import { CategoryActions } from '../actions/index';
import { AppState, CategoriesSelector } from '@panjab-digi-lib/shared';
import { MasterFormsService } from 'shared/src/services/admin/master-form/master-forms.service';

@Injectable()
export class CategoriesEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private homeDataService: HomeDataService,
        private masterFormService: MasterFormsService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.LoadCategories),
            exhaustMap((action) => {
                
                return this.homeDataService.loadCategories(action.payload).pipe(
                    map((response) => {
                        return CategoryActions.LoadCategoriesSuccess({ categories: response.categories, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(CategoryActions.LoadCategoriesError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );

    UpdateCategoryHomeStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.UpdateCategoriesHomeStatus),
            exhaustMap((action) => {
                
                return this.homeDataService.updateCategoryShowOnHomeStatus(action.id, action.status).pipe(
                    map((response: any) => {
                        
                        return CategoryActions.UpdateCategoriesHomeStatusSuccess({categories: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(CategoryActions.UpdateCategoriesHomeStatusError({message: error?.error?.error?.message ? error?.error?.error?.message : error?.message}))
                    ))
            })
        )
    );

    ReorderCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.ReorderCategories),
            exhaustMap((action) => {
                
                return this.homeDataService.reorderCategories(action.reorderFor, action.categories).pipe(
                    map((response: GenericReponse) => {

                        return CategoryActions.ReorderCategoriesSuccess({categories: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(CategoryActions.ReorderCategoriesError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );

    AddCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.AddCategory),
            exhaustMap((action) => {
                
                return this.masterFormService.addCategory(action.formData).pipe(
                    map((response) => {

                        return CategoryActions.AddCategorySuccess({message: response.message});
                    }),
                    catchError((error: any) => of(CategoryActions.AddCategoryError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );

    CheckPreCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.CheckPreCode),
            exhaustMap((action) => {
                
                return this.masterFormService.checkPreCode(action.preCode).pipe(
                    map((response) => {

                        return CategoryActions.CheckPreCodeSuccess({preCodeExists: response.preCodeExists});
                    }),
                    catchError((error: any) => of(CategoryActions.CheckPreCodeError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );
    
    AddEditCategorySuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.AddCategorySuccess, CategoryActions.EditCategorySuccess),
            switchMap(() => this.store.select(CategoriesSelector.getListingPayload).pipe(
                take(1)
            )),
            map((payload) => {
                this.router.navigateByUrl('/master-forms/manage/categories');
                return CategoryActions.LoadCategories({payload});
            })
        )
    );

    EditCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.EditCategory),
            exhaustMap((action) => {
                
                return this.masterFormService.editCategory(action.formData, action.categoryID).pipe(
                    map((response) => {

                        return CategoryActions.EditCategorySuccess({message: response.message});
                    }),
                    catchError((error: any) => of(CategoryActions.EditCategoryError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );
    
    UpdateCategoryStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.UpdateCategoriesStatus),
            exhaustMap((action) => {
                
                return this.masterFormService.updateCategoryStatus(action.categoryIDs, action.status).pipe(
                    map((response: any) => {
                        
                        return CategoryActions.UpdateCategoriesStatusSuccess({categories: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(CategoryActions.UpdateCategoriesStatusError({message: error?.error?.error?.message ? error?.error?.error?.message : error?.message}))
                    ))
            })
        )
    );

    
    LoadCategoriesListOnSuccessActions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.UpdateCategoriesStatusSuccess, CategoryActions.ReorderCategoriesSuccess, CategoryActions.UpdateCategoriesHomeStatusSuccess),
            switchMap(() => this.store.select(CategoriesSelector.getListingPayload).pipe(
                take(1)
            )),
            map((payload) => {
                return CategoryActions.LoadCategories({payload});
            })
        )
    );
}