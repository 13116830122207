<panjab-digi-lib-static-page-head pageName="Adopt A Book" pagePath="/adopt-a-book"></panjab-digi-lib-static-page-head>
<section class="adoptbook">
  <div class="container">
    <div class="d-flex justify-content-center">
      <p class="text">Books are a source of constant knowledge and motivation. Some readers prefer a particular book for
        its aesthetic
        appeal, others for the message of the text. Whatever their tastes or reasons, here is an opportunity for book
        lovers to have a say in the selection and digitization of the books that PDL selects for online public access.
        <a href="" target="_blank" class="cursor-pointer text linktext">Click Here </a> to access a list of books that
        are
        available adoption. You can adopt any book from the list that you
        wish to promote and are ready to sponsor the costs incurred on its digitization and keeping it online forever.
        The
        costs are detailed against each book on the list.Your name or the name of the person you want to dedicate this
        adoption will accompany the selected book on the metadata page for all times to come. The book will stay online
        forever because you have adopted it and have helped to promote it through online public access.Younger and
        future
        generations interested in reading books on Panjab will always look for them online. These books have already
        taught us so much, and have much more to show the world and future generations. Are you ready to take that one
        step in helping those books reach their intended audience?
      </p>
    </div>
    <div class="tool_wrapper align-items-center flex-wrap">
      <div class="page-filters-dropdown">
        <div class="dropdown page-filter-dropdown">
          <a class="page-filter-action btn  dropdown-toggle" style="border: none" href="#" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="assets/images/icons/filters-ico.svg" alt="icon"> Filters
          </a>

          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </div>
      </div>
      <div class="d-flex flex-wrap row-gap-15">
        <div class="search_with_glass_at_end padding-12">
          <label class="search-label"><img src="assets/images/icons/search-icon.svg"></label>
          <input type="text" class="form-control search__input disabled" id="exampleFormControlInput1"
            placeholder="Search By name, category...">
        </div>
        <span class="btn btn-default btn__secondary p-0 ms-3">
          <select class="form-select sort__filter" aria-label="Default select example">
            <option selected>All Category</option>
            <option value="1">XYZ</option>
          </select>
        </span>
      </div>
    </div>
    <!-- // table for desktop  -->
    <div class="table-responsive mt-4 desktop-only">
      <table class="adopt_a_book_table table">
        <thead class="">
          <tr class="">
            <th>Book Thumbnail</th>
            <th>Book Name</th>
            <th>Category</th>
            <th>Budget</th>
            <th>Adoption</th>
          </tr>
        </thead>

        <tbody>
          <!-- row 1 -->
          <tr class="">
            <td><img class="thumbnail" src="/assets/images/adopt-book1.jpg" /></td>
            <td>
              <div class="mb-3"><b>Dasam Granth</b></div>
              <div>Examines the first sustained contacts between Native American peoples and European explorers,
                conquerors, and settlers between1492 and 1600.Migrations, and the Works Progress Administration.</div>
            </td>
            <td>Manuscripts</td>
            <td>
              USD 20.0 Only
            </td>
            <td>
              <a href="/adopt-a-book/bk-001" class="adopt_btn btn  btn__primary">
                Adopt&nbsp;&nbsp; <img src="/assets/images/icons/adopt-book-icon.svg" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- lsiting for mobile -->
    <!-- // item 1 -->
    <div class="listWrapper no-scrollbar mb-1 mob-only">
      <div class="list">
        <div class="image">
          <img class="thumbnail" src="/assets/images/adopt-book1.jpg" alt="item.id" />
        </div>
        <div class="meta-data">
          <div class="d-flex flex-wrap col-gap-12">
            <div class="meta-btn">
              <span>
                Manuscripts
              </span>
            </div>
          </div>

          <div class="name mt-3">Dasam Granth</div>
          <div class="desc">Examines the first sustained contacts <span class="see_more">see more</span>....</div>
          <div><a href="/adopt-a-book/bk-001" class="adopt_btn btn btn__primary">
              Adopt&nbsp;&nbsp; <img src="/assets/images/icons/adopt-book-icon.svg" />
            </a></div>
        </div>
      </div>
      <!-- // item 2 -->
      <div class="list">
        <div class="image">
          <img class="thumbnail" src="/assets/images/adopt-book1.jpg" alt="item.id" />
        </div>
        <div class="meta-data">
          <div class="d-flex flex-wrap col-gap-12">
            <div class="meta-btn">
              <span>
                Manuscripts
              </span>

            </div>
          </div>

          <div class="name mt-3">Dasam Granth</div>
          <div class="desc">Examines the first sustained contacts <span class="see_more">see more</span>....</div>
          <div><a href="/adopt-a-book/bk-001" class="adopt_btn btn btn__primary">
              Adopt&nbsp;&nbsp; <img src="/assets/images/icons/adopt-book-icon.svg" />
            </a></div>
        </div>
      </div>
    </div>

  </div>
</section>
