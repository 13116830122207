import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'shared/src/environments/environment';
import { CategoryResponse, GenericReponse, getCategoriesPayload } from "shared/src/interfaces/category.interface";
import { API_ENDPOINTS } from "../app.endpoints";
import { Language } from 'shared/src/interfaces/language.interface';

@Injectable({
  providedIn: 'root'
})
export class HomeDataService {
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadBanners() {
    return this.http.get(this.rootURL + 'admin/banner').pipe(map((e: any) => e.body));
  }
  
  loadCategories(payload: getCategoriesPayload): Observable<{categories: CategoryResponse[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.homePage.category.getCategories}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }

  loadLanguage(): Observable<{languages: Language[]}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.openRoutes.getLanguages}`).pipe(map((e: any) => e.body));
  }
  
  updateCategoryShowOnHomeStatus(id: number, status: boolean): Observable<GenericReponse> {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.homePage.category.toggleShowOnHome}${id}`, {'status': status}).pipe(map((e: any) => e));
  }
  
  reorderCategories(reorderFor: string, categories: Array<object>): Observable<GenericReponse> {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.homePage.category.reorderCategories}`, {reorderFor, categories}).pipe(map((e: any) => e));
  }
}
