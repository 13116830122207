import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { WebHeaderComponent } from '@panjab-digi-lib/shared';
@Component({
  selector: 'panjab-digi-lib-main',
  standalone: true,
  imports: [WebHeaderComponent, FooterComponent, CommonModule, RouterOutlet],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent { }
