import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[panjabDigiLibNumberonly]',
  standalone: true,
})
export class NumberonlyDirective {
  constructor() {}

  @HostListener('keydown', ['$event']) 
  onInput(event: any) {
    return (event.which >= 48 && event.which <= 57) || ((event.which >= 96 && event.which <= 105)) || event.which == 8 || event.which == 46;
  }
}
