
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { GenericListingPayloadWithSearch } from 'shared/src/interfaces/common.interface';
import { DocumentList } from 'shared/src/interfaces/staff/document.interface';

export const LoadDocumentList = createAction(
  '[Staff] Load Document List',
  props<{ payload: GenericListingPayloadWithSearch }>()
);

export const LoadDocumentListSuccess = createAction(
  '[Staff] Load Document List Success',
  props<{ documentList: DocumentList[]; totalRecords: number }>()
);

export const LoadDocumentListError = createAction(
  '[Staff] Load Document List Error',
  props<{ message: string }>()
);

export const SubmitForApproval = createAction(
  '[Staff] Submit For Approval',
  props<{ DocID: number }>()
);

export const SubmitForApprovalSuccess = createAction(
  '[Staff] Submit For Approval Success',
  props<{ message: string }>()
);

export const SubmitForApprovalError = createAction(
  '[Staff] Submit For Approval Error',
  props<{ message: string }>()
);