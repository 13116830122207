import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';

@Component({
  selector: 'panjab-digi-lib-edit-topic',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './edit-topic.component.html',
  styleUrl: './edit-topic.component.scss',
})
export class EditTopicComponent {
  constructor(private location: Location) {}
  goback = () => this.location.back();
}
