import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { API_ENDPOINTS } from '../app.endpoints';
import { map, Observable } from 'rxjs';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { SubjectsPerCategory, SubjectsOfCategory, SaveAllocationPayload } from "shared/src/interfaces/master-form.interface";
import { GenericReponse } from 'shared/src/interfaces/category.interface';
@Injectable({
  providedIn: 'root'
})
export class SubjectsPerCategoryService {
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadSubjectsPerCategory(): Observable<{categories: SubjectsPerCategory[]}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.subjectsPerCategory.default}`).pipe(map((e: any) => e.body));
  }

  loadSubjectsOfCategory(payload: GenericListingPayload, categoryID: number): Observable<{subjects: SubjectsOfCategory[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.subjectsPerCategory.getSubjectsForCategory}`.replace('{id}', (categoryID).toString()), {params: {...payload}}).pipe(map((e: any) => e.body));
  }

  saveAllocations(payload: SaveAllocationPayload, categoryID: number): Observable<GenericReponse> {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.subjectsPerCategory.saveAllocation}`.replace('{id}', (categoryID).toString()), payload).pipe(map((e: any) => e));
  }
  
  downloadSubjectsOfCategory(categoryID: number) {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.subjectsPerCategory.downloadSubjectsOfCategory}`.replace('{id}', (categoryID).toString()), { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }
}
