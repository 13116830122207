import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState, Update } from "@ngrx/entity";
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { Task } from 'shared/src/interfaces/staff/document.interface';
import { TaskActions } from 'shared/src/store/actions';

export interface State extends EntityState<any> {
    payload: GenericListingPayload,
    totalRecords: number,
    isLoading: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<Task>({
    selectId: (e) => e.PSID
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: true,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(TaskActions.LoadTaskList, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(TaskActions.LoadTaskListSuccess, (state, action) =>
    ({
        ...adapter.setAll(action.taskList, state),
        totalRecords: action.totalRecords,
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(TaskActions.LoadTaskListError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(TaskActions.ApproveTask, (state, action) =>
    ({
        ...state,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(TaskActions.ApproveTaskSuccess, (state, action) =>
    ({
        ...adapter.updateOne({ id: action.task.PSID, changes: action.task }, state),
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(TaskActions.ApproveTaskError, (state, action) =>
    ({
        ...state,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(TaskActions.BypassTask, (state, action) =>
    ({
        ...state,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(TaskActions.BypassTaskSuccess, (state, action) =>
    ({
        ...adapter.updateOne({ id: action.task.PSID, changes: action.task }, state),
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(TaskActions.BypassTaskError, (state, action) =>
    ({
        ...state,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(TaskActions.RejectTask, (state, action) =>
    ({
        ...state,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(TaskActions.RejectTaskSuccess, (state, action) =>
    ({
        ...adapter.updateOne({ id: action.task.PSID, changes: action.task }, state),
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(TaskActions.RejectTaskError, (state, action) =>
    ({
        ...state,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();