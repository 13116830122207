/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import {
  addLanguagePayload,
  deleteLanguagePayload,
  deleteLanguageResponse,
  editLanguagePayload,
  getLanguagePayload,
  Language,
  updateLanguageStatusPayload,
} from 'shared/src/interfaces/language.interface';
export const LoadLanguages = createAction(
  '[Language] Load Language List',
  props<{ payload: getLanguagePayload }>()
);

export const LoadLanguageSuccessfully = createAction(
  '[Language] Load Language Successfully',
  props<{ languageList: Language[]; totalRecords: number }>()
);

export const LoadLanguageError = createAction(
  '[Language] Load Language Error',
  props<{ message: string }>()
);

export const AddLanguage = createAction(
  '[Language] Add Language to List',
  props<{ payload: addLanguagePayload }>()
);

export const AddLanguageSuccessfully = createAction(
  '[Language] Language Added Successfully',
  props<{ language: Language; message: string }>()
);

export const AddLanguageError = createAction(
  '[Language] Language Added Error',
  props<{ message: string }>()
);

export const UpdateLanguage = createAction(
  '[Language] Edit Language in List',
  props<{ payload: editLanguagePayload }>()
);

export const UpdateLanguageSuccessfully = createAction(
  '[Language] Language Updated Successfully',
  props<{ language: Language; message: string }>()
);

export const UpdateLanguageError = createAction(
  '[Language] Language Updated Error',
  props<{ message: string }>()
);

export const UpdateLanguageStatus = createAction(
    '[Language] Update Language Status in List',
    props<{ payload: updateLanguageStatusPayload }>()
  );
  
  export const UpdateLanguageStatusSuccessfully = createAction(
    '[Language] Language Status Updated Successfully',
    props<{ language: Language[]; message: string }>()
  );
  
  export const UpdateLanguageStatusError = createAction(
    '[Language] Language Status Updated Error',
    props<{ message: string }>()
  );

  export const DeleteLanguage= createAction(
    '[Language] Delete Status from List',
    props<{ payload: deleteLanguagePayload }>()
  );
  
  export const DeleteLanguageSuccessfully = createAction(
    '[Language] Language Deleted Successfully',
    props<{ message: string }>()
  );
  
  export const DeleteLanguageError = createAction(
    '[Language] Language Status Updated Error',
    props<{ message: string }>()
  );