<ng-container>
    <a class="breadcrumb-link font-regular" href="#">
        <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="home icon">
        Home
    </a> 
    <span class="chevron-arrow font-regular">
        <img src="assets/images/icons/arrow-right.svg" alt="right arrow">
    </span>
    <a class="breadcrumb-link font-regular" href="#">
        {{pageName}}
    </a>
</ng-container>