import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SocialAuthService, GoogleSigninButtonModule, SocialUser } from '@abacritt/angularx-social-login';
import { Subscription } from 'rxjs';

@Component({
  selector: 'panjab-digi-lib-social-login',
  standalone: true,
  imports: [CommonModule, TranslateModule, GoogleSigninButtonModule],
  templateUrl: './social-login.component.html',
  styleUrl: './social-login.component.scss',
})
export class SocialLoginComponent implements OnInit, OnDestroy {
  @Output() handleLoginEvent = new EventEmitter<SocialUser>();
  @Output() handleFBLoginEvent = new EventEmitter<null>();
  subscription = new Subscription();
  constructor(
    private translate: TranslateService,
    private socialAuthService: SocialAuthService
  ) { }

    ngOnInit() {
      // subscribe to google login
      this.subscription.add(
        this.socialAuthService.authState.subscribe((user) => {
          if(user && user.provider === 'GOOGLE') {
            this.handleSocialLogin(user);
          }
        })
      );
    }

    handleSocialLogin(user: SocialUser): void {
      this.handleLoginEvent.emit(user);
    }

    loginWithFB():void {
      this.handleFBLoginEvent.emit();
    }

    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }
}
