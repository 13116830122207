<div class="modal__style">
  <button type="button" class="btn close btn__close" aria-label="Close" (click)="decline()">
    <img
      src="assets/images/icons/cross-close-icon.svg"
      alt="Close"
      class="img-fluid"
    />
  </button>
  <div class="modal-header justify-content-center" *ngIf="title">
    <h4 class="modal-title">{{ title }}</h4>
  </div>
  <div class="modal-body pt-0 pb-4">
    {{ message }}
    @if(optionalMessage) {
    <p class="optional-message" [ngStyle]="{'color' : optionalMessage['color'] ? optionalMessage['color'] : '' }">{{optionalMessage['message']}}</p>  
    }
  </div>
  <div class="modal-footer justify-content-center gap-2">
    <button type="button" class="btn btn-primary btn__primary" (click)="accept()">{{ btnOkText }}</button>
    <button type="button" class="btn btn-default btn__secondary" (click)="decline()">{{ btnCancelText }}</button>
  </div>
</div>
