/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from 'shared/src/environments/environment';
import {
  addEditTranslatorPayload,
  addEditTranslatorResponse,
  deleteTranslatorPayload,
  deleteTranslatorResponse,
  getTranslatorPayload,
  getTranslatorResponse,
  mergeTranslatorPayload,
  mergeTranslatorsResponse,
  updateTranslatorStatusPayload,
  updateTranslatorStatusResponse,
} from 'shared/src/interfaces/translator.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslatorService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.masterForm.translator;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  ////////// load Translators /////////
  loadTranslators(payload: getTranslatorPayload) {
    let url = `${this.rootURL}${this.endPointUrl.default}`;
    url = url
      .replace('{sortBy}', payload?.sortBy ?? 'name')
      .replace('{orderBy}', payload?.orderBy ?? 'asc');
      if(payload?.pageSize) {
      url += `&pageSize=${payload?.pageSize}`
      }
      if(payload?.pageNumber) {
      url += `&pageNumber=${payload?.pageNumber}`
      }
      if(payload?.query) {
      url += `&query=${payload?.query}`
      }
    return this.http
      .get<getTranslatorResponse>(url)
      .pipe(map((e: getTranslatorResponse) => e?.body));
  }

  ////////////// Translator detail ////////////////
  translatorDetail(id:number) {
    const url = `${this.rootURL}${this.endPointUrl.translatorById}`.replace('{id}', (id ?? '').toString());
    return this.http.get<addEditTranslatorResponse>(url)
    .pipe(map((e: addEditTranslatorResponse) => e?.body));
  }

  ////////// add Translator /////////
  addTranslator(payload: addEditTranslatorPayload) {
    const url = `${this.rootURL}${this.endPointUrl.translator}`;
    return this.http
      .post<addEditTranslatorResponse>(url, payload)
      .pipe(map((e: addEditTranslatorResponse) => e));
  }

  ////////// update Translator /////////
  updateTranslator(payload: addEditTranslatorPayload,id:number) {
    let url = `${this.rootURL}${this.endPointUrl.translatorById}`;
    url = url.replace('{id}', (id ?? '').toString());
    return this.http
      .put<addEditTranslatorResponse>(url, payload)
      .pipe(map((e: addEditTranslatorResponse) => e));
  }

  ////////// update Translator status/////////
  updateTranslatorStatus(payload: updateTranslatorStatusPayload) {
    const url = `${this.rootURL}${this.endPointUrl.translator}`;
    return this.http
      .patch<updateTranslatorStatusResponse>(url, payload)
      .pipe(map((e: updateTranslatorStatusResponse) => e));
  }

  ////////// delete Translator/////////
  deleteTranslator(payload: deleteTranslatorPayload) {
    const url = `${this.rootURL}${this.endPointUrl.translator}`;
    return this.http
      .delete<deleteTranslatorResponse>(url, { body: payload })
      .pipe(map((e: deleteTranslatorResponse) => e));
  }

  ////////// merge Translators /////////
  mergeTranslators(payload: mergeTranslatorPayload) {
    const url = `${this.rootURL}${this.endPointUrl.merge}`;
    return this.http
      .put<mergeTranslatorsResponse>(url, payload)
      .pipe(map((e: mergeTranslatorsResponse) => e));
  }
}
