import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'panjab-digi-lib-most-popular',
  standalone: true,
  imports: [CommonModule, 
    SlickCarouselModule,TranslateModule
  ],
  templateUrl: './most-popular.component.html',
  styleUrl: './most-popular.component.scss',
})
export class MostPopularComponent {

  constructor(private translate: TranslateService) {

  }

  slides = [
    {
      img: "assets/images/mostpopular-1.jpg"
    },
    {
      img: "assets/images/mostpopular-2.jpg"
    },
    {
      img: "assets/images/mostpopular-3.jpg"
    },
    {
      img: "assets/images/mostpopular-4.jpg"
     },
     {
       img: "assets/images/mostpopular-5.jpg"
     },
     {
       img: "assets/images/mostpopular-6.jpg"
     },
     {
       img: "assets/images/mostpopular-7.jpg"
     },
     {
       img: "assets/images/mostpopular-8.jpg"
     },
     {
       img: "assets/images/mostpopular-9.jpg"
     },
     {
       img: "assets/images/mostpopular-10.jpg"
     },
     {
       img: "assets/images/mostpopular-11.jpg"
     }
  ];
  slideConfig = {
    centerMode: true,
    centerPadding: '40px',
    autoplay: true,
    draggable: true,
    autoplaySpeed: 3000,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          infinite: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          infinite: true
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 3,
          infinite: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          infinite: true
        }
      }
    ]
  };

  slickInit(e: any) {
    console.log('slick initialized');
  }

}
