import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'httpFormat',
  standalone: true,
})
export class HttpFormatPipe implements PipeTransform {
  transform(href: string): string {
    
    return href.startsWith("http://") || href.startsWith("https://") ? href : "http://" + href;
  }
}
