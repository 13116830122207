import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageService } from '../StaticPageService';
import { TranslateModule } from '@ngx-translate/core';
import { StaticPageHeadComponent } from '../staticPageHead/staticPageHead.component';
import { SafeHtmlPipe } from 'shared/src/pipes/safe-html.pipe';
import { SpinnerComponent } from '@panjab-digi-lib/shared';

@Component({
  selector: 'panjab-digi-lib-faq',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    StaticPageHeadComponent,
    SafeHtmlPipe,
    SpinnerComponent
  ],
  templateUrl: './FAQ.component.html',
  styleUrl: './FAQ.component.scss',
})
export class FAQComponent {
  content: any = {};
  activeKey = '';
  openedIndex: any = [];
  loading=true;

  constructor(private staticPageService: StaticPageService) {}
  ngOnInit(): void {
    this.staticPageService.getFAQContent('en').subscribe((data: any) => {
      this.content = data?.body;
      this.activeKey = this.getObjectKeys(this.content?.data)?.[0] || '';
      this.loading=false
    });
  }

  getObjectKeys = (obj: any):any => (obj ? Object.keys(obj) : []);

  setActiveKey = (key: any) => {
    this.activeKey = key;
    this.openedIndex = [];
  };

  openedKeysHandler(num: number) {
    const index = this.openedIndex.indexOf(num);
    if (index === -1) {
      // Number is not in the array, add it
      this.openedIndex.push(num);
    } else {
      // Number is in the array, remove it
      this.openedIndex.splice(index, 1);
    }
  }

  getQAList = (key: string) => {
    return this.content?.data[key];
  };

  isActiveIndex = (index: number) => {
    return this.openedIndex.includes(index);
  };

  getContent = (fullText: string, index: number) => {
    const isActive = this.isActiveIndex(index);
    // if is openedIndex return full
    if (isActive) return fullText;
    // return first paragraph if exists
    if (fullText.includes('</p>')) {
      const hasMoreText = fullText.indexOf('</p>') + 4 < fullText.length;
      return fullText.split('</p>')[0] + (hasMoreText ? '...</p>' : '</p>');
    }
    // return first 400 chars if text is having more than 200 chars
    if (fullText.length > 400) return fullText.substring(0, 400) + '...';
    return fullText;
  };
}
