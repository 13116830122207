import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { BannerActions } from "../actions/index"

export interface State extends EntityState<any> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  reloadBanners?: boolean;
  totalBanners?: number;
}

export const adapter = createEntityAdapter<any>({
  selectId: (e) => e.BannerId
});

export const initialState = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
  reloadBanners: false,
  totalBanners: 0
});

export const reducer = createReducer(
  initialState,
  on(BannerActions.LoadBanners, (state, action) =>
  ({
    ...state,
    isLoading: true,
    isError: false,
    errorMessage: '',
    successMessage: '',
    reloadBanners: false
  })
  ),
  on(BannerActions.LoadBannersSuccessfully, (state, action) =>
  ({
    ...adapter.setAll(action.banners, state),
    isLoading: false,
    isError: false,
    errorMessage: '',
    successMessage: '',
    reloadBanners: false,
    totalBanners: action.totalRecords
  })
  ),
  on(BannerActions.BannersError, (state, action) =>
  ({
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.message,
    successMessage: '',
    reloadBanners: action.reload ? true : false
  })
  ),
  on(BannerActions.BannerSuccessMsg, (state, action) =>
  ({
    ...state,
    isLoading: false,
    isError: false,
    errorMessage: '',
    successMessage: action.message,
    reloadBanners: action.reload
  })
  )

);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();