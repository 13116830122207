import { createReducer, on } from '@ngrx/store';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {WebHomePageActions} from "../../actions/index"
import { Keywords } from 'shared/src/interfaces/homepage.interface';

export interface State extends EntityState<any> {
  trendingKeywords: Keywords[],
  historicKeywords: Keywords[],
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

export const adapter = createEntityAdapter<any>({
  selectId: (e) => e.keyword
});

export const initialState = adapter.getInitialState( {
  trendingKeywords: [] as Keywords[],
  historicKeywords: [] as Keywords[],
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: ''
});

export const reducer = createReducer(
  initialState,
  on(WebHomePageActions.LoadWebTrendingKeywords,(state, action) =>
    ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebHomePageActions.LoadWebTrendingKeywordsSuccess,(state, action) =>
  ({
      ...state,
      trendingKeywords: action.trendingKeywords,
      historicKeywords: action.historicKeywords,
      isLoading: false,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebHomePageActions.LoadWebTrendingKeywordsError,(state, action) =>
    ({
      ...state,
      trendingKeywords: [],
      historicKeywords: [],
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    })
  )
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();