/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { AdminSponsorshipActions } from '../actions/index';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Sponsorship } from 'shared/src/interfaces/sponsorship.interface';

export interface State extends EntityState<Sponsorship> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  totalRecords: number;
  pageNumber : number,
  pageSize: number,
  order: string,
  orderBy:string
}

export const adapter = createEntityAdapter<Sponsorship>({
  selectId: (e) => e?.CID,
});

export const initialState = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0,
  pageNumber : 0,
  pageSize: 0,
  orderBy: 'Date',
  order: 'desc'
});

export const reducer = createReducer(
  initialState,

  on(AdminSponsorshipActions.LoadSponsorshipList, (state,action) => ({
    ...state,
    isLoading: true,
    successMessage: '',
    errorMessage: '',
    pageNumber: action.payload?.pageNumber || 1,
    pageSize: action.payload?.pageSize || 0,
  })),

  on(AdminSponsorshipActions.LoadSponsorshipListSuccessfully, (state, action) =>
    adapter.setAll(action?.sponsorshipList, {
      ...state,
      totalRecords: action.totalRecords,
      isLoading: false,
      successMessage : '',
      errorMessage : ''
    })
  ),

  on(AdminSponsorshipActions.LoadSponsorshipListError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
      successMessage : ''
    };
  }),

  on(AdminSponsorshipActions.AddSponsorship, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminSponsorshipActions.AddSponsorshipSuccessfully, (state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: action?.message
    })
  ),

  on(AdminSponsorshipActions.AddSponsorshipError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminSponsorshipActions.UpdateSponsorship, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminSponsorshipActions.UpdateSponsorshipSuccessfully, (state, action) => ({
    ...adapter.updateOne(
      { id: action?.sponsorship?.CID, changes: action.sponsorship },
      state
    ),
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: action?.message,
  })),

  on(AdminSponsorshipActions.UpdateSponsorshipError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      successMessage: '',
      errorMessage: action?.message,
    };
  }),

  on(AdminSponsorshipActions.UpdateSponsorshipStatus, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminSponsorshipActions.UpdateSponsorshipStatusSuccessfully, (state, action) => {
    const updates = action?.sponsorship;
    const updatedEntities = adapter.updateMany(
      updates.map((update: Sponsorship) => ({
        id: update.CID,
        changes: update,
      })),
      state
    );
    return {
      ...updatedEntities,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action?.message,
    };
  }),

  on(AdminSponsorshipActions.UpdateSponsorshipStatusError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      successMessage : '',
      errorMessage: action?.message,
    };
  }),

  on(AdminSponsorshipActions.DeleteSponsorship, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminSponsorshipActions.DeleteSponsorshipSuccessfully, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: false,
      successMessage: action?.message,
      errorMessage: '',
    };
  }),

  on(AdminSponsorshipActions.DeleteSponsorshipError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  })
);

export const { selectAll } = adapter.getSelectors();
