export const APP_CONSTANTS = {
  UserType: {
    ADMIN: 1,
    STAFF: 2,
    WebUser: 3
  },
  DefaultStaffRoute: '/dashboard',
  StaffUpdatePwdRoute: '/update-password',
  defaultErrMsg: "Something went wrong! Please try again later.",
  maxSize: 10,
  pageSize : 20,
  mergedCount: 15,
  defaultDateFormat : 'dd-MM-yyyy',
  documentStatus : {
    ONLINE: 1,
    WEB_READY: 2,
    OFFLINE: 3
  },
  dynamicMenu : {
    accessionNumber : "Accession Number",
    assignTask: "Assign Task",
    approveTask: "Approve"
  },
  volunteerReqOptions: [
   {
    type: 'organize',
    value: 'Organize - events, exhibitions, campaigns'
   },
   {
    type: 'fundraise',
    value: 'Fundraise - grants, corporate and individual gifts'
   },
   {
    type: 'digitize',
    value: 'Digitize - scan, edit images and text, translate'
   },
   {
    type: 'recruit',
    value: 'Recruit - screen, interview, train, and develop personnel'
   },
   {
    type: 'develop',
    value: 'Develop - strategy and plans (PR, Legal, IT, HR, Fund)'
   },
  ],
  regExp: {
    alphaNumericOnly: /^[a-zA-Z]+(?:\s[a-zA-Z0-9]+)*(?:\s[a-zA-Z0-9]+)?$/,
    email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    numberOnly: /^\d+$/,
    url: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
  },
  volunteerRequestStatus: {
    APPROVED: 1,
    REJECTED: 2,
    PENDING: 3
  },
  userPortalAccess: {
    YES: 1,
    NO: 2
  }
}