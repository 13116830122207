<section class="top_section">
  <img src="assets/images/download-image1.png" class="banner_image" />
  <div class="innerpage-banner">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
            <a class="breadcrumb-link font-regular" href="#">
              <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
              Home
            </a>
            <span class="chevron-arrow font-regular">
              <img src="assets/images/icons/arrow-right.svg" alt="icon">
            </span>
            <a class="breadcrumb-link font-regular" href="#">
              All Exhibitions
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="top_section_bottom">
    <div class="content">
      <p class="heading">
        Not an Ostrich: & Other Images From America’s Library
      </p>
      <div class="subheading ">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <div>
            <div>07 July 2023 - 08 July 2023 | 10:00 am - 6:00 pm</div>
            <div class="mb-3">
              Plot 23, Industrial area phase II, Chandigarh.
            </div>
            <button class="check_more_locations cursor-pointer">Check 3 More Locations</button>
          </div>
          <div class="btn_wrapper">
            <button class="btn btn__secondary">
              Get Directions
            </button>
            <button class="btn btn__primary">
              Plan Your Visit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="d-flex tabs no-scrollbar">
  <ul class="nav nav-pills no-scrollbar" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" aria-current="page" data-bs-toggle="pill" data-bs-target="#exhibition_detail"
        type="button" role="tab" aria-selected="true">Explore</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" href="#" data-bs-toggle="pill" data-bs-target="#exhibition_items">Exhibition
        Items</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" href="#" data-bs-target="#event_resources" data-bs-toggle="pill">Events &
        Resources</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Credits</button>
    </li>
  </ul>
</div>
<div class="tab-content">
  <div id="exhibition_detail" role="tabpanel" class="tab-pane fade show active ">
    <section class="container exhibition_detail mt-5">
      <p class="heading">
        Our Multiple location address
      </p>
      <p class="subheading">Organized by the Annenberg Space for Photography in Los Angeles and drawn from the
        photography
        collections of the Library of Congress, presents a taste of this institution’s spectacular holdings of more than
        14
        million photographs. Selected images reproduced for this on</p>
      <div class="mapouter mb-5 mt-5 d-flex justify-content-center">
        <div class="gmap_canvas"><iframe
            src="https://maps.google.com/maps?q=trantor&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
            frameborder="0" scrolling="no" style="width: 100%; height: 100%"></iframe>
        </div>
      </div>


      <p class="heading">Not an Ostrich: & Other Images From America’s Library</p>
      <p class="subheading">Organized by the Annenberg Space for Photography in Los Angeles and drawn from the
        photography
        collections of the Library of Congress, presents a taste of this institution’s spectacular holdings of more than
        14
        million photographs. Selected images reproduced for this online exhibition were made between 1839 and today.
        They
        trace the evolution of photography, from daguerreotypes and other early processes to contemporary digital
        technology.</p>
      <p class="subheading">Organized into distinct sections, Not an Ostrich captures the great range of subjects
        available
        in the Library’s photographic collections, including portraits, the arts, sports, leisure, political and social
        issues, icons, panoramas, and business and science. Other sections highlight the distinct American visions from
        the
        archives of Carol M. Highsmith, Camilo José Vergara, and the Detroit Publishing Company.</p>
      <p class="subheading">Not an Ostrich includes famous pictures, famous subjects, and famous photographers. Many of
        the
        images capture glorious moments in our history, some are entertaining or even absurd, and others are deeply
        troubling. Some photos will give you new perspectives on celebrated places, faces, and events, while still
        others
        will literally open your eyes to unfamiliar moments in American culture and history.</p>
      <ul>
        <li>
          View all 428 exhibition photographs selected by curator Anne Wilkes Tucker
        </li>
        <li>
          <a href="/">View all 428 exhibition photographs selected by curator Anne Wilkes Tucker</a>
        </li>
      </ul>
    </section>
    <section class="container d-flex flex-column justify-content-center p-5 bottom_section">
      <p class="heading">
        NOT AN OSTRICH: & OTHER IMAGES FROM AMERICA’S LIBRARY
      </p>
      <p class="subheading">
        Photography exhibition made possible by
      </p>
      <p class="subheading">
        Wallis Annenberg and the Annenberg Space for Photography in Los Angeles
      </p>
    </section>
    <section class="navigation container mb-5">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex gap-20">
          <button class="btn btn__primary">
            <img src="assets/images/icons/arrow-white-left.svg" alt="">
          </button>
          <div class="d-flex flex-column justify-content-between">
            <div class="prev_next">Previous Section</div>
            <div>A Toolkit for Associations</div>
          </div>
        </div>
        <div class="current semibold">
          Continue exploring Voluntary Associations in America
        </div>
        <div class="d-flex gap-20">
          <div class="d-flex flex-column justify-content-between">
            <div class="prev_next">Previous Section</div>
            <div>A Toolkit for Associations</div>
          </div>
          <button class="btn btn__primary">
            <img src="assets/images/icons/arrow-white-right.svg" alt="">
          </button>
        </div>
      </div>
    </section>
    <section class="explore_exhibit">
      <p class="semibold text-center">Explore the exhibit</p>
      <div class="d-flex flex-wrap item_wrapper">
        <div class="item d-flex flex-column">
          <img src="assets/images/download-image1.png" />
          <span class="text"> Continue exploring Voluntary Associations in America</span>
        </div>
        <div class="item d-flex flex-column">
          <img src="assets/images/download-image1.png" />
          <span class="text"> Continue exploring Voluntary Associations in America</span>
        </div>

        <div class="item d-flex flex-column">
          <img src="assets/images/download-image1.png" />
          <span class="text"> Continue exploring Voluntary Associations in America</span>
        </div>
        <div class="item d-flex flex-column">
          <img src="assets/images/download-image1.png" />
          <span class="text"> Continue exploring Voluntary Associations in America</span>
        </div>
        <div class="item d-flex flex-column">
          <img src="assets/images/download-image1.png" />
          <span class="text"> Continue exploring Voluntary Associations in America</span>
        </div>
      </div>
    </section>
    <div class="mb-5 mt-5">
      <panjab-digi-lib-global-divider />
    </div>
    <div>
      <p class="semibold text-center">Exhibition Gallery</p>
      <img src="assets/images/download-image1.png" alt="" class="full_width_img mb-3">
      <div class="d-flex justify-content-center mb-5 mt-3" style="gap: 20px">
        <button class="btn btn__primary">
          <img src="assets/images/icons/arrow-white-left.svg" alt="">
        </button>
        <button class="btn btn__primary">
          <img src="assets/images/icons/arrow-white-right.svg" alt="">
        </button>
      </div>
      <div class="d-flex infinite-hr-scroll no-scrollbar mb-4">
        <img src="assets/images/download-image1.png" />
        <img src="assets/images/download-image1.png" />
        <img src="assets/images/download-image1.png" />
        <img src="assets/images/download-image1.png" />
        <img src="assets/images/download-image1.png" />
        <img src="assets/images/download-image1.png" />
        <img src="assets/images/download-image1.png" />
        <img src="assets/images/download-image1.png" />
        <img src="assets/images/download-image1.png" />
        <img src="assets/images/download-image1.png" />
        <img src="assets/images/download-image1.png" />
        <img src="assets/images/download-image1.png" />
      </div>
    </div>
  </div>
  <div class=" tab-pane fade show exhibition_items" role="tabpanel" id="exhibition_items">
    <div class="banner_image">
      <img src="assets/images/download-image1.png" class="" />
      <div class="banner_text semibold">
        Not an Ostrich: & Other Images From America’s Library
      </div>
    </div>

    <section class="container">
      <div class="innerpage-banner" style="background: none;">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
                <a class="breadcrumb-link font-regular" href="#">
                  <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
                  Home
                </a>
                <span class="chevron-arrow font-regular">
                  <img src="assets/images/icons/arrow-right.svg" alt="icon">
                </span>
                <a class="breadcrumb-link font-regular" href="#">
                  All Exhibitions
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="bold">
          Exhibition Items
        </p>
        <p class="semibold">
          Icons
        </p>
        <ul>
          <li>
            Robert Cornelius. [Self-portrait: believed to be the earliest extant American portrait photo]. [October or
            November], 1839. Prints and Photographs Division, Library of Congress (42.00.00)
          </li>
        </ul>
      </div>
    </section>
  </div>
  <div class=" tab-pane fade show event_resources" role="tabpanel" id="event_resources">
    <div class="banner_image">
      <img src="assets/images/download-image1.png" class="" />
      <div class="banner_text semibold">
        Not an Ostrich: & Other Images From America’s Library
      </div>
    </div>

    <section class="container">
      <div class="innerpage-banner" style="background: none;">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
                <a class="breadcrumb-link font-regular" href="#">
                  <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
                  Home
                </a>
                <span class="chevron-arrow font-regular">
                  <img src="assets/images/icons/arrow-right.svg" alt="icon">
                </span>
                <a class="breadcrumb-link font-regular" href="#">
                  All Exhibitions
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="bold">
          Events & Resources
        </p>
        <div class="item_wrapper">
          <div class="item d-flex align-items-center">
            <img src="assets/images/download-image1.png" class="item_image" />
            <div class="item_content">
              <p class="heading">Not an Ostrich Photo Zine</p>
              <p class="text">The Not an Ostrich Photo Zine incorporates characteristics of both high-end photography
                magazines and independent zines into a dynamic paperback that users word play, captions, and quotes to
                add social and historical context to approximately 100 photos featured in the exhibition. Eye-catching
                and innovative, the photo zine celebrates the Library's remarkable collection and echoes the exhibit's
                challenge to viewers to consider "What are we really looking at?"</p>
              <p class="fade_text">
                Available in the Library of Congress Store External
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
