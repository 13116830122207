import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState, SpinnerComponent, WebDonationActions, WebDonationSelector } from '@panjab-digi-lib/shared';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Donation } from 'shared/src/interfaces/web-management.interface';
import { TranslateModule } from '@ngx-translate/core';
import { NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { APP_CONSTANTS } from 'apps/panjab-digi-lib/src/common/constants/app.constants';
import { GenericListingPayload, SortOrder } from 'shared/src/interfaces/common.interface';

@Component({
  selector: 'panjab-digi-lib-donations',
  standalone: true,
  imports: [
    CommonModule, 
    RouterModule, 
    TranslateModule, 
    NgbPaginationModule, 
    NgbPopoverModule,
    SpinnerComponent
  ],
  templateUrl: './donations.component.html',
  styleUrl: './donations.component.scss',
})
export class DonationsComponent implements OnInit, OnDestroy {
  pageNo = 1;
  pageSize = APP_CONSTANTS.pageSize;
  totalRecords: number = 0;
  sortOrder: SortOrder = { orderBy: 'Date', order: 'DESC' };
  sortBy: string = 'recent';
  
  isLoading$: Observable<boolean> = this.store.select(WebDonationSelector.isLoading);
  donations$: Observable<Donation[]> = this.store.select(WebDonationSelector.getDonations);
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.loadDonations();  
  }

  loadDonations() {
    let payload: GenericListingPayload = { ...this.sortOrder, "pageNumber": this.pageNo, "pageSize": this.pageSize };
    this.store.dispatch(WebDonationActions.GetDonationList({payload}));
    this.store.select(WebDonationSelector.getTotalRecords).pipe(takeUntil(this.unsubscribe$))
    .subscribe((totalRecords) => {
      this.totalRecords = totalRecords;
    });
  }

  sortDonations(orderBy: string, order: string, sortBy: string) {
    this.sortOrder = {orderBy, order};
    this.sortBy = sortBy;
    this.loadDonations();
  }
  
  ngOnDestroy(): void {
    
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
