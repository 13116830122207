
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import {AddEditContributorPayload,DeleteContributorPayload,GetContributorPayload, MergeContributorPayload,Contributor,UpdateContributorStatusPayload} from 'shared/src/interfaces/contributor.interface';
export const LoadContributors = createAction(
  '[contributor] Load contributor List',
  props<{ payload: GetContributorPayload }>()
);

export const LoadContributorSuccessfully = createAction(
  '[contributor] Load contributor Successfully',
  props<{ contributorList: Contributor[]; totalRecords: number }>()
);

export const LoadContributorError = createAction(
  '[contributor] Load contributor Error',
  props<{ message: string }>()
);

export const AddContributor = createAction(
  '[contributor] Add contributor to List',
  props<{ payload: AddEditContributorPayload }>()
);

export const AddContributorSuccessfully = createAction(
  '[contributor] contributor Added Successfully',
  props<{ contributor: Contributor; message: string }>()
);

export const AddContributorError = createAction(
  '[contributor] contributor Added Error',
  props<{ message: string }>()
);

export const UpdateContributor = createAction(
  '[contributor] Edit contributor in List',
  props<{ payload: AddEditContributorPayload,id:number }>()
);

export const UpdateContributorSuccessfully = createAction(
  '[contributor] contributor Updated Successfully',
  props<{ contributor: Contributor; message: string }>()
);

export const UpdateContributorError = createAction(
  '[contributor] contributor Updated Error',
  props<{ message: string }>()
);

export const UpdateContributorStatus = createAction(
    '[contributor] Update contributor Status in List',
    props<{ payload: UpdateContributorStatusPayload }>()
  );
  
  export const UpdateContributorStatusSuccessfully = createAction(
    '[contributor] contributor Status Updated Successfully',
    props<{ contributor: Contributor[]; message: string }>()
  );
  
  export const UpdateContributorStatusError = createAction(
    '[contributor] contributor Status Updated Error',
    props<{ message: string }>()
  );

  export const MergeContributor = createAction(
    '[contributor] Merge contributor',
    props<{ payload: MergeContributorPayload }>()
  );
  
  export const MergeContributorSuccessfully = createAction(
    '[contributor] Merge contributor Successfully',
    props<{ message: string }>()
  );
  
  export const MergeContributorError = createAction(
    '[contributor] contributor Status Updated Error',
    props<{ message: string }>()
  );

  export const DeleteContributor= createAction(
    '[contributor] Delete Status from List',
    props<{ payload: DeleteContributorPayload }>()
  );
  
  export const DeleteContributorSuccessfully = createAction(
    '[contributor] contributor Deleted Successfully',
    props<{ message: string }>()
  );
  
  export const DeleteContributorError = createAction(
    '[contributor] contributor Status Updated Error',
    props<{ message: string }>()
  );