import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  loadScript(scripts: { src: string, attributes?: { [key: string]: string }, targetElementId?: string }[]) {
    let scriptElements: HTMLScriptElement[] = [];
    scripts.forEach(scriptInfo => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptInfo.src;
      scriptElement.async = true;
      if (scriptInfo.attributes) {
        for (const [key, value] of Object.entries(scriptInfo.attributes)) {
          if (value !== undefined) {
            scriptElement.setAttribute(key, value);
          }
        }
      }
      const targetElement = scriptInfo.targetElementId ? document.getElementById(scriptInfo.targetElementId) : document.body;
      if (targetElement) {
        targetElement.appendChild(scriptElement);
        scriptElements.push(scriptElement);
      } else {
        console.error(`Target element with ID '${scriptInfo.targetElementId}' not found.`);
      }
    });
    return scriptElements
  }

  
  removeScript(scriptElements: HTMLScriptElement[]) {
    scriptElements.forEach(scriptElement => {
      if (scriptElement && scriptElement.parentNode) {
        scriptElement.parentNode.removeChild(scriptElement);
      }
    });
    scriptElements = [];
    return scriptElements;
  }
}
