import { createReducer, on } from '@ngrx/store';
import {AdminKeywordActions} from "../actions/index"
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface State extends EntityState<any> {
    isLoading?: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
    totalRecords : number

  }
  
  export const adapter = createEntityAdapter<any>({
    selectId: (e) => e?.keywordID,
  });
  
  export const initialState = adapter.getInitialState( {
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    totalRecords : 0
  });

  export const reducer = createReducer(
    initialState,
 
    on(AdminKeywordActions.LoadAllTrendingKeywords, (state, action) => ({
      ...state,
      isLoading: true,
    })),

    on(
      AdminKeywordActions.LoadAllTrendingKeywordsSuccessfully,
      (state, action) =>
        adapter.setAll(action.trendingList, {
          ...state,
          totalRecords: action.totalRecords,
          isLoading: false,
        })
    ),

    on(AdminKeywordActions.LoadAllTrendingKeywordsError, (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    })),

    on(AdminKeywordActions.GetKeywordDetail, (state, action) => ({
      ...state,
      isLoading: true,
    })),

    on(
      AdminKeywordActions.UpdateKeywordShowOnHomePageStatus,
      (state, action) => ({ ...state, isLoading: true })
    ),

    on(AdminKeywordActions.UpdateKeywordShowOnHomePageSuccessfully,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action.message
    })
    ),
    on(AdminKeywordActions.UpdateKeywordShowOnHomePageStatusError,(state, action) =>
    ({
    ... state,
    isLoading: false,
    isSaving: false,
    isError: true,
    errorMessage: action.message,
    successMessage: ''
    })
    ),

    on(AdminKeywordActions.GetKeywordDetailSuccessfully, (state, action) =>
      adapter.updateOne(action.keywordDetail, {
        ...state,
        isLoading: false,
        errorMessage: '',
        successMessage: action?.message,
      })
    ),

    on(AdminKeywordActions.GetKeywordDetailError, (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    })),

    on(AdminKeywordActions.MergeKeywords, (state, action) => ({
      ...state,
      isLoading: true,
    })),

    on(AdminKeywordActions.MergeKeywordsSuccessfully, (state, action) => ({
      ...state,
      errorMessage: '',
      successMessage: action.message,
      isLoading: false,
      isError: false,
    })),

    on(AdminKeywordActions.MergeKeywordsStatusError, (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
      successMessage: '',
    }))
  );
  
  
  export const {
    selectAll
    } = adapter.getSelectors();