/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminContributorActions } from '../actions';
import { catchError, exhaustMap, map, of, switchMap, withLatestFrom } from 'rxjs';
import { ContributorService } from 'shared/src/services/admin/master-form/contributor.service';
import { Store, select } from '@ngrx/store';
import { AdminContributorSelector, AppState } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';


@Injectable()
export class AdminContributorEffects {
  constructor(private actions$: Actions, private service: ContributorService, private store: Store<AppState>, private router: Router,) {}

  //////////////// get all Contributors ////////////
  LoadContributorList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminContributorActions.LoadContributors),
      exhaustMap((action) => {
        return this.service.loadContributors(action?.payload).pipe(
          map((response) => {
            return AdminContributorActions.LoadContributorSuccessfully({
              contributorList: response?.contributorList,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              AdminContributorActions.LoadContributorError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// add Contributor ////////////
  AddContributor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminContributorActions.AddContributor),
      exhaustMap((action) => {
        return this.service.addContributor(action?.payload).pipe(
          map((response) => {
            return AdminContributorActions.AddContributorSuccessfully({
              contributor: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminContributorActions.AddContributorError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Contributor ////////////
  UpdateContributor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminContributorActions.UpdateContributor),
      exhaustMap((action) => {
        return this.service.updateContributor(action?.payload,action?.id).pipe(
          map((response) => {
            return AdminContributorActions.UpdateContributorSuccessfully({
              contributor: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminContributorActions.UpdateContributorError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Contributor status////////////
  UpdateContributorStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminContributorActions.UpdateContributorStatus),
      exhaustMap((action) => {
        return this.service.updateContributorStatus(action?.payload).pipe(
          map((response) => {
            return AdminContributorActions.UpdateContributorStatusSuccessfully({
              contributor: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminContributorActions.UpdateContributorStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  ///////////////// delete Contributor ///////////////////////
  DeleteContributor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminContributorActions.DeleteContributor),
      exhaustMap((action) =>
        this.service.deleteContributor(action?.payload).pipe(
          map((response) => {
            return AdminContributorActions.DeleteContributorSuccessfully({
              message: response?.message,
            });
          }),
          catchError((res) =>
            of(
              AdminContributorActions.DeleteContributorError({
                message: res.error?.error?.message,
              })
            )
          )
        )
      )
    )
  );

//////////////// add Contributor ////////////
  MergeContributor$ = createEffect(() =>
        this.actions$.pipe(
          ofType(AdminContributorActions.MergeContributor),
          exhaustMap((action) => {
            return this.service.mergeContributors(action?.payload).pipe(
              map((response) => {
                return AdminContributorActions.MergeContributorSuccessfully({
                  message: response?.message,
                });
              }),
              catchError((error) =>
                of(
                  AdminContributorActions.AddContributorError({
                    message: error?.error?.error?.message,
                  })
                )
              )
            );
          })
        )
      );

 ///////////////////// redirect after adding/deleting/merging Contributor /////////////////////     
  AddDeleteMergeContributorSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminContributorActions.AddContributorSuccessfully, AdminContributorActions.DeleteContributorSuccessfully,AdminContributorActions.MergeContributorSuccessfully),
      withLatestFrom(this.store.pipe(select(AdminContributorSelector.loadContributorList))),
      switchMap(([action, state]) => {
        this.router.navigateByUrl('/master-forms/manage/contributors');
        const pageNumber = (action.type === AdminContributorActions.AddContributorSuccessfully.type || action.type === AdminContributorActions.MergeContributorSuccessfully.type) ? 1 : state?.pageNumber;
        const payload = { pageNumber: pageNumber, pageSize: state?.pageSize, sortBy: state?.sortBy,orderBy: state?.orderBy };
        return of(AdminContributorActions.LoadContributors({payload}));
      })
    )
  )
}
