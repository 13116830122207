
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { GenericListingPayloadWithCategoryFilter } from 'shared/src/interfaces/common.interface';
import { AssignedTask } from 'shared/src/interfaces/staff/document.interface';

export const LoadAssignedTaskList = createAction(
  '[Staff] Load Assigned Task List',
  props<{ payload: GenericListingPayloadWithCategoryFilter }>()
);

export const LoadAssignedTaskListSuccess = createAction(
  '[Staff] Load Assigned Task List Success',
  props<{ taskList: AssignedTask[]; totalRecords: number }>()
);

export const LoadAssignedTaskListError = createAction(
  '[Staff] Load Assigned Task List Error',
  props<{ message: string }>()
);

export const SubmitAssignedTask = createAction(
  '[Staff] Submit Assigned Task',
  props<{ PSID: number }>()
);

export const SubmitAssignedTaskSuccess = createAction(
  '[Staff] Submit Assigned Task Success',
  props<{ task: AssignedTask, message: string }>()
);

export const SubmitAssignedTaskError = createAction(
  '[Staff] Submit Assigned Task Error',
  props<{ message: string }>()
);
