import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState, Update } from "@ngrx/entity";
import { ProcessActions } from '../actions';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { Process } from "shared/src/interfaces/master-form.interface";

export interface State extends EntityState<any> {
    payload: GenericListingPayload,
    totalRecords: number,
    isLoading?: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<Process>({
    selectId: (e) => e.ProcessID
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(ProcessActions.LoadProcess, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(ProcessActions.LoadProcessSuccess, (state, action) =>
    ({
        ...adapter.setAll(action.processes, state),
        totalRecords: action.totalRecords,
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(ProcessActions.LoadProcessError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(ProcessActions.UpdateProcessStatus, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(ProcessActions.UpdateProcessStatusSuccess, (state, action) =>
    ({
        ...adapter.updateMany(action.processUpdates, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(ProcessActions.UpdateProcessStatusError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(ProcessActions.AddProcess, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(ProcessActions.AddProcessSuccess, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(ProcessActions.AddProcessError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(ProcessActions.EditProcess,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(ProcessActions.EditProcessSuccess,(state, action) =>
     ({
        ...adapter.updateOne({id: action.process.ProcessID, changes: action.process}, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
      })
  ),
  on(ProcessActions.EditProcessError,(state, action) => 
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
  on(ProcessActions.DeleteProcess, (state, action) =>
  ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
  })
  ),
  on(ProcessActions.DeleteProcessSuccess, (state, action) =>
  ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action.message
  })
  ),
  on(ProcessActions.DeleteProcessError, (state, action) =>
  ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
  })
  ),
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();