import { Component, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { CountryActions, CountrySelector, WebAuthUserActions, WebAuthUserSelector } from '@panjab-digi-lib/shared';
import { AlertService } from 'shared/src/services/website/alert.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { SpinnerButtonComponent } from 'shared/src/lib/components/web/spinner-button/spinner-button.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DeleteDeactivateAccountComponent } from './delete-deactivate-account/delete-deactivate-account.component';

@Component({
  selector: 'panjab-digi-lib-user-profile',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, AlertsComponent, NgbTooltipModule, SpinnerButtonComponent, ChangePasswordComponent, DeleteDeactivateAccountComponent],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss',
})
export class UserProfileComponent implements OnInit, OnDestroy {
  countryList$!: Observable<any>;
  userProfileForm!: FormGroup;
  isReadOnly = true;
  subscription = new Subscription();
  isSubmitted = false;
  showAlert = true;
  enableChangePassword = true;
  @ViewChild(ChangePasswordComponent) changePasswordComponent!: ChangePasswordComponent;
  @ViewChild(DeleteDeactivateAccountComponent) deleteDeactivateAccountComponent!: DeleteDeactivateAccountComponent;
  constructor(
    private fb: FormBuilder,
    private localStorage: LocalStorageService,
    private store: Store,
    private alertService: AlertService
  ) {
    this.getCountriesList();
    this.initForm();
  }

  ngOnInit() {
    this.subscription.add(
      this.store.select(WebAuthUserSelector.selectError).subscribe((error: any) => {
        if (error && error.message) {
          this.isSubmitted = false;
          this.alertService.error(error.message);
        }
      })
    );
    this.subscription.add(
      this.store.select(WebAuthUserSelector.selectSuccess).subscribe((message: any) => {
        if (message) {
          this.alertService.success(message);
          this.isSubmitted = false;
        }
      })
    );
  }

  handleAlerts(value: boolean) {
    this.showAlert = value;
  }

  getCountriesList() {
    const payload = {};
    this.store.dispatch(CountryActions.LoadActiveCountryList({payload}));
    this.countryList$ = this.store.select(CountrySelector.getCountries);
  }

  initForm() {
    const activeUser = this.localStorage.get('webUser');
    const {
      email = '',
      username = '',
      fullname = '',
      country = '',
      state = '',
      address1 = '',
      address2 = ''
    } = activeUser;
    this.userProfileForm = this.fb.group({
      email: [email],
      username: [username],
      fullname: [fullname, [Validators.required, Validators.minLength(5), Validators.pattern(/^[a-zA-Z]+(?:\s[a-zA-Z0-9]+)*(?:\s[a-zA-Z0-9]+)?$/)]],
      country: [{value: country, disabled: this.isReadOnly}, Validators.required],
      state: [state],
      address1: [address1],
      address2: [address2],
    });
    this.enableChangePassword = activeUser?.changePasswd;
  }

  onEditClick() {
    this.isReadOnly = false;
    this.userProfileForm.controls['country'].enable();
  }

  resetFormState() {
    const user = this.localStorage.get('webUser');
    this.userProfileForm.patchValue({
      fullname: user.fullname,
      username: user.username,
      country: user.country,
      state: user.state,
      address1: user.address1,
      address2: user.address2
    });
  }
  
  onCancel() {
    this.isReadOnly = true;
    this.userProfileForm.controls['country'].disable();
    this.resetFormState();
  }

  onSubmit() {
    this.alertService.clear();
    this.userProfileForm.markAllAsTouched();
    if(this.userProfileForm.valid) {
      this.isSubmitted = true;
      const payload = {
        fullname: this.userProfileForm.controls['fullname'].value,
        country: this.userProfileForm.controls['country'].value,
        state: this.userProfileForm.controls['state'].value,
        address1: this.userProfileForm.controls['address1'].value,
        address2: this.userProfileForm.controls['address2'].value,
      }
      this.subscription.add(this.store.dispatch(WebAuthUserActions.updateProfileRequest({payload})));
      this.subscription.add(this.store.dispatch(WebAuthUserActions.resetState()));
    }
  }

  openChangePasswordModal() {
    if(this.changePasswordComponent) {
      this.changePasswordComponent.openModalPopup();
    }
  }

  openDeleteDeactivateModal() {
    if(this.deleteDeactivateAccountComponent) {
      this.deleteDeactivateAccountComponent.openModalPopup();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
