import { createAction, props } from '@ngrx/store';
import { UserProfile } from 'shared/src/interfaces/auth-user.interface';
// web edit user profile actions
export const updateProfileRequest = createAction(
  '[WebUser] Update Profile Request',
  props<{ payload: UserProfile }>()
);

export const updateProfileSuccess = createAction(
  '[WebUser] Update Profile Success',
  props<{ successMessage: string }>()
);

export const updateProfileError = createAction(
  '[WebUser] Update Profile Error',
  props<{ errorMessage: any }>()
);

export const resetState = createAction(
  '[WebUser] Reset User State'
)