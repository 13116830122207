import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap, take } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { DocumentListActions, TaskActions } from 'shared/src/store/actions';
import { TaskService } from 'shared/src/services/staff/task.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { DocumentListSelector, TaskSelector } from '@panjab-digi-lib/shared';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';

@Injectable()
export class TaskEffects {
  constructor(
    private actions$: Actions,
    private taskService: TaskService,
    private store: Store<AppState>
  ) { }
  LoadTaskList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.LoadTaskList),
      switchMap(({ payload, DocID }) =>
        this.taskService.loadTaskList(payload, DocID).pipe(
          map((response) => {
            return TaskActions.LoadTaskListSuccess({
              taskList: response.taskList,
              totalRecords: response.totalRecords
            })
          }),
          catchError((error: any) => {
            return of(TaskActions.LoadTaskListError({ message: (error.message) }))
          })
        )
      )
    )
  );

  BypassTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.BypassTask),
      switchMap(({ PSID, undoBypass }) =>
        this.taskService.bypassTask(PSID, undoBypass).pipe(
          map((response) => {
            return TaskActions.BypassTaskSuccess({
              task: response.body.task,
              message: response.message
            })
          }),
          catchError((error: any) => {
            return of(TaskActions.BypassTaskError({ message: (error.message) }))
          })
        )
      )
    )
  );

  OnBypassTaskSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.ApproveTaskSuccess, TaskActions.BypassTaskSuccess),
      switchMap(() =>
        this.store.select(DocumentListSelector.getListingPayload).pipe(
          take(1),
          map(payload => DocumentListActions.LoadDocumentList({ payload }))
        )
      )
    )
  );
}
