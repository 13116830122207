/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { VolunteerActions } from '../actions';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { Volunteer } from 'shared/src/interfaces/volunteer.interface';

export interface State extends EntityState<Volunteer> {
    payload: GenericListingPayload,
    totalRecords: number,
    isLoading?: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<Volunteer>({
    selectId: (e) => e.ID
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(VolunteerActions.LoadVolunteer, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(VolunteerActions.LoadVolunteerSuccess, (state, action) =>
    ({
        ...adapter.setAll(action.volunteerList, state),
        totalRecords: action.totalRecords,
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(VolunteerActions.LoadVolunteerError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(VolunteerActions.UpdateVolunteerStatus, (state) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(VolunteerActions.UpdateVolunteerStatusSuccess, (state, action) =>
    ({
        ...adapter.updateMany(action.volunteerUpdates, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(VolunteerActions.UpdateVolunteerStatusError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),

    on(VolunteerActions.AddVolunteer, (state) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(VolunteerActions.AddVolunteerSuccess, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(VolunteerActions.AddVolunteerError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(VolunteerActions.EditVolunteer, (state) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(VolunteerActions.EditVolunteerSuccess, (state, action) =>
    ({
        ...adapter.updateOne({ id: action.volunteer.ID, changes: action.volunteer }, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(VolunteerActions.EditVolunteerError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    )
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();