
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import {AddEditAuthorPayload,DeleteAuthorPayload,GetAuthorPayload, MergeAuthorPayload,Author,UpdateAuthorStatusPayload} from 'shared/src/interfaces/author.interface';
export const LoadAuthors = createAction(
  '[Author] Load Author List',
  props<{ payload: GetAuthorPayload }>()
);

export const LoadAuthorSuccessfully = createAction(
  '[Author] Load Author Successfully',
  props<{ authorList: Author[]; totalRecords: number }>()
);

export const LoadAuthorError = createAction(
  '[Author] Load Author Error',
  props<{ message: string }>()
);

export const AddAuthor = createAction(
  '[Author] Add Author to List',
  props<{ payload: AddEditAuthorPayload }>()
);

export const AddAuthorSuccessfully = createAction(
  '[Author] Author Added Successfully',
  props<{ author: Author; message: string }>()
);

export const AddAuthorError = createAction(
  '[Author] Author Added Error',
  props<{ message: string }>()
);

export const UpdateAuthor = createAction(
  '[Author] Edit Author in List',
  props<{ payload: AddEditAuthorPayload,id:number }>()
);

export const UpdateAuthorSuccessfully = createAction(
  '[Author] Author Updated Successfully',
  props<{ author: Author; message: string }>()
);

export const UpdateAuthorError = createAction(
  '[Author] Author Updated Error',
  props<{ message: string }>()
);

export const UpdateAuthorStatus = createAction(
    '[Author] Update Author Status in List',
    props<{ payload: UpdateAuthorStatusPayload }>()
  );
  
  export const UpdateAuthorStatusSuccessfully = createAction(
    '[Author] Author Status Updated Successfully',
    props<{ author: Author[]; message: string }>()
  );
  
  export const UpdateAuthorStatusError = createAction(
    '[Author] Author Status Updated Error',
    props<{ message: string }>()
  );

  export const MergeAuthor = createAction(
    '[Author] Merge Author',
    props<{ payload: MergeAuthorPayload }>()
  );
  
  export const MergeAuthorSuccessfully = createAction(
    '[Author] Merge Author Successfully',
    props<{ message: string }>()
  );
  
  export const MergeAuthorError = createAction(
    '[Author] Author Status Updated Error',
    props<{ message: string }>()
  );

  export const DeleteAuthor= createAction(
    '[Author] Delete Status from List',
    props<{ payload: DeleteAuthorPayload }>()
  );
  
  export const DeleteAuthorSuccessfully = createAction(
    '[Author] Author Deleted Successfully',
    props<{ message: string }>()
  );
  
  export const DeleteAuthorError = createAction(
    '[Author] Author Status Updated Error',
    props<{ message: string }>()
  );