/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { CountryActions } from '../actions';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { Country } from "shared/src/interfaces/master-form.interface";

export interface State extends EntityState<any> {
    payload: GenericListingPayload,
    totalRecords: number,
    isLoading?: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<Country>({
    selectId: (e) => e.CountryID
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(CountryActions.LoadCountry, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(CountryActions.LoadActiveCountryList, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(CountryActions.LoadActiveCountryListSuccess, (state, action) =>
    ({
        ...adapter.setAll(action.countryList, state),
        totalRecords: action.totalRecords,
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(CountryActions.LoadCountrySuccess, (state, action) =>
    ({
        ...adapter.setAll(action.countries, state),
        totalRecords: action.totalRecords,
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(CountryActions.LoadCountryError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(CountryActions.UpdateCountryStatus, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(CountryActions.UpdateCountryStatusSuccess, (state, action) =>
    ({
        ...adapter.updateMany(action.countryUpdates, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(CountryActions.UpdateCountryStatusError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),

    on(CountryActions.AddCountry, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(CountryActions.AddCountrySuccess, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(CountryActions.AddCountryError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(CountryActions.EditCountry, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(CountryActions.EditCountrySuccess, (state, action) =>
    ({
        ...adapter.updateOne({ id: action.country.CountryID, changes: action.country }, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(CountryActions.EditCountryError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(CountryActions.DeleteCountry, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(CountryActions.DeleteCountrySuccess, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(CountryActions.DeleteCountryError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();