<panjab-digi-lib-static-page-head [pageName]="'pdl-website.donorLevels.titleText' | translate" pagePath="/donor-levels">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <div class="d-flex workingGroup">
      <div class="img"><img src="/assets/images/data-mining.png" /></div>
      <div>
        <h1 class="heading mb-3">Every Gift Makes a Difference</h1>

        <p class="text">Every donation goes a long way to preserving and safeguarding the tangible and intangible
          heritage of Panjab. We are blessed and grateful for the generosity of all our patrons. Your support really
          matters.</p>

        <h1 class="heading mb-3">Annual Donor Levels and Benefits</h1>

        <p class="text"> Satluj - $365 - $1000</p>
        <p class="text"> Acknowledgement in quarterly newsletter.</p>
        <p class="text"> Satluj & Ravi - $1001 - $2000</p>
        <p class="text"> Above benefits, plus:</p>
        <p class="text"> Coin of the Sikh nation</p>
        <p class="text"> Satluj, Ravi & Beas - $2001 - $5000Above benefits, plus:</p>
        <p class="text"> Framed Art</p>
        <p class="text"> Satluj, Ravi, Beas & Chenab - $5001 - $10000Above benefits, plus:</p>
        <p class="text"> Acknowledgement in Annual Report</p>
        <p class="text"> Satluj, Ravi, Beas, Chenab & Jhelum - $10001 plus</p>
        <p class="text"> Above benefits, plus:</p>
        <p class="text"> Acknowledgement on website and quarterly phone call from Executive Director</p>
        <p class="text"> We assure you that Panjab Digital Library DOES NOT disclose, rent or otherwise sell our donor
          lists.</p>
      </div>
    </div>

    }
  </div>
</section>
