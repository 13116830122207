import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState, WebHomePageActions, WebTrendingKeywordsSelector } from '@panjab-digi-lib/shared';
import { Observable } from 'rxjs';
import { Keywords } from 'shared/src/interfaces/homepage.interface';


@Component({
  selector: 'panjab-digi-lib-trending-keywords',
  standalone: true,
  imports: [CommonModule,TranslateModule],
  templateUrl: './trending-keywords.component.html',
  styleUrl: './trending-keywords.component.scss',
})
export class TrendingKeywordsComponent implements OnInit {

  todayKeywords$!: Observable<Keywords[]>;
  historicKeywords$!: Observable<Keywords[]>;
  
  constructor(private translate: TranslateService, private store: Store<AppState>) {

  }

  ngOnInit(): void {
    this.store.dispatch(WebHomePageActions.LoadWebTrendingKeywords());

    this.todayKeywords$ = this.store.select(WebTrendingKeywordsSelector.getTrendingKeywords);
    
    this.historicKeywords$ = this.store.select(WebTrendingKeywordsSelector.getHistoricalTrending);
  }
}
