/* eslint-disable @nx/enforce-module-boundaries */
import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromTask from "shared/src/store/reducers/staff/task.reducer";

export const selectTaskState =
  createFeatureSelector<fromTask.State>('task');

export const getTaskList = createSelector(
    selectTaskState,
    fromTask.selectAll
);

export const getTotalRecords = createSelector(
  selectTaskState,
  (state) => state.totalRecords
);

export const getTaskByID = (props: {id: string}) => createSelector(
  selectTaskState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectTaskState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectTaskState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectTaskState,
  (state) => state.isLoading,
);