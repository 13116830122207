import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { API_ENDPOINTS } from '../app.endpoints';
import { map, Observable } from 'rxjs';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { AddEditDonationPayload, Donor } from "shared/src/interfaces/web-management.interface";
@Injectable({
  providedIn: 'root'
})
export class DonorService {
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadDonorList(payload: GenericListingPayload): Observable<{donorList: Donor[], totalCount: number}> {
    let endpoint = payload.viewType == 'donors' ? API_ENDPOINTS.webUserMgmt.donor.default : API_ENDPOINTS.webUserMgmt.donor.donations;
    return this.http.get(`${this.rootURL}${endpoint}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }

  getDonationByID(DonID: string) {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.webUserMgmt.donor.donations}/${DonID}`).pipe(map((e: any) => e.body));
  }

  getDonationTypes() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.webUserMgmt.donor.donationTypes}`).pipe(map((e: any) => e.body));
  }
  
  downloadDonors(viewType: string) {
    let endpoint = viewType == 'donors' ? API_ENDPOINTS.webUserMgmt.donor.download : API_ENDPOINTS.webUserMgmt.donor.downloadDonations;
    return this.http.get(`${this.rootURL}${endpoint}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }

  addDonation(payload: AddEditDonationPayload) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.webUserMgmt.donor.default}`, payload).pipe(map((e: any) => e));
  }
  
  editDonation(payload: AddEditDonationPayload, DonationId: string) {
    return this.http.put(`${this.rootURL}${API_ENDPOINTS.webUserMgmt.donor.default}/${DonationId}`, payload).pipe(map((e: any) => e));
  }
  
  deleteDonation(DonationIDs: number[]) {
    return this.http.delete(`${this.rootURL}${API_ENDPOINTS.webUserMgmt.donor.default}`, {body: {id: DonationIDs}}).pipe(map((e: any) => e));
  }
}
