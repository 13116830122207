import { Injectable, TemplateRef } from '@angular/core';
export interface Toast {
  message: string;
  classname?: string;
  delay?: number;
}

@Injectable({
  providedIn: 'root'
})

export class ToastService {

  toasts: Toast[] = [];

  private show(toast: Toast) {
    this.toasts.push(toast);
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  showStandard(message: string) {
    this.show({ message });
  }

  showSuccess(message: string, delay: number=7000) {
    this.show({ message, classname: 'bg-success text-light', delay });
  }

  showError(message: string, delay: number=7000) {
    this.show({ message, classname: 'bg-danger text-light', delay });
  }

  showWarning(message: string, delay: number=7000) {
    this.show({ message, classname: 'toast__warning__bg text-light', delay });
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }

}
