import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, DonorActions, DonorSelector, ViewDonationActions } from '@panjab-digi-lib/shared';
import { DonorService } from 'shared/src/services/admin/master-form/donor.service';

@Injectable()
export class ViewDonationEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private donorService: DonorService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadDonationListList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ViewDonationActions.LoadDonationList),
            exhaustMap((action) => {
                return this.donorService.loadDonorList(action.payload).pipe(
                    map((response) => {
                        return ViewDonationActions.LoadDonationListSuccess({ donors: response.donorList, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(ViewDonationActions.LoadDonationListError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
}