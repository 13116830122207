import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromMetaData from "../reducers/metadata.reducer";

export const selectMetaDataState =
  createFeatureSelector<fromMetaData.State>('metaData');

export const getMetaData = createSelector(
    selectMetaDataState,
    fromMetaData.selectAll
);

export const getTotalRecords = createSelector(
  selectMetaDataState,
  (state) => state.totalRecords
);

export const getMetaDataByID = (props: {id: string}) => createSelector(
  selectMetaDataState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectMetaDataState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectMetaDataState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);