import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromSection from "../reducers/section.reducer";

export const selectSectionState =
  createFeatureSelector<fromSection.State>('section');

export const getSections = createSelector(
    selectSectionState,
    fromSection.selectAll
);

export const getTotalRecords = createSelector(
  selectSectionState,
  (state) => state.totalRecords
);

export const getSectionByID = (props: {id: string}) => createSelector(
  selectSectionState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectSectionState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectSectionState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);