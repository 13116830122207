/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { ProcessesPerCategory, ProcessesOfCategory, SaveAllocationPayload } from "shared/src/interfaces/master-form.interface";

export const LoadProcessPerCategory = createAction(
    '[ProcessPerCategory] Load processes Per Category'
)

export const LoadProcessPerCategorySuccess = createAction(
    '[ProcessPerCategory] Load processes Per Category Successfully',
    props<{category: ProcessesPerCategory[]}>()
)

export const LoadProcessPerCategoryError = createAction(
    '[ProcessPerCategory] Load processes Per Category Error',
    props<{message: string}>()
)

export const LoadprocessesOfCategory = createAction(
    '[ProcessPerCategory] Load processes for single Category',
    props<{payload: GenericListingPayload, CategoryID: number}>(),
)

export const LoadprocessesOfCategorySuccess = createAction(
    '[ProcessPerCategory] Load processes for single Category Successfully',
    props<{processes: ProcessesOfCategory[], totalCount: number, CategoryID: number}>()
)

export const LoadprocessesOfCategoryError = createAction(
    '[ProcessPerCategory] Load processes for single Category Error',
    props<{message: string}>()
)

export const SaveAllocations = createAction(
    '[ProcessPerCategory] Save Allocations',
    props<{payload: SaveAllocationPayload, CategoryID: number}>(),
)

export const SaveAllocationsSuccess = createAction(
    '[ProcessPerCategory] Save Allocations Successfully',
    props<{message: string, CategoryID: number}>()
)

export const SaveAllocationsError = createAction(
    '[ProcessPerCategory] Save Allocations Error',
    props<{message: string}>()
)
