/* eslint-disable @nx/enforce-module-boundaries */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar/topbar.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { AuthSelector } from '@panjab-digi-lib/shared';
import * as jwt_decode from 'jwt-decode';
import { Subscription } from 'rxjs';
import { UserService } from 'shared/src/services/website/user/user.service';
import { LoggedInUser } from 'shared/src/interfaces/auth-user.interface';

@Component({
  selector: 'panjab-digi-lib-web-header',
  standalone: true,
  imports: [CommonModule, TopbarComponent, TranslateModule, RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class WebHeaderComponent implements OnInit, OnDestroy {
  activeUser: any;
  subscription = new Subscription();
  constructor(
    private translate: TranslateService,
    private store: Store,
    private router: Router,
    private localStorage: LocalStorageService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.localStorage.changes$.subscribe((data:any) => {
        if(data && data.key === 'webUser') {
          this.activeUser = data.type === 'remove' ? null : {...this.activeUser, ...data.value};
        }
      })
    );

    this.subscription.add(
      this.store.select(AuthSelector.selectWebUser).subscribe((user: LoggedInUser) => {
        if(user) {
          this.activeUser = user;
        } else if(this.localStorage.get('webUser')) {
          this.activeUser = this.localStorage.get('webUser');
        }
      })
    );
  }

  async signOut(): Promise<void> {
    this.userService.logOutWebUser();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
