/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { AddEditDepartmentPayload, Department } from "shared/src/interfaces/master-form.interface";

export const LoadDepartment = createAction(
    '[Department] Load Department',
    props<{payload: GenericListingPayload}>(),
)

export const LoadDepartmentSuccess = createAction(
    '[Department] Load Department Successfully',
    props<{departments: Department[], totalRecords: number}>()
)

export const LoadDepartmentError = createAction(
    '[Department] Load Department Error',
    props<{message: string}>()
)

export const UpdateDepartmentStatus = createAction(
    '[Department] Update Department Status',
    props<{departmentIDs: number[], status: string}>(),
)

export const UpdateDepartmentStatusSuccess = createAction(
    '[Department] Update Department Status Success',
    props<{departmentUpdates: any, message: string}>(),
)

export const UpdateDepartmentStatusError = createAction(
    '[Department] Update Department Status Error',
    props<{message: string}>(),
)

export const AddDepartment = createAction(
    '[Department] Add Department',
    props<{payload: AddEditDepartmentPayload}>(),
)

export const AddDepartmentSuccess = createAction(
    '[Department] Add Department Success',
    props<{department: Department, message: string}>(),
)

export const AddDepartmentError = createAction(
    '[Department] Add Department Error',
    props<{message: string}>(),
)

export const EditDepartment = createAction(
    '[Department] Edit Department',
    props<{payload: AddEditDepartmentPayload, departmentId: string}>(),
)

export const EditDepartmentSuccess = createAction(
    '[Department] Edit Department Success',
    props<{department: Department, message: string}>(),
)

export const EditDepartmentError = createAction(
    '[Department] Edit Department Error',
    props<{message: string}>(),
)

export const DeleteDepartment = createAction(
    '[Department] Delete Department',
    props<{departmentIDs: number[]}>(),
)

export const DeleteDepartmentSuccess = createAction(
    '[Department] Delete Department Success',
    props<{message: string}>(),
)

export const DeleteDepartmentError = createAction(
    '[Department] Delete Department Error',
    props<{message: string}>(),
)