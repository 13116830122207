import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromProcessPerCategory from "../reducers/processPerCategory.reducer";

export const selectProcessPerCategoryState =
  createFeatureSelector<fromProcessPerCategory.State>('processPerCategory');

export const getProcessPerCategory = createSelector(
    selectProcessPerCategoryState,
    fromProcessPerCategory.selectAll
);

export const getProcessPerCategoryByID = (props: {id: number}) => createSelector(
  selectProcessPerCategoryState,
  (state) => state.entities[props.id]
)

export const getProcessOfCategory = createSelector(
    selectProcessPerCategoryState,
    (state) => state.processesOfCategory,
);

export const getTotalRecords = createSelector(
    selectProcessPerCategoryState,
    (state) => state.totalRecords,
);

export const onSave = createSelector(
  selectProcessPerCategoryState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectProcessPerCategoryState,
  (state) => state.isLoading
);

export const getListingPayload = createSelector(
    selectProcessPerCategoryState,
    (state) => state.payload
)