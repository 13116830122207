<panjab-digi-lib-static-page-head [pageName]="'pdl-website.caseStudies.titleText' | translate" pagePath="/case-studies">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container">
    <p class="text">The following section includes case studies, ranging from building a theoretical understanding of
      how a digitization project is run to the actual ability to formulate, implement and evaluate a small pilot program
      or a full-fledged digitization project. Some sections may deal with only a part of the complete process and the
      issues related to it, while others may present you with a consolidated view of all the aspects that constitute a
      complete and effective digitization program. Each case study focuses on certain distinct areas that are peculiars
      to that particular project and explores their pros and cons. This will help the readers to see what works best in
      a particular situation or environment, thus making them more equipped in decision making. In these case studies
      beginners will find enough information and guidelines to help them plan or initiate a digitization program.Case
      studies are not meant to me implemented exactly as they are explained. They are simply meant to provide
      information and a wider framework in which a project may be executed. Each environment and project affects the
      approach and may vary from the other drastically depending on its scope. Readers are requested to use their own
      wisdom while extracting information that serves their purpose best and is relevant to their particular needs and
      working environment.</p>
    <!-- case study 1 -->
    <div class="not-opened case-study">
      <h1 class="heading">An OCR Case Study</h1>
      <h1 class="info">Eileen Gifford Fenton JSTOR, University of Michigan</h1>
      <p class="text">Optical character recognition, or OCR, is the process that converts the text of a printed page to
        a digital file. This is accomplished by using an OCR software package to process a digital image of the printed
        page. The software first analyzes the layout of text on the page and divides the text into zones that usually
        correspond approximately ...</p>
      <div class="d-flex mt-2 btn-wrapper">
        <span class="cursor-pointer botbtn read">
          <span><a target="_blank" href="/assets/documents/case-studies/An OCR Case Study-pdf.pdf">Read</a></span>
          <img src="/assets/BookReader/icons/person_reading_book_green.svg" alt="download" />

        </span>
        <span class="cursor-pointer botbtn download">
          <span>
            <a target="_blank" href="/assets/documents/case-studies/An OCR Case Study.doc">Download</a>
          </span>
          <img src="/assets/images/icons/download-icon.svg" alt="download" />
        </span>
      </div>
    </div>
    <!-- case study 2 -->
    <div class=" case-study">
      <h1 class="heading">Cooperative Imaging: Scans Well with Others</h1>
      <h1 class="info">Steven D. Smith, Amigos Library Services, Inc.</h1>
      <p class="text">Digital imaging technology can assist libraries, archives, and museums in achieving a level of
        cooperation never before possible. Institutions traditionally have cooperated in filling voids within local
        collections --microfilming archives and offering them for sale, supplying missing journal issues, and, most
        obviously, participating in interlibrary ...</p>
      <div class="d-flex mt-2 btn-wrapper">
        <span class="cursor-pointer botbtn read">
          <span><a target="_blank" href="/assets/documents/case-studies/Cooperative Imaging-pdf.pdf">Read</a></span>
          <img src="/assets/BookReader/icons/person_reading_book_green.svg" alt="download" />

        </span>
        <span class="cursor-pointer botbtn download">
          <span>
            <a target="_blank" href="/assets/documents/case-studies/Cooperative Imaging.doc">Download</a>
          </span>
          <img src="/assets/images/icons/download-icon.svg" alt="download" />
        </span>
      </div>
    </div>

    <!-- case study 3 -->
    <div class="not-opened case-study">
      <h1 class="heading">Digitization of Maps and Other Oversize Documents</h1>
      <h1 class="info">Janet Gertz Columbia University Libraries</h1>
      <p class="text">Even the mechanics of scanning are affected. Many flatbed scanners have size limitations and
        cannot handle large maps. Scanning may require film intermediaries such as 4x5 transparencies or single-frame
        microfiche, where the original object fills the body of the microfiche. Thirty-five mm slides are too small to
        fully capture details ...</p>
      <div class="d-flex mt-2 btn-wrapper">
        <span class="cursor-pointer botbtn read">
          <span><a target="_blank"
              href="/assets/documents/case-studies/Digitization of Maps and Other Oversize Documents-pdf.pdf">Read</a></span>
          <img src="/assets/BookReader/icons/person_reading_book_green.svg" alt="download" />

        </span>
        <span class="cursor-pointer botbtn download">
          <span>
            <a target="_blank"
              href="/assets/documents/case-studies/Digitization of Maps and Other Oversize Documents.doc">Download</a>
          </span>
          <img src="/assets/images/icons/download-icon.svg" alt="download" />
        </span>
      </div>
    </div>

    <!-- case study 4 -->
    <div class=" case-study">
      <h1 class="heading">Working with Microfilm</h1>
      <h1 class="info">Paul Conway Yale University Library</h1>
      <p class="text">Preservation microfilm can be an excellent source-medium for digital conversion projects if
        certain caveats are taken into consideration. This section describes what librarians and archivists need to know
        about working with existing microfilm to produce high-quality digital images that can be displayed as images
        and/or processed ...</p>
      <div class="d-flex mt-2 btn-wrapper">
        <span class="cursor-pointer botbtn read">
          <span><a target="_blank" href="/assets/documents/case-studies/Working with Microfilm-pdf.pdf">Read</a></span>
          <img src="/assets/BookReader/icons/person_reading_book_green.svg" alt="download" />

        </span>
        <span class="cursor-pointer botbtn download">
          <span>
            <a target="_blank" href="/assets/documents/case-studies/Working with Microfilm.doc">Download</a>
          </span>
          <img src="/assets/images/icons/download-icon.svg" alt="download" />
        </span>
      </div>
    </div>

    <!-- case study 5 -->
    <div class="not-opened case-study">
      <h1 class="heading">Working with Photographs</h1>
      <h1 class="info">Franziska Frey, Image Permanence Institute</h1>
      <p class="text">The materials that make up photographs are not chemically stable. These materials include silver
        or dyes as image-forming materials; paper, celluloid, or other plastics as base materials; and gelatin, albumen,
        or collodion as binders. Environmental influences such as light, chemical agents, heat, humidity, and storage
        conditions ...</p>
      <div class="d-flex mt-2 btn-wrapper">
        <span class="cursor-pointer botbtn read">
          <span><a target="_blank" href="#read">Read</a></span>
          <img src="/assets/BookReader/icons/person_reading_book_green.svg" alt="download" />

        </span>
        <span class="cursor-pointer botbtn download">
          <span>
            <a target="_blank" href="/assets/documents/case-studies/Working with Photographs.doc">Download</a>
          </span>
          <img src="/assets/images/icons/download-icon.svg" alt="download" />
        </span>
      </div>
    </div>

    <!-- case study 6 -->
    <div class="case-study">
      <h1 class="heading">Working with Printed Text and Manuscripts</h1>
      <h1 class="info">Stephen Chapman, Harvard University Library</h1>
      <p class="text">Look at the growing body of network-accessible books, journals, and archives from cultural
        institutions and commercial publishers and you will discover that electronic text is not all alike. Some
        collections are searchable, others are not; some have highquality color reproductions, others limit their
        content to black-and-white (1-bit) images...</p>
      <div class="d-flex mt-2 btn-wrapper">
        <span class="cursor-pointer botbtn read">
          <span><a target="_blank"
              href="/assets/documents/case-studies/Working with Printed Text and Manuscripts-pdf.pdf">Read</a></span>
          <img src="/assets/BookReader/icons/person_reading_book_green.svg" alt="download" />

        </span>
        <span class="cursor-pointer botbtn download">
          <span>
            <a target="_blank"
              href="/assets/documents/case-studies/Working with Printed Text and Manuscripts.doc">Download</a>
          </span>
          <img src="/assets/images/icons/download-icon.svg" alt="download" />
        </span>
      </div>
    </div>
  </div>
</section>
