/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { AdminTranslatorActions } from '../actions/index';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Translator } from 'shared/src/interfaces/translator.interface';

export interface State extends EntityState<Translator> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  totalRecords: number;
  pageNumber : number,
  pageSize: number,
  sortBy: string,
  orderBy:string
}

export const adapter = createEntityAdapter<Translator>({
  selectId: (e) => e?.TransID,
});

export const initialState = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0,
  pageNumber : 0,
  pageSize: 0,
  sortBy: 'DateAdded',
  orderBy: 'desc'
});

export const reducer = createReducer(
  initialState,

  on(AdminTranslatorActions.LoadTranslators, (state,action) => ({
    ...state,
    isLoading: true,
    successMessage: '',
    errorMessage: '',
    pageNumber: action.payload?.pageNumber || 1,
    pageSize: action.payload?.pageSize || 0,
  })),

  on(AdminTranslatorActions.LoadTranslatorSuccessfully, (state, action) =>
    adapter.setAll(action?.translatorList, {
      ...state,
      totalRecords: action.totalRecords,
      isLoading: false,
    })
  ),

  on(AdminTranslatorActions.LoadTranslatorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminTranslatorActions.AddTranslator, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminTranslatorActions.AddTranslatorSuccessfully, (state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: action?.message
    })
  ),

  on(AdminTranslatorActions.AddTranslatorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminTranslatorActions.UpdateTranslator, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminTranslatorActions.UpdateTranslatorSuccessfully, (state, action) => ({
    ...adapter.updateOne(
      { id: action?.translator?.TransID, changes: action.translator },
      state
    ),
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: action?.message,
  })),

  on(AdminTranslatorActions.UpdateTranslatorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      successMessage: '',
      errorMessage: action?.message,
    };
  }),

  on(AdminTranslatorActions.UpdateTranslatorStatus, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminTranslatorActions.UpdateTranslatorStatusSuccessfully, (state, action) => {
    const updates = action?.translator;
    const updatedEntities = adapter.updateMany(
      updates.map((update: Translator) => ({
        id: update.TransID,
        changes: update,
      })),
      state
    );
    return {
      ...updatedEntities,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action?.message,
    };
  }),

  on(AdminTranslatorActions.UpdateTranslatorStatusError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),

  on(AdminTranslatorActions.DeleteTranslator, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminTranslatorActions.DeleteTranslatorSuccessfully, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: false,
      successMessage: action?.message,
      errorMessage: '',
    };
  }),

  on(AdminTranslatorActions.DeleteTranslatorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),
  on(AdminTranslatorActions.MergeTranslator, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminTranslatorActions.MergeTranslatorSuccessfully, (state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: action?.message
    })
  ),

  on(AdminTranslatorActions.MergeTranslatorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),
);

export const { selectAll } = adapter.getSelectors();
