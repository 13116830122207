<section class="overview-section section-padding pb-lg-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="overview-content d-flex align-items-center justify-content-between">
                    <div class="title-block me-4">
                        <span class="d-flex justify-content-start">{{'pdl-website.overviewSection.sectionTitle' | translate }}</span>
                        <h2 class="d-flex justify-content-start mb-3">{{'pdl-website.headerPage.logoTitle' | translate }}</h2>
                    </div>
                    <div class="overview-text d-flex justify-content-start">
                        <p class="mb-0">{{'pdl-website.overviewText.sectionText' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>