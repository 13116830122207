/* eslint-disable @nx/enforce-module-boundaries */
import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromStaffWork from "shared/src/store/reducers/staff/staff-work.reducer";

export const selectTaskState =
  createFeatureSelector<fromStaffWork.State>('staffWork');

export const getTaskList = createSelector(
    selectTaskState,
    fromStaffWork.selectAll
);

export const getTotalRecords = createSelector(
  selectTaskState,
  (state) => state.totalRecords
);

export const getTaskByID = (props: {id: string}) => createSelector(
  selectTaskState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectTaskState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectTaskState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectTaskState,
  (state) => state.isLoading,
);