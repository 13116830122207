/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { AdoptPriceService } from 'shared/src/services/admin/Fundraising/adopt-price.service';
import { AdminAdoptPriceActions } from '../actions';

@Injectable()
export class AdoptPriceEffects {
  defaultErrMsg = 'Something went wrong! Please try again later.';
  constructor(
    private actions$: Actions,
    private service: AdoptPriceService
  ) {}

  ////////////////// load categories with adopt price ////////////////////////////
  LoadAdoptPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminAdoptPriceActions.LoadCategoryWithPriceList),
      exhaustMap((action) => {
        return this.service.loadCategoriesWithPriceList(action.payload).pipe(
          map((response) => {
            return AdminAdoptPriceActions.LoadCategoryWithPriceListSuccess({
              categoryList: response?.body?.categoryList,
              totalRecords: response.body?.totalRecords,
            });
          }),
          catchError((error: any) =>
            of(
              AdminAdoptPriceActions.LoadCategoryWithPriceListError({
                message: error?.error?.error?.message || this.defaultErrMsg,
              })
            )
          )
        );
      })
    )
  );

  //////////////////// update adopt price of category /////////////////////////

  UpdateAdoptPriceStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminAdoptPriceActions.UpdateAdoptPrice),
      exhaustMap((action) => {
        return this.service.updateAdoptPrice(action?.id, action?.price).pipe(
          map((response) => {
            return AdminAdoptPriceActions.UpdateAdoptPriceSuccess({
              category: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminAdoptPriceActions.UpdateAdoptPriceError({
                message: error?.error?.error?.message || this.defaultErrMsg,
              })
            )
          )
        );
      })
    )
  );
}
