
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { GenericListingPayloadWithSearch } from 'shared/src/interfaces/common.interface';
import { StaffList } from 'shared/src/interfaces/staff/staff.interface';

export const LoadStaffList = createAction(
  '[Staff] Load Staff List',
  props<{ payload: GenericListingPayloadWithSearch }>()
);

export const LoadStaffListSuccess = createAction(
  '[Staff] Load Staff List Successfully',
  props<{ staffList: StaffList[]; totalRecords: number }>()
);

export const LoadStaffListError = createAction(
  '[Staff] Load Staff List Error',
  props<{ message: string }>()
);
