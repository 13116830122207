<panjab-digi-lib-static-page-head [pageName]="'pdl-website.digitizationTraining.titleText' | translate"
  pagePath="/digitalization-training">
</panjab-digi-lib-static-page-head>

<section class="static-section section-padding ">
  <div class="container">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <div class="d-flex workingGroup">
      <div class="img"><img src="/assets/images/data-mining.png" /></div>
      <div>
        <h1 class="heading mb-3">Panjab Digital Library digitization-training</h1>
        <p class="text">
          Panjab Digital Library (PDL), has been in the field of digitization since 2003, and is now a thoroughly
          professional digitization institution with a team of 31 dedicated personnel and modern digitization equipment.
          Because we want to share the experience with more of the public, we have commenced a digitization training
          program and will be holding workshops to train more people in various digitization techniques and processes.
          If you are interested in becoming training in digitization of books, manuscripts, pictures or your family
          treasures, you can fill in the attached <a href="" target="_blank"
            class="email cursor-pointer text">performa</a> and send it back to us.
        </p>
        <p class="text">We will inform you of the costs involved and invite you to attend a workshop as we plan them.
        </p>
      </div>
    </div>
    }
  </div>
</section>
