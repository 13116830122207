<section class="innerpage-banner">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
                    <a class="breadcrumb-link font-regular" href="#">
                        <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
                        Home
                    </a> 
                    <span class="chevron-arrow font-regular">
                        <img src="assets/images/icons/arrow-right.svg" alt="icon">
                    </span>
                    <a class="breadcrumb-link font-regular" href="#">
                        Advance Search
                    </a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="page-title">
                    <h1 class="heading-dark semibold mb-0">{{'pdl-website.advancesearchTitle.titleText' | translate }}</h1>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="advancesearch-section section-padding ">
    <div class="container">
        <div class="row search-title">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="page-title">
                    <h2 class="heading-dark semibold mb-0">{{'pdl-website.searchText.titleText' | translate }}</h2>
                </div>
            </div>

            <!-- <div class="col-12 mb-3">
                <div class="results-and-filters d-flex align-items-center justify-content-between">
                    <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
                        <span class="category-name semibold">Manuscripts</span>
                        <span>( 1</span>
                        <span>to</span>
                        <span>4</span>
                        <span>of about</span>
                        <span>1963</span>
                        <span>records)</span>
                    </div>
                    <div class="category-action">
                        <a href="#" class="btn pdl-btn see-all font-14">See All</a>
                    </div>
                </div>
            </div> -->

        </div>
        

        <!-- Row 1 -->

        <div class="row">

            <div class="col-12 mb-3">
                <div class="results-and-filters d-flex align-items-center justify-content-between">
                    <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
                        <span class="category-name semibold"> {{'pdl-website.categoryName1.categoryText' | translate }}</span>
                        <span>( 1</span>
                        <span>to</span>
                        <span>4</span>
                        <span>of about</span>
                        <span>1963</span>
                        <span>records)</span>
                    </div>
                    <div class="category-action">
                        <a href="javascript:void(0);" [routerLink]="'/all-results'" class="btn pdl-btn see-all font-14">
                            {{'pdl-website.seeallButton.buttonText' | translate }}
                        </a>
                        <!-- <a href="javascript:void(0);" [routerLink]="'/all-categories'" class="btn pdl-btn">{{'pdl-website.categoriesButton.buttonText' | translate }}</a> -->
                    </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="searches-in-grid mt-2">
                    
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            {{'pdl-website.categoryName1.categoryText' | translate }}
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card1.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">{{'pdl-website.languageText1.languageText' | translate }}</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            {{'pdl-website.categoryName1.categoryText' | translate }}
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card2.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">{{'pdl-website.languageText1.languageText' | translate }}</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            {{'pdl-website.categoryName1.categoryText' | translate }}
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card3.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">{{'pdl-website.languageText1.languageText' | translate }}</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card4.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">{{'pdl-website.languageText1.languageText' | translate }}</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>

                </div>
            </div>            
        </div>

        <!-- Row 2 -->

        <div class="row">

            <div class="col-12 mb-3">
                <div class="results-and-filters d-flex align-items-center justify-content-between">
                    <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
                        <span class="category-name semibold">Manuscripts</span>
                        <span>( 1</span>
                        <span>to</span>
                        <span>4</span>
                        <span>of about</span>
                        <span>1963</span>
                        <span>records)</span>
                    </div>
                    <div class="category-action">
                        <a href="#" class="btn pdl-btn see-all font-14">See All</a>
                    </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="searches-in-grid mt-2">
                    
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card1.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card2.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card3.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card4.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>

                </div>
            </div>            
        </div>

        <!-- Row 3 -->

        <div class="row">

            <div class="col-12 mb-3">
                <div class="results-and-filters d-flex align-items-center justify-content-between">
                    <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
                        <span class="category-name semibold">Manuscripts</span>
                        <span>( 1</span>
                        <span>to</span>
                        <span>4</span>
                        <span>of about</span>
                        <span>1963</span>
                        <span>records)</span>
                    </div>
                    <div class="category-action">
                        <a href="#" class="btn pdl-btn see-all font-14">See All</a>
                    </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="searches-in-grid mt-2">
                    
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card1.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card2.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card3.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card4.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>

                </div>
            </div>            
        </div>

        <!-- Row 4 -->

        <div class="row">

            <div class="col-12 mb-3">
                <div class="results-and-filters d-flex align-items-center justify-content-between">
                    <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
                        <span class="category-name semibold">Manuscripts</span>
                        <span>( 1</span>
                        <span>to</span>
                        <span>4</span>
                        <span>of about</span>
                        <span>1963</span>
                        <span>records)</span>
                    </div>
                    <div class="category-action">
                        <a href="#" class="btn pdl-btn see-all font-14">See All</a>
                    </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="searches-in-grid mt-2">
                    
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card1.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card2.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card3.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card4.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>

                </div>
            </div>            
        </div>

        <!-- Row 5 -->

        <div class="row">

            <div class="col-12 mb-3">
                <div class="results-and-filters d-flex align-items-center justify-content-between">
                    <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
                        <span class="category-name semibold">Manuscripts</span>
                        <span>( 1</span>
                        <span>to</span>
                        <span>4</span>
                        <span>of about</span>
                        <span>1963</span>
                        <span>records)</span>
                    </div>
                    <div class="category-action">
                        <a href="#" class="btn pdl-btn see-all font-14">See All</a>
                    </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="searches-in-grid mt-2">
                    
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card1.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card2.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card3.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card4.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>

                </div>
            </div>            
        </div>

        <!-- Row 6 -->

        <div class="row">

            <div class="col-12 mb-3">
                <div class="results-and-filters d-flex align-items-center justify-content-between">
                    <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
                        <span class="category-name semibold">Manuscripts</span>
                        <span>( 1</span>
                        <span>to</span>
                        <span>4</span>
                        <span>of about</span>
                        <span>1963</span>
                        <span>records)</span>
                    </div>
                    <div class="category-action">
                        <a href="#" class="btn pdl-btn see-all font-14">See All</a>
                    </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="searches-in-grid mt-2">
                    
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card1.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card2.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card3.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card4.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>

                </div>
            </div>            
        </div>

        <!-- Row 7 -->

        <div class="row">

            <div class="col-12 mb-3">
                <div class="results-and-filters d-flex align-items-center justify-content-between">
                    <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
                        <span class="category-name semibold">Manuscripts</span>
                        <span>( 1</span>
                        <span>to</span>
                        <span>4</span>
                        <span>of about</span>
                        <span>1963</span>
                        <span>records)</span>
                    </div>
                    <div class="category-action">
                        <a href="#" class="btn pdl-btn see-all font-14">See All</a>
                    </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="searches-in-grid mt-2">
                    
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card1.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card2.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card3.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <a href="#" class="search-card mb-4">

                                <div class="block-img">
                                    <div class="image-overlay">
                                        <span class="category-title">
                                            Manuscripts
                                        </span>
                                        <span class="ratings">
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                            <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                        </span>
                                    </div>
                                    <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                                    <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card4.titleText' | translate }}
                                    </h6>
                                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                                        <span class="font-10 d-flex">Persian</span>
                                        <span class="font-10 d-flex">16 Pages</span>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>

                </div>
            </div>            
        </div>

    </div>

</section>