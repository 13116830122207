/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GenericListingPayloadWithSearchAndFilters } from "shared/src/interfaces/common.interface";
import { VolunteerRequests } from "shared/src/interfaces/volunteer.interface";

export const LoadVolunteersRequest = createAction(
    '[Volunteer Reqs] Load Volunteer Request',
    props<{payload: GenericListingPayloadWithSearchAndFilters}>(),
)

export const LoadVolunteersRequestSuccess = createAction(
    '[Volunteer Reqs] Load Volunteer Request Successfully',
    props<{requestsList: VolunteerRequests[], totalRecords: number}>()
)

export const LoadVolunteersRequestError = createAction(
    '[Volunteer Reqs] Load Volunteer Request Error',
    props<{message: string}>()
)

export const UpdateVolunteerRequestStatus = createAction(
    '[Volunteer Reqs] Update Volunteer Request Status',
    props<{id: number, status: number}>()
)

export const UpdateVolunteerRequestStatusSuccess = createAction(
    '[Volunteer Reqs] Update Volunteer Request Successfully',
    props<{requestsList: VolunteerRequests[], message: string}>()
)

export const UpdateVolunteerRequestStatusError = createAction(
    '[Volunteer Reqs] Update Volunteer Request Error',
    props<{message: string}>()
)