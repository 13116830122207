/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap, exhaustMap } from 'rxjs/operators';
import { AdminWebDocManagerActions } from '../actions';
import { WebDocManagerService } from 'shared/src/services/admin/web-user-management/web-doc-manager.service';
import { Update } from '@ngrx/entity';
import { Document } from "shared/src/interfaces/web-doc-manager.interface";

@Injectable()
export class AdminWebDocMngrEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private webDocManagerService: WebDocManagerService
    ) {
    }
    
    LoadDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminWebDocManagerActions.LoadDocuments),
            switchMap((action) => {
                return this.webDocManagerService.loadDocuments(action.payload).pipe(
                    map((response) => {
                        return AdminWebDocManagerActions.LoadDocumentsSuccess({ documentList: response.documentList, totalRecords: response.totalRecords });
                    }),
                    catchError((error: any) => of(AdminWebDocManagerActions.LoadDocumentsError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                ))
            })
        )
    );

    UpdateDocumentStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminWebDocManagerActions.UpdateDocumentStatus),
            exhaustMap((action) => {
                return this.webDocManagerService.toggleListOfDocumentsStatus(action.ids, action.status).pipe(
                    map((response: any) => {
                        const updatedDocs: Update<Document>[] = action.ids.map((id: any) => {
                            const changes: any = {Status : action.status};
                            return { id, changes }
                        });
                        return AdminWebDocManagerActions.UpdateDocumentStatusSuccess({ documentsList: updatedDocs, message: response.message });
                    }),
                    catchError((error: any) => of(AdminWebDocManagerActions.UpdateDocumentStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message })))
                )
            })
        )
    );
}