import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'shared/src/environments/environment';
import { Document } from 'shared/src/interfaces/web-doc-manager.interface';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';

@Injectable({
  providedIn: 'root'
})
export class WebDocManagerService {

  rootURL = '';

  constructor(private http: HttpClient) { 
    this.rootURL = environment.rootApiUrl;
  }
  loadDocuments(payload: GenericListingPayload): Observable<{documentList: Document[], totalRecords: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.webUserMgmt.webDocManager.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }
  toggleListOfDocumentsStatus(ids: number[], status: number ) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.webUserMgmt.webDocManager.toggleDocumentStatus}`, {ids, status}).pipe(map((e: any) => e));
  }
  downloadDocuments() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.webUserMgmt.webDocManager.downloadDocuments}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }
}
