import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, DonorActions, DonorSelector } from '@panjab-digi-lib/shared';
import { DonorService } from 'shared/src/services/admin/master-form/donor.service';

@Injectable()
export class DonorEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private donorService: DonorService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadDonorList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonorActions.LoadDonorList),
            exhaustMap((action) => {
                return this.donorService.loadDonorList(action.payload).pipe(
                    map((response) => {
                        return DonorActions.LoadDonorListSuccess({ donors: response.donorList, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(DonorActions.LoadDonorListError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    donationChildView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonorActions.AddDonation),
            exhaustMap((action) => {
                return this.donorService.addDonation(action?.payload).pipe(
                    map((response) => {
                        return DonorActions.AddDonationSuccess({ donation: response?.body, message: response?.message, });
                    }),
                    catchError((error) =>
                        of(DonorActions.AddDonationError({ message: error?.error?.error?.message, }))
                    )
                );
            })
        )
    );

    EditDonation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonorActions.EditDonation),
            exhaustMap((action) => {
                
                return this.donorService.editDonation(action.payload, action.DonationId).pipe(
                    map((response) => {
                        this.router.navigateByUrl('/web-user-management/donors/list-of-donors');
                        return DonorActions.EditDonationSuccess({Donation: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(DonorActions.EditDonationError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );
        
    DeleteDonation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonorActions.DeleteDonation),
            exhaustMap((action) => {

                return this.donorService.deleteDonation(action.DonationIDs).pipe(
                    map((response: any) => {

                        return DonorActions.DeleteDonationSuccess({ message: response.message });
                    }),
                    catchError((error: any) => of(DonorActions.DeleteDonationError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );

    OnAddDeleteDonation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonorActions.AddDonationSuccess, DonorActions.DeleteDonationSuccess),
            switchMap((action) => combineLatest([
                of(action.type),
                this.store.select(DonorSelector.getListingPayload).pipe(take(1))
            ])
            ),
            map(([type, payload]) => {

                if (type == '[Donor] Add Donation Success') {
                    this.router.navigateByUrl('/web-user-management/donors/list-of-donors');
                }
                return DonorActions.LoadDonorList({ payload });
            })
        )
    );

}