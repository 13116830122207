import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { API_ENDPOINTS } from '../app.endpoints';
import { environment } from 'shared/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrendingDataService {
  rootUrl = '';
  baseUrl = API_ENDPOINTS.homePage.trending;
  constructor(private http: HttpClient) {
    this.rootUrl = environment.rootApiUrl;

  }
  /////////// get trending list on admin homepage //////////
  homePageTrendingKeywords(data: any) {
    const url = `${this.rootUrl}${this.baseUrl.homePageTrendingKeywords}`
      .replace('{pageSize}', data?.pageSize)
      .replace('{pageNumber}', data?.pageNumber)
      .replace('{type}', data?.type);
    return this.http.get(url);
  }
  /////////// get all trending keywords  //////////
  allTrendingKeywords(data: any) {
    const url = `${this.rootUrl}${this.baseUrl.allTrendingKeywords}`
      .replace('{pageSize}', data?.pageSize)
      .replace('{pageNumber}', data?.pageNumber);
    return this.http.get(url);
  }
  /////////// update status of keyword whether to show on application or not //////////
  updateKeywordApplicationStatus(data: any) {
    const url = `${this.rootUrl}${this.baseUrl.trendingKeywordApplicationStatus}`;
    return this.http.patch(url, data);
  }
  /////////// update status of keyword whether to show on homepage of admin or not //////////
  updateKeywordHomePageStatus(data: any) {
    const url = `${this.rootUrl}${this.baseUrl.trendingKeywordHomePageStatus}`;
    return this.http.patch(url, data);
  }
  /////////// keyword detail //////////
  keywordDetail(data: any) {
    const url = `${this.rootUrl}${this.baseUrl.keywordDetail}`.replace(
      '{id}',
      data?.id
    );
    return this.http.get(url);
  }

  /////////// merge keywords //////////
  mergeKeywords(payload: any) {
    const url = `${this.rootUrl}${this.baseUrl.mergeKeywords}`;
    return this.http.put(url, payload?.data);
  }

  /////////// search keyword //////////
  searchKeyword(data: any) {
    const url = `${this.rootUrl}${this.baseUrl.searchKeyword}`
    return this.http.post(url,data);
  }
}
