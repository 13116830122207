import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, MetadataPerCategoryActions, MetadataPerCategorySelector } from '@panjab-digi-lib/shared';
import { MetadataPerCategoryService } from 'shared/src/services/admin/master-form/metadataPerCategory.service';

@Injectable()
export class MetadataPerCategoryEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private metadataPerCategoryService: MetadataPerCategoryService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadMetadataPerCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetadataPerCategoryActions.LoadMetadataPerCategory),
            exhaustMap((action) => {

                return this.metadataPerCategoryService.loadMetadataPerCategory().pipe(
                    map((response) => {
                        return MetadataPerCategoryActions.LoadMetadataPerCategorySuccess({ categories: response.categories });
                    }),
                    catchError((error: any) => of(MetadataPerCategoryActions.LoadMetadataPerCategoryError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    LoadMetadataOfCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetadataPerCategoryActions.LoadMetadataOfCategory),
            exhaustMap((action) => {

                return this.metadataPerCategoryService.loadMetadataOfCategory(action.payload, action.categoryID).pipe(
                    map((response) => {
                        return MetadataPerCategoryActions.LoadMetadataOfCategorySuccess({ metadata: response.metadata, totalCount: response.totalCount, categoryID: action.categoryID });
                    }),
                    catchError((error: any) => of(MetadataPerCategoryActions.LoadMetadataOfCategoryError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    SaveAllocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetadataPerCategoryActions.SaveAllocations),
            exhaustMap((action) => {

                return this.metadataPerCategoryService.saveAllocations(action.payload, action.categoryID).pipe(
                    map((response) => {
                        return MetadataPerCategoryActions.SaveAllocationsSuccess({ message: response.message, categoryID: action.categoryID });
                    }),
                    catchError((error: any) => of(MetadataPerCategoryActions.SaveAllocationsError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    SaveAccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetadataPerCategoryActions.SaveAccess),
            exhaustMap((action) => {

                return this.metadataPerCategoryService.saveAccess(action.payload, action.categoryID).pipe(
                    map((response) => {
                        return MetadataPerCategoryActions.SaveAccessSuccess({ message: response.message, categoryID: action.categoryID });
                    }),
                    catchError((error: any) => of(MetadataPerCategoryActions.SaveAccessError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    OnSaveAllocationsAccessSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetadataPerCategoryActions.SaveAllocationsSuccess, MetadataPerCategoryActions.SaveAccessSuccess),
            switchMap((action) => combineLatest([
                this.store.select(MetadataPerCategorySelector.getListingPayload).pipe(take(1)),
                of(action.categoryID),
            ])
            ),
            map(([payload, categoryID]) => {
                return MetadataPerCategoryActions.LoadMetadataOfCategory({payload, categoryID})
            })
        )
    );

    OnSaveAllocationsAccessSuccessLoadCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetadataPerCategoryActions.SaveAllocationsSuccess),
            map(() => {
                return MetadataPerCategoryActions.LoadMetadataPerCategory()
            })
        )
    );
    
}