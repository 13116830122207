import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromDocumentList from "shared/src/store/reducers/staff/document-list.reducer";

export const selectDocumentListState =
  createFeatureSelector<fromDocumentList.State>('documentList');

export const getDocumentList = createSelector(
    selectDocumentListState,
    fromDocumentList.selectAll
);

export const getTotalRecords = createSelector(
  selectDocumentListState,
  (state) => state.totalRecords
);

export const getDocumentByID = (props: {id: string}) => createSelector(
  selectDocumentListState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectDocumentListState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectDocumentListState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectDocumentListState,
  (state) => state.isLoading,
);