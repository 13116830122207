import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '@panjab-digi-lib/shared'

@Injectable({
  providedIn: 'root'
})
export class BannersService {
  //   public getAllBanners$ = new BehaviorSubject<any>(false);

  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl + environment.adminApiPrefix;
  }

  getAllBanners(data: any) {
    return this.http.get(this.rootURL + 'banner', { params: data.params }).pipe(map((e: any) => e && e.body ? e.body : e));
  }
  sortBanner(id: number, toOrder: number) {
    return this.http.put(this.rootURL + `sortBanner/${id}`, { toOrder }, {}).pipe(map((e: any) => e && e.body ? e.body : e));
  }
  updateStatusBanner(id: number, status: boolean) {
    return this.http.patch(this.rootURL + `banner/${id}`, { status }, {}).pipe(map((e: any) => e && e.body ? e.body : e));
  }
  deleteBanners(id: number) {
    return this.http.delete(this.rootURL + `banner/${id}`).pipe(map((e: any) => e && e.body ? e.body : e));
  }
  addBanners(body: FormData) {
    return this.http.post(this.rootURL + `banner`, body).pipe(map((e: any) => e && e.body ? e.body : e));
  }
  updateBanner(id: number, body: FormData) {
    return this.http.put(this.rootURL + `banner/${id}`, body).pipe(map((e: any) => e && e.body ? e.body : e));
  }
}
