/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HomeCategories, TrendingKeywords } from 'shared/src/interfaces/homepage.interface';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { HomepageService } from 'shared/src/services/website/home/homepage.service';
import { WebHomePageActions} from '../actions/index';

@Injectable()
export class WebHomePageEffects {
  constructor(
    private actions$: Actions,
    private homepageService: HomepageService
  ) {}

  LoadWebBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebHomePageActions.LoadWebBanners),
      exhaustMap((action) =>
        this.homepageService.loadWebBanners().pipe(
          map((banners: any) => {
            return WebHomePageActions.LoadWebBannersSuccess({
              banners: banners,
            });
          }),
          catchError((error: any) =>
            of(WebHomePageActions.LoadWebBannersError(error))
          )
        )
      )
    )
  );

  LoadWebTrendingKeywords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebHomePageActions.LoadWebTrendingKeywords),
      exhaustMap((action) =>
        this.homepageService.loadWebTrendingKeywords().pipe(
          map((keywords: TrendingKeywords) => {
            return WebHomePageActions.LoadWebTrendingKeywordsSuccess({
              trendingKeywords: keywords.todayTrending,
              historicKeywords: keywords.historicalTrending,
            });
          }),
          catchError((error: any) =>
            of(WebHomePageActions.LoadWebTrendingKeywordsError(error))
          )
        )
      )
    )
  );

  LoadWebCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebHomePageActions.LoadWebCategories),
      exhaustMap((action) =>
        this.homepageService.loadWebCategories().pipe(
          map((categories: HomeCategories[]) => {
            return WebHomePageActions.LoadWebCategoriesSuccess({ categories });
          }),
          catchError((error: any) =>
            of(WebHomePageActions.LoadWebCategoriesError(error))
          )
        )
      )
    )
  );

  /////////////// load homepage videos ///////////////////////////
  LoadHomepageVideos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebHomePageActions.LoadWebHomepageVideos),
      exhaustMap(() =>
        this.homepageService.loadHomepageVideos().pipe(
          map((response) => {
            return WebHomePageActions.LoadWebHomepageVideosSuccess({
              videos: response?.body?.videoList,
            });
          }),
          catchError((error) =>
            of(WebHomePageActions.LoadWebHomepageVideosError(error))
          )
        )
      )
    )
  );

  /////////////// update video views ///////////////////////////
  UpdateVideoCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebHomePageActions.UpdateVideoCount),
      exhaustMap((action) =>
        this.homepageService.updateVideoCount(action?.payload).pipe(
          map((response) => {
            return WebHomePageActions.UpdateVideoCountSuccess({
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(WebHomePageActions.UpdateVideoCountError(error))
          )
        )
      )
    )
  );
}