import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthSelector, BreadcrumbsComponent, AppState, WebUserActions, WebUserSelector, environment } from '@panjab-digi-lib/shared';
import { Router, RouterLink } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { AlertService } from 'shared/src/services/website/alert.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { SpinnerButtonComponent } from 'shared/src/lib/components/web/spinner-button/spinner-button.component';
import { RecaptchaModule, RecaptchaFormsModule, RecaptchaComponent } from 'ng-recaptcha';
@Component({
  selector: 'panjab-digi-lib-recover-password',
  standalone: true,
  imports: [CommonModule, TranslateModule, BreadcrumbsComponent, RouterLink, ReactiveFormsModule, NgbTooltipModule, AlertsComponent, SpinnerButtonComponent, RecaptchaModule, RecaptchaFormsModule],
  templateUrl: './recover-password.component.html',
  styleUrl: './recover-password.component.scss'
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
  pageTitle = 'Password Recovery';
  recoverPasswdForm!: FormGroup;
  errMsg = '';
  successMsg = '';
  subscription = new Subscription();
  isSubmitted = false;
  sitekey = environment.recaptcha.siteKey;
  isMobile = false;
  @ViewChild('captchaRef') captchaRef!:RecaptchaComponent
  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private localStorage: LocalStorageService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.initForm();
    if(this.localStorage.get('webToken')) {
      this.router.navigate(['/']);
    }
    this.subscription.add(
      this.store.select(AuthSelector.selectWebToken).subscribe((token: string) => {
        if(token) {
          this.router.navigate(['/']);
        }
      })
    );
    this.subscription.add(
      this.store.select(WebUserSelector.selectError).subscribe((error: any) => {
        if (error && error.message) {
          this.alertService.error(error.message);
          this.isSubmitted = false;
          this.resetReCaptcha();
        }
      })
    );
    this.subscription.add(
      this.store.select(WebUserSelector.selectSuccess).subscribe((message: any) => {
        if (message) {
          this.alertService.success(message);
          this.isSubmitted = false;
          this.recoverPasswdForm.reset();
          this.resetReCaptcha();
        }
      })
    );
    // change recaptcha size when it is on mobile view
    if(window.innerWidth < 370) {
      this.isMobile = true;
    }
  }

  initForm() {
    this.recoverPasswdForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      cpt: [null, Validators.required]
    });
  }

  resolveCaptcha(captchaResponse: string | null) {
    if(captchaResponse) {
      this.recoverPasswdForm.patchValue({'cpt': captchaResponse})
    } else {
      this.recoverPasswdForm.controls['cpt'].setErrors({'invalid': true});
    }
  }

  resetReCaptcha() {
    this.captchaRef.reset();
  }

  onSubmit() {
    this.alertService.clear();
    this.recoverPasswdForm.markAllAsTouched();
    if(this.recoverPasswdForm.valid) {
      this.isSubmitted = true;
      const payloads = {
        email: this.recoverPasswdForm.controls['email'].value,
        cpt: this.recoverPasswdForm.controls['cpt'].value
      }
      this.store.dispatch(WebUserActions.recoverPasswordRequest(payloads));
      this.subscription.add(this.store.dispatch(WebUserActions.resetState()));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
