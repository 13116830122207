import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';

@Component({
  selector: 'panjab-digi-lib-create-new-topic',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './create-new-topic.component.html',
  styleUrl: './create-new-topic.component.scss',
})
export class CreateNewTopicComponent {
  constructor(private location: Location) {}
  goback = () => this.location.back();
}
