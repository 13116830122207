/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { ArticleComment } from "shared/src/interfaces/master-form.interface";

export const LoadArticleComment = createAction(
    '[ArticleComment] Load Article Comment',
    props<{payload: GenericListingPayload}>(),
)

export const LoadArticleCommentSuccess = createAction(
    '[ArticleComment] Load Article Comment Successfully',
    props<{articleComments: ArticleComment[], totalRecords: number}>()
)

export const LoadArticleCommentError = createAction(
    '[ArticleComment] Load Article Comment Error',
    props<{message: string}>()
)

export const UpdateArticleCommentStatus = createAction(
    '[ArticleComment] Update Article Comment Status',
    props<{articleCommentIDs: number[], status: string, comment?: string}>(),
)

export const UpdateArticleCommentStatusSuccess = createAction(
    '[ArticleComment] Update Article Comment Status Success',
    props<{articleCommentUpdates: any, message: string}>(),
)

export const UpdateArticleCommentStatusError = createAction(
    '[ArticleComment] Update Article Comment Status Error',
    props<{message: string}>(),
)