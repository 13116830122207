<!-- social media buttons -->
<div class="row social-icon">
    <div class="social1 text-sm-end text-center">
        <a href="javascript:void(0);" type="button" (click)="loginWithFB()" class="fb-icon rounded-1 d-inline-flex justify-content-center">
            <img src="../../../assets/images/icons/fb-icon.svg" alt="facebook" />
            <span>{{'pdl-shared.socialLogin.connectWithFb' | translate }}</span>
        </a>
    </div>
    <div class="social2 text-sm-start text-center">
        <asl-google-signin-button type='standard' size='large' [width]='230' text="continue_with">
        </asl-google-signin-button>
    </div>
</div>