/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { AddEditVideoPayload, DeleteVideoPayload, GetVideoGalleryPayload, TemplateVideo, UpdateTemplateVideoPayload, UpdateVideoStatusPayload, Video } from 'shared/src/interfaces/video-gallery.interface';
export const LoadVideoGallery = createAction(
  '[VideoGallery] Load VideoGallery List',
  props<{ payload: GetVideoGalleryPayload }>()
);

export const LoadVideoGallerySuccessfully = createAction(
  '[VideoGallery] Load VideoGallery Successfully',
  props<{ videoList: Video[]; totalRecords: number }>()
);

export const LoadVideoGalleryError = createAction(
  '[VideoGallery] Load VideoGallery Error',
  props<{ message: string }>()
);

export const AddVideo = createAction(
  '[VideoGallery] Add Video to List',
  props<{ payload: AddEditVideoPayload }>()
);

export const AddVideoSuccessfully = createAction(
  '[VideoGallery] VideoGallery Added Successfully',
  props<{ video: Video; message: string }>()
);

export const AddVideoError = createAction(
  '[VideoGallery] VideoGallery Added Error',
  props<{ message: string }>()
);

export const UpdateVideo = createAction(
  '[VideoGallery] Edit VideoGallery in List',
  props<{ payload: AddEditVideoPayload,id:number }>()
);

export const UpdateVideoSuccessfully = createAction(
  '[VideoGallery] VideoGallery Updated Successfully',
  props<{ video: Video; message: string }>()
);

export const UpdateVideoError = createAction(
  '[VideoGallery] VideoGallery Updated Error',
  props<{ message: string }>()
);

export const UpdateVideoStatus = createAction(
    '[VideoGallery] Update VideoGallery Status in List',
    props<{ payload: UpdateVideoStatusPayload }>()
  );
  
  export const UpdateVideoStatusSuccessfully = createAction(
    '[VideoGallery] VideoGallery Status Updated Successfully',
    props<{ video: Video[]; message: string }>()
  );
  
  export const UpdateVideoStatusError = createAction(
    '[VideoGallery] VideoGallery Status Updated Error',
    props<{ message: string }>()
  );

  export const DeleteVideo= createAction(
    '[VideoGallery] Delete Status from List',
    props<{ payload: DeleteVideoPayload }>()
  );
  
  export const DeleteVideoSuccessfully = createAction(
    '[VideoGallery] VideoGallery Deleted Successfully',
    props<{ message: string }>()
  );
  
  export const DeleteVideoError = createAction(
    '[VideoGallery] VideoGallery Status Updated Error',
    props<{ message: string }>()
  );

  export const AddTemplateVideo= createAction(
    '[VideoGallery] Add video to template',
    props<{ payload: TemplateVideo | []}>()
  );

  export const UpdateTemplateVideo = createAction(
    '[VideoGallery] Update video of template',
    props<{ payload: UpdateTemplateVideoPayload[]}>()
  );

  export const UpdateTemplateVideoSuccessfully = createAction(
    '[VideoGallery] Update video of template Successfully',
    props<{ message: string }>()
  );
  
  export const UpdateTemplateVideoError = createAction(
    '[VideoGallery] Update video of template Error',
    props<{ message: string }>()
  );

