/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { AddEditCountryPayload, Country } from "shared/src/interfaces/master-form.interface";

export const LoadCountry = createAction(
    '[Country] Load Country',
    props<{payload: GenericListingPayload}>(),
)

export const LoadActiveCountryList = createAction(
    '[Country] Load Active Country List',
    props<{payload: GenericListingPayload}>(),
)

export const LoadActiveCountryListSuccess = createAction(
    '[Country] Load Active Country List Successfully',
    props<{countryList: Country[], totalRecords: number}>()
)

export const LoadCountrySuccess = createAction(
    '[Country] Load Country Successfully',
    props<{countries: Country[], totalRecords: number}>()
)

export const LoadCountryError = createAction(
    '[Country] Load Country Error',
    props<{message: string}>()
)

export const UpdateCountryStatus = createAction(
    '[Country] Update Country Status',
    props<{countryIDs: number[], status: string}>(),
)

export const UpdateCountryStatusSuccess = createAction(
    '[Country] Update Country Status Success',
    props<{countryUpdates: any, message: string}>(),
)

export const UpdateCountryStatusError = createAction(
    '[Country] Update Country Status Error',
    props<{message: string}>(),
)

export const AddCountry = createAction(
    '[Country] Add Country',
    props<{payload: AddEditCountryPayload}>(),
)

export const AddCountrySuccess = createAction(
    '[Country] Add Country Success',
    props<{country: Country, message: string}>(),
)

export const AddCountryError = createAction(
    '[Country] Add Country Error',
    props<{message: string}>(),
)

export const EditCountry = createAction(
    '[Country] Edit Country',
    props<{payload: AddEditCountryPayload, subjectId: string}>(),
)

export const EditCountrySuccess = createAction(
    '[Country] Edit Country Success',
    props<{country: Country, message: string}>(),
)

export const EditCountryError = createAction(
    '[Country] Edit Country Error',
    props<{message: string}>(),
)

export const DeleteCountry = createAction(
    '[Country] Delete Country',
    props<{countryIDs: number[]}>(),
)

export const DeleteCountrySuccess = createAction(
    '[Country] Delete Country Success',
    props<{message: string}>(),
)

export const DeleteCountryError = createAction(
    '[Country] Delete Country Error',
    props<{message: string}>(),
)