import { createReducer, on } from '@ngrx/store';
import { WebUserActions } from '../actions/index';

export interface State {
  token: string | null;
  error: any;
  message?: string | null;
  isLoading: boolean;
}

const initialState: State = {
  token: null,
  error: null,
  message: '',
  isLoading: false
};

export const reducer = createReducer(initialState,
  on(WebUserActions.recoverPasswordRequest, state => ({ ...state, isLoading: true })),
  on(WebUserActions.recoverPasswordRequestSuccess, (state, { message }) => ({ ...state, error: null, message, isLoading: false })),
  on(WebUserActions.recoverPasswordRequestError, (state,{ error }) => ({ ...state, error, message: '', isLoading: false })),
  on(WebUserActions.validateResetPasswdRequest, state => ({ ...state, isLoading: true })),
  on(WebUserActions.validateResetPasswdRequestSuccess, (state, { message }) => ({ ...state, error: null, message, isLoading: false })),
  on(WebUserActions.validateResetPasswdRequestError, (state, { error }) => ({ ...state, error, message: '', isLoading: false })),
  on(WebUserActions.resetAccountPasswd, state => ({ ...state, isLoading: true })),
  on(WebUserActions.resetAccountPasswdSuccess, (state, { message }) => ({ ...state, error: null, message, isLoading: false })),
  on(WebUserActions.resetAccountPasswdError, (state, { error }) => ({ ...state, message:'', error, isLoading: false })),
  on(WebUserActions.forgotUsernameRequest, state => ({ ...state, isLoading: true })),
  on(WebUserActions.forgotUsernameSuccess, (state, { message }) => ({ ...state, error: null, message, isLoading: false })),
  on(WebUserActions.forgotUsernameError, (state,{ error }) => ({ ...state, error, message: '', isLoading: false })),
  on(WebUserActions.resetState, state => ({...initialState}))
);