/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DocumentInfo } from 'shared/src/interfaces/document-info.interface';
import { API_ENDPOINTS } from '../app.endpoints';
import { environment } from 'shared/src/environments/environment';
import { AddAdopters, adopterGenericListing } from 'shared/src/interfaces/fundraising.interface';

@Injectable({
  providedIn: 'root'
})
export class ToAdoptService {
  rootURL = '';

  constructor(private http: HttpClient) { 
    this.rootURL = environment.rootApiUrl;
  }
  loadToAdopt(payload: adopterGenericListing): Observable<{documentList: DocumentInfo[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.fundraising.toAdopt.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }

  addAdopter(data: AddAdopters ) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.fundraising.toAdopt.addAdoption}`, data).pipe(map((e: any) => e));
  }

  toggleListOfAdoptionStatus(ids: number[], status: boolean ) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.fundraising.toAdopt.toggleListOfAdoptionStatus}`, {ids, status}).pipe(map((e: any) => e));
  }
  
  downloadDocuments() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.fundraising.toAdopt.downloadDocuments}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }
}
