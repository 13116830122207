import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAdminContributor from '../reducers/admin-contributor.reducer';

export const selectState =
  createFeatureSelector<fromAdminContributor.State>('adminContributor');

export const loadContributorList = createSelector(selectState, (state) => ({
  contributorList: fromAdminContributor.selectAll(state),
  totalRecords: state.totalRecords,
  pageSize: state.pageSize,
  pageNumber : state.pageNumber,
  sortBy: state.sortBy,
  orderBy: state?.orderBy
}));

export const getContributorById = (id: number) => createSelector(
  selectState,
  (state) => state.entities[id]
)

export const onSave = createSelector(
  selectState,
  (state) => [state.isError, state.errorMessage, state.successMessage,state.isLoading]
);