import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromAdoptedBy from "../reducers/admin-adopted-by.reducer";

export const selectAdoptedByState =
  createFeatureSelector<fromAdoptedBy.State>('adoptedByList');

export const getAdopters = createSelector(
    selectAdoptedByState,
    fromAdoptedBy.selectAll
);

export const getTotalRecords = createSelector(
  selectAdoptedByState,
  (state) => state.totalRecords
);

export const getAdopterByID = (props: {id: string}) => createSelector(
  selectAdoptedByState,
  (state) => state.entities[props.id]
);

export const getListingPayload = createSelector(
  selectAdoptedByState,
  (state) => state.payload
);

export const onSave = createSelector(
  selectAdoptedByState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);