<div class="filterSectionWrapper container">
  <div class="greenBorderText padding-12">Saved Search</div>
  <div class="savedSearches no-scrollbar padding-12">
    <span class="savedSearchText">
      <span class="text">Saved Search</span> <img class="cursor-pointer" src="/assets/images/icons/cross-gray-20.svg"
        alt="icon" />
    </span>
    <span class="savedSearchText">
      <span class="text">Saved Search</span> <img class="cursor-pointer" src="/assets/images/icons/cross-gray-20.svg"
        alt="icon" />
    </span>
    <span class="savedSearchText">
      <span class="text">Saved Search</span> <img class="cursor-pointer" src="/assets/images/icons/cross-gray-20.svg"
        alt="icon" />
    </span>
    <span class="savedSearchText">
      <span class="text">Saved Search</span> <img class="cursor-pointer" src="/assets/images/icons/cross-gray-20.svg"
        alt="icon" />
    </span>
  </div>
  <div class="search_with_glass_at_end padding-12">
    <label class="search-label"><img src="assets/images/icons/search-icon.svg"></label>
    <input type="text" class="form-control search__input disabled" id="exampleFormControlInput1"
      placeholder="Search By Keywords">
  </div>
  <div class="greenBorderText padding-12">
    <div class="d-flex justify-content-between">
      <span>Advance Search</span>
      <span class="clearAll cursor-pointer">Clear All</span>
    </div>
  </div>
  <div class="seperatorLine mt-3 mb-3"></div>

  <!-- content search -->
  <div class=" padding-12">
    <div class="contentheader d-flex justify-content-between">
      <span>Content search for</span>
      <span class="cursor-pointer" (click)="toggleCollapsedMenu('content')">
        <img
          src="{{isCollapsed('content') ? '/assets/images/icons/arrowdown.svg' : '/assets/images/icons/arrowup.svg'}}" />
      </span>
    </div>
    @if (!isCollapsed('content')) {
    <div class="mt-1" id="content">
      <input class="form-control search__input disabled" type="text" placeholder="eg" />
    </div>
    }
  </div>
  <!-- subject -->
  <div class="padding-12">
    <div class="contentheader d-flex justify-content-between">
      <span>Subject</span>
      <span class="cursor-pointer" (click)="toggleCollapsedMenu('subject')">
        <img
          src="{{isCollapsed('subject') ? '/assets/images/icons/arrowdown.svg' : '/assets/images/icons/arrowup.svg'}}" />
      </span>
    </div>
    @if (!isCollapsed('subject')) {
    <div class="mt-1" id="subject">
      @for (subject of getFirstel('subjects'); track subject; let index = $index) {
      @if (index < 10) { <div class="text-start form-check">
        <input type="radio" class="form-check-input" value="{{subject.id}}" aria-label="Deactivate Account">
        <label for="deactivateAcc" class=" "> {{subject.subject}}</label>
    </div>
    }
    @if (index === 10) {
    <span (click)="setModalInfo('subject')" class="cursor-pointer see-more">see more</span>
    }
    }
  </div>


  }
</div>


<!-- year -->
<div class="padding-12">
  <div class="contentheader d-flex justify-content-between">
    <span>Year</span>
    <span class="cursor-pointer" (click)="toggleCollapsedMenu('year')">
      <img src="{{isCollapsed('year') ? '/assets/images/icons/arrowdown.svg' : '/assets/images/icons/arrowup.svg'}}" />
    </span>
  </div>
  @if (!isCollapsed('year')) {
  <div class="mt-1 year-box" id="year">
    <div class="d-flex  align-items-center">
      <span style="width: 101px;">2021</span>
      <div>
      </div>
      <div class="page-filters-dropdown d-flex align-items-center justify-content-between"
        style="flex-grow: 1; color: #c2c7d0 !important">
        <div class="dropdown page-filter-dropdown d-flex justify-content-between" style="flex-grow: 1;">
          <div class="page-filter-action btn d-flex justify-content-between  align-items-center  dropdown-toggle"
            style="border: none; flex-grow: 1;" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.75 1.625V2.5H8.25V1.625C8.25 1.16016 8.63281 0.75 9.125 0.75C9.58984 0.75 10 1.16016 10 1.625V2.5H11.3125C12.0234 2.5 12.625 3.10156 12.625 3.8125V5.125H0.375V3.8125C0.375 3.10156 0.949219 2.5 1.6875 2.5H3V1.625C3 1.16016 3.38281 0.75 3.875 0.75C4.33984 0.75 4.75 1.16016 4.75 1.625ZM0.375 6H12.625V13.4375C12.625 14.1758 12.0234 14.75 11.3125 14.75H1.6875C0.949219 14.75 0.375 14.1758 0.375 13.4375V6ZM2.125 9.0625C2.125 9.30859 2.31641 9.5 2.5625 9.5H3.4375C3.65625 9.5 3.875 9.30859 3.875 9.0625V8.1875C3.875 7.96875 3.65625 7.75 3.4375 7.75H2.5625C2.31641 7.75 2.125 7.96875 2.125 8.1875V9.0625ZM5.625 9.0625C5.625 9.30859 5.81641 9.5 6.0625 9.5H6.9375C7.15625 9.5 7.375 9.30859 7.375 9.0625V8.1875C7.375 7.96875 7.15625 7.75 6.9375 7.75H6.0625C5.81641 7.75 5.625 7.96875 5.625 8.1875V9.0625ZM9.5625 7.75C9.31641 7.75 9.125 7.96875 9.125 8.1875V9.0625C9.125 9.30859 9.31641 9.5 9.5625 9.5H10.4375C10.6562 9.5 10.875 9.30859 10.875 9.0625V8.1875C10.875 7.96875 10.6562 7.75 10.4375 7.75H9.5625ZM2.125 12.5625C2.125 12.8086 2.31641 13 2.5625 13H3.4375C3.65625 13 3.875 12.8086 3.875 12.5625V11.6875C3.875 11.4688 3.65625 11.25 3.4375 11.25H2.5625C2.31641 11.25 2.125 11.4688 2.125 11.6875V12.5625ZM6.0625 11.25C5.81641 11.25 5.625 11.4688 5.625 11.6875V12.5625C5.625 12.8086 5.81641 13 6.0625 13H6.9375C7.15625 13 7.375 12.8086 7.375 12.5625V11.6875C7.375 11.4688 7.15625 11.25 6.9375 11.25H6.0625ZM9.125 12.5625C9.125 12.8086 9.31641 13 9.5625 13H10.4375C10.6562 13 10.875 12.8086 10.875 12.5625V11.6875C10.875 11.4688 10.6562 11.25 10.4375 11.25H9.5625C9.31641 11.25 9.125 11.4688 9.125 11.6875V12.5625Z"
                fill="#B3B9C4" />
            </svg> <span style="flex-grow: 1; color: #c2c7d0 !important">1 year</span>
          </div>

          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </div>
      </div>

    </div>
  </div>


  }
</div>



<!-- type -->
<div class="padding-12">
  <div class="contentheader d-flex justify-content-between">
    <span>Type</span>
    <span class="cursor-pointer" (click)="toggleCollapsedMenu('type')">
      <img src="{{isCollapsed('type') ? '/assets/images/icons/arrowdown.svg' : '/assets/images/icons/arrowup.svg'}}" />
    </span>
  </div>
  @if (!isCollapsed('type')) {
  <div class="mt-1" id="type">
    <div class=" form-check">
      <div class="d-flex  align-items-center " style="column-gap: 40px;">

        <div><input type="radio" class="form-check-input" value="exact" aria-label="Deactivate Account">
          <label for="deactivateAcc" class=" ">Exact</label>
        </div>

        <div><input type="radio" class="form-check-input" value="any" aria-label="Deactivate Account">
          <label for="deactivateAcc" class=" "> Any</label>
        </div>

      </div>
    </div>
  </div>


  }
</div>
</div>

<!-- modals -->
@if (modalInfo && modalInfo.type && modalInfo.modalData) {
<panjab-digi-lib-list-modal type="subject" [listData]="subjects" (onClose)="clearModalInfo()">
</panjab-digi-lib-list-modal>

}
