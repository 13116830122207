import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'panjab-digi-lib-mailing-list',
  standalone: true,
  imports: [CommonModule,TranslateModule],
  templateUrl: './mailing-list.component.html',
  styleUrl: './mailing-list.component.scss',
})
export class MailingListComponent {
  constructor(private translate: TranslateService) {

  }
}
