import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';

@Component({
  selector: 'panjab-digi-lib-my-research-topic-detail',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterOutlet],
  templateUrl: './my-research-topic-detail.component.html',
  styleUrl: './my-research-topic-detail.component.scss',
})
export class MyResearchTopicDetailComponent {
  showData = true;
  toggleShowData = () => (this.showData = !this.showData);
}
