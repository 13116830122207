import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageService } from '../StaticPageService';
import { TranslateModule } from '@ngx-translate/core';
import { StaticPageHeadComponent } from '../staticPageHead/staticPageHead.component';
import { SafeHtmlPipe } from 'shared/src/pipes/safe-html.pipe';

@Component({
  selector: 'panjab-digi-lib-list-of-custodians',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    StaticPageHeadComponent,
    SafeHtmlPipe,
  ],
  templateUrl: './list-of-custodians.component.html',
  styleUrl: './list-of-custodians.component.scss',
})
export class ListOfCustodiansComponent {
  content = '';

  constructor(private staticPageService: StaticPageService) {}
  ngOnInit(): void {}
}
