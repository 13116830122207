import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAdminTranslator from '../reducers/admin-translator.reducer';

export const selectState =
  createFeatureSelector<fromAdminTranslator.State>('adminTranslator');

export const loadTranslatorList = createSelector(selectState, (state) => ({
  translatorList: fromAdminTranslator.selectAll(state),
  totalRecords: state.totalRecords,
  pageSize: state.pageSize,
  pageNumber : state.pageNumber,
  sortBy: state.sortBy,
  orderBy: state?.orderBy
}));

export const getTranslatorById = (id: number) => createSelector(
  selectState,
  (state) => state.entities[id]
)

export const onSave = createSelector(
  selectState,
  (state) => [state.isError, state.errorMessage, state.successMessage,state.isLoading]
);