/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminEditorActions } from '../actions';
import { catchError, exhaustMap, map, of, switchMap, withLatestFrom } from 'rxjs';
import { EditorService } from 'shared/src/services/admin/master-form/editor.service';
import { Store, select } from '@ngrx/store';
import { AdminEditorSelector, AppState } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';


@Injectable()
export class AdminEditorEffects {
  constructor(private actions$: Actions, private service: EditorService, private store: Store<AppState>, private router: Router,) {}

  //////////////// get all Editors ////////////
  LoadEditorList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEditorActions.LoadEditors),
      exhaustMap((action) => {
        return this.service.loadEditors(action?.payload).pipe(
          map((response) => {
            return AdminEditorActions.LoadEditorSuccessfully({
              editorList: response?.editorList,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              AdminEditorActions.LoadEditorError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// add Editor ////////////
  AddEditor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEditorActions.AddEditor),
      exhaustMap((action) => {
        return this.service.addEditor(action?.payload).pipe(
          map((response) => {
            return AdminEditorActions.AddEditorSuccessfully({
              editor: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminEditorActions.AddEditorError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Editor ////////////
  UpdateEditor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEditorActions.UpdateEditor),
      exhaustMap((action) => {
        return this.service.updateEditor(action?.payload,action?.id).pipe(
          map((response) => {
            return AdminEditorActions.UpdateEditorSuccessfully({
              editor: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminEditorActions.UpdateEditorError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Editor status////////////
  UpdateEditorStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEditorActions.UpdateEditorStatus),
      exhaustMap((action) => {
        return this.service.updateEditorStatus(action?.payload).pipe(
          map((response) => {
            return AdminEditorActions.UpdateEditorStatusSuccessfully({
              editor: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminEditorActions.UpdateEditorStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  ///////////////// delete Editor ///////////////////////
  DeleteEditor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEditorActions.DeleteEditor),
      exhaustMap((action) =>
        this.service.deleteEditor(action?.payload).pipe(
          map((response) => {
            return AdminEditorActions.DeleteEditorSuccessfully({
              message: response?.message,
            });
          }),
          catchError((res) =>
            of(
              AdminEditorActions.DeleteEditorError({
                message: res.error?.error?.message,
              })
            )
          )
        )
      )
    )
  );

//////////////// add Editor ////////////
  MergeEditor$ = createEffect(() =>
        this.actions$.pipe(
          ofType(AdminEditorActions.MergeEditor),
          exhaustMap((action) => {
            return this.service.mergeEditors(action?.payload).pipe(
              map((response) => {
                return AdminEditorActions.MergeEditorSuccessfully({
                  message: response?.message,
                });
              }),
              catchError((error) =>
                of(
                  AdminEditorActions.AddEditorError({
                    message: error?.error?.error?.message,
                  })
                )
              )
            );
          })
        )
      );

 ///////////////////// redirect after adding/deleting/merging Editor /////////////////////     
  AddDeleteMergeEditorSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminEditorActions.AddEditorSuccessfully, AdminEditorActions.DeleteEditorSuccessfully,AdminEditorActions.MergeEditorSuccessfully),
      withLatestFrom(this.store.pipe(select(AdminEditorSelector.loadEditorList))),
      switchMap(([action, state]) => {
        this.router.navigateByUrl('/master-forms/manage/editors');
        const pageNumber = (action.type === AdminEditorActions.AddEditorSuccessfully.type || action.type === AdminEditorActions.MergeEditorSuccessfully.type) ? 1 : state?.pageNumber;
        const payload = { pageNumber: pageNumber, pageSize: state?.pageSize, sortBy: state?.sortBy,orderBy: state?.orderBy };
        return of(AdminEditorActions.LoadEditors({payload}));
      })
    )
  )
}
