/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WebAdoptedBooksActions } from '../actions/index';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { UserService } from '../../services/website/user/user.service';

@Injectable()
export class WebAdoptedBooksEffects {

  constructor(
    private actions$: Actions,
    private userService: UserService
  ) {}
  
  GetAdoptedBooksList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebAdoptedBooksActions.GetAdoptedBooksList),
      exhaustMap(action =>
        this.userService.getAdoptedBooksList(action?.payload).pipe(
          map((response) => {
            return WebAdoptedBooksActions.GetAdoptedBooksListSuccess({ 
                adoptedBooksList: response.body.adoptedBooksList, 
                totalCount: response.body.totalCount 
            });
          }),
          catchError((error) => of(WebAdoptedBooksActions.GetAdoptedBooksListError(error))))
      )
    )
  );
}