import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { CommonListingComponent } from '../common-listing/common-listing.component';
import { ReaderComponent } from '../meta-data/reader/reader.component';

@Component({
  selector: 'panjab-digi-lib-metadata',
  standalone: true,
  imports: [CommonModule, RouterLink, TranslateModule, CommonListingComponent, ReaderComponent],
  templateUrl: './metadata.component.html',
  styleUrl: './metadata.component.scss',
})
export class MetadataComponent {
  data: any = null;
  dummyArr = [1,2,3,4,5,6,7,8,9];
  constructor(private translate: TranslateService) {
    this.data = this.getDataCorrespondingToCategory('Books');
  }
  ngOnInit() {
    this.data = this.getDataCorrespondingToCategory('Books');
  }
  getRandomCount = () => {
    const randNum = 20;
    return Array(randNum).fill(1);
  };

  getDataCorrespondingToCategory = (currentKey: any): any => {
    const data = [];
    const types = ['Manuscripts', 'Books', 'Magzines'];
    const stars = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
    for (let i = 0; i < 10; i++) {
      data.push({
        id: 1,
        type:
          currentKey.id === 0
            ? types[Math.ceil(Math.random() * 3) - 1]
            : currentKey.categoryName,
        rating: stars[Math.ceil(Math.random() * 10) - 1],
        name: 'Item ' + Math.ceil(Math.random() * 38374),
        script: 'Gurmukhi',
        pages: Math.ceil(Math.random() * 10000),
        thumbnail: 'assets/images/list-thumb1.jpg',
        author: 'Author ' + Math.ceil(Math.random() * 38374),
        subject: 'Subject ' + Math.ceil(Math.random() * 38374),
        description:
          'Following declaration of independence of India and its simultaneous partition, there was a transfer of population on the basis of religion from the asdasda'.substring(
            0,
            150
          ),
      });
    }
    return { data, total: data.length + Math.ceil(Math.random() * 500) };
  };
  getRating = (rating: number) => {
    const fullStars = Math.floor(rating);
    const isHalf = rating - fullStars > 0;
    const noStars = 5 - fullStars - (isHalf ? 1 : 0);
    const op = Array(fullStars).fill(1);
    const zeroStars = Array(noStars).fill(0);
    if (isHalf) {
      op.push(0.5);
    }

    return [...op, ...zeroStars];
  };
}
