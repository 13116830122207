import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromWebVideos from "../../reducers/website/web-videos.reducer";

export const selectVideoState =
  createFeatureSelector<fromWebVideos.State>('webVideos');

// export const getVideos = createSelector(
//     selectVideoState,
//     fromWebVideos.selectAll
// );

export const getVideos = createSelector(selectVideoState, (state) => {
    return {
      videoList: fromWebVideos.selectAll(state),
      totalRecords: state.totalRecords,
    };
  });

export const isLoading = createSelector(
  selectVideoState,
  (state) => state.isLoading
);

export const isSaving = createSelector(
    selectVideoState,
  (state) => [state.isSaving, state.isError, state.errorMessage],
);