/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState, ProcessPerCategoryActions, ProcessPerCategorySelector } from '@panjab-digi-lib/shared';
import { ProcessPerCategoryService } from 'shared/src/services/admin/master-form/processPerCategory.service';

@Injectable()
export class ProcessPerCategoryEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private ProcessPerCategoryService: ProcessPerCategoryService,
        private store: Store<AppState>,
    ) {
    }

    LoadProcessPerCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessPerCategoryActions.LoadProcessPerCategory),
            exhaustMap((action) => {

                return this.ProcessPerCategoryService.loadProcessPerCategory().pipe(
                    map((response) => {
                        return ProcessPerCategoryActions.LoadProcessPerCategorySuccess({ category: response.categories });
                    }),
                    catchError((error: any) => of(ProcessPerCategoryActions.LoadProcessPerCategoryError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    LoadProcessOfCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessPerCategoryActions.LoadprocessesOfCategory),
            exhaustMap((action) => {

                return this.ProcessPerCategoryService.loadProcessOfCategory(action.payload, action.CategoryID).pipe(
                    map((response) => {
                        return ProcessPerCategoryActions.LoadprocessesOfCategorySuccess({ processes: response.processes, totalCount: response.totalCount, CategoryID: action.CategoryID });
                    }),
                    catchError((error: any) => of(ProcessPerCategoryActions.LoadprocessesOfCategoryError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    SaveAllocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessPerCategoryActions.SaveAllocations),
            exhaustMap((action) => {

                return this.ProcessPerCategoryService.saveAllocations(action.payload, action.CategoryID).pipe(
                    map((response) => {
                        return ProcessPerCategoryActions.SaveAllocationsSuccess({ message: response.message, CategoryID: action.CategoryID });
                    }),
                    catchError((error: any) => of(ProcessPerCategoryActions.SaveAllocationsError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    OnSaveAllocationsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessPerCategoryActions.SaveAllocationsSuccess),
            switchMap((action) => combineLatest([
                this.store.select(ProcessPerCategorySelector.getListingPayload).pipe(take(1)),
                of(action.CategoryID),
            ])
            ),
            map(([payload, CategoryID]) => {
                return ProcessPerCategoryActions.LoadprocessesOfCategory({payload, CategoryID})
            })
        )
    );
    
}