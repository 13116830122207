/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@panjab-digi-lib/shared';
import { Observable, throwError } from 'rxjs';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { STAFF_API_ENDPOINTS } from './app.endpoints';
import { TaskAssignee, Task, StaffTask, AssignedTask, StaffWork } from 'shared/src/interfaces/staff/document.interface';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  staffApiURL = '';
  constructor(private http: HttpClient) {
    this.staffApiURL = environment.rootApiUrl + environment.staffApiPrefix;
  }

  loadTaskList(payload: GenericListingPayload, DocID: string): Observable<{taskList: Task[], totalRecords: number}> {
    return this.http.get(`${this.staffApiURL}${STAFF_API_ENDPOINTS.task.list}`.replace('{DocID}', DocID), {params: {...payload}}).pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  loadStaffWork(payload: GenericListingPayload): Observable<{taskList: StaffWork[], totalRecords: number}> {
    return this.http.get(`${this.staffApiURL}${STAFF_API_ENDPOINTS.task.staffWork}`, {params: {...payload}}).pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }
  
  loadAssignedTaskList(payload: GenericListingPayload): Observable<{taskList: AssignedTask[], totalRecords: number}> {
    return this.http.get(`${this.staffApiURL}${STAFF_API_ENDPOINTS.task.assignedTasklist}`, {params: {...payload}}).pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  loadStaffTaskList(payload: GenericListingPayload, StaffID: string): Observable<{taskList: StaffTask[], totalRecords: number}> {
    return this.http.get(`${this.staffApiURL}${STAFF_API_ENDPOINTS.task.staffTaskList}`.replace('{StaffID}', StaffID), {params: {...payload}}).pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  loadTasksWithAssignee(DocID: string): Observable<{assigneeList: TaskAssignee[]}> {
    return this.http.get(`${this.staffApiURL}${STAFF_API_ENDPOINTS.task.tasksAssigneeList}`.replace('{DocID}', DocID)).pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  assignTask(PSID: number, StaffID: number) {
    return this.http.patch(`${this.staffApiURL}${STAFF_API_ENDPOINTS.task.assignTask}`, {PSID, StaffID}).pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  submitTask(PSID: number) {
    return this.http.patch(`${this.staffApiURL}${STAFF_API_ENDPOINTS.task.submitTask}`, {PSID}).pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  approveTask(PSID: number) {
    return this.http.patch(`${this.staffApiURL}${STAFF_API_ENDPOINTS.task.approveTask}`, {PSID}).pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  bypassTask(PSID: number, undoBypass: boolean) {
    return this.http.patch(`${this.staffApiURL}${STAFF_API_ENDPOINTS.task.bypassTask}`, {PSID, undoBypass}).pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  rejectTask(PSID: number, reason: string) {
    return this.http.patch(`${this.staffApiURL}${STAFF_API_ENDPOINTS.task.rejectTask}`, {PSID, reason}).pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  downloadPendingApprovalTasksList() {
    return this.http.get(`${this.staffApiURL}${STAFF_API_ENDPOINTS.task.pendingApprovalTasksDownload}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }

  private handleError(error: HttpErrorResponse) {
    let transformedError: HttpErrorResponse;

    if (error.error && error.error.error) {
      transformedError = error.error.error;
    }


    return throwError(() => transformedError);
  }
}
