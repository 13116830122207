import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsComponent, AppState, CountryActions, CountrySelector, environment  } from '@panjab-digi-lib/shared';
import { SocialLoginComponent } from '../common/social-login/social-login.component';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { Observable, Subscription } from 'rxjs';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { AlertService } from 'shared/src/services/website/alert.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { SpinnerButtonComponent } from 'shared/src/lib/components/web/spinner-button/spinner-button.component';
import { RecaptchaModule, RecaptchaFormsModule, RecaptchaComponent } from 'ng-recaptcha';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { WEB_API_ENDPOINTS } from 'shared/src/services/website/app.endpoints';
import { UtilService } from '../../../common/services/util.service';
import { TrimmedTextDirective } from '../../../common/directives/trimmed-text.directive';
import { NumberonlyDirective } from '../../../common/directives/numberonly.directive';

const VALIDATE_TYPE = {
  EMAIL: 1,
  PHONE: 2
}

@Component({
  selector: 'panjab-digi-lib-volunteer-request',
  standalone: true,
  imports: [CommonModule, TranslateModule, SocialLoginComponent, BreadcrumbsComponent, ReactiveFormsModule, NgbTooltipModule, RouterLink, AlertsComponent, SpinnerButtonComponent, RecaptchaModule, RecaptchaFormsModule, TrimmedTextDirective, NumberonlyDirective],
  templateUrl: './volunteer-request.component.html',
  styleUrl: './volunteer-request.component.scss',
})
export class VolunteerRequestComponent implements OnInit, AfterViewInit, OnDestroy {
  pageTitle = 'Volunteer';
  volunteerReqForm!: FormGroup;
  countryList$!: Observable<any>;
  successMsg = false;
  isSubmitted = false;
  sitekey = environment.recaptcha.siteKey;
  isMobile = false;
  checkboxData = APP_CONSTANTS.volunteerReqOptions;
  subscription = new Subscription();
  @ViewChild('emailInput') emailInput!: ElementRef;
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  @ViewChild('captchaRef') captchaRef!: RecaptchaComponent;
  @ViewChild('moveToTop', { static: false }) moveToTop!: ElementRef;
  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private authService: AuthenticationService,
    private el: ElementRef,
    private alertService: AlertService,
    private renderer: Renderer2,
    private utilService: UtilService
  ) {

    this.getCountriesList();
    this.initVolunteerReqForm();
  }

  ngOnInit() {
    // change recaptcha size when it is on mobile view
    if(window.innerWidth < 370) {
      this.isMobile = true;
    }
  }

  ngAfterViewInit() {
    this.renderer.listen(this.emailInput.nativeElement, 'blur', () => {
      this.validateOnBlur(VALIDATE_TYPE.EMAIL);
    });
    this.renderer.listen(this.phoneInput.nativeElement, 'blur', () => {
      this.validateOnBlur(VALIDATE_TYPE.PHONE);
    });
  }

  getCountriesList() {
    const payload = {};
    this.store.dispatch(CountryActions.LoadActiveCountryList({payload}));
    this.countryList$ = this.store.select(CountrySelector.getCountries);
  }

  displayError(err: any) {
    if(err.error?.error?.message == 'Phone number already taken') {
      this.updateErrorState('phone');
    } else if(err.error?.error?.message == 'Email already taken') {
      this.updateErrorState('email');
    }
  }

  validateVolunteer(
    type: number,
    control: AbstractControl
  ): void {
    let requestType;
    if(type == VALIDATE_TYPE.EMAIL) {
      requestType = {
        email: control.value
      }
    } else if(type == VALIDATE_TYPE.PHONE) {
      requestType = {
        phone: control.value
      }
    }
    this.authService.sendHttpRequest('POST', WEB_API_ENDPOINTS.volunteer.validateReq, requestType, false).subscribe({
      next: (res) => {
        //
      },
      error: (err) => {
        if(err.status == 400) {
          this.displayError(err);
        }
      }
    })
  }

  get checkboxesFormArray() {
    return this.volunteerReqForm.get('options') as FormArray;
  }

  private addCheckboxes() {
    this.checkboxData.forEach(() => this.checkboxesFormArray.push(this.fb.control(false)));
  }

  minSelectedCheckboxes(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const formArray = control as FormArray;
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => next ? prev + 1 : prev, 0);

      return totalSelected >= min ? null : { required: true };
    };
  }

  initVolunteerReqForm() {
    this.volunteerReqForm = this.fb.group(
      {
        options: this.fb.array([], this.minSelectedCheckboxes(1)),
        name: ['', [Validators.required, Validators.minLength(5), Validators.pattern(APP_CONSTANTS.regExp.alphaNumericOnly)]],
        designation: ['', [Validators.required, Validators.maxLength(30)]],
        organization: ['', [Validators.required, Validators.maxLength(30)]],
        address1: ['', [Validators.required, Validators.maxLength(100)]],
        address2: ['', [Validators.required, Validators.maxLength(100)]],
        country: [null, Validators.required],
        phone: ['', [Validators.required, Validators.pattern(APP_CONSTANTS.regExp.numberOnly) ,Validators.minLength(10), Validators.maxLength(12)]],
        email: ['', {
          validators: [Validators.required, Validators.pattern(APP_CONSTANTS.regExp.email)],
          updateOn: 'blur'
        }],
        url: ['', [Validators.pattern(APP_CONSTANTS.regExp.url)]],
        cpt: [null, Validators.required]
      }
    );
    this.addCheckboxes();
  }

  validateOnBlur(type: number) {
    if(type == VALIDATE_TYPE.EMAIL) {
      const email = this.volunteerReqForm.get('email');
      if(email && email?.valid) {
        this.validateVolunteer(type, email);
      }
    } else if(type == VALIDATE_TYPE.PHONE) {
      const phone = this.volunteerReqForm.get('phone');
      if(phone && phone?.valid) {
        this.validateVolunteer(type, phone);
      }
    }
  }

  updateErrorState(field: string) {
    this.volunteerReqForm.controls[field].setErrors({ 'invalidField': true });
  }

  resetForm() {
    this.volunteerReqForm.reset();
    this.moveToFirstElement();
    this.resetReCaptcha();
  }

  moveToFirstElement(): void {
    this.renderer.selectRootElement(this.moveToTop.nativeElement).focus();
  }

  resolveCaptcha(captchaResponse: string | null) {
    if(captchaResponse) {
      this.volunteerReqForm.patchValue({'cpt': captchaResponse})
    } else {
      this.volunteerReqForm.controls['cpt'].setErrors({'invalid': true});
    }
  }

  resetReCaptcha() {
    this.captchaRef.reset();
  }

  formatSelectedOptions() {
    this.volunteerReqForm.get('options')
  }

  onSubmit() {
    this.alertService.clear();
    this.volunteerReqForm.markAllAsTouched();
    if(this.volunteerReqForm.valid) {
      this.formatSelectedOptions();
      this.isSubmitted = true;
      let formData = this.volunteerReqForm.value;
      const selectedOptions = this.volunteerReqForm.get('options')?.value;
      const optionsName: string[] = [];
      selectedOptions.forEach((selected:boolean|null, i:number) => {
        if(selected) {
          optionsName.push(this.checkboxData[i].type);
        }
      });
      formData = {...formData, options: optionsName.join(',')};
      if(formData.url === "") {
        delete formData.url;
      }
      this.authService.sendHttpRequest('POST', WEB_API_ENDPOINTS.volunteer.addReq, formData, false).subscribe({
        next: (res:any) => {
          this.successMsg = true;
          this.alertService.success(res.message);
          this.resetForm();
          this.isSubmitted = false;
        },
        error: (err) => {
          this.volunteerReqForm.controls['email'].setErrors(null);
          if(err.status == 400) {
            this.displayError(err);
          } else {
            this.alertService.error(err.error?.error?.message);
          }
          this.isSubmitted = false;
          this.resetReCaptcha();
        }
      });
    } else {
      this.utilService.focusOnInvalidField(this.volunteerReqForm, this.el);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
