import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap, take } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { AssignedTaskActions } from 'shared/src/store/actions';
import { TaskService } from 'shared/src/services/staff/task.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { DocumentListSelector, TaskSelector } from '@panjab-digi-lib/shared';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';

@Injectable()
export class AssignedTaskEffects {
  constructor(
    private actions$: Actions, 
    private taskService: TaskService,
    private store: Store<AppState>
  ) {}
  LoadAssignedTaskList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignedTaskActions.LoadAssignedTaskList),
      switchMap(({ payload }) =>
        this.taskService.loadAssignedTaskList(payload).pipe(
          map((response) => {
            return AssignedTaskActions.LoadAssignedTaskListSuccess({ 
              taskList: response.taskList, 
              totalRecords: response.totalRecords
            })
          }),
          catchError((error: any) => {
            return of(AssignedTaskActions.LoadAssignedTaskListError({ message: (error.message) }))
          })
        )
      )
    )
  );
  
  SubmitAssignedTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignedTaskActions.SubmitAssignedTask),
      switchMap(({ PSID }) =>
        this.taskService.submitTask(PSID).pipe(
          map((response) => {
            return AssignedTaskActions.SubmitAssignedTaskSuccess({ 
              task: response.body.task,
              message: response.message
            })
          }),
          catchError((error: any) => {
            return of(AssignedTaskActions.SubmitAssignedTaskError({ message: (error.message) }))
          })
        )
      )
    )
  );

}
