import { createAction, props } from '@ngrx/store';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { Donation } from 'shared/src/interfaces/web-management.interface';

export const GetDonationList = createAction(
  '[WebDonation] Donations List Request',
  props<{ payload: GenericListingPayload }>()
);

export const GetDonationListSuccess = createAction(
  '[WebDonation] Donations List Request Success',
  props<{ donations: Donation[], totalRecords: number }>()
);

export const GetDonationListError = createAction(
  '[WebDonation] Donations List Request Error',
  props<{ message: string }>()
);