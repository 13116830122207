/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { AdminEmployeeActions } from '../actions/index';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Employee } from 'shared/src/interfaces/employee.interface';
export interface State extends EntityState<Employee> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  orderBy: string;
}

export const adapter = createEntityAdapter<Employee>({
  selectId: (e) => e?.StaffID,
});

export const initialState = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0,
  pageNumber: 0,
  pageSize: 0,
  sortBy: 'DateAdded',
  orderBy: 'desc',
});

export const reducer = createReducer(
  initialState,

  on(AdminEmployeeActions.LoadEmployees, (state, action) => ({
    ...state,
    isLoading: true,
    successMessage: '',
    errorMessage: '',
    pageNumber: action.payload?.pageNumber || 1,
    pageSize: action.payload?.pageSize || 0,
  })),

  on(AdminEmployeeActions.LoadEmployeesSuccessfully, (state, action) =>
    adapter.setAll(action?.employeeList, {
      ...state,
      totalRecords: action.totalRecords,
      isLoading: false,
    })
  ),

  on(AdminEmployeeActions.LoadEmployeesError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminEmployeeActions.AddEmployee, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminEmployeeActions.AddEmployeeSuccessfully, (state, action) => ({
    ...state,
    isLoading: false,
    isSaving: true,
    isError: false,
    errorMessage: '',
    successMessage: action?.message,
  })),

  on(AdminEmployeeActions.AddEmployeeError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      successMessage : false,
      errorMessage: action.message,
    };
  }),

  on(AdminEmployeeActions.UpdateEmployee, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminEmployeeActions.UpdateEmployeeSuccessfully, (state, action) => ({
    ...adapter.updateOne(
      { id: action?.employee?.StaffID, changes: action.employee },
      state
    ),
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: action?.message,
  })),

  on(AdminEmployeeActions.UpdateEmployeeError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      successMessage: '',
      errorMessage: action?.message,
    };
  }),

  on(AdminEmployeeActions.DeleteEmployee, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminEmployeeActions.DeleteEmployeeSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: false,
      successMessage: action?.message,
      errorMessage: '',
    };
  }),

  on(AdminEmployeeActions.DeleteEmployeeError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),

  on(AdminEmployeeActions.UpdateRoleEmployee, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminEmployeeActions.UpdateRoleEmployeeSuccessfully, (state, action) => ({
    ...adapter.updateOne(
      { id: action?.employee?.StaffID, changes: action.employee },
      state
    ),
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: action?.message,
  })),

  on(AdminEmployeeActions.UpdateRoleEmployeeError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      successMessage: '',
      errorMessage: action?.message,
    };
  }),

  on(AdminEmployeeActions.UpdateEmployeeStatusSuccessfully, (state, action) => {
    const updates = action?.employee;
    const updatedEntities = adapter.updateMany(
      updates.map((update: Employee) => ({
        id: update.StaffID,
        changes: update,
      })),
      state
    );
    return {
      ...updatedEntities,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action?.message,
    };
  }),

  on(AdminEmployeeActions.UpdateEmployeeStatusError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  })
);

export const { selectAll } = adapter.getSelectors();
