import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromWebCategories from "../../reducers/website/web-category.reducer"

export const selectCategoriesState =
  createFeatureSelector<fromWebCategories.State>('webCategories');

export const getCategories = createSelector(
    selectCategoriesState,
    fromWebCategories.selectAll
);

export const isLoading = createSelector(
  selectCategoriesState,
  (state) => state.isLoading
);

export const isSaving = createSelector(
    selectCategoriesState,
  (state) => [state.isSaving, state.isError, state.errorMessage],
);