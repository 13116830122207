/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { MetadataComment } from "shared/src/interfaces/master-form.interface";

export const LoadMetadataComment = createAction(
    '[MetadataComment] Load Metadata Comment',
    props<{payload: GenericListingPayload}>(),
)

export const LoadMetadataCommentSuccess = createAction(
    '[MetadataComment] Load Metadata Comment Successfully',
    props<{metadataComments: MetadataComment[], totalRecords: number}>()
)

export const LoadMetadataCommentError = createAction(
    '[MetadataComment] Load Metadata Comment Error',
    props<{message: string}>()
)

export const UpdateMetadataCommentStatus = createAction(
    '[MetadataComment] Update Metadata Comment Status',
    props<{metadataCommentIDs: number[], status: string, comment?: string}>(),
)

export const UpdateMetadataCommentStatusSuccess = createAction(
    '[MetadataComment] Update Metadata Comment Status Success',
    props<{metadataCommentUpdates: any, message: string}>(),
)

export const UpdateMetadataCommentStatusError = createAction(
    '[MetadataComment] Update Metadata Comment Status Error',
    props<{message: string}>(),
)