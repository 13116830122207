/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionReducerMap } from "@ngrx/store";
import * as fromBanners from './banners.reducer';
import * as fromAuth from './auth.reducer';
import * as fromCategories from './categories.reducer';
import * as fromServices from './services.reducer';
import * as fromMetaData from './metadata.reducer';
import * as fromProcess from './process.reducer';
import * as fromSubject from './subject.reducer';
import * as fromCurrency from './currency.reducer';
import * as fromCountry from './country.reducer';
import * as fromAdminVolunteer from './admin-volunteer.reducer';
import * as fromDepartment from './department.reducer';
import * as fromSection from './section.reducer';
import * as fromDesignation from './designation.reducer';
import * as fromSubjectsPerCategory from './subjectsPerCategory.reducer';
import * as fromScriptsPerLanguage from './scriptsPerLanguage.reducer';
import * as fromMetadataPerCategory from './metadataPerCategory.reducer';
import * as fromDonor from './donor.reducers';
import * as fromViewDonation from './view-donation.reducer';
import * as fromWebBanners from './website/web-banners.reducer';
import * as fromWebTrendingKeywords from './website/web-trending-keywords.reducer';
import * as fromWebCategories from './website/web-category.reducer';
import * as fromAdminKeyword from './admin-keywords.reducer';
import * as fromAdminTrending from './admin-trending.reducer';
import * as fromAdminDownload from './admin-download-reducer';
import * as fromWebDownloads from './website/web-download.reducer';
import * as fromAdminLanguage from './admin-language-reducer';
import * as fromAdminScript from './admin-script.reducer';
import * as fromAdminPublisher from './admin-publisher.reducer';
import * as fromAdminTranslator from './admin-translator.reducer';
import * as fromAdminAuthor from './admin-author.reducer';
import * as fromAdminContributor from './admin-contributor.reducer';
import * as fromAdminEditor from './admin-editor.reducer';
import * as fromWebUser from './web-user.reducer';
import * as fromArticleComment from './admin-article-comment.reducer';
import * as fromAdminVideoGallery from './admin-video-gallery.reducer';
import * as fromMetaDataComment from './admin-metadata-comment.reducer';
import * as fromWebVideos from './website/web-videos.reducer';
import * as fromWebDonations from './website/web-donation.reducer';
import * as fromAdoptedBy from './admin-adopted-by.reducer';
import * as fromToAdopt from './admin-to-adopt.reducer';
import * as fromAdminSponsorship from './admin-sponsorship.reducer';
import * as fromAdoptedBooks from './admin-adopted-books.reducer';
import * as fromAdoptPrice from './admin-adopt-price.reducer';
import * as fromWebAuthUser from './website/web-authuser.reducer';
import * as fromAdminEmployee from './admin-employee.reducer';
import * as fromWebAdoptedBooks from './website/web-adopted-books.reducer';
import * as fromStaffAuth from './staff/auth.reducer';
import * as fromProcessPerCategory from './processPerCategory.reducer';
import * as fromStaffList from './staff/staff-list.reducer';
import * as fromDocumentList from './staff/document-list.reducer';
import * as fromTask from './staff/task.reducer';
import * as fromStaffTask from './staff/staff-task.reducer';
import * as fromAssignedTask from './staff/assigned-tasks.reducer';
import * as fromStaffWork from './staff/staff-work.reducer';
import * as fromAdminWebDocMngr from './admin-web-doc-manager.reducer';
import * as fromPermission from './permission.reducer';
import * as fromAdminUserService from './admin-user-service.reducer';
import * as fromAdminVolunteerRequests from './admin-volunteer-requests.reducer';
import * as fromUserTypes from './user-types.reducer';
import * as fromWebSearch from './website/web-search.reducer';
export interface AppState {
    banners: fromBanners.State;
    auth: fromAuth.State;
    categories: fromCategories.State;
    services: fromServices.State;
    metaData: fromMetaData.State;
    process: fromProcess.State;
    subject: fromSubject.State;
    currency: fromCurrency.State;
    country: fromCountry.State;
    department: fromDepartment.State;
    section: fromSection.State;
    designation: fromDesignation.State;
    subjectsPerCategory: fromSubjectsPerCategory.State;
    scriptsPerLanguage: fromScriptsPerLanguage.State;
    metadataPerCategory: fromMetadataPerCategory.State;
    donor: fromDonor.State;
    viewDonation: fromViewDonation.State;
    webBanners: fromWebBanners.State;
    webKeywords: fromWebTrendingKeywords.State;
    webCategories: fromWebCategories.State;
    adminKeyword: fromAdminKeyword.State,
    adminTrending : fromAdminTrending.State,
    adminDownload: fromAdminDownload.State,
    webDownloads: fromWebDownloads.State;
    adminLanguage: fromAdminLanguage.State,
    adminScript : fromAdminScript.State,
    adminPublisher: fromAdminPublisher.State,
    adminTranslator: fromAdminTranslator.State,
    countryList: fromCountry.State,
    volunteerList: fromAdminVolunteer.State;
    adminAuthor: fromAdminAuthor.State,
    adminContributor: fromAdminContributor.State,
    adminEditor: fromAdminEditor.State,
    webUser: fromWebUser.State,
    articleCommentList: fromArticleComment.State,
    adminVideoGallery : fromAdminVideoGallery.State,
    metadataCommentList: fromMetaDataComment.State,
    fromWebVideos : fromWebVideos.State,
    fromWebDonations : fromWebDonations.State,
    adoptedByList :fromAdoptedBy.State,
    adoptedBooksList: fromAdoptedBooks.State,
    adoptPrice : fromAdoptPrice.State,
    webAuthUser: fromWebAuthUser.State,
    staffAuth: fromStaffAuth.State,
    staffList: fromStaffList.State,
    documentList: fromDocumentList.State,
    task: fromTask.State,
    staffTask: fromStaffTask.State,
    assignedTask: fromAssignedTask.State,
    staffWork: fromStaffWork.State,
    toAdoptList: fromToAdopt.State,
    adminSponsorship : fromAdminSponsorship.State,
    adminEmployee : fromAdminEmployee.State,
    webAdoptedBooks: fromWebAdoptedBooks.State,
    processPerCategory: fromProcessPerCategory.State,
    adminWebDocMngr: fromAdminWebDocMngr.State,
    userPermission : fromPermission.State,
    adminUserService:  fromAdminUserService.State,
    adminVolunteerRequests: fromAdminVolunteerRequests.State,
    userTypes: fromUserTypes.State,
    webSearch : fromWebSearch.State
}

export const reducers: ActionReducerMap<any> = {
    banners: fromBanners.reducer,
    auth: fromAuth.authReducer,
    categories: fromCategories.reducer,
    services: fromServices.reducer,
    metaData: fromMetaData.reducer,
    process: fromProcess.reducer,
    subject: fromSubject.reducer,
    currency: fromCurrency.reducer,
    country: fromCountry.reducer,
    department: fromDepartment.reducer,
    section: fromSection.reducer,
    designation: fromDesignation.reducer,
    subjectsPerCategory: fromSubjectsPerCategory.reducer,
    scriptsPerLanguage: fromScriptsPerLanguage.reducer,
    metadataPerCategory: fromMetadataPerCategory.reducer,
    donor: fromDonor.reducer,
    viewDonation: fromViewDonation.reducer,
    adminKeyword: fromAdminKeyword.reducer,
    webBanners: fromWebBanners.reducer,
    webKeywords: fromWebTrendingKeywords.reducer,
    webCategories: fromWebCategories.reducer,
    adminTrending : fromAdminTrending.reducer,
    adminDownload: fromAdminDownload.reducer,
    webDownloads: fromWebDownloads.reducer,
    adminLanguage: fromAdminLanguage.reducer,
    adminScript: fromAdminScript.reducer,
    adminPublisher: fromAdminPublisher.reducer,
    adminTranslator: fromAdminTranslator.reducer,
    countryList: fromCountry.reducer,
    volunteerList: fromAdminVolunteer.reducer,
    adminAuthor: fromAdminAuthor.reducer,
    adminContributor: fromAdminContributor.reducer,
    adminEditor: fromAdminEditor.reducer,
    webUser: fromWebUser.reducer,
    articleCommentList: fromArticleComment.reducer,
    adminVideoGallery : fromAdminVideoGallery.reducer,
    metadataCommentList: fromMetaDataComment.reducer,
    webVideos: fromWebVideos.reducer,
    webDonations: fromWebDonations.reducer,
    adoptedByList: fromAdoptedBy.reducer,
    adoptPrice : fromAdoptPrice.reducer,
    webAuthUser: fromWebAuthUser.reducer,
    staffAuth: fromStaffAuth.reducer,
    staffList: fromStaffList.reducer,
    documentList: fromDocumentList.reducer,
    task: fromTask.reducer,
    staffTask: fromStaffTask.reducer,
    assignedTask: fromAssignedTask.reducer,
    staffWork: fromStaffWork.reducer,
    toAdoptList: fromToAdopt.reducer,
    adoptedBooksList: fromAdoptedBooks.reducer,
    adminSponsorship : fromAdminSponsorship.reducer,
    adminEmployee : fromAdminEmployee.reducer,
    webAdoptedBooks: fromWebAdoptedBooks.reducer,
    processPerCategory:  fromProcessPerCategory.reducer,
    adminWebDocMngr: fromAdminWebDocMngr.reducer,
    userPermission : fromPermission.reducer,
    adminUserService: fromAdminUserService.reducer,
    adminVolunteerRequests: fromAdminVolunteerRequests.reducer,
    userTypes: fromUserTypes.reducer,
    webSearch :fromWebSearch.reducer
}