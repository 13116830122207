/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { StaffTaskActions } from 'shared/src/store/actions';
import { TaskService } from 'shared/src/services/staff/task.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';

@Injectable()
export class StaffTaskEffects {
  constructor(
    private actions$: Actions, 
    private taskService: TaskService,
    private store: Store<AppState>
  ) {}
  LoadStaffTaskList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffTaskActions.LoadStaffTaskList),
      switchMap(({ payload, StaffID }) =>
        this.taskService.loadStaffTaskList(payload, StaffID).pipe(
          map((response) => {
            return StaffTaskActions.LoadStaffTaskListSuccess({ 
              taskList: response.taskList, 
              totalRecords: response.totalRecords
            })
          }),
          catchError((error: any) => {
            return of(StaffTaskActions.LoadStaffTaskListError({ message: (error.message) }))
          })
        )
      )
    )
  );

}
