import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromMetadataPerCategory from "../reducers/metadataPerCategory.reducer";

export const selectMetadataPerCategoryState =
  createFeatureSelector<fromMetadataPerCategory.State>('metadataPerCategory');

export const getMetadataForCategories = createSelector(
    selectMetadataPerCategoryState,
    fromMetadataPerCategory.selectAll
);

export const getMetadataPerCategoryByID = (props: {id: number}) => createSelector(
  selectMetadataPerCategoryState,
  (state) => state.entities[props.id]
)

export const getMetadataOfCategory = createSelector(
    selectMetadataPerCategoryState,
    (state) => state.metadataOfCategory,
);

export const getTotalRecords = createSelector(
    selectMetadataPerCategoryState,
    (state) => state.totalRecords,
);

export const onSave = createSelector(
  selectMetadataPerCategoryState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectMetadataPerCategoryState,
  (state) => state.isLoading
);

export const getListingPayload = createSelector(
    selectMetadataPerCategoryState,
    (state) => state.payload
)