import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalPopupService } from "../../../../common/services/modal-popup.service";
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertService } from 'shared/src/services/website/alert.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { ConfirmationModalService } from 'shared/src/services/confirmation-modal/confirmation-modal.service';
import { UserService } from '../../../../common/services/user.service';

@Component({
  selector: 'panjab-digi-lib-delete-deactivate-account',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, AlertsComponent],
  templateUrl: './delete-deactivate-account.component.html',
  styleUrl: './delete-deactivate-account.component.scss',
})
export class DeleteDeactivateAccountComponent {
  deleteDeactivateForm!: FormGroup;
  @ViewChild('deleteDeactivateModal') deleteDeactivateModal!: TemplateRef<any>;
  constructor(
    private modalPopupService: ModalPopupService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private confirmationModalService: ConfirmationModalService,
    private translate: TranslateService,
    private userService: UserService
  ) {}

  handleOnSubmit() {
    this.alertService.clear();
    if(this.deleteDeactivateForm.valid) {
      const selValue = this.deleteDeactivateForm.controls['deldeactivateAcc'].value;
      let deleteDataType:number;
      let title = '';
      let description = '';
      let OkBtnText = '';
      let changeStatusTo:number;
      if(selValue === 'deactivateAcc') {
        title = this.translate.instant('pdl-website.forms.labels.deleteDeactivateForm.deactivateConfirmTitle');
        description = this.translate.instant('pdl-website.forms.labels.deleteDeactivateForm.deactivateConfirmDesc');
        OkBtnText = this.translate.instant('pdl-website.forms.labels.deleteDeactivateForm.deactivateConfirmOkBtn');
        changeStatusTo = 5;
      } else if(selValue === 'deleteAcc') {
        title = this.translate.instant('pdl-website.forms.labels.deleteDeactivateForm.delConfirmTitle');
        description = this.translate.instant('pdl-website.forms.labels.deleteDeactivateForm.delConfirmDesc');
        OkBtnText = this.translate.instant('pdl-website.forms.labels.deleteDeactivateForm.delConfirmOkBtn');
        changeStatusTo = 3;
        deleteDataType = +this.deleteDeactivateForm.controls['delDataConfirm'].value;
      }
      if(title && description && OkBtnText) {
        this.confirmationModalService.confirm(
          title, 
          description, 
          OkBtnText, 
          'Cancel', 
          'md').then((confirmed) => {
          if(confirmed) {
            this.userService.updateConfirmModalStatus(true);
            this.alertService.warning(this.translate.instant('pdl-shared.loaderState.requestInProgress'));
            this.userService.updateAccountStatus(changeStatusTo, deleteDataType).subscribe({
              next: (res) => {
                this.alertService.success(res.message);
                setTimeout(() => {
                  this.userService.logOutWebUser();
                }, 4000);
              },
              error: (err) => {
                this.alertService.error(err?.error?.error?.message);
                this.userService.updateConfirmModalStatus(false);
              }
            });
          }
        });
      }
    }
  }

  initDeleteDeactivateForm() {
    this.deleteDeactivateForm = this.fb.group({      
      deldeactivateAcc : ['deactivateAcc'],
      delDataConfirm: ['1']
    })
  }

  openModalPopup() {
    this.modalPopupService.open(
      this.translate.instant('pdl-website.forms.labels.deleteDeactivateForm.modalTitle'), 
      this.translate.instant('pdl-website.forms.labels.deleteDeactivateForm.modalSubtitle'), 
      this.deleteDeactivateModal, 
      this.translate.instant('pdl-shared.buttonText.continue'),
      this.translate.instant('pdl-shared.buttonText.cancel'),
      this.handleOnSubmit.bind(this));
    this.initDeleteDeactivateForm();
  }
}
