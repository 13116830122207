import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAdminSponsorship from '../reducers/admin-sponsorship.reducer';

export const selectState =
  createFeatureSelector<fromAdminSponsorship.State>('adminSponsorship');

export const loadSponsorshipList = createSelector(selectState, (state) => ({
  sponsorshipList: fromAdminSponsorship.selectAll(state),
  totalRecords: state.totalRecords,
  pageSize: state.pageSize,
  pageNumber : state.pageNumber,
  orderBy: state.orderBy,
  order: state.order
}));

export const getSponsorshipById = (id: number) => createSelector(
  selectState,
  (state) => state.entities[id]
)

export const onSave = createSelector(
  selectState,
  (state) => [state.isError, state.errorMessage, state.successMessage,state.isLoading]
);