import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ReaderComponent } from '../meta-data/reader/reader.component';
import { MetadataService } from 'shared/src/services/website/metadata/metadata.service';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import {
  AuthSelector,
  CountryActions,
  CountrySelector,
} from '@panjab-digi-lib/shared';
import { LoggedInUser } from 'shared/src/interfaces/auth-user.interface';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SafeHtmlPipe } from 'shared/src/pipes/safe-html.pipe';

@Component({
  selector: 'panjab-digi-lib-metadata',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    TranslateModule,
    ReaderComponent,
    NgbPaginationModule,
    SafeHtmlPipe,
  ],
  templateUrl: './metadata.component.html',
  styleUrl: './metadata.component.scss',
})
export class MetadataComponent {
  dummyArr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  accession_number = '';
  toastService = new ToastService();
  metadata: any = {};
  publishedInSameYear: any = [];
  documentsByTopic: any = [];
  activeUser: any;
  subscription = new Subscription();
  collapsedMenus: any = { comments: true };
  countryList!: Observable<any>;
  comments: any = null;
  docId: any = null;
  apaFormat = '';
  chicagoFormat = '';
  mlaFormat = '';
  pageNo = 1;
  comment = '';
  commentError = '';
  latestCommentTime = 0;

  constructor(
    private metaDataService: MetadataService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit() {
    this.getMetaData();
    this.subscription.add(
      this.store
        .select(AuthSelector.selectWebUser)
        .subscribe((user: LoggedInUser) => {
          if (user) {
            this.activeUser = user;
          } else if (this.localStorage.get('webUser')) {
            this.activeUser = this.localStorage.get('webUser');
          }
        })
    );
    this.getCountriesList();
  }

  getMetaData = () => {
    this.activatedRoute.params.subscribe((paramsId: any) => {
      this.metadata = {};
      this.documentsByTopic = [];
      this.docId = paramsId.id;
      // fetch metadata
      this.metaDataService.getMetaData(this.docId).subscribe(
        (response: any) => {
          this.metadata = response.body.data;
          this.accession_number = this.metadata.AccessionNo;
          this.getcomments();
          if (this.metadata.PublisherYear) {
            // fetch docs published in same year
            this.metaDataService
              .getDocuments({
                publishYear: this.metadata.PublisherYear,
                excludedAccessionNumbers: this.accession_number,
              })
              .subscribe(
                (response: any) => {
                  this.publishedInSameYear = response.body.data;
                },
                (error: any) => {
                  if (error?.error?.error?.message) {
                    this.toastService.showError(error?.error?.error?.message);
                  }
                }
              );

            // fetch docs published with same keywords
            if (this.metadata?.WebDocument?.[0]?.Keywords?.trim()) {
              this.metaDataService
                .getDocumentsByTopics(
                  this.metadata?.WebDocument?.[0]?.Keywords,
                  this.accession_number
                )
                .subscribe(
                  (response: any) => {
                    this.documentsByTopic = response.body.data;
                  },
                  (error: any) => {
                    if (error?.error?.error?.message) {
                      this.toastService.showError(error?.error?.error?.message);
                    }
                  }
                );
            }
          }
        },
        (error: any) => {
          if (error?.error?.error?.message) {
            this.toastService.showError(error?.error?.error?.message);
          }
        }
      );
    });
  };
  getCountriesList() {
    const payload = {};
    this.store.dispatch(CountryActions.LoadActiveCountryList({ payload }));
    this.store
      .select(CountrySelector.getCountries)
      .subscribe((countryList: any) => {
        this.countryList = countryList;
      });
  }

  onCitationOpen = () => {
    this.apaFormat = this.getFormat('APA');
    this.chicagoFormat = this.getFormat('CHICAGO');
    this.mlaFormat = this.getFormat('MLA');
  };
  getMetaDataPath = (accession_number: string) =>
    `/metadata/${btoa(accession_number)}`;

  toggleCollapsed = (menu: string) => {
    this.collapsedMenus[menu] = !this.collapsedMenus[menu];
  };
  getSize = () => {
    let op = '';
    if (
      Number(this.metadata?.Length) +
        Number(this.metadata?.Breadth) +
        Number(this.metadata?.Height) ===
      0
    )
      return '-';
    if (Number(this.metadata?.Length)) {
      op = op + `${this.metadata?.Length} * `;
    } else {
      op = op + '- *';
    }
    if (Number(this.metadata?.Breadth)) {
      op = op + `${this.metadata?.Breadth} * `;
    } else {
      op = op + '- *';
    }
    if (Number(this.metadata?.Height)) {
      op = op + `${this.metadata?.Height}`;
    } else {
      op = op + '-';
    }
    return op;
  };
  getRandomCount = () => {
    const randNum = 20;
    return Array(randNum).fill(1);
  };

  getRating = (rating: number) => {
    const fullStars = Math.floor(rating);
    const isHalf = rating - fullStars > 0;
    const noStars = 5 - fullStars - (isHalf ? 1 : 0);
    const op = Array(fullStars).fill(1);
    const zeroStars = Array(noStars).fill(0);
    if (isHalf) {
      op.push(0.5);
    }

    return [...op, ...zeroStars];
  };

  getcomments = () => {
    this.metaDataService
      .getMetadataComments(this.accession_number, this.pageNo)
      .subscribe(
        (resp: any) => {
          this.comments = resp.body;
        },
        (error: any) => {
          if (error?.error?.error?.message) {
            this.toastService.showError(error?.error?.error?.message);
          }
        }
      );
  };
  getResearches = () => {
    this.metaDataService.getResearches(this.docId).subscribe(
      (resp: any) => {
        this.metadata.Researches = resp?.body?.data?.ResearchID;
      },
      (error: any) => {
        if (error?.error?.error?.message) {
          this.metadata.Researches = null;
          this.toastService.showError(error?.error?.error?.message);
        }
      }
    );
  };
  setAssociatedResearch = () => {
    this.metaDataService
      .setAsResearch(this.docId, this.metadata.Researches)
      .subscribe(
        (resp: any) => {
          if (!this.metadata.Researches) {
            this.metadata.Researches = resp?.body?.data?.ResearchID;
          } else {
            this.metadata.Researches = null;
          }
        },
        (error: any) => {
          if (error?.error?.error?.message) {
            this.toastService.showError(error?.error?.error?.message);
          }
        }
      );
  };
  handleCommentChange = (e: any) => {
    if (!this.activeUser) {
      return;
    }
    this.comment = e.target.value;
    this.commentError = '';
  };

  sendComment = () => {
    let timeStamp = Date.now();
    const timePassedSinceLastComment =
      (timeStamp - this.latestCommentTime) / 1000;
    if (timePassedSinceLastComment < 90) {
      this.toastService.showError('Please wait for a while for next comment.');
    }
    if (this.activeUser && this.comment?.length > 3) {
      this.metaDataService
        .setComment(this.comment, this.accession_number)
        .subscribe(
          (res) => {
            this.comment = '';
            this.getcomments();
          },
          (error: any) => {
            if (error?.error?.error?.message) {
              this.toastService.showError(error?.error?.error?.message);
            } else {
              this.toastService.showError('Failed to post comment');
            }
          }
        );
    }
    if (this.comment?.length <= 3) {
      this.commentError = 'Too Short to comment!!';
    }
  };

  getFormat = (formatType: 'APA' | 'CHICAGO' | 'MLA') => {
    if (formatType === 'APA')
      return this.metaDataService.getAPAFormat(this.metadata, this.countryList);
    if (formatType === 'CHICAGO') {
      return this.metaDataService.getChicagoFormat(
        this.metadata,
        this.countryList
      );
    }
    if (formatType === 'MLA') {
      return this.metaDataService.getMLAFormat(this.metadata, this.countryList);
    }
    return '';
  };

  getMetaDataJSON = () => {
    let op: any = {};
    op.title = this?.metadata?.Title;
    op.year = this.metadata?.PublisherYear ?? '';
    op.scripts = [];
    this?.metadata?.Script?.forEach((script: any) => {
      op.scripts.push(script.Name);
    });
    op.languages = [];
    this?.metadata?.Language?.forEach((language: any) => {
      op.languages.push(language.Name);
    });
    op.authors = this?.metadata?.AuthorsfullName.split(',').map(
      (author: string) => author.trim()
    );
    op.publishers = [];
    this?.metadata?.PublishersName?.forEach((publisher: any) => {
      op.publishers.push({ publisher });
    });
    op.custodian = this.metadata?.Custodian;
    op.length = this.metadata?.Length;
    op.breadth = this.metadata?.Breadth;
    op.height = this.metadata?.Height;
    op.completion = this.metadata?.Completion;
    op.docCondition = this.metadata?.DocCondition;
    op.totalPages = this.metadata?.TotalPages;
    op.keywords = [];
    op.descriptions = [];
    this?.metadata?.WebDocument?.forEach((webdoc: any) => {
      op.descriptions.push({ description: webdoc.Description });
      op.keywords.push({ keyword: webdoc.Keywords });
    });
    op.rightsAdvisory =
      'Anyone may reprint or copy parts of this study with due permission or acknowledgement.';

    /**
     * For Validation or addition of new fields in metadata, learn about codes here
     * https://www.loc.gov/marc/bibliographic/
     */
    const marcFields = {
      '245': { ind1: '1', ind2: '0', subfields: { a: op.title } }, // Title
      '260': {
        ind1: ' ',
        ind2: ' ',
        subfields: {
          b: op.publishers.map((p: any) => p.publisher).join('; '),
          c: op.year,
        },
      },
      // Publishers and year
      '300': {
        ind1: ' ',
        ind2: ' ',
        subfields: {
          a: `Pages: ${op.totalPages}`,
          c: `Dimensions: ${op.length}x${op.breadth}x${op.height} cm`,
        },
      }, // Physical description
      '500': { ind1: ' ', ind2: ' ', subfields: { a: op.docCondition } }, // Document condition
      '546': {
        ind1: ' ',
        ind2: ' ',
        subfields: {
          a: `Languages: ${op.languages.join(', ')}`,

          b: `Scripts: ${op.scripts.join(', ')}`,
        },
      }, // Language and script
      '520': {
        ind1: ' ',
        ind2: ' ',
        subfields: {
          a: `Descriptions: ${op.descriptions
            .map((d: any) => d.description)
            .join('. ')}`,
        },
      }, // Description
      '650': {
        ind1: ' ',
        ind2: '0',
        subfields: {
          a: `Keywords: ${op.keywords.map((k: any) => k.keyword).join(', ')}`,
        },
      }, // Keywords
      '540': { ind1: ' ', ind2: ' ', subfields: { a: op.rightsAdvisory } }, // Rights Advisory
      '561': {
        ind1: ' ',
        ind2: ' ',
        subfields: { a: `Custodian: ${op.custodian}` },
      }, // Custodian
      '100': {
        ind1: 1,
        ind2: '',
        subfields: {
          a: this?.metadata?.AuthorsfullName,
        },
      },
    };

    return { jsonMetadata: op, marcMetaData: marcFields };
  };

  downloadMarc = () => {
    const { jsonMetadata, marcMetaData } = this.getMetaDataJSON();
    this.metaDataService.loadMarcJSandConvert(jsonMetadata, marcMetaData);
  };

  downloadDublinCore = () => {
    let { jsonMetadata } = this.getMetaDataJSON();
    let xml = `<srw_dc:dc xmlns:srw_dc="info:srw/schema/1/dc-schema" xmlns:zs="http://docs.oasis-open.org/ns/search-ws/sruResponse" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="info:srw/schema/1/dc-schema http://www.loc.gov/standards/sru/resources/dc-schema.xsd">
    <title xmlns="http://purl.org/dc/elements/1.1/">${
      jsonMetadata.title
    }</title>
    ${jsonMetadata.authors
      .map(
        (author: string) =>
          `<creator xmlns="http://purl.org/dc/elements/1.1/">${author}</creator>`
      )
      .join('\n')}
    ${jsonMetadata.publishers
      .map(
        (publisher: any) =>
          `<publisher xmlns="http://purl.org/dc/elements/1.1/">${publisher.publisher}</publisher>`
      )
      .join('\n')}
    <type xmlns="http://purl.org/dc/elements/1.1/">${
      this.metadata?.Category?.Name
    }</type>
    <date xmlns="http://purl.org/dc/elements/1.1/">${jsonMetadata?.year}</date>
    ${jsonMetadata.languages
      .map(
        (language: string) =>
          `<language xmlns="http://purl.org/dc/elements/1.1/">${language}</language>`
      )
      .join('\n')}
    ${jsonMetadata.descriptions
      .map(
        (desc: any) => `
      <description xmlns="http://purl.org/dc/elements/1.1/">${desc.description}</description>
      `
      )
      .join('\n')}
    </srw_dc:dc>
    `;
    this.metaDataService.downloadXML(xml, `${jsonMetadata.title}_DC.xml`);
  };

  downloadMODS = () => {
    let { jsonMetadata } = this.getMetaDataJSON();

    let xml = `<mods xmlns="http://www.loc.gov/mods/v3">
  <titleInfo>
    <title>${jsonMetadata.title}</title>
  </titleInfo>
  ${jsonMetadata.authors.map(
    (author: string) => `<name type="personal">
  <namePart>${author}</namePart>
</name>`
  )}
  ${jsonMetadata.publishers
    .map(
      (publisher: any) =>
        `<name type="corporate"><namePart>${publisher.publisher}</namePart><role><roleTerm type="text">publisher</roleTerm></role></name>`
    )
    .join('')}
  <originInfo>
    <dateIssued>${jsonMetadata.year}</dateIssued>
  </originInfo>
  ${jsonMetadata.scripts
    .map(
      (script: string) =>
        `<language><languageTerm>${script}</languageTerm></language>`
    )
    .join('')}
  ${jsonMetadata.languages
    .map(
      (language: string) =>
        `<language><languageTerm>${language}</languageTerm></language>`
    )
    .join('')}
  
  <note>Custodian: ${jsonMetadata.custodian}</note>
  <physicalDescription>
    <extent>Length: ${jsonMetadata.length} cm, Breadth: ${
      jsonMetadata.breadth
    } cm, Height: ${jsonMetadata.height} cm</extent>
    <form>Completion: ${jsonMetadata.completion}</form>
    <condition>Condition: ${jsonMetadata.docCondition}</condition>
  </physicalDescription>
  <physicalDescription>
    <extent>Total Pages: ${jsonMetadata.totalPages}</extent>
  </physicalDescription>
  ${jsonMetadata.descriptions
    .map((desc: any) => `<abstract>${desc.description}</abstract>`)
    .join('')}
  ${jsonMetadata.keywords
    .map(
      (keyword: any) => `<subject><topic>${keyword.keyword}</topic></subject>`
    )
    .join('')}
  <accessCondition>Anyone may reprint or copy parts of this study with due permission or acknowledgement.</accessCondition>
</mods>`;
    this.metaDataService.downloadXML(xml, `${jsonMetadata.title}_MODS.xml`);
  };
}
