import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromCategories from "../reducers/categories.reducer";

export const selectCategoryState =
  createFeatureSelector<fromCategories.State>('categories');

export const getCategories = createSelector(
    selectCategoryState,
    fromCategories.selectAll
);

export const getTotalRecords = createSelector(
    selectCategoryState,
    (state) => state.totalRecords
);

export const getCategoryByID = (props: {id: string}) => createSelector(
  selectCategoryState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectCategoryState,
  (state) => state.payload
)

export const HomeCategories = createSelector(
    selectCategoryState,
    (state) => state.homeCategories
);

export const isLoading = createSelector(
  selectCategoryState,
  (state) => state.isLoading
);

export const categoriesViewType = createSelector(
  selectCategoryState,
  (state) => state.viewType
);

export const isSaving = createSelector(
    selectCategoryState,
  (state) => state.isSaving,
);

export const onSave = createSelector(
    selectCategoryState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const preCodeExists = createSelector(
    selectCategoryState,
  (state) => state.preCodeExists,
);