import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromWebDonations from "../../reducers/website/web-donation.reducer";

export const selectUserDonationState = createFeatureSelector<fromWebDonations.State>('webDonations');

export const getDonations = createSelector(
  selectUserDonationState,
  fromWebDonations.selectAll
);

export const getTotalRecords = createSelector(
  selectUserDonationState,
  (state) => state.totalRecords
);

export const isLoading = createSelector(
  selectUserDonationState,
  (state) => state.isLoading
);