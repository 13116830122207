import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of, exhaustMap } from 'rxjs';
import { StaffListActions } from 'shared/src/store/actions';
import { Router } from '@angular/router';
import { StaffService } from 'shared/src/services/staff/staff.service';

@Injectable()
export class StaffListEffects {
  constructor(
    private actions$: Actions, 
    private staffService: StaffService
  ) {}
  loadStaffList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffListActions.LoadStaffList),
      switchMap(({ payload }) =>
        this.staffService.loadStaffList(payload).pipe(
          map((response) => {
              
            return StaffListActions.LoadStaffListSuccess({ staffList: response.staffList, totalRecords: response.totalRecords})
          }),
          catchError((error: any) => {
            return of(StaffListActions.LoadStaffListError({ message: (error?.error?.error) }))
          })
        )
      )
    )
  );
}
