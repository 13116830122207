import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { WebDownloadActions } from '../../actions/index';

export interface State extends EntityState<any> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

export const adapter = createEntityAdapter<any>({
  selectId: (e) => e.ID,
});
export const initialState = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0
});

export const reducer = createReducer(
  initialState,
  on(WebDownloadActions.LoadWebDownloads, (state, action) => ({
    ...state,
    isLoading: true,
    isError: false,
    errorMessage: '',
  })),

  on(WebDownloadActions.LoadWebDownloadsSuccess, (state, action) => ({
    ...adapter.setAll(action?.downloads, {
      ...state,
      totalRecords: action?.totalRecords,
      isLoading: false,
      isError: false,
    })
  })),

  on(WebDownloadActions.LoadWebDownloadsError, (state, action) => ({
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.message,
  })),

 );

export const { selectAll, selectEntities } = adapter.getSelectors();
