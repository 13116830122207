/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from 'shared/src/environments/environment';
import {
  addEditPublisherPayload,
  addEditPublisherResponse,
  deletePublisherPayload,
  deletePublisherResponse,
  getPublisherPayload,
  getPublisherResponse,
  mergePublisherPayload,
  mergePublishersResponse,
  updatePublisherStatusPayload,
  updatePublisherStatusResponse,
} from 'shared/src/interfaces/publisher.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublisherService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.masterForm.publisher;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  ////////// load Publishers /////////
  loadPublishers(payload: getPublisherPayload) {
    let url = `${this.rootURL}${this.endPointUrl.default}`;
    url = url
      .replace('{sortBy}', payload?.sortBy ?? 'name')
      .replace('{orderBy}', payload?.orderBy ?? 'asc');
      if(payload?.pageSize) {
      url += `&pageSize=${payload?.pageSize}`
      }
      if(payload?.pageNumber) {
      url += `&pageNumber=${payload?.pageNumber}`
      }
      if(payload?.query) {
      url += `&query=${payload?.query}`
      }
    return this.http
      .get<getPublisherResponse>(url)
      .pipe(map((e: getPublisherResponse) => e?.body));
  }

  ////////////// publisher detail ////////////////
  publisherDetail(id:number) {
    const url = `${this.rootURL}${this.endPointUrl.publisherById}`.replace('{id}', (id ?? '').toString());
    return this.http.get<addEditPublisherResponse>(url)
    .pipe(map((e: addEditPublisherResponse) => e?.body));
  }

  ////////// add Publisher /////////
  addPublisher(payload: addEditPublisherPayload) {
    const url = `${this.rootURL}${this.endPointUrl.publisher}`;
    return this.http
      .post<addEditPublisherResponse>(url, payload)
      .pipe(map((e: addEditPublisherResponse) => e));
  }

  ////////// update Publisher /////////
  updatePublisher(payload: addEditPublisherPayload,id:number) {
    let url = `${this.rootURL}${this.endPointUrl.publisherById}`;
    url = url.replace('{id}', (id ?? '').toString());
    return this.http
      .put<addEditPublisherResponse>(url, payload)
      .pipe(map((e: addEditPublisherResponse) => e));
  }

  ////////// update Publisher status/////////
  updatePublisherStatus(payload: updatePublisherStatusPayload) {
    const url = `${this.rootURL}${this.endPointUrl.publisher}`;
    return this.http
      .patch<updatePublisherStatusResponse>(url, payload)
      .pipe(map((e: updatePublisherStatusResponse) => e));
  }

  ////////// delete Publisher/////////
  deletePublisher(payload: deletePublisherPayload) {
    const url = `${this.rootURL}${this.endPointUrl.publisher}`;
    return this.http
      .delete<deletePublisherResponse>(url, { body: payload })
      .pipe(map((e: deletePublisherResponse) => e));
  }

  ////////// merge Publishers /////////
  mergePublishers(payload: mergePublisherPayload) {
    const url = `${this.rootURL}${this.endPointUrl.merge}`;
    return this.http
      .put<mergePublishersResponse>(url, payload)
      .pipe(map((e: mergePublishersResponse) => e));
  }
}
