/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import {
  addScriptPayload,
  deleteScriptPayload,
  editScriptPayload,
  getScriptPayload,
  Script,
  updateScriptStatusPayload,
} from 'shared/src/interfaces/script.interface';
export const LoadScripts = createAction(
  '[Script] Load Script List',
  props<{ payload: getScriptPayload }>()
);

export const LoadScriptSuccessfully = createAction(
  '[Script] Load Script Successfully',
  props<{ scriptList: Script[]; totalRecords: number }>()
);

export const LoadScriptError = createAction(
  '[Script] Load Script Error',
  props<{ message: string }>()
);

export const AddScript = createAction(
  '[Script] Add Script to List',
  props<{ payload: addScriptPayload }>()
);

export const AddScriptSuccessfully = createAction(
  '[Script] Script Added Successfully',
  props<{ script: Script; message: string }>()
);

export const AddScriptError = createAction(
  '[Script] Script Added Error',
  props<{ message: string }>()
);

export const UpdateScript = createAction(
  '[Script] Edit Script in List',
  props<{ payload: editScriptPayload }>()
);

export const UpdateScriptSuccessfully = createAction(
  '[Script] Script Updated Successfully',
  props<{ script: Script; message: string }>()
);

export const UpdateScriptError = createAction(
  '[Script] Script Updated Error',
  props<{ message: string }>()
);

export const UpdateScriptStatus = createAction(
    '[Script] Update Script Status in List',
    props<{ payload: updateScriptStatusPayload }>()
  );
  
  export const UpdateScriptStatusSuccessfully = createAction(
    '[Script] Script Status Updated Successfully',
    props<{ script: Script[]; message: string }>()
  );
  
  export const UpdateScriptStatusError = createAction(
    '[Script] Script Status Updated Error',
    props<{ message: string }>()
  );

  export const DeleteScript= createAction(
    '[Script] Delete Status from List',
    props<{ payload: deleteScriptPayload }>()
  );
  
  export const DeleteScriptSuccessfully = createAction(
    '[Script] Script Deleted Successfully',
    props<{ message: string }>()
  );
  
  export const DeleteScriptError = createAction(
    '[Script] Script Status Updated Error',
    props<{ message: string }>()
  );