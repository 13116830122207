import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap, tap, take } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { DocumentListActions } from 'shared/src/store/actions';
import { DocumentService } from 'shared/src/services/staff/document.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, DocumentListSelector } from '@panjab-digi-lib/shared';

@Injectable()
export class DocumentListEffects {
  constructor(
    private actions$: Actions,
    private documentService: DocumentService,
    private router: Router,
    private store: Store<AppState>,
  ) { }
  loadDocumentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentListActions.LoadDocumentList),
      switchMap(({ payload }) =>
        this.documentService.loadDocumentList(payload).pipe(
          map((response) => {

            return DocumentListActions.LoadDocumentListSuccess({ documentList: response.documentList, totalRecords: response.totalRecords })
          }),
          catchError((error: any) => {
            return of(DocumentListActions.LoadDocumentListError({ message: (error?.message) }))
          })
        )
      )
    )
  );

  SubmitForApproval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentListActions.SubmitForApproval),
      switchMap(({ DocID }) =>
        this.documentService.submitForApproval(DocID).pipe(
          map((response) => {

            return DocumentListActions.SubmitForApprovalSuccess({ message: response.message })
          }),
          catchError((error: any) => {
            return of(DocumentListActions.SubmitForApprovalError({ message: (error?.message) }))
          })
        )
      )
    )
  );

  OnSuccessSubmitForApproval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentListActions.SubmitForApprovalSuccess),
      switchMap((action) => combineLatest([
        this.store.select(DocumentListSelector.getListingPayload).pipe(take(1))
      ])
      ),
      tap(() => {
        this.router.navigate(['/document-list']);
      }),
      map(([payload]) => {
        return DocumentListActions.LoadDocumentList({payload});
      })
    )
  );
}
