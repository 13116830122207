/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, DepartmentActions, DepartmentSelector } from '@panjab-digi-lib/shared';
import { Update } from '@ngrx/entity';
import { Department } from 'shared/src/interfaces/master-form.interface';
import { DepartmentService } from 'shared/src/services/admin/master-form/department.service';

@Injectable()
export class DepartmentEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private departmentService: DepartmentService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadDepartment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DepartmentActions.LoadDepartment),
            exhaustMap((action) => {

                return this.departmentService.loadDepartment(action.payload).pipe(
                    map((response) => {
                        return DepartmentActions.LoadDepartmentSuccess({ departments: response.departments, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(DepartmentActions.LoadDepartmentError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
    
    UpdateDepartmentStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DepartmentActions.UpdateDepartmentStatus),
            exhaustMap((action) => {

                return this.departmentService.updateDepartmentStatus(action.departmentIDs, action.status).pipe(
                    map((response: any) => {
                        const departmentUpdates: Update<Department>[] = action.departmentIDs.map((id) => {
                            return {
                                id, changes: {
                                   Status : action.status
                                }
                            }
                        })

                        return DepartmentActions.UpdateDepartmentStatusSuccess({ departmentUpdates, message: response.message });
                    }),
                    catchError((error: any) => of(DepartmentActions.UpdateDepartmentStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    AddDepartment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DepartmentActions.AddDepartment),
            exhaustMap((action) => {

                return this.departmentService.addDepartment(action.payload).pipe(
                    map((response) => {

                        return DepartmentActions.AddDepartmentSuccess({ department: response.body, message: response.message });
                    }),
                    catchError((error: any) => of(DepartmentActions.AddDepartmentError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    EditDepartment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DepartmentActions.EditDepartment),
            exhaustMap((action) => {
                
                return this.departmentService.editDepartment(action.payload, action.departmentId).pipe(
                    map((response) => {

                        this.router.navigateByUrl('/master-forms/manage/departments');
                        return DepartmentActions.EditDepartmentSuccess({department: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(DepartmentActions.EditDepartmentError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );
        
    DeleteDepartment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DepartmentActions.DeleteDepartment),
            exhaustMap((action) => {

                return this.departmentService.deleteDepartment(action.departmentIDs).pipe(
                    map((response: any) => {

                        return DepartmentActions.DeleteDepartmentSuccess({ message: response.message });
                    }),
                    catchError((error: any) => of(DepartmentActions.DeleteDepartmentError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    AddDeleteDepartmentSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DepartmentActions.AddDepartmentSuccess, DepartmentActions.DeleteDepartmentSuccess),
            switchMap((action) => combineLatest([
                    of(action.type),
                    this.store.select(DepartmentSelector.getListingPayload).pipe(take(1))
                ])
            ),
            map(([type, payload]) => {
                
                if(type == '[Department] Add Department Success') {
                    this.router.navigateByUrl('/master-forms/manage/departments');
                }
                return DepartmentActions.LoadDepartment({ payload });
            })
        )
    );
}