import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { API_ENDPOINTS } from '../app.endpoints';
import { map, Observable } from 'rxjs';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { AddEditDesignationPayload, Designation } from 'shared/src/interfaces/master-form.interface';
@Injectable({
  providedIn: 'root'
})
export class DesignationService {
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadDesignation(payload: GenericListingPayload): Observable<{designations: Designation[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.designation.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }
  
  addDesignation(payload: AddEditDesignationPayload) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.masterForm.designation.default}`, payload).pipe(map((e: any) => e));
  }
  
  editDesignation(payload: AddEditDesignationPayload, DesignationId: string) {
    return this.http.put(`${this.rootURL}${API_ENDPOINTS.masterForm.designation.default}/${DesignationId}`, payload).pipe(map((e: any) => e));
  }

  updateDesignationStatus(DesignationIDs: number[], status: string) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.designation.updateStatus}`, {id: DesignationIDs, status}).pipe(map((e: any) => e));
  }
  
  deleteDesignation(DesignationIDs: number[]) {
    return this.http.delete(`${this.rootURL}${API_ENDPOINTS.masterForm.designation.default}`, {body: {id: DesignationIDs}}).pipe(map((e: any) => e));
  }
  
  downloadDesignations() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.designation.download}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }  

  enabledDesignation() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.designation.enabledDesignation}`).pipe(map((e: any) => e.body));
  }
}
