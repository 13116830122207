import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAdminUserService from '../reducers/admin-user-service.reducer';

export const selectState =
  createFeatureSelector<fromAdminUserService.State>('adminUserService');

export const loadUserList = createSelector(selectState, (state) => ({
  userList: fromAdminUserService.selectAll(state),
  totalRecords: state.totalRecords,
  pageSize: state.pageSize,
  pageNumber : state.pageNumber,
  order: state.order,
  orderBy: state?.orderBy
}));

export const getUserById = (id: number) => createSelector(
  selectState,
  (state) => state.entities[id]
)

export const onSave = createSelector(
  selectState,
  (state) => [state.isError, state.errorMessage, state.successMessage,state.isLoading]
);