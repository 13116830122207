import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from 'shared/src/services/admin/app.endpoints';
import { environment } from '@panjab-digi-lib/shared';

@Injectable({
  providedIn: 'root',
})
export class StaticPageService {
  rootUrl = environment.rootApiUrl;
  baseUrl = API_ENDPOINTS.static.getContent;
  faqBaseUrl = API_ENDPOINTS.static.getFAQ;
  getDonationsUrl = API_ENDPOINTS.static.getDonations;

  constructor(private http: HttpClient) {}
  getStaticContent = (language: string, pageName: string) => {
    const res = this.http.get(
      `${this.rootUrl}${this.baseUrl}?language=${language}&pageName=${pageName}`
    );
    return res;
  };
  getFAQContent = (language: string) => {
    const res = this.http.get(
      `${this.rootUrl}${this.faqBaseUrl}?language=${language}`
    );
    return res;
  };
  getDonations = (lowerLimit?: number, upperLimit?: number) => {
    let queryparams: any = [];
    if (lowerLimit) {
      queryparams.push([`lowerLimit=${lowerLimit}`]);
    }
    if (upperLimit) {
      queryparams.push([`upperLimit=${upperLimit}`]);
    }
    if (queryparams.length) {
      queryparams = `?${queryparams.join('&')}`;
    } else {
      queryparams = '';
    }
    const res = this.http.get(
      `${this.rootUrl}${this.getDonationsUrl}${queryparams}`
    );
    return res;
  };
}
