/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminPublisherActions } from '../actions';
import { catchError, exhaustMap, map, of, switchMap, take, withLatestFrom } from 'rxjs';
import { PublisherService } from 'shared/src/services/admin/master-form/publisher.service';
import { Store, select } from '@ngrx/store';
import { AdminPublisherSelector, AppState } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';


@Injectable()
export class AdminPublisherEffects {
  constructor(private actions$: Actions, private service: PublisherService, private store: Store<AppState>, private router: Router,) {}

  //////////////// get all Publishers ////////////
  LoadPublisherList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPublisherActions.LoadPublishers),
      exhaustMap((action) => {
        return this.service.loadPublishers(action?.payload).pipe(
          map((response) => {
            return AdminPublisherActions.LoadPublisherSuccessfully({
              publisherList: response?.publisherList,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              AdminPublisherActions.LoadPublisherError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// add Publisher ////////////
  AddLoadPublisher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPublisherActions.AddPublisher),
      exhaustMap((action) => {
        return this.service.addPublisher(action?.payload).pipe(
          map((response) => {
            return AdminPublisherActions.AddPublisherSuccessfully({
              publisher: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminPublisherActions.AddPublisherError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Publisher ////////////
  UpdateLoadPublisher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPublisherActions.UpdatePublisher),
      exhaustMap((action) => {
        return this.service.updatePublisher(action?.payload,action?.id).pipe(
          map((response) => {
            return AdminPublisherActions.UpdatePublisherSuccessfully({
              publisher: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminPublisherActions.UpdatePublisherError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Publisher status////////////
  UpdateLoadPublisherStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPublisherActions.UpdatePublisherStatus),
      exhaustMap((action) => {
        return this.service.updatePublisherStatus(action?.payload).pipe(
          map((response) => {
            return AdminPublisherActions.UpdatePublisherStatusSuccessfully({
              publisher: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminPublisherActions.UpdatePublisherStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  ///////////////// delete Publisher ///////////////////////
  DeletePublisher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPublisherActions.DeletePublisher),
      exhaustMap((action) =>
        this.service.deletePublisher(action?.payload).pipe(
          map((response) => {
            return AdminPublisherActions.DeletePublisherSuccessfully({
              message: response?.message,
            });
          }),
          catchError((res) =>
            of(
              AdminPublisherActions.DeletePublisherError({
                message: res.error?.error?.message,
              })
            )
          )
        )
      )
    )
  );

//////////////// add Publisher ////////////
  MergePublisher$ = createEffect(() =>
        this.actions$.pipe(
          ofType(AdminPublisherActions.MergePublisher),
          exhaustMap((action) => {
            return this.service.mergePublishers(action?.payload).pipe(
              map((response) => {
                return AdminPublisherActions.MergePublisherSuccessfully({
                  message: response?.message,
                });
              }),
              catchError((error) =>
                of(
                  AdminPublisherActions.AddPublisherError({
                    message: error?.error?.error?.message,
                  })
                )
              )
            );
          })
        )
      );

 ///////////////////// redirect after adding/deleting/merging Publisher /////////////////////     
  AddDeleteMergePublisherSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminPublisherActions.AddPublisherSuccessfully, AdminPublisherActions.DeletePublisherSuccessfully,AdminPublisherActions.MergePublisherSuccessfully),
      withLatestFrom(this.store.pipe(select(AdminPublisherSelector.loadPublisherList))),
      switchMap(([action, state]) => {
        this.router.navigateByUrl('/master-forms/manage/publishers');
        const pageNumber = (action.type === AdminPublisherActions.AddPublisherSuccessfully.type || action.type === AdminPublisherActions.MergePublisherSuccessfully.type) ? 1 : state?.pageNumber;
        const payload = { pageNumber: pageNumber, pageSize: state?.pageSize, sortBy: state?.sortBy,orderBy: state?.orderBy };
        return of(AdminPublisherActions.LoadPublishers({payload}));
      })
    )
  )
}
