import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, MetaDataActions, MetaDataSelector } from '@panjab-digi-lib/shared';
import { MasterFormsService } from 'shared/src/services/admin/master-form/master-forms.service';
import { Update } from '@ngrx/entity';
import { MetaData } from 'shared/src/interfaces/metadata.interface';

@Injectable()
export class MetaDataEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private masterFormService: MasterFormsService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetaDataActions.LoadMetaData),
            exhaustMap((action) => {

                return this.masterFormService.loadMetaData(action.payload).pipe(
                    map((response) => {
                        return MetaDataActions.LoadMetaDataSuccess({ metaData: response.metaData, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(MetaDataActions.LoadMetaDataError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    AddMetaData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetaDataActions.AddMetaData),
            exhaustMap((action) => {

                return this.masterFormService.addMetaData(action.payload).pipe(
                    map((response) => {

                        return MetaDataActions.AddMetaDataSuccess({ metaData: response.body, message: response.message });
                    }),
                    catchError((error: any) => of(MetaDataActions.AddMetaDataError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    UpdateMetaDataStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetaDataActions.UpdateMetaDataStatus),
            exhaustMap((action) => {

                return this.masterFormService.updateMetaDataStatus(action.MetaDataIDs, action.status).pipe(
                    map((response: any) => {
                        const metaDataUpdates: Update<MetaData>[] = action.MetaDataIDs.map((id) => {
                            return {
                                id, changes: {
                                    Status: action.status
                                }
                            }
                        })

                        return MetaDataActions.UpdateMetaDataStatusSuccess({ metaDataUpdates, message: response.message });
                    }),
                    catchError((error: any) => of(MetaDataActions.UpdateMetaDataStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );

    EditMetaData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetaDataActions.EditMetaData),
            exhaustMap((action) => {
                
                return this.masterFormService.editMetaData(action.payload, action.metaDataId).pipe(
                    map((response) => {

                        this.router.navigateByUrl('/master-forms/manage/metadata');
                        return MetaDataActions.EditMetaDataSuccess({metaData: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(MetaDataActions.EditMetaDataError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );

    DeleteMetaData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetaDataActions.DeleteMetaData),
            exhaustMap((action) => {

                return this.masterFormService.deleteMetaData(action.MetaDataIDs).pipe(
                    map((response: any) => {

                        return MetaDataActions.DeleteMetaDataSuccess({ message: response.message });
                    }),
                    catchError((error: any) => of(MetaDataActions.DeleteMetaDataError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );

    AddDeleteMetaDataSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetaDataActions.AddMetaDataSuccess, MetaDataActions.DeleteMetaDataSuccess),
            switchMap((action) => combineLatest([
                    of(action.type),
                    this.store.select(MetaDataSelector.getListingPayload).pipe(take(1))
                ])
            ),
            map(([type, payload]) => {
                
                if(type == '[MetaData] Add MetaData Success') {
                    this.router.navigateByUrl('/master-forms/manage/metadata');
                }
                return MetaDataActions.LoadMetaData({ payload });
            })
        )
    );

}