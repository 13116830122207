<div [ngClass]="{'spinner-overlap': isFullScreen}">
    <div class="spinner-border" role="status">
        <span class="sr-only"></span>
    </div>
    @if(name) {
        <div class="loader-msg">
            {{name | translate}}
        </div>
    }
</div>
