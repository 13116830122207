<section class="form-page">
    <div class="container form-container">
        <div class="row d-flex">
            <div *ngIf="invalidReq; else loadForm" class="mx-auto w-50 py-5 invalid-user">
                <div class="alert text-center alert__error">{{invalidReq}}</div>
            </div>
            <ng-template #loadForm>
                <!-- Column 1 - Static Content -->
                <div class="col-md-5 column-1">
                    <div class="content fw-normal text-justify">
                        <!-- Hide breadcrumbs in mobile view -->
                        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-md-block d-none">
                            <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                        </div>
                        <h5 class="fw-bold">{{'pdl-website.forms.user.pdl' | translate }}</h5>
                        <div class="static-txt" [innerHtml]="'pdl-website.forms.user.pdlText' | translate"></div>
                    </div>
                </div>
                <!-- Column 2 - Form -->
                <div class="col-md-7 column-2">
                    <div class="right-section">
                        <div class="top-content">
                            <div class="header-title">
                                <!-- Show breadcrumbs in mobile view -->
                                <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-block d-md-none">
                                    <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                                </div>
                                <div class="title-txt mt-4">
                                    <h5 class="fw-bolder">{{'pdl-website.forms.user.resetYourPasswd' | translate }}</h5>
                                </div>
                            </div>
                            <div class="header-body">
                                <p class="fw-light" [innerHtml]="'pdl-website.forms.user.resetPassHeaderText' | translate"></p>
                            </div>
                        </div>
                        <div class="form-section">
                            <!-- show alert -->
                            <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
                            <!-- /show alert -->
                            <p class="fw-bold">{{'pdl-website.forms.user.formHeader' | translate }}</p>
                            <form [formGroup]="resetPasswdForm" (ngSubmit)="onSubmit()">
                                <!-- password -->
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-flex">
                                            <label for="password" class="form-label reqd" [ngClass]="{'text-danger': resetPasswdForm.controls['password'].invalid && resetPasswdForm.controls['password'].touched}">{{'pdl-website.forms.labels.newPassword' | translate }}</label>
                                            <span class="flex-grow-1"></span>
                                            @if(resetPasswdForm.controls['password'].touched && resetPasswdForm.controls['password'].invalid) {
                                                <ng-container *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.password' | translate }"></ng-container>
                                            }
                                            @if(resetPasswdForm.controls['password'].touched && resetPasswdForm.controls['password'].valid) {
                                                <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                            }
                                        </div>
                                        <input type="password" class="form-control" formControlName="password" [ngClass]="{'border-danger': resetPasswdForm.controls['password'].invalid && resetPasswdForm.controls['password'].touched}">
                                    </div>
                                </div>
                                <!-- confirm password -->
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-flex">
                                            <label for="confirmpassword" class="form-label reqd" [ngClass]="{'text-danger': resetPasswdForm.controls['confirmpassword'].invalid && resetPasswdForm.controls['confirmpassword'].touched}">{{'pdl-website.forms.labels.confNewPassword' | translate }}</label>
                                            <span class="flex-grow-1"></span>
                                            @if(resetPasswdForm.controls['confirmpassword'].touched && resetPasswdForm.controls['confirmpassword'].invalid) {
                                                <ng-container *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidConfirmPasswd' | translate }"></ng-container>
                                            }
                                            @if(resetPasswdForm.controls['confirmpassword'].touched && resetPasswdForm.controls['confirmpassword'].valid) {
                                                <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                            }
                                        </div>
                                        <input type="password" class="form-control" formControlName="confirmpassword" [ngClass]="{'border-danger': resetPasswdForm.controls['confirmpassword'].invalid && resetPasswdForm.controls['confirmpassword'].touched}">
                                    </div>
                                </div>
                                <!-- submit buttons -->
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button type="submit" class="btn btn-primary btn__primary" [disabled]="resetPasswdForm.controls['password'].invalid || resetPasswdForm.controls['confirmpassword'].invalid">{{'pdl-website.forms.labels.resetPasswd' | translate }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="pdl-user text-center pb-4">
                            <span>{{'pdl-website.forms.user.knowYourPasswd' | translate }} </span>
                            <a href="javascript:void(0);" [routerLink]="'/login-user'" class="fw-bold">{{'pdl-website.forms.labels.logInBtn' | translate }}</a>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
      </div>
      <!-- Template - required field error -->
    <ng-template #requiredError>
        <span class="text-danger">
            <img src="../../../assets/images/icons/error-icon.svg" alt="error" />
        </span>
    </ng-template>
    <!-- Template - invalid field error -->
    <ng-template #invalidError let-msg="msg">
        <span class="text-danger">
            <img src="../../../assets/images/icons/error-icon.svg" [ngbTooltip]="tipContent" alt="error" />
            <ng-template #tipContent>{{msg}}</ng-template>
        </span>
    </ng-template>
    <!-- Template - valid field -->
    <ng-template #validFld>
    <span class="text-success">
        <img src="../../../assets/images/icons/checked-icon.svg" alt="success" />
    </span>
    </ng-template>
</section>