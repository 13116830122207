/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { environment } from 'shared/src/environments/environment';
import { Observable, map } from 'rxjs';
import { AddEditCountryPayload, Country } from 'shared/src/interfaces/master-form.interface';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.masterForm.language;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadCountry(payload: GenericListingPayload): Observable<{countries: Country[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.country.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }

  loadActiveCountryList(payload: GenericListingPayload): Observable<{countryList: Country[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.openRoutes.getActiveCountries}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }
  
  addCountry(payload: AddEditCountryPayload) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.masterForm.country.default}`, payload).pipe(map((e: any) => e));
  }
  
  editCountry(payload: AddEditCountryPayload, countryId: string) {
    return this.http.put(`${this.rootURL}${API_ENDPOINTS.masterForm.country.default}/${countryId}`, payload).pipe(map((e: any) => e));
  }

  updateCountryStatus(countryIDs: number[], status: string) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.country.updateCountryStatus}`, {id: countryIDs, status}).pipe(map((e: any) => e));
  }
  
  deleteCountry(countryIDs: number[]) {
    return this.http.delete(`${this.rootURL}${API_ENDPOINTS.masterForm.country.default}`, {body: {id: countryIDs}}).pipe(map((e: any) => e));
  }
  
  downloadCountries() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.country.downloadCountries}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }  
}

