<div class="home-banner">
    <div class="home-slider">
        <ngx-slick-carousel class="carousel" 
                            #slickModal="slick-carousel" 
                            [config]="slideConfig" 
                            (init)="slickInit($event)">
            @for (slide of bannerSlides$ | async; track slide.BannerOrder) {
                <div ngxSlickItem class="slide homebanner-slide">
                    @if(slide.BannerUrl.length > 0) {
                        <a href="{{slide.BannerUrl | httpFormat}}" target="_blank">
                            <picture>
                              <source media="(min-width:320px) and (max-width: 600px)" [srcset]="rootURL+slide.BannerMobileImage">
                              <img [src]="rootURL+slide.BannerImage" class="homebanner-slide-bg" alt="" width="100%">
                            </picture>
                        </a>
                    } @else {
                        <picture>
                            <source media="(min-width:320px) and (max-width: 600px)" [srcset]="rootURL+slide.BannerMobileImage">
                            <img [src]="rootURL+slide.BannerImage" class="homebanner-slide-bg" alt="" width="100%">
                        </picture>
                    }
                </div>
            }
        </ngx-slick-carousel>
    </div>
                 
    <div class="card homesearch-card">
        <div class="card-body">
            <form class="col-12 col-lg-auto mb-4  homecard-search d-flex " role="search">
                <label class="search-label"> <img src="assets/images/icons/search-icon.svg"> 
                    </label>
                <input type="search" class="form-control form-control-dark homesearch-input" placeholder="Search..." aria-label="Search">
                
                <div class="dropdown homesearch-dropdown">
                    <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{'pdl-website.headerSearch.searchText' | translate }}
                    </a>
                    
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#">{{'pdl-website.searchDropdown.dropdownText' | translate }}</a></li>
                        <li><a class="dropdown-item" href="#">{{'pdl-website.searchDropdown.dropdownText' | translate }}</a></li>
                    </ul>
                </div>
            </form>
            <div class="home-advanced-link d-flex justify-content-end">
                <a href="javascript:void(0);" [routerLink]="'/advanced-search'" class="pdl-link">{{'pdl-website.advancedsearchText.titleText' | translate }}</a>
            </div>   
        </div>
    </div>
</div>
<div class="trending-block">
    <panjab-digi-lib-trending-keywords></panjab-digi-lib-trending-keywords>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="most-popular-reads">
    <panjab-digi-lib-most-popular></panjab-digi-lib-most-popular>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="overview-section">
    <panjab-digi-lib-overview></panjab-digi-lib-overview>
</div>
<div class="categories-block">
    <panjab-digi-lib-categories></panjab-digi-lib-categories>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="curated-collections-block">
    <panjab-digi-lib-curated-collections></panjab-digi-lib-curated-collections>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="exhibitions-block">
    <panjab-digi-lib-exhibitions></panjab-digi-lib-exhibitions>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="downloads-block">
    <panjab-digi-lib-downloads></panjab-digi-lib-downloads>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="video-gallery-section">
    <panjab-digi-lib-video-gallery></panjab-digi-lib-video-gallery>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="mailinglist-section">
    <panjab-digi-lib-mailing-list></panjab-digi-lib-mailing-list>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="adopt-donate">
    <panjab-digi-lib-donate></panjab-digi-lib-donate>
</div>
