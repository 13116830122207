
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { GenericListingPayloadWithSearch } from 'shared/src/interfaces/common.interface';
import { StaffTask, Task } from 'shared/src/interfaces/staff/document.interface';

export const LoadStaffTaskList = createAction(
  '[Staff] Load StaffTask List',
  props<{ payload: GenericListingPayloadWithSearch, StaffID: string }>()
);

export const LoadStaffTaskListSuccess = createAction(
  '[Staff] Load StaffTask List Success',
  props<{ taskList: StaffTask[]; totalRecords: number }>()
);

export const LoadStaffTaskListError = createAction(
  '[Staff] Load StaffTask List Error',
  props<{ message: string }>()
);