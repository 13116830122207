import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsComponent, AppState, CountryActions, CountrySelector, environment } from '@panjab-digi-lib/shared';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterLink } from '@angular/router';
import { CommonService } from 'shared/src/services/website/common.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { Country } from 'shared/src/interfaces/master-form.interface';

@Component({
    selector: 'panjab-digi-lib-donation',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        BreadcrumbsComponent,
        FormsModule,
        NgbTooltipModule,
        RouterLink,
        AlertsComponent,
    ],
    templateUrl: './donation.component.html',
    styleUrl: './donation.component.scss'
})
export class DonationComponent implements OnInit, AfterViewInit {
    pageTitle = 'Donations';
    countryList$!: Observable<any>;
    countries: Country[] = [];
    selectedCountry: string = "";
    donorBoxIframeID: string = 'donorbox_widget_frame';
    donorBoxiframeSrc!: SafeResourceUrl;
    razorPayBtnID: string = environment.razorPayConfig.razorPayBtnID;
    private unsubscribe$: Subject<void> = new Subject();

    private scriptElements: HTMLScriptElement[] = [];
    
    constructor(
        private translate: TranslateService,
        private commonService: CommonService,
        private store: Store<AppState>,
        private renderer: Renderer2,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        this.getCountriesList();
        
        this.donorBoxiframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(environment.donorBoxConfig.donationLink);
    }

    ngAfterViewInit() {
        this.scriptElements = this.commonService.loadScript([
            {
                src: environment.donorBoxConfig.donorBoxJS
            }, 
            {
                src: environment.razorPayConfig.razorPayJS,
                attributes: {
                    'data-payment_button_id': environment.razorPayConfig.razorPayBtnID
                },
                targetElementId: 'razor-pay-btn-wrapper'
            }
        ]);
    }

    getCountriesList() {
        const payload = {};
        this.store.dispatch(CountryActions.LoadActiveCountryList({ payload }));
        this.store.select(CountrySelector.getCountries).pipe(takeUntil(this.unsubscribe$)).subscribe((countries) => {
            if(countries?.length > 0) {
                this.countries = countries.filter((country) => environment.availableCountriesForDonation.includes(country.CountryID)).sort((a, b) => a.Name > b.Name ? 1 : -1)
            }
        })
    }

    removeElementsByClasses(classNames: string[]): void {
        classNames.forEach(className => {
            const elements = document.getElementsByClassName(className);
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                this.renderer.removeChild(element.parentNode, element);
            }
        });
    }

    ngOnDestroy() {
        this.removeElementsByClasses(['razorpay-payment-form-container']);
        this.commonService.removeScript(this.scriptElements);
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
