<panjab-digi-lib-static-page-head [pageName]="'pdl-website.team.titleText' | translate"
  pagePath="/team">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <div class="d-flex justify-content-center">
      <p class="text">Something Went Wrong</p>
    </div>
    <!-- KEEP THE BELOW COMMENTED HTML FOR BACKUP -->


    <!-- <div class="teamsWrapper">
      <div>
        <h1 class="heading mb-3">Parton</h1>
        <ul class="text">
          <li>Gani Kaur & Jodha Singh, Anandpur Sahib </li>
          <li>Parvinder Singh & Parveen Kaur Khanuja, USA</li>
        </ul>
      </div>
      <div>
        <h1 class="heading mb-3">Coordinators</h1>
        <ul class="text">
          <li>Guru Charan Singh, India</li>
        </ul>
      </div>
      <div>
        <h1 class="heading mb-3">Boards of Directors</h1>
        <ul class="text">
          <li>Baljinder Kaur Narang (Canada) </li>
          <li>Davinder Pal Singh (India) </li>
          <li>Gurnihal Singh Pirzada (India) </li>
          <li>Gurpreet Kaur (UK) </li>
          <li>Gurvinder Singh (USA) </li>
          <li>Jasvinder Singh Chadha (USA) </li>
          <li>Jagdeep Singh Gill (UK) </li>
          <li>Parvinder Jit Singh Khanuja (USA) </li>
          <li>Prem Garg (India) </li>
          <li>Puneet Kaur Sandhar (Canada)</li>
        </ul>
      </div>
      <div>
        <h1 class="heading mb-3">Advisory Board</h1>
        <ul class="text">
          <li>Balwant Singh Dhillon, Prof.Guru Nanak Dev University, Amritsar </li>
          <li>Daljit Ami, Punjabi Documentary Filmmaker & Journalist, Chandigarh </li>
          <li>Gurjeet Singh Cheema, Retd. IAS Chandigarh </li>
          <li>Harpreet Singh, USA </li>
          <li>Iqbal Singh, Assistant Director IB, MHA, Government of India (Retd.) </li>
          <li>Dr P C Sharma, Officer Incharge,Govt. Museum & Art Gallery, Chandigarh </li>
          <li>Parminder Kaur Sandhu, Retd. Archivist, Punjab Archives, Chandigarh</li>
        </ul>
      </div>
      <div>
        <h1 class="heading mb-3">Digitization Team</h1>
        <ul class="text">
          <li>Amandeep Singh, Project Manager</li>
          <li>Ankita, Assistant Librarian</li>
          <li>Dharmender Kumar, Digital Imaging Assistant </li>
          <li>Dilip Paswan, Digital Imaging Assistant </li>
          <li>Gurpreet Kaur, Assistant Image Editor </li>
          <li>Harish Sharma, Backup Incharge </li>
          <li>Karamjit Singh, Sr. Digital Imaging Assistant </li>
          <li>Kawal Bikram Singh, Back Office Manager </li>
          <li>Kulwant Singh, Senior Metadata Developer </li>
          <li>Meena Kumari, Assistant Image Editor </li>
          <li>Parminder Singh, Manager IT, HR</li>
          <li>Pooja Yadav, Digital Scanning Assistant </li>
          <li>Reeta Rani, Image Editor </li>
          <li>Seema Rani, Image Editor </li>
          <li>Somnath, Digital Imaging Assistant </li>
          <li>Surender Pal Singh, Content Developer </li>
          <li>Tulsi, Graphics Designer </li>
          <li>Vikas Rana, Digital Imaging Assistant</li>
        </ul>
      </div>
      <div>
        <h1 class="heading mb-3">Consultants</h1>
        <ul class="text">
          <li>Gagandeep Kaur, Accounts</li>
          <li>Gunjeet Kaur, Graphic Design</li>
          <li>Prem Garg and Associates, Audits</li>
          <li>Ravpreet Singh, Design</li>
        </ul>
      </div>
    </div> -->

    }
  </div>
</section>
