import { createAction, props } from '@ngrx/store';

export const Login = createAction(
  '[StaffLogin] Staff Login',
  props<{ email: string, password: string }>()
);

export const LoginSuccess = createAction(
  '[StaffLogin] Staff Login Success',
  props<{ token: string, refreshToken: string }>()
);

export const LoginError = createAction(
  '[StaffLogin] Staff Login Error',
  props<{ error: string }>()
);

export const ResetLoginError = createAction(
  '[StaffLogin] Staff Reset Login Error'
);

export const tokenExpired = createAction(
  '[StaffLogin] Staff Token Expired',
);

export const logout = createAction(
  '[Logout]'
);
