import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { API_ENDPOINTS } from '../app.endpoints';
import { map, Observable } from 'rxjs';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { AddEditCurrencyPayload, Currency } from 'shared/src/interfaces/master-form.interface';
@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.masterForm.language;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadCurrency(payload: GenericListingPayload): Observable<{currencies: Currency[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.currency.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }
  
  addCurrency(payload: AddEditCurrencyPayload) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.masterForm.currency.default}`, payload).pipe(map((e: any) => e));
  }
  
  editCurrency(payload: AddEditCurrencyPayload, currencyId: string) {
    return this.http.put(`${this.rootURL}${API_ENDPOINTS.masterForm.currency.default}/${currencyId}`, payload).pipe(map((e: any) => e));
  }

  updateCurrencyStatus(currencyIDs: number[], status: string) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.currency.updateCurrencyStatus}`, {id: currencyIDs, status}).pipe(map((e: any) => e));
  }
  
  deleteCurrency(currencyIDs: number[]) {
    return this.http.delete(`${this.rootURL}${API_ENDPOINTS.masterForm.currency.default}`, {body: {id: currencyIDs}}).pipe(map((e: any) => e));
  }
  
  downloadCurrencies() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.currency.downloadCurrencies}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }  
}
