/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'shared/src/environments/environment';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import {
  AdoptPriceResponse,
  UpdateAdoptPriceResponse,
} from 'shared/src/interfaces/fundraising.interface';

@Injectable({
  providedIn: 'root',
})
export class AdoptPriceService {
  rootURL = environment.rootApiUrl;
  endPointUrl = API_ENDPOINTS.fundraising.adoptPrice;
  constructor(private http: HttpClient) {}

  /////////////////// load categories with adopt price ////////////////////
  loadCategoriesWithPriceList(payload: GenericListingPayload) {
    const url = `${this.rootURL}${this.endPointUrl.default}`
      .replace('{pageNumber}', (payload?.pageNumber ?? 1).toString())
      .replace('{pageSize}', (payload?.pageSize ?? 20).toString())
      .replace('{orderBy}', payload?.orderBy ?? 'Name')
      .replace('{order}', payload?.order ?? 'asc');
    return this.http
      .get<AdoptPriceResponse>(url)
      .pipe(map((e: AdoptPriceResponse) => e));
  }

  //////////////////////// update adopt price ///////////////////////////////
  updateAdoptPrice(id: number, price: string) {
    const url = `${this.rootURL}${this.endPointUrl.price}`.replace(
      '{id}',
      id.toString()
    );
    return this.http
      .patch<UpdateAdoptPriceResponse>(url, { price })
      .pipe(map((e: UpdateAdoptPriceResponse) => e));
  }

  ////////// download categories with adopt price as excel /////////
  download() {
    const url = `${this.rootURL}${this.endPointUrl.download}`;
    return this.http
      .get(url, {
        responseType: 'blob',
      })
      .pipe(map((data: Blob) => data));
  }
}
