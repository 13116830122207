<panjab-digi-lib-static-page-head [pageName]="'pdl-website.acknowledgments.titleText' | translate"
  pagePath="/acknowledgments">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container">
    <div class="ack_wrapper">
      <div class="header">
        <div>
          ₹1,00,000 PLUS
        </div>
        <div>
          ₹50,000 PLUS
        </div>
      </div>
      @for (year of getListOfYears(); track year) {
      @if (donations_bw_50000_100000?.[year]?.length || donations_above_100000?.[year]?.length) {
      <div class="year_row">
        {{year}}
      </div>
      <div class="values text">
        <div class="left">
          @if(donations_above_100000[year]?.length) {
          <ul>
            @for (donation of donations_above_100000?.[year]; track donation) {
            <li>{{donation.Name}}</li>
            }
          </ul>
          }
        </div>
        <div class="right">
          @if(donations_bw_50000_100000?.[year]?.length) {
          <ul>
            @for (donation of donations_bw_50000_100000?.[year]; track donation) {
            <li>{{donation.Name}}</li>
            }
          </ul>
          }
        </div>
      </div>
      }
      }
    </div>
  </div>
</section>
