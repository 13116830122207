<section class="container">
  <div class="d-flex justify-content-between align-items-center mb-5 flex-wrap">
    <h5 class="semibold">My Research Topic</h5>
    <button class="btn btn__primary d-flex" [routerLink]="'create-new-research-topic'">Add New Research Topic
      <img src="/assets/images/icons/arrow-right-up.svg" />
    </button>
  </div>
  <div class="researchTopicWrapper">
    @for (topic of researchTopics; track topic) {
    <div class="topic mb-5">
      <div class="topic_title d-flex justify-content-between mb-2 flex-wrap">
        <span><span class="semibold">{{topic.name}}</span><span class="fade_text"> (1 to {{topic.items.length}} of about
            {{topic.total}})</span></span>
        <button class="btn btn__primary"  [routerLink]="'/research/' + topic.id"><span>View Details</span></button>
      </div>
      <div class="d-flex col-gap-12 ofs no-scrollbar">
        @for (item of topic.items; track item) {
        <img class="img" [src]="item.thumbnail" />
        }
      </div>
    </div>
    }
  </div>

  <div class="unassinged_literature">
    <div class="semibold">Unassigned Literatures</div>
    <panjab-digi-lib-common-listing [data]="dataItems" listType="card" [showLikeAndMoreOptions]="true">
    </panjab-digi-lib-common-listing>
  </div>

</section>
<router-outlet></router-outlet>
