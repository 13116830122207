import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { Service } from "shared/src/interfaces/services.interface";

export const LoadServices = createAction(
    '[Service] Load Services',
    props<{payload: GenericListingPayload}>(),
)

export const LoadServicesSuccess = createAction(
    '[Service] Load Services Successfully',
    props<{services: Service[], totalRecords: number}>()
)

export const LoadServicesError = createAction(
    '[Service] Load Services Error',
    props<{message: string}>()
)

export const UpdateServicesStatus = createAction(
    '[Service] Update Service Status',
    props<{ServiceIDs: number[], status: string}>(),
)

export const UpdateServicesStatusSuccess = createAction(
    '[Service] Update Service Status Success',
    props<{serviceUpdates: any, message: string}>(),
)

export const UpdateServicesStatusError = createAction(
    '[Service] Update Service Status Error',
    props<{message: string}>(),
)

export const DeleteServices = createAction(
    '[Service] Delete Service',
    props<{ServiceIDs: number[]}>(),
)

export const DeleteServicesSuccess = createAction(
    '[Service] Delete Service Success',
    props<{ServiceIDs: number[], message: string}>(),
)

export const DeleteServicesError = createAction(
    '[Service] Delete Service Error',
    props<{message: string}>(),
)

export const AddService = createAction(
    '[Service] Add Service',
    props<{formData: FormData}>(),
)

export const AddServiceSuccess = createAction(
    '[Service] Add Service Success',
    props<{service: Service, message: string}>(),
)

export const AddServiceError = createAction(
    '[Service] Add Service Error',
    props<{message: string}>(),
)

export const EditService = createAction(
    '[Service] Edit Service',
    props<{formData: FormData, serviceID: string}>(),
)

export const EditServiceSuccess = createAction(
    '[Service] Edit Service Success',
    props<{service: Service, message: string}>(),
)

export const EditServiceError = createAction(
    '[Service] Edit Service Error',
    props<{message: string}>(),
)
