import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromWebAuthUser from "../../reducers/website/web-authuser.reducer";

export const selectUserState =
  createFeatureSelector<fromWebAuthUser.State>('webAuthUser');

export const selectError = createSelector(
  selectUserState,
  (state) => state.errorMessage
);

export const isLoading = createSelector(
  selectUserState,
  (state) => state.isLoading
);

export const selectSuccess = createSelector(
  selectUserState,
  (state) => state.successMessage,
);
