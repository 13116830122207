import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { API_ENDPOINTS } from '../app.endpoints';
import { map, Observable } from 'rxjs';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { ScriptsPerLanguage, ScriptsOfLanguage, SaveAllocationPayload } from "shared/src/interfaces/master-form.interface";
import { GenericReponse } from 'shared/src/interfaces/category.interface';
@Injectable({
  providedIn: 'root'
})
export class ScriptsPerLanguageService {
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadScriptsPerLanguage(): Observable<{languages: ScriptsPerLanguage[]}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.scriptsPerLanguage.default}`).pipe(map((e: any) => e.body));
  }

  loadScriptsOfLanguage(payload: GenericListingPayload, LangID: number): Observable<{scripts: ScriptsOfLanguage[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.scriptsPerLanguage.getScriptsForLanguage}`.replace('{id}', (LangID).toString()), {params: {...payload}}).pipe(map((e: any) => e.body));
  }

  saveAllocations(payload: SaveAllocationPayload, LangID: number): Observable<GenericReponse> {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.scriptsPerLanguage.saveAllocation}`.replace('{id}', (LangID).toString()), payload).pipe(map((e: any) => e));
  }
  
  downloadScriptsOfLanguage(LangID: number) {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.scriptsPerLanguage.downloadScriptsOfLanguage}`.replace('{id}', (LangID).toString()), { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }
}
