/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import { WebDonationActions } from '../../actions/index';
import { Donation } from 'shared/src/interfaces/web-management.interface';


export interface State extends EntityState<Donation> {
  isLoading: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  totalRecords:number;
}

export const adapter = createEntityAdapter<Donation>({
  selectId: (e) => e?.DonID
});

export const initialState = adapter.getInitialState( {
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0
});

export const reducer = createReducer(
  initialState,
  on(WebDonationActions.GetDonationList,(state) =>
    ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebDonationActions.GetDonationListSuccess,(state, action) =>
  ({
      ...adapter.setAll(action.donations, state),
      totalRecords: action.totalRecords,
      isLoading: false,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebDonationActions.GetDonationListError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    })
  )
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();