import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@panjab-digi-lib/shared';
import { RecoverUser } from '../../../interfaces/auth-user.interface';
import { LocalStorageService } from '../../common/local-storage.service';
import { Store } from '@ngrx/store';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { AuthActions } from 'shared/src/store/actions';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  rootURL = '';
  appURL = '';
  constructor(private http: HttpClient, private localStorage: LocalStorageService, private store: Store, private authService: AuthenticationService) {
    this.rootURL = environment.rootApiUrl + environment.adminApiPrefix;
    this.appURL = environment.rootApiUrl + environment.appApiPrefix;
  }

  sendPasswordRequest(data: RecoverUser) {
    return this.http.post<RecoverUser>(this.appURL + 'recover-password', data).pipe(map((e: any) => e));
  }

  validateResetPasswdRequest(data: any) {
    return this.http.post<any>(this.appURL + 'validate-reset-req', data).pipe(map((e: any) => e));
  }

  resetAccountPasswd(data: any) {
    return this.http.patch<any>(this.appURL + 'reset-password/' + data.token, {password: data?.password}).pipe(map((e: any) => e));
  }

  sendUsernameRequest(data: RecoverUser) {
    return this.http.post<RecoverUser>(this.appURL + 'recover-username', data).pipe(map((e: any) => e));
  }

  clearUserSession() {
    this.localStorage.remove('webToken');
    this.localStorage.remove('webRefreshtoken');
    this.localStorage.remove('socialProvider');
    this.localStorage.remove('webUser');
  }

  logOutWebUser() {
    this.store.dispatch(AuthActions.webLogout());
    this.clearUserSession();
    window.location.href = '/login-user';
  }

  getDonationList(payload: GenericListingPayload) {    
    return this.http.get(`${this.appURL}${WEB_API_ENDPOINTS.user.donations}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }

  getAdoptedBooksList(payload: GenericListingPayload){
    return this.authService.sendHttpRequest('GET', `${WEB_API_ENDPOINTS.user.getAdoptedBooks}`, {params: {...payload}}).pipe(map((e: any) => e));
  }
}