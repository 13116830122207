import { Component, ElementRef, EventEmitter, Renderer2, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ReactiveFormsModule, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { AlertService } from 'shared/src/services/website/alert.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { SpinnerButtonComponent } from 'shared/src/lib/components/web/spinner-button/spinner-button.component';
import { ModalPopupService } from "../../../../common/services/modal-popup.service";
import { UserService } from '../../../../common/services/user.service';
import { ResetPasswordValidator } from 'shared/src/validators/reset-password.validator';

@Component({
  selector: 'panjab-digi-lib-change-password',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, AlertsComponent, NgbTooltipModule, SpinnerButtonComponent],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent {
  changePasswdForm!: FormGroup;
  @ViewChild('changePasswdModal') changePasswdModal!: TemplateRef<any>;
  @ViewChild('oldPassword') oldPassword!: ElementRef;
  @ViewChild('newPassword') newPassword!: ElementRef;
  @ViewChild('retypePassword') retypePassword!: ElementRef;
  @Output() handleParentAlert = new EventEmitter<boolean>();
  oldPasswdInputType = 'password';
  newPasswdInputType = 'password';
  retypePasswdInputType = 'password';
  hidePasswordImg = "../../../assets/images/icons/eye-slash-ico.svg";
  showPasswordImg = "../../../assets/images/icons/eye-ico.svg"
  constructor(
    private fb: FormBuilder,
    private localStorage: LocalStorageService,
    private alertService: AlertService,
    private modalPopupService: ModalPopupService,
    private userService: UserService,
    private renderer: Renderer2,
    private translate: TranslateService
  ) {}
  initChangePasswdForm() {
    this.changePasswdForm = this.fb.group({      
      oldPassword : ['', [Validators.required]],
      newPassword: ['', [
        Validators.required, 
        Validators.minLength(6), 
        Validators.maxLength(30), 
        Validators.pattern('^[a-zA-Z0-9$@#^!%*?&_]+$'),
        ResetPasswordValidator.verifyStrength()
      ]],
      retypePassword: ['', [Validators.required]],
    })
  }

  updateErrorState() {
    const password = this.changePasswdForm.controls['newPassword'].value;
    const cpassword = this.changePasswdForm.controls['retypePassword'].value;
    if(cpassword !== password) {
      this.changePasswdForm.controls['retypePassword'].setErrors({ 'invalid': true });
    } else {
      this.changePasswdForm.controls['retypePassword'].setErrors(null);
    }
  }

  detectChangePasswdChanges() {
    // detect password changes
    this.changePasswdForm.get('newPassword')?.valueChanges.subscribe(() => {
      this.updateErrorState();
    });
    // detect confirm password changes
    this.changePasswdForm.get('retypePassword')?.valueChanges.subscribe(() => {
      this.updateErrorState();
    });
  }

  handleOnSubmitChangePasswd() {
    this.alertService.clear();
    this.changePasswdForm.markAllAsTouched();
    if(this.changePasswdForm.valid) {
      const payload = {
        oldPasswd: this.changePasswdForm.controls['oldPassword'].value,
        newPasswd: this.changePasswdForm.controls['newPassword'].value,
        retypePasswd: this.changePasswdForm.controls['retypePassword'].value
      }
      // disable parent component alert
      this.handleParentAlert.emit(false);
      this.userService.changePassword(payload).subscribe({
        next: (response) => {
          this.alertService.success(response.message);
          this.changePasswdForm.reset();
          setTimeout(() => {
            this.userService.logOutWebUser();
          }, 4000);
        },
        error: (err) => {
          this.alertService.error(err?.error?.error?.message);
        }
      })
    }
  }

  handleOnCancel() {
    // enable parent component alert
    this.handleParentAlert.emit(true);
  }

  toggleInputType(inputField: HTMLInputElement) {
    const fieldType = (inputField.type === "password" ? "text" : "password");
    inputField.type = fieldType;
    if(inputField === this.oldPassword.nativeElement) {
      this.oldPasswdInputType = fieldType;
    } else if(inputField === this.newPassword.nativeElement) {
      this.newPasswdInputType = fieldType;
    } else if(inputField === this.retypePassword.nativeElement) {
      this.retypePasswdInputType = fieldType;
    }
  }

  openModalPopup() {
    this.modalPopupService.open(
      this.translate.instant('pdl-website.forms.user.changePasswordBtn'), 
      this.translate.instant('pdl-website.forms.labels.changePasswordForm.subtitle'),
      this.changePasswdModal, 
      this.translate.instant('pdl-shared.buttonText.update'), 
      this.translate.instant('pdl-shared.buttonText.cancel'), 
      this.handleOnSubmitChangePasswd.bind(this), 
      this.handleOnCancel.bind(this));
    this.initChangePasswdForm();
    this.detectChangePasswdChanges();
  }
}
