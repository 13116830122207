import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupComponent } from '../../app/main/common/modal-popup/modal-popup.component';

@Injectable({
  providedIn: 'root'
})
export class ModalPopupService {

  constructor(private modalService: NgbModal) {}

  public open(
    title: string,
    message: string,
    template: TemplateRef<any>,
    btnOkText = 'Submit',
    btnCancelText = 'Cancel',
    onSubmitCallback: () => void,
    onCancelCallback?: () => void,
    dialogSize: 'sm' | 'md' | 'lg' = 'lg'
    ): Promise<boolean> {
    const modalRef = this.modalService.open(ModalPopupComponent, { 
      size: dialogSize, 
      backdrop: 'static', // Prevent closing on click outside
      keyboard: false,
      centered: true
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.loadTemplate(template);
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.onSubmitCallback = onSubmitCallback;
    modalRef.componentInstance.onCancelCallback = onCancelCallback;
    return modalRef.result;
  }

  public dismiss() {
    this.modalService.dismissAll();
  }
}
