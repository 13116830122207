import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { AddEditDonationPayload, Donor } from "shared/src/interfaces/web-management.interface";

export const LoadDonorList = createAction(
    '[Donor] Load Donor List',
    props<{ payload: GenericListingPayload }>(),
)

export const LoadDonorListSuccess = createAction(
    '[Donor] Load Donor List Successfully',
    props<{ donors: Donor[], totalRecords: number }>()
)

export const LoadDonorListError = createAction(
    '[Donor] Load Donor List Error',
    props<{ message: string }>()
)

export const AddDonation = createAction(
    '[Donor] Add Donation to List',
    props<{ payload: AddEditDonationPayload }>()
);

export const AddDonationSuccess = createAction(
    '[Donor] Add Donation Success',
    props<{ donation: Donor; message: string }>()
);

export const AddDonationError = createAction(
    '[Donor] Add Donation Error',
    props<{ message: string }>()
);

export const EditDonation = createAction(
    '[Donation] Edit Donation',
    props<{payload: AddEditDonationPayload, DonationId: string}>(),
)

export const EditDonationSuccess = createAction(
    '[Donation] Edit Donation Success',
    props<{Donation: Donor, message: string}>(),
)

export const EditDonationError = createAction(
    '[Donation] Edit Donation Error',
    props<{message: string}>(),
)

export const DeleteDonation = createAction(
    '[Donation] Delete Donation',
    props<{DonationIDs: number[]}>(),
)

export const DeleteDonationSuccess = createAction(
    '[Donation] Delete Donation Success',
    props<{message: string}>(),
)

export const DeleteDonationError = createAction(
    '[Donation] Delete Donation Error',
    props<{message: string}>(),
)