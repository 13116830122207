/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { AdminAdoptPriceActions } from '../actions';
import { CategoryWithAdoptPrice } from 'shared/src/interfaces/fundraising.interface';

export interface State extends EntityState<CategoryWithAdoptPrice> {
  payload: GenericListingPayload;
  totalRecords: number;
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
}

export const adapter = createEntityAdapter<CategoryWithAdoptPrice>({
  selectId: (e) => e.categoryID,
});

export const initialState = adapter.getInitialState({
  totalRecords: 0,
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
});

export const reducer = createReducer(
  initialState,
  on(AdminAdoptPriceActions.LoadCategoryWithPriceList, (state, action) => ({
    ...state,
    payload: action.payload,
    isLoading: true,
    isError: false,
    errorMessage: '',
    successMessage: '',
  })),

  on(AdminAdoptPriceActions.LoadCategoryWithPriceListSuccess, (state, action) => ({
    ...state,
    ...adapter.setAll(action.categoryList, state),
    totalRecords: action.totalRecords,
    isLoading: false,
    isError: false,
    errorMessage: '',
    successMessage: '',
  })),

  on(AdminAdoptPriceActions.LoadCategoryWithPriceListError, (state, action) => ({
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.message,
    successMessage: '',
  })),

  on(AdminAdoptPriceActions.UpdateAdoptPrice, (state) => ({
    ...state,
    isLoading: false,
    isSaving: true,
    isError: false,
    errorMessage: '',
    successMessage: '',
  })),

  on(AdminAdoptPriceActions.UpdateAdoptPriceSuccess, (state, action) => ({
    ...adapter.updateOne(
      { id: action?.category?.categoryID, changes: action.category },
      state
    ),
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: action?.message,
  })),

  on(AdminAdoptPriceActions.UpdateAdoptPriceError, (state, action) => ({
    ...state,
    isLoading: false,
    isSaving: false,
    isError: true,
    errorMessage: action.message,
    successMessage: '',
  }))
);

export const { selectAll, selectEntities } = adapter.getSelectors();
