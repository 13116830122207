import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromDesignation from "../reducers/designation.reducer";

export const selectDesignationState =
  createFeatureSelector<fromDesignation.State>('designation');

export const getDesignations = createSelector(
    selectDesignationState,
    fromDesignation.selectAll
);

export const getTotalRecords = createSelector(
  selectDesignationState,
  (state) => state.totalRecords
);

export const getDesignationByID = (props: {id: string}) => createSelector(
  selectDesignationState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectDesignationState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectDesignationState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);