import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private http : HttpClient
  ) { }

  downloadFile(path: string, fileName:string) {
    this.http.get(path, { responseType: 'blob' }).subscribe(response => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName
      link.click();
      window.URL.revokeObjectURL(link.href);
    });
  }

  focusOnInvalidField(controls: AbstractControl, elem: ElementRef): void {
    if (controls instanceof FormGroup) {
      for (const key of Object.keys(controls.controls)) {
        if (controls.controls[key].invalid) {
          const invalidControl = elem.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          break;
        }
      }
    }
  }
}
