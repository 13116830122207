import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAdminVideoGallery from '../reducers/admin-video-gallery.reducer';

export const selectState =
  createFeatureSelector<fromAdminVideoGallery.State>('adminVideoGallery');

export const loadVideoGalleryList = createSelector(selectState, (state) => ({
  videoList: fromAdminVideoGallery.selectAll(state),
  totalRecords: state.totalRecords,
  pageSize: state.pageSize,
  pageNumber : state.pageNumber,
  templateVideos :  state.templateVideos
}));

export const getVideoById = (id: number) => createSelector(
  selectState,
  (state) => ({body :state.entities[id]})
)

export const onSave = createSelector(
  selectState,
  (state) => [state.isError, state.errorMessage, state.successMessage,state.isLoading]
);