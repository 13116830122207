import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TrendingKeywordsComponent } from './trending/trending-keywords.component';
import { GlobalDividerComponent } from '../divider/global-divider.component';
import { MostPopularComponent } from './most-popular/most-popular.component';
import { OverviewComponent } from './overview/overview.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CategoriesComponent } from './categories/categories.component';
import { CuratedCollectionsComponent } from './curated-collections/curated-collections.component';
import { ExhibitionsComponent } from './exhibitions/exhibitions.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { VideoGalleryComponent } from './video-gallery/video-gallery.component';
import { MailingListComponent } from './mailing-list/mailing-list.component';

import { Store } from '@ngrx/store';
import { AppState, WebHomePageActions, environment, WebBannersSelector } from '@panjab-digi-lib/shared';
import { Banner } from 'shared/src/interfaces/homepage.interface';
import { Observable } from 'rxjs';
import { RouterLink } from '@angular/router';
import { DonateComponent } from './donate/donate.component';
import { HttpFormatPipe } from 'shared/src/pipes/httpFormat.pipe';

@Component({
  selector: 'panjab-digi-lib-home',
  standalone: true,
  imports: [CommonModule, SlickCarouselModule,TrendingKeywordsComponent,GlobalDividerComponent,MostPopularComponent,OverviewComponent,TranslateModule,CategoriesComponent,CuratedCollectionsComponent,ExhibitionsComponent,DownloadsComponent,VideoGalleryComponent,MailingListComponent,RouterLink,DonateComponent,HttpFormatPipe],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  
  bannerSlides$!: Observable<Banner[]>;
  
  rootURL = environment.rootApiPublicUrl;
  
  constructor(
    private translate: TranslateService, 
    private store: Store<AppState>) {
  }

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      },
      {
        breakpoint: 300,
        // settings: "unslick" // destroys slick
      }
    ]
  };

  slickInit(e: any) {
    // console.log('slick initialized');
  }

  ngOnInit(): void {
    this.store.dispatch(WebHomePageActions.LoadWebBanners());

    this.bannerSlides$ = this.store.select(WebBannersSelector.getBanners);
  }
}
