/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {WebHomePageActions} from "../../actions/index";
import { Video } from 'shared/src/interfaces/video-gallery.interface';
import { WebVideosActions } from '../../actions/index';


export interface State extends EntityState<Video> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  totalRecords?:number;
}

export const adapter = createEntityAdapter<Video>({
  selectId: (e) => e?.ID
});

export const initialState = adapter.getInitialState( {
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0
});

export const reducer = createReducer(
  initialState,
  on(WebHomePageActions.LoadWebHomepageVideos,(state) =>
    ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebHomePageActions.LoadWebHomepageVideosSuccess,(state, action) =>
  ({
      ...adapter.setAll(action.videos, state),
      isLoading: false,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebHomePageActions.LoadWebCategoriesError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    })
  ),
  on(WebVideosActions.LoadWebVideos,(state) =>
    ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebVideosActions.LoadWebVideosSuccess,(state, action) =>
  ({
      ...adapter.setAll(action.videos, state),
      totalRecords: action?.totalRecords,
      isLoading: false,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebHomePageActions.LoadWebHomepageVideosError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    })
  )
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();