/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { STAFF_API_ENDPOINTS } from '../app.endpoints';
import { UserPermissionResponse } from 'shared/src/interfaces/permission.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  rootURL = environment.rootApiUrl + environment.staffApiPrefix;
  endPointUrl = STAFF_API_ENDPOINTS.permission;
  constructor(private http: HttpClient) {}

  ////////// load permissions of user /////////
  loadPermissions() {
    const url = `${this.rootURL}${this.endPointUrl.default}`;
    return this.http
      .get<UserPermissionResponse>(url)
      .pipe(map((e: UserPermissionResponse) => e));
  }
}
