/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { environment } from 'shared/src/environments/environment';
import { Observable, map } from 'rxjs';
import { AddEditDepartmentPayload, Department } from 'shared/src/interfaces/master-form.interface';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadDepartment(payload: GenericListingPayload): Observable<{departments: Department[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.department.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }
  
  addDepartment(payload: AddEditDepartmentPayload) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.masterForm.department.default}`, payload).pipe(map((e: any) => e));
  }
  
  editDepartment(payload: AddEditDepartmentPayload, departmentId: string) {
    return this.http.put(`${this.rootURL}${API_ENDPOINTS.masterForm.department.default}/${departmentId}`, payload).pipe(map((e: any) => e));
  }

  updateDepartmentStatus(departmentIDs: number[], status: string) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.department.updateDepartmentStatus}`, {id: departmentIDs, status}).pipe(map((e: any) => e));
  }
  
  deleteDepartment(departmentIDs: number[]) {
    return this.http.delete(`${this.rootURL}${API_ENDPOINTS.masterForm.department.default}`, {body: {id: departmentIDs}}).pipe(map((e: any) => e));
  }

  enabledDepartment() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.department.enabledDepartments}`).pipe(map((e: any) => e.body));
  }
  
  downloadDepartments() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.department.downloadDepartments}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }  
}
