<div class="reader-container">
  <div class="d-flex">
    <div style="flex-grow: 1;">
      <ngx-extended-pdf-viewer #pdfViewer [classList]="getClass()" [src]="'assets/B-000965_BK-000718.pdf'"
        [zoom]="'page-fit'" [showBookModeButton]="false" [textLayer]="true" [showZoomButtons]="'always-visible'"
        [showDownloadButton]="false" [showOpenFileButton]="'always-visible'" [showDrawEditor]="false"
        [showFindCurrentPageOnly]="true" [showFreeFloatingBar]="true" [showRotateButton]="false"
        [showPrintButton]="false" [showHighlightEditor]="false" [showSinglePageModeButton]="true" [showBorders]="true"
        [showSinglePageModeButton]="'always-visible'" [showWrappedScrollButton]="'always-visible'"
        [showTextEditor]="false" [showStampEditor]="false" [theme]="'dark'" [backgroundColor]="'#000000'"
        [showPresentationModeButton]="true" [showVerticalScrollButton]="'always-visible'"
        [showSecondaryToolbarButton]="false" [ignoreKeyboard]="true" [showFindButton]="false"
        (updateFindMatchesCount)="onUpdateFindResult($event)" [showHandToolButton]="false" [showSidebarButton]="true"
        [showSpreadButton]="'always-visible'" (pageRendered)="onPageRendered()">
      </ngx-extended-pdf-viewer>
    </div>
    <div class="search-container py-2 px-4" [class.show_search]="showSearchContainer">
      <form #searchForm="ngForm" (submit)="search()" name="searchForm" autocomplete="off">
        <div class="text-end mb-2">
          <button (click)="closeSearch()" type="button" class="btn btn-link p-0">
            <img src="assets/images/icons/close-rounded-icon.svg" alt="Search" class="img-fluid" />
          </button>
        </div>
        <div class="reader__search">
          <div class="position-relative inner__search">
            <label for="" class="search-label"><img src="assets/images/icons/search-white-icon.svg"></label>
            <input name="searchText" type="text" class="form-control search__input" placeholder="Search..."
              [(ngModel)]="searchText" />
            <div class="clear__search">
              <button type="button" class="btn btn-link p-1" type="button" (click)="clearSearch();searchForm.reset();">
                <img src="assets/images/icons/cross-gray-icon.svg" alt="clear" class="img-fluid" />
              </button>
            </div>
          </div>
        </div>
        @if (searchResults.length > 1 && isSearchActive) {
        <div class="text-end my-2 prev__next__btns">
          <button class="btn btn-default btn__secondary" type="submit" (click)="prev()">
            <img src="assets/images/icons/arrow-prev.svg" alt="Previous">
          </button>
          <button class="btn btn-default btn__secondary ms-2" type="submit" (click)="next()">
            <img src="/assets/images/icons/arrow-next.svg" alt="Next">
          </button>
        </div>
        }


        <div class="results">
          @for (item of searchResults; track item.text;) {
          <div (click)="goToSearchResult(item, $index + 1)" [highlightText]="currentSearchTerm"
            class="search-result p-2 mb-3">
            {{ item.text }}
          </div>
          }
          @if( searchResults.length == 0 && isSearchActive) {
          <div class="no-result">
            {{'pdl-shared.content.noResultFoundFor' | translate}} "{{currentSearchTerm}}"
          </div>
          }
        </div>
      </form>
    </div>
    <!-- // Bookmarks view -->
    <div class="search-container py-2 px-4" [class.show_search]="showBookMarksContainer">
      <div class="bookmarks">
        <div class="bookmarkHeading mb-4">
          My All Bookmarks
        </div>
        <div class="bookmarksContainer">
          @for (bookmark of bookmarks; track bookmark) {
          <div class="bookmarkItem">{{bookmark.name}}</div>
          }
        </div>
      </div>
    </div>
    <!-- // notes view -->
    <div class="search-container py-2 px-4" [class.show_search]="showNotesContainer">
      <form #searchForm="ngForm" name="searchForm" autocomplete="off">
        <div class="text-end mb-2">
          <button (click)="closeNotes()" type="button" class="btn btn-link p-0">
            <img src="assets/images/icons/close-rounded-icon.svg" alt="Search" class="img-fluid" />
          </button>
        </div>
        <div class="reader__search">
          <div (click)="showAddNotes()"
            class="position-relative inner__search d-flex align-items-center border-dotted cursor-pointer">
            <label for="" class=" "><img src="/assets/images/icons/plus-icon-white.svg"></label>
            <div name="addNotes" type="text" class="form-control notes_input" placeholder="Search...">Add Notes</div>
            <div class="clear__search">
              <button type="button" class="btn btn-link p-1" type="button" (click)="closeAddNotes();">
                <img src="assets/images/icons/cross-gray-icon.svg" (click)="closeAddNotes()" alt="clear"
                  class="img-fluid" />
              </button>
            </div>
          </div>
        </div>
        @if (!enableAddNotes) {
        <div class="notes">
          @if (notes.length && !!showNotesContainer) {
          @for (note of notes; track note;let index = $index;) {
          <div class="note_wrapper">
            <div class="heading_note">
              @if (note.noteName) {
              <span>{{note.noteName}}</span>
              } @else {
              <span> Note {{ index + 1}}</span>
              }
            </div>
            <div class="note_content">
              {{note.noteContent}}
            </div>
          </div>
          }
          }
        </div>
        } @else {
        <div class="add_notes_textarea">
          <span class="heading">Note 1</span>
          <textarea col="20"></textarea>
          <button class="btn btn__primary">Add</button>
        </div>

        }
      </form>
    </div>





    <!-- buttons view -->
    @if(!isHovered && !(showSearchContainer || showNotesContainer)){
    <button class="btn btn-primary btn__primary toggle__right" (click)="showControls()">
      <img src="assets/images/icons/reader-control-btn.svg" alt="toggle" class="img-fluid" />
    </button>
    }
    <div class="btn-container controller__container" [class.hover_class]="isHovered">
      <ul>
        <li><button (click)="closeToggle()" class="btn btn-link" title="close menu">
            <img src="assets/images/icons/reader-close-icon.svg" alt="Search" class="img-fluid" /></button>
        </li>
        <li><button (click)="showSearchView()" class="btn btn-link" title="Search book content">
            <img src="assets/images/icons/reader-search-icon.svg" alt="Search" class="img-fluid" /></button>
        </li>
        <li><button (click)="showNotesView()" class="btn btn-link" title="Show Notes">
            <img src="assets/images/icons/memo-pad.svg" alt="Notes" class="img-fluid" /></button>
        </li>
        <li><button (click)="showBookMarksView()" class="btn btn-link" title="Show Bookmarks">
            <img src="assets/images/icons/bookmark-ico.svg" alt="Bookmarks" class="img-fluid" /></button>
        </li>
        <li><button (click)="changeBrightness()" class="btn btn-link" title="Change brightness">
            <img src="assets/images/icons/reader-brightness-icon.svg" alt="Brightness" class="img-fluid" />
          </button></li>
        <li><button (click)="changeGrayscale()" class="btn btn-link" title="Change color scheme to Grayscale mode">
            <img src="assets/images/icons/reader-grayscale-icon.svg" alt="Grayscale" class="img-fluid" />
          </button></li>
        <!-- <li><button (click)="changeSepia()" class="btn btn-link">
        Sepia
      </button></li> -->
        <li><button (click)="reset()" class="btn btn-link" title="Reset all">
            <img src="assets/images/icons/reader-reset-icon.svg" alt="reset" class="img-fluid" />
          </button></li>
      </ul>
    </div>
  </div>
</div>
