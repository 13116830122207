<div class="dashboard__header">
    <div class="main__heading">
        <h1>{{title | translate}}</h1>
    </div>
    <div class="right_header">
        <div class="header__search">
          <label class="search-label"><img src="assets/images/icons/search-icon.svg"></label>
          <input type="email" class="form-control search__input" id="exampleFormControlInput1" placeholder="Search">
        </div>
        <div class="user__info">
            <div class="dropdown">
                <a href="#" class="d-flex align-items-center text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <div class="user__settings">
                    @if(activeUser?.fullname) {
                      <p>{{activeUser.fullname}}</p>
                    } @else {
                      <p>Jaswinder Pal Singh</p>
                    }
                    @if(activeUser?.username) {
                      <span>{{activeUser.username}}</span>
                    } @else {
                      <span>Super Admin</span>
                    }
                  </div>
                  @if(activeUser?.staffUser) {
                    @if(activeUser?.image) {
                      <img [src]="baseUrl+'/'+activeUser.image" alt="avatar" width="40" height="40" class="rounded-circle me-2">
                    } @else {
                      <img src="assets/images/default-avatar.svg" alt="avatar" width="40" height="40" class="rounded-circle me-2">
                    }
                  } @else {
                    <img src="assets/images/avatar.svg" alt="avatar" width="40" height="40" class="rounded-circle me-2">
                  }
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><a class="dropdown-item" href="#">Profile</a></li>
                  <li><hr class="dropdown-divider"></li>
                  <li><a class="dropdown-item" href="javascript:void(0)" (click)="signOut()">Sign out</a></li>
                </ul>
              </div>
        </div>
    </div>
</div>
