import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromDonor from "../reducers/donor.reducers";

export const selectDonorState =
  createFeatureSelector<fromDonor.State>('donor');

export const getDonors = createSelector(
    selectDonorState,
    fromDonor.selectAll
);

export const getTotalRecords = createSelector(
  selectDonorState,
  (state) => state.totalRecords
);

export const getDonorByID = (props: {id: string}) => createSelector(
  selectDonorState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectDonorState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectDonorState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectDonorState,
  (state) => state.isLoading,
);