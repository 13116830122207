<section class="trending section-padding padding-top-md-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="heading-style semibold text-center mb-lg-40">{{'pdl-website.trendingSection.sectionTitle' |
                    translate
                    }}</h2>
            </div>
            <div class="col-12">
                <div class="pill-tabls-design text-center">
                    <ul class="nav nav-pills d-flex align-items-center justify-content-center mb-lg-40 mb-4" id="pills-tab"
                        role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link medium-font active" id="pills-today-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-today" type="button" role="tab" aria-controls="pills-today"
                                aria-selected="true">{{'pdl-website.trendingTab1.tabTitle' | translate }}</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link medium-font" id="pills-historical-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-historical" type="button" role="tab"
                                aria-controls="pills-historical"
                                aria-selected="false">{{'pdl-website.trendingTab2.tabTitle' | translate }}</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link medium-font" id="pills-searches-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-searches" type="button" role="tab" aria-controls="pills-searches"
                                aria-selected="false">{{'pdl-website.trendingTab3.tabTitle' | translate }}</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-today" role="tabpanel"
                            aria-labelledby="pills-today-tab" tabindex="0">
                            <ul class="trending-tags ps-0 mb-0 list-style-none d-flex align-items-center justify-content-center flex-wrap">
                                @for(todayKeyword of todayKeywords$ | async; track todayKeyword.keyword) {
                                <li>
                                    <a href="#" class="trending-tag font-regular">
                                        {{todayKeyword.keyword | translate }}
                                    </a>
                                </li>
                                } @empty {
                                    There are no searches today.
                                }
                            </ul>
                        </div>
                        <div class="tab-pane fade" id="pills-historical" role="tabpanel"
                            aria-labelledby="pills-historical-tab" tabindex="0">
                            <ul class="trending-tags ps-0 mb-0 list-style-none d-flex align-items-center justify-content-center flex-wrap">
                                @for(historicKeyword of historicKeywords$ | async; track historicKeyword.keyword) {
                                    <li>
                                        <a href="#" class="trending-tag font-regular">
                                            {{historicKeyword.keyword | translate }}
                                        </a>
                                    </li>
                                } @empty {
                                    No searches made yet.
                                }
                            </ul>
                        </div>
                        <div class="tab-pane fade" id="pills-searches" role="tabpanel"
                            aria-labelledby="pills-searches-tab" tabindex="0">
                            <ul
                                class="trending-tags ps-0 mb-0 list-style-none d-flex align-items-center justify-content-center flex-wrap">
                                You have not made any search yet, start searching and the keywords will appear here.
                                <!-- <li>
                                    <a href="#" class="trending-tag font-regular">
                                        Punjab Farmers
                                    </a>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>