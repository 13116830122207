<div class="d-flex justify-content-center align-items-center modal-wrapper">
  <div class="modal-content">
    <!-- search and close -->
    <div class="d-flex justify-content-between mb-3 align-items-center">
      <div class="search_with_glass_at_end padding-12">
        <label class="search-label"><img src="assets/images/icons/search-icon.svg"></label>
        <input type="text" class="form-control search__input disabled" id="exampleFormControlInput1"
          [placeholder]="'Search By ' + type ">
      </div> <span>
        <svg (click)="closeClickHandler()" class="cursor-pointer" width="13" height="13" viewBox="0 0 13 13" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.3594 10.6406C12.8672 11.1094 12.8672 11.9297 12.3594 12.3984C12.125 12.6328 11.8125 12.75 11.5 12.75C11.1484 12.75 10.8359 12.6328 10.6016 12.3984L6.5 8.29688L2.35938 12.3984C2.125 12.6328 1.8125 12.75 1.5 12.75C1.14844 12.75 0.835938 12.6328 0.601562 12.3984C0.09375 11.9297 0.09375 11.1094 0.601562 10.6406L4.70312 6.5L0.601562 2.39844C0.09375 1.92969 0.09375 1.10938 0.601562 0.640625C1.07031 0.132812 1.89062 0.132812 2.35938 0.640625L6.5 4.74219L10.6016 0.640625C11.0703 0.132812 11.8906 0.132812 12.3594 0.640625C12.8672 1.10938 12.8672 1.92969 12.3594 2.39844L8.25781 6.53906L12.3594 10.6406Z"
            fill="#C2C7D0" />
        </svg>
      </span>
    </div>
    <div class="mb-5 d-flex gap-20 no-scrollbar">
      <span class="savedSearchText">
        <span class="text">Saved Search</span> <img class="cursor-pointer" src="/assets/images/icons/cross-gray-20.svg"
          alt="icon" />
      </span>
      <span class="savedSearchText">
        <span class="text">Saved Search</span> <img class="cursor-pointer" src="/assets/images/icons/cross-gray-20.svg"
          alt="icon" />
      </span>
      <span class="savedSearchText">
        <span class="text">Saved Search</span> <img class="cursor-pointer" src="/assets/images/icons/cross-gray-20.svg"
          alt="icon" />
      </span>
      <span class="savedSearchText">
        <span class="text">Saved Search</span> <img class="cursor-pointer" src="/assets/images/icons/cross-gray-20.svg"
          alt="icon" />
      </span>
      <span class="savedSearchText">
        <span class="text">Saved Search</span> <img class="cursor-pointer" src="/assets/images/icons/cross-gray-20.svg"
          alt="icon" />
      </span>
      <span class="savedSearchText">
        <span class="text">Saved Search</span> <img class="cursor-pointer" src="/assets/images/icons/cross-gray-20.svg"
          alt="icon" />
      </span>
      <span class="savedSearchText">
        <span class="text">Saved Search</span> <img class="cursor-pointer" src="/assets/images/icons/cross-gray-20.svg"
          alt="icon" />
      </span>
      <span class="savedSearchText">
        <span class="text">Saved Search</span> <img class="cursor-pointer" src="/assets/images/icons/cross-gray-20.svg"
          alt="icon" />
      </span>
    </div>
    <div class="d-flex chunkWrapper thin-scrollbar">
      @for (chunk of getTotalChunks(10); track chunk; let i = $index) {
      <div>
        @for (data of getNthChunk(i+1, 10); track data.data;) {
        <div class="text-start form-check">
          <input type="radio" class="form-check-input" value="{{data.id}}" aria-label="Deactivate Account">
          <label for="deactivateAcc" class=" "> {{data.subject}}</label>
        </div>
        }
      </div>
      }
    </div>

  </div>
</div>
