/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminSponsorshipActions } from '../actions';
import { catchError, exhaustMap, map, of,switchMap, withLatestFrom } from 'rxjs';
import { SponsorshipService } from 'shared/src/services/admin/sponsorship/sponsorship.service';
import { Store, select } from '@ngrx/store';
import { AdminSponsorshipSelector, AppState } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';

@Injectable()
export class AdminSponsorshipEffects {
  constructor(private actions$: Actions, private service: SponsorshipService, private store: Store<AppState>, private router: Router,) {}

  //////////////// get all Sponsorships ////////////
  LoadSponsorshipList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminSponsorshipActions.LoadSponsorshipList),
      exhaustMap((action) => {
        return this.service.loadSponsorshipList(action?.payload).pipe(
          map((response) => {
            return AdminSponsorshipActions.LoadSponsorshipListSuccessfully({
              sponsorshipList: response?.sponsorshipItems,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              AdminSponsorshipActions.LoadSponsorshipListError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// add Sponsorship ////////////
  AddSponsorship$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminSponsorshipActions.AddSponsorship),
      exhaustMap((action) => {
        return this.service.addSponsorship(action?.payload).pipe(
          map((response) => {
            return AdminSponsorshipActions.AddSponsorshipSuccessfully({
              sponsorshipItem: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminSponsorshipActions.AddSponsorshipError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Sponsorship ////////////
  UpdateSponsorship$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminSponsorshipActions.UpdateSponsorship),
      exhaustMap((action) => {
        return this.service.updateSponsorship(action?.payload,action?.id).pipe(
          map((response) => {
            return AdminSponsorshipActions.UpdateSponsorshipSuccessfully({
              sponsorship: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminSponsorshipActions.UpdateSponsorshipError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Sponsorship status////////////
  UpdateLoadSponsorshipStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminSponsorshipActions.UpdateSponsorshipStatus),
      exhaustMap((action) => {
        return this.service.updateSponsorshipStatus(action?.payload).pipe(
          map((response) => {
            return AdminSponsorshipActions.UpdateSponsorshipStatusSuccessfully({
              sponsorship: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminSponsorshipActions.UpdateSponsorshipStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  ///////////////// delete Sponsorship ///////////////////////
  DeleteSponsorship$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminSponsorshipActions.DeleteSponsorship),
      exhaustMap((action) =>
        this.service.deleteSponsorship(action?.payload).pipe(
          map((response) => {
            return AdminSponsorshipActions.DeleteSponsorshipSuccessfully({
              message: response?.message,
            });
          }),
          catchError((res) =>
            of(
              AdminSponsorshipActions.DeleteSponsorshipError({
                message: res.error?.error?.message,
              })
            )
          )
        )
      )
    )
  );

 ///////////////////// redirect after adding/deleting Sponsorship /////////////////////     
  AddSponsorshipSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminSponsorshipActions.AddSponsorshipSuccessfully,AdminSponsorshipActions.DeleteSponsorshipSuccessfully),
      withLatestFrom(this.store.pipe(select(AdminSponsorshipSelector.loadSponsorshipList))),
      switchMap(([action, state]) => {
        this.router.navigateByUrl('/pdl-employees/reports/sponsorships');
        const pageNumber = (action.type === AdminSponsorshipActions.AddSponsorshipSuccessfully.type) ? 1 : state?.pageNumber;
        const payload = { pageNumber: pageNumber, pageSize: state?.pageSize, orderBy: state?.orderBy, order : state?.order };
        return of(AdminSponsorshipActions.LoadSponsorshipList({payload}));
      })
    )
  )
}
