import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageHeadComponent } from '../static/staticPageHead/staticPageHead.component';

@Component({
  selector: 'panjab-digi-lib-adopt-a-book',
  standalone: true,
  imports: [CommonModule, StaticPageHeadComponent],
  templateUrl: './adopt-a-book.component.html',
  styleUrl: './adopt-a-book.component.scss',
})
export class AdoptABookComponent {}
