/* eslint-disable @nx/enforce-module-boundaries */
import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromStaffList from "shared/src/store/reducers/staff/staff-list.reducer";

export const selectStaffListState =
  createFeatureSelector<fromStaffList.State>('staffList');

export const getStaffList = createSelector(
    selectStaffListState,
    fromStaffList.selectAll
);

export const getTotalRecords = createSelector(
  selectStaffListState,
  (state) => state.totalRecords
);

export const getStaffByID = (props: {id: string}) => createSelector(
  selectStaffListState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectStaffListState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectStaffListState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectStaffListState,
  (state) => state.isLoading,
);