import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromDepartment from "../reducers/department.reducer";

export const selectDepartmentState =
  createFeatureSelector<fromDepartment.State>('department');

export const getDepartments = createSelector(
    selectDepartmentState,
    fromDepartment.selectAll
);

export const getTotalRecords = createSelector(
  selectDepartmentState,
  (state) => state.totalRecords
);

export const getDepartmentByID = (props: {id: string}) => createSelector(
  selectDepartmentState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectDepartmentState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectDepartmentState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);