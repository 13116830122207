import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[panjabDigiLibTrimmedText]',
  standalone: true,
})
export class TrimmedTextDirective {
  constructor() {}
  
  @HostListener('keyup', ['$event'])
  onInput(evt: any) {
    evt.target.value = evt.target.value.replace(/^\s/, "")
  }
}
