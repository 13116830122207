
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import {AddEditEditorPayload,DeleteEditorPayload,GetEditorPayload, MergeEditorPayload,Editor,UpdateEditorStatusPayload} from 'shared/src/interfaces/editor.interface';
export const LoadEditors = createAction(
  '[Editor] Load Editor List',
  props<{ payload: GetEditorPayload }>()
);

export const LoadEditorSuccessfully = createAction(
  '[Editor] Load Editor Successfully',
  props<{ editorList: Editor[]; totalRecords: number }>()
);

export const LoadEditorError = createAction(
  '[Editor] Load Editor Error',
  props<{ message: string }>()
);

export const AddEditor = createAction(
  '[Editor] Add Editor to List',
  props<{ payload: AddEditEditorPayload }>()
);

export const AddEditorSuccessfully = createAction(
  '[Editor] Editor Added Successfully',
  props<{ editor: Editor; message: string }>()
);

export const AddEditorError = createAction(
  '[Editor] Editor Added Error',
  props<{ message: string }>()
);

export const UpdateEditor = createAction(
  '[Editor] Edit Editor in List',
  props<{ payload: AddEditEditorPayload,id:number }>()
);

export const UpdateEditorSuccessfully = createAction(
  '[Editor] Editor Updated Successfully',
  props<{ editor: Editor; message: string }>()
);

export const UpdateEditorError = createAction(
  '[Editor] Editor Updated Error',
  props<{ message: string }>()
);

export const UpdateEditorStatus = createAction(
    '[Editor] Update Editor Status in List',
    props<{ payload: UpdateEditorStatusPayload }>()
  );
  
  export const UpdateEditorStatusSuccessfully = createAction(
    '[Editor] Editor Status Updated Successfully',
    props<{ editor: Editor[]; message: string }>()
  );
  
  export const UpdateEditorStatusError = createAction(
    '[Editor] Editor Status Updated Error',
    props<{ message: string }>()
  );

  export const MergeEditor = createAction(
    '[Editor] Merge Editor',
    props<{ payload: MergeEditorPayload }>()
  );
  
  export const MergeEditorSuccessfully = createAction(
    '[Editor] Merge Editor Successfully',
    props<{ message: string }>()
  );
  
  export const MergeEditorError = createAction(
    '[Editor] Editor Status Updated Error',
    props<{ message: string }>()
  );

  export const DeleteEditor= createAction(
    '[Editor] Delete Status from List',
    props<{ payload: DeleteEditorPayload }>()
  );
  
  export const DeleteEditorSuccessfully = createAction(
    '[Editor] Editor Deleted Successfully',
    props<{ message: string }>()
  );
  
  export const DeleteEditorError = createAction(
    '[Editor] Editor Status Updated Error',
    props<{ message: string }>()
  );