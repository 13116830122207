import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, ProcessActions, ProcessSelector } from '@panjab-digi-lib/shared';
import { MasterFormsService } from 'shared/src/services/admin/master-form/master-forms.service';
import { Update } from '@ngrx/entity';
import { Process } from 'shared/src/interfaces/master-form.interface';

@Injectable()
export class ProcessEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private masterFormService: MasterFormsService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadProcesses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessActions.LoadProcess),
            exhaustMap((action) => {

                return this.masterFormService.loadProcess(action.payload).pipe(
                    map((response) => {
                        return ProcessActions.LoadProcessSuccess({ processes: response.processes, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(ProcessActions.LoadProcessError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
    
    AddProcess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessActions.AddProcess),
            exhaustMap((action) => {

                return this.masterFormService.addProcess(action.payload).pipe(
                    map((response) => {

                        return ProcessActions.AddProcessSuccess({ process: response.body, message: response.message });
                    }),
                    catchError((error: any) => of(ProcessActions.AddProcessError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    EditProcess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessActions.EditProcess),
            exhaustMap((action) => {
                
                return this.masterFormService.editProcess(action.payload, action.processId).pipe(
                    map((response) => {

                        this.router.navigateByUrl('/master-forms/manage/processes');
                        return ProcessActions.EditProcessSuccess({process: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(ProcessActions.EditProcessError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );

    UpdateProcessStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessActions.UpdateProcessStatus),
            exhaustMap((action) => {

                return this.masterFormService.updateProcessStatus(action.processIDs, action.status).pipe(
                    map((response: any) => {
                        const processUpdates: Update<Process>[] = action.processIDs.map((id) => {
                            return {
                                id, changes: {
                                    Status: action.status
                                }
                            }
                        })

                        return ProcessActions.UpdateProcessStatusSuccess({ processUpdates, message: response.message });
                    }),
                    catchError((error: any) => of(ProcessActions.UpdateProcessStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    DeleteProcess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessActions.DeleteProcess),
            exhaustMap((action) => {

                return this.masterFormService.deleteProcess(action.processIDs).pipe(
                    map((response: any) => {

                        return ProcessActions.DeleteProcessSuccess({ message: response.message });
                    }),
                    catchError((error: any) => of(ProcessActions.DeleteProcessError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );

    AddDeleteProcessSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessActions.AddProcessSuccess, ProcessActions.DeleteProcessSuccess),
            switchMap((action) => combineLatest([
                    of(action.type),
                    this.store.select(ProcessSelector.getListingPayload).pipe(take(1))
                ])
            ),
            map(([type, payload]) => {
                
                if(type == '[Process] Add Process Success') {
                    this.router.navigateByUrl('/master-forms/manage/processes');
                }
                return ProcessActions.LoadProcess({ payload });
            })
        )
    );
}