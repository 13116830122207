import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { API_ENDPOINTS } from '../app.endpoints';
import { map, Observable } from 'rxjs';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { AddEditSectionPayload, Section } from 'shared/src/interfaces/master-form.interface';
@Injectable({
  providedIn: 'root'
})
export class SectionService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.masterForm.language;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadSection(payload: GenericListingPayload): Observable<{sections: Section[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.section.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }
  
  addSection(payload: AddEditSectionPayload) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.masterForm.section.default}`, payload).pipe(map((e: any) => e));
  }
  
  editSection(payload: AddEditSectionPayload, SectionId: string) {
    return this.http.put(`${this.rootURL}${API_ENDPOINTS.masterForm.section.default}/${SectionId}`, payload).pipe(map((e: any) => e));
  }

  updateSectionStatus(SectionIDs: number[], status: string) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.section.updateStatus}`, {id: SectionIDs, status}).pipe(map((e: any) => e));
  }
  
  deleteSection(SectionIDs: number[]) {
    return this.http.delete(`${this.rootURL}${API_ENDPOINTS.masterForm.section.default}`, {body: {id: SectionIDs}}).pipe(map((e: any) => e));
  }
  
  downloadSections() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.section.download}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }  
}
