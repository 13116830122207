import { createReducer, on } from '@ngrx/store';
import { WebAuthUserActions } from '../../actions/index';

export interface State {
  isLoading?: boolean;
  isError?: boolean;
  successMessage?: string;
  errorMessage?: string;
}

const initialState: State = {
  isLoading: false,
  isError: false,
  successMessage: '',
  errorMessage: ''
};

export const reducer = createReducer(initialState,
  on(WebAuthUserActions.updateProfileRequest, state => ({ ...state, isLoading: true })),
  on(WebAuthUserActions.updateProfileSuccess, (state, { successMessage }) => ({ ...state, isError: false, errorMessage: '', successMessage, isLoading: false })),
  on(WebAuthUserActions.updateProfileError, (state,{ errorMessage }) => ({ ...state, isError: true, successMessage: '', errorMessage, isLoading: false })),
  on(WebAuthUserActions.resetState, state => ({...initialState}))
);