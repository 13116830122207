import { createReducer, on } from '@ngrx/store';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {WebHomePageActions} from "../../actions/index"

export interface State extends EntityState<any> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

export const adapter = createEntityAdapter<any>({
  selectId: (e) => e.BannerOrder
});

export const initialState = adapter.getInitialState( {
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: ''
});

export const reducer = createReducer(
  initialState,
  on(WebHomePageActions.LoadWebBanners,(state, action) =>
    ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebHomePageActions.LoadWebBannersSuccess,(state, action) =>
  ({
      ...adapter.setAll(action.banners, state),
      isLoading: false,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebHomePageActions.LoadWebBannersError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    })
  )
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();