import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppLoadingService } from '../common/services/app-loading.service';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'panjab-digi-lib-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'panjab-digi-lib';

  constructor(private appLoadingService: AppLoadingService) {

  }

  ngOnInit(): void {
    this.appLoadingService.initApp()
  }
}
