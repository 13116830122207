/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { ArticleCommentActions } from '@panjab-digi-lib/shared';
import { Update } from '@ngrx/entity';
import { ArticleComment } from 'shared/src/interfaces/master-form.interface';
import { ArticleCommentsService } from 'shared/src/services/admin/master-form/article-comments.service';
import { Router } from '@angular/router';

@Injectable()
export class ArticleCommentEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private router: Router,
        private articleCommentService: ArticleCommentsService
    ) {
    }

    LoadArticleComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ArticleCommentActions.LoadArticleComment),
            exhaustMap((action) => {

                return this.articleCommentService.loadArticleComment(action.payload).pipe(
                    map((response) => {
                        return ArticleCommentActions.LoadArticleCommentSuccess({ articleComments: response.articleCommentsList, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(ArticleCommentActions.LoadArticleCommentError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
    
    UpdateArticleCommentStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ArticleCommentActions.UpdateArticleCommentStatus),
            exhaustMap((action) => {

                return this.articleCommentService.updateArticleCommentStatus(action.articleCommentIDs, action.status, action.comment || '').pipe(
                    map((response: any) => {
                        const articleCommentUpdates: Update<ArticleComment>[] = action.articleCommentIDs.map((id: any) => {
                            const changes: any = {Status : action.status}
                            if(action.comment) {
                                changes.Comment = action.comment
                            }
                            return { id, changes }
                        })
                        if (action.articleCommentIDs.length === 1 && action.comment){
                            this.router.navigateByUrl('/master-forms/comments/articles-comments');
                        }
                        return ArticleCommentActions.UpdateArticleCommentStatusSuccess({ articleCommentUpdates, message: response.message });
                    }),
                    catchError((error: any) => of(ArticleCommentActions.UpdateArticleCommentStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
}