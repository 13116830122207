<panjab-digi-lib-static-page-head [pageName]="'pdl-website.privacyPolicy.titleText' | translate"
  pagePath="/privacy-policy">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <p class="text">Thank you for visiting our Web site. At PDL, we are committed to transparency, and yet also
      protecting the
      privacy
      of our visitors.</p>
    <h1 class="heading mb-3">Personal Information</h1>
    <p class="text">
      You do not have to provide any personal information to visit this Web site as a guest. When joining, you must
      provide us information about yourself via e-mail or a Web form; however, your information will be used only for
      the purposes described at the point of collection. If you provide contact information, PDL staff or its
      contractors, if any, may contact you to clarify your comment or question, or to learn about your level of customer
      satisfaction with our services.
    </p>
    <ul class="text">
      <li>
        We do not sell any personal information to third parties.</li>
      <li>We may transfer personal information to a third party only in the following cases:</li>
      <ul class="hyphen">
        <li>To a bank, which must receive
          payment for a transaction that you initiated;
        </li>
        <li>
          To a company that is contracted to assist us with specific
          services, including electronic commerce or donations, and which agrees not to sell or use personal information
          for
          other purposes;
        </li>
        <li>
          To legal authorities if we suspect that you are attempting to change or otherwise damage this web
          site, or you are using it in violation of the State laws
        </li>
      </ul>
    </ul>
    <h1 class="heading mb-3">E-mail Information</h1>
    <p class="text">
      Please do not send us sensitive information, such as your credit card or social security numbers, via e-mail. It
      is possible that during transmission of your e-mail this information may be intercepted by a third party. We do
      not guarantee its safety while in transit.
    </p>
    <p class="text">
      We use return e-mail addresses to answer the e-mail we receive. We would share your e-mail and other personal
      information with a government agency only if we are required to do so by law.
    </p>
    }
  </div>
</section>
