/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { AdminAuthorActions } from '../actions/index';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Author } from 'shared/src/interfaces/author.interface';

export interface State extends EntityState<Author> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  totalRecords: number;
  pageNumber : number,
  pageSize: number,
  sortBy: string,
  orderBy:string
}

export const adapter = createEntityAdapter<Author>({
  selectId: (e) => e?.AuthorID,
});

export const initialState = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0,
  pageNumber : 0,
  pageSize: 0,
  sortBy: 'dDate',
  orderBy: 'desc'
});

export const reducer = createReducer(
  initialState,

  on(AdminAuthorActions.LoadAuthors, (state,action) => ({
    ...state,
    isLoading: true,
    successMessage: '',
    errorMessage: '',
    pageNumber: action.payload?.pageNumber || 1,
    pageSize: action.payload?.pageSize || 0,
  })),

  on(AdminAuthorActions.LoadAuthorSuccessfully, (state, action) =>
    adapter.setAll(action?.authorList, {
      ...state,
      totalRecords: action.totalRecords,
      isLoading: false,
    })
  ),

  on(AdminAuthorActions.LoadAuthorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminAuthorActions.AddAuthor, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminAuthorActions.AddAuthorSuccessfully, (state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: action?.message
    })
  ),

  on(AdminAuthorActions.AddAuthorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminAuthorActions.UpdateAuthor, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminAuthorActions.UpdateAuthorSuccessfully, (state, action) => ({
    ...adapter.updateOne(
      { id: action?.author?.AuthorID, changes: action.author },
      state
    ),
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: action?.message,
  })),

  on(AdminAuthorActions.UpdateAuthorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      successMessage: '',
      errorMessage: action?.message,
    };
  }),

  on(AdminAuthorActions.UpdateAuthorStatus, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminAuthorActions.UpdateAuthorStatusSuccessfully, (state, action) => {
    const updates = action?.author;
    const updatedEntities = adapter.updateMany(
      updates.map((update: Author) => ({
        id: update.AuthorID,
        changes: update,
      })),
      state
    );
    return {
      ...updatedEntities,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action?.message,
    };
  }),

  on(AdminAuthorActions.UpdateAuthorStatusError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),

  on(AdminAuthorActions.DeleteAuthor, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminAuthorActions.DeleteAuthorSuccessfully, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: false,
      successMessage: action?.message,
      errorMessage: '',
    };
  }),

  on(AdminAuthorActions.DeleteAuthorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),
  on(AdminAuthorActions.MergeAuthor, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminAuthorActions.MergeAuthorSuccessfully, (state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: action?.message
    })
  ),

  on(AdminAuthorActions.MergeAuthorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),
);

export const { selectAll } = adapter.getSelectors();
