import { createAction, props } from "@ngrx/store";
import { CategoryResponse, getCategoriesPayload } from "shared/src/interfaces/category.interface";

export const LoadCategories = createAction(
    '[Category] Load Categories',
    props<{payload: getCategoriesPayload}>(),
)

export const LoadCategoriesSuccess = createAction(
    '[Category] Load Categories Successfully',
    props<{categories: CategoryResponse[], totalRecords: number}>()
)

export const LoadCategoriesError = createAction(
    '[Category] Load Categories Error',
    props<{message: string}>()
)

export const ToggleCategoriesViewType = createAction(
    '[Category] Toggle Category View Type',
    props<{viewType: string}>(),
)

export const UpdateCategoriesHomeStatus = createAction(
    '[Category] Update Category Home Status',
    props<{id: number, status: boolean}>(),
)

export const UpdateCategoriesHomeStatusSuccess = createAction(
    '[Category] Update Category Home Status Success',
    props<{categories: CategoryResponse[], message: string}>(),
)

export const UpdateCategoriesHomeStatusError = createAction(
    '[Category] Update Category Home Status Error',
    props<{message: string}>(),
)

export const ReorderCategories = createAction(
    '[Category] Reorder Categories',
    props<{reorderFor: string, categories: Array<Object>}>(),
)

export const ReorderCategoriesSuccess = createAction(
    '[Category] Reorder Categories Success',
    props<{categories: CategoryResponse[], message: string}>(),
)

export const ReorderCategoriesError = createAction(
    '[Category] Reorder Categories Error',
    props<{message: string}>(),
)

export const AddCategory = createAction(
    '[Category] Add Category',
    props<{formData: FormData}>(),
)

export const AddCategorySuccess = createAction(
    '[Category] Add Category Success',
    props<{message: string}>(),
)

export const AddCategoryError = createAction(
    '[Category] Add Category Error',
    props<{message: string}>(),
)

export const EditCategory = createAction(
    '[Category] Edit Category',
    props<{formData: FormData, categoryID: string}>(),
)

export const EditCategorySuccess = createAction(
    '[Category] Edit Category Success',
    props<{message: string}>(),
)

export const EditCategoryError = createAction(
    '[Category] Edit Category Error',
    props<{message: string}>(),
)

export const UpdateCategoriesStatus = createAction(
    '[Category] Update Category Status',
    props<{categoryIDs: number[], status: string}>(),
)

export const UpdateCategoriesStatusSuccess = createAction(
    '[Category] Update Category Status Success',
    props<{categories: CategoryResponse[], message: string}>(),
)

export const UpdateCategoriesStatusError = createAction(
    '[Category] Update Category Status Error',
    props<{message: string}>(),
)

export const CheckPreCode = createAction(
    '[Category] Check Category',
    props<{preCode: string}>(),
)

export const CheckPreCodeSuccess = createAction(
    '[Category] Check Category Success',
    props<{preCodeExists: boolean}>(),
)

export const CheckPreCodeError = createAction(
    '[Category] Check Category Error',
    props<{message: string}>(),
)
