import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageHeadComponent } from '../staticPageHead/staticPageHead.component';
import { TranslateModule } from '@ngx-translate/core';
import { StaticPageService } from '../StaticPageService';

@Component({
  selector: 'panjab-digi-lib-acknowledgements',
  standalone: true,
  imports: [CommonModule, StaticPageHeadComponent, TranslateModule],
  templateUrl: './acknowledgements.component.html',
  styleUrl: './acknowledgements.component.scss',
})
export class AcknowledgementsComponent {
  donations_bw_50000_100000:any = null;
  donations_above_100000:any = null;
  years: any = [];

  constructor(private staticPageService: StaticPageService) {}
  ngOnInit(): void {
    this.staticPageService.getDonations(100000).subscribe((data: any) => {
      const yearWiseResp: any = {};
      data?.body?.map((obj: any) => {
        if (yearWiseResp[obj.DonationYear]) {
          yearWiseResp[obj.DonationYear].push(obj);
        } else {
          yearWiseResp[obj.DonationYear] = [obj];
        }
      });
      this.donations_above_100000 = yearWiseResp;
    });
    this.staticPageService
      .getDonations(50000, 100000)
      .subscribe((data: any) => {
        const yearWiseResp: any = {};
        data?.body?.map((obj: any) => {
          if (yearWiseResp[obj.DonationYear]) {
            yearWiseResp[obj.DonationYear].push(obj);
          } else {
            yearWiseResp[obj.DonationYear] = [obj];
          }
        });
        this.donations_bw_50000_100000 = yearWiseResp;
      });
  }
  getListOfYears = ():any => {
    const date = new Date();
    this.years = [];
    for (let year = date.getFullYear(); year > 2000; year--) {
      this.years.push(year);
    }
    return this.years || []
  };
}
