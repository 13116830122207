<section class="donation-form">
    <div class="container reg-container">
        <div class="row d-flex">
            <!-- Column 1 - Static Content -->
            <div class="col-md-5 column-1">
                <div class="content fw-normal text-justify">
                    <!-- Hide breadcrumbs in mobile view -->
                    <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-md-block d-none">
                        <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                    </div>
                    <h5 class="fw-bold">{{'pdl-website.forms.donation.sidebar.title' | translate }}</h5>
                    <div class="static-txt" [innerHtml]="'pdl-website.forms.donation.sidebar.content' | translate">
                    </div>
                </div>
            </div>
            <!-- Column 2 -->
            <div class="col-md-7 column-2">
                <div class="right-section">
                    <div class="top-content">
                        <div class="header-title">
                            <!-- Show breadcrumbs in mobile view -->
                            <div
                                class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-block d-md-none">
                                <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                            </div>
                            <div class="title-txt mt-4">
                                <h5 class="fw-bolder">{{'pdl-website.forms.donation.title' | translate }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="form-section">
                        <!-- show alert -->
                        <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
                        <!-- /show alert -->
                        <div class="row mt-4">
                            <div class="col-md-7 col-sm-12 mx-auto">
                                <label for="country" class="form-label reqd">
                                    {{'pdl-website.forms.labels.country' | translate }}
                                </label>
                                <select class="form-select" id="country" [(ngModel)]="selectedCountry">
                                    <option value="">Select</option>
                                    @for(country of countries; track country.CountryID) {
                                    <option [value]="country?.CountryID">{{country?.Name}}</option>
                                    }
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div class="row my-4">
                            <div class="col-md-12 text-center">
                                <!-- show razor pay button for India -->
                                <form id="razor-pay-btn-wrapper" [hidden]="!selectedCountry || selectedCountry!=='1'">
                                    <!-- Razor pay JS will be loaded here dynamically -->
                                </form>
                                <div id="donorbox-btn-wrapper">
                                    <!-- show donorbox button for outside India -->
                                    @if(selectedCountry && selectedCountry!=='1') {
                                    <button type="button" class="btn btn-primary mt-0" data-bs-toggle="modal"
                                        data-bs-target="#donorboxIframe">
                                        {{'pdl-website.donateTitle.titleText' | translate}}
                                    </button>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 bg-gray px-5 py-4">
                        <div class="text-center">
                            <p class="mb-0 px-4 form-footer-text">
                                {{'pdl-website.forms.donation.donationFormHelpText' | translate}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Modal -->
<div class="modal fade db-iframe-modal" id="donorboxIframe" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-content">
                <iframe [src]="donorBoxiframeSrc" name="donorbox" allowpaymentrequest="allowpaymentrequest"
                    seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%"
                    style="max-width: 500px; min-width: 250px; max-height:none!important" allow="payment"></iframe>
            </div>
        </div>
    </div>
</div>