<ng-container *ngIf="alert$ | async as alert">
    @if(alert.type && alert.message) {
    <div class="alert__box d-flex p-3 alert" [ngClass]="{'alert__success': alert.type === 'success', 'alert__error': alert.type === 'error', 'alert__warning': alert.type === 'warning'}">
        <div class="alert__msg me-2">{{ alert.message }}</div>
        <div class="alert__close">
            <a href="javascript:void(null);" (click)="close()">
                <img [src]="'assets/images/icons/close-' + alert.type + '-alert.svg'" alt="close-alert" />
            </a>
        </div>
    </div>
    }
</ng-container>
