/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { Document } from "shared/src/interfaces/web-doc-manager.interface";

export const LoadDocuments = createAction(
    '[AdminWebDocMngr] Load Documents',
    props<{payload: GenericListingPayload}>(),
)

export const LoadDocumentsSuccess = createAction(
    '[AdminWebDocMngr] Load Documents Successfully',
    props<{documentList: Document[], totalRecords: number}>()
)

export const LoadDocumentsError = createAction(
    '[AdminWebDocMngr] Load Documents Error',
    props<{message: string}>()
)

export const UpdateDocumentStatus = createAction(
    '[AdminWebDocMngr] Update Web Documents Status',
    props<{ids: number[], status: number}>(),
)

export const UpdateDocumentStatusSuccess = createAction(
    '[AdminWebDocMngr] Update Web Documents Status Success',
    props<{documentsList: any, message: string}>(),
)

export const UpdateDocumentStatusError = createAction(
    '[AdminWebDocMngr] Update Web Documents Status Error',
    props<{message: string}>(),
)