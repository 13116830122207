import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromArticleComment from "../reducers/admin-article-comment.reducer";

export const selectArticleCommentState =
  createFeatureSelector<fromArticleComment.State>('articleCommentList');

export const getArticleComments = createSelector(
    selectArticleCommentState,
    fromArticleComment.selectAll
);

export const getTotalRecords = createSelector(
  selectArticleCommentState,
  (state) => state.totalRecords
);

export const getArticleCommentByID = (props: {id: string}) => createSelector(
  selectArticleCommentState,
  (state) => state.entities[props.id]
);

export const getListingPayload = createSelector(
  selectArticleCommentState,
  (state) => state.payload
);

export const onSave = createSelector(
  selectArticleCommentState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);