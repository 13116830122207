import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAdminDownload from '../reducers/admin-download-reducer';

export const selectState =
  createFeatureSelector<fromAdminDownload.State>('adminDownload');

export const getAccession = createSelector(
  selectState,
  fromAdminDownload.selectAll
);

export const isLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const onSave = createSelector(selectState, (state) => [
  state.isError,
  state.errorMessage,
  state.successMessage,
  state.isLoading,
]);

export const getAccessionInfo = createSelector(selectState, (state) => ({
  accessionDetail: fromAdminDownload.selectAll(state),
}));

export const getDownloadList = createSelector(selectState, (state) => {
  return {
    downloadList: fromAdminDownload.selectAll(state),
    totalRecords: state.totalRecords,
  };
});

// Define selector to retrieve download list for a specific page
// export const getDownloadList = (pageNumber: number) => createSelector(
//   selectState,
//   (state:any) => {
//     const downloadListsByPage = state.downloadListsByPage;
//     return {
//     downloadList: downloadListsByPage[pageNumber] || [],
//     totalRecords: state.totalRecords,
//   };
//   }
// );

export const selectedAccession = (ID: number) =>
  createSelector(selectState, (state: any) => {
    return state?.entities[ID];
  });
