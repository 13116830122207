import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { WebUserActions } from '../actions/index';
import { UserService } from 'shared/src/services/website/user/user.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';

@Injectable()
export class WebUserEffects {
  constructor(private actions$: Actions, private userService: UserService, private router: Router, private localStorageService: LocalStorageService) { }

  sendWebUserPasswordRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[WebUser] Recover Password Request'),
      switchMap(({ email, cpt }) =>
        this.userService.sendPasswordRequest({email,cpt}).pipe(
          map((res) => {
              return WebUserActions.recoverPasswordRequestSuccess({ message: res.message });
          }),
          catchError((error: any) => of(WebUserActions.recoverPasswordRequestError({ error: (error?.error?.error) })))
        )
      )
    )
  );

  validateResetPasswdRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[WebUser] Validate Web User Reset Password Request'),
      switchMap(({ token }) =>
        this.userService.validateResetPasswdRequest({token}).pipe(
          map((res) => {
            return WebUserActions.validateResetPasswdRequestSuccess({ status: res.body.status, message: res.body.message })
          }),
          catchError((error: any) => of(WebUserActions.validateResetPasswdRequestError({ error: (error?.error?.error) })))
        )
      )
    )
  );

  resetAccountPasswd$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[WebUser] Reset Password Request'),
      switchMap(({ token, password }) =>
        this.userService.resetAccountPasswd({token, password}).pipe(
          map((res) => {
              return WebUserActions.resetAccountPasswdSuccess({ message: res.message })
          }),
          catchError((error: any) => of(WebUserActions.resetAccountPasswdError({ error: (error?.error?.error) })))
        )
      )
    )
  );

  sendWebUserUsernameRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[WebUser] Forgot Username Request'),
      switchMap(({ email, cpt }) =>
        this.userService.sendUsernameRequest({email, cpt}).pipe(
          map((res) => {
              return WebUserActions.forgotUsernameSuccess({ message: res.message });
          }),
          catchError((error: any) => of(WebUserActions.forgotUsernameError({ error: (error?.error?.error) })))
        )
      )
    )
  );
}