/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'shared/src/environments/environment';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { ArticleComment } from 'shared/src/interfaces/master-form.interface';

@Injectable({
  providedIn: 'root'
})
export class ArticleCommentsService {
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadArticleComment(payload: GenericListingPayload): Observable<{articleCommentsList: ArticleComment[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.articleComment.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }

  updateArticleCommentStatus(articleCommentIDs: number[], status: string, comment: string) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.articleComment.updateArticleCommentStatus}`, {id: articleCommentIDs, status, comment}).pipe(map((e: any) => e));
  }
  
  downloadArticleComments() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.articleComment.downloadArticleComments}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }  
}
