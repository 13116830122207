import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { AddEditCurrencyPayload, Currency } from "shared/src/interfaces/master-form.interface";

export const LoadCurrency = createAction(
    '[Currency] Load Currency',
    props<{payload: GenericListingPayload}>(),
)

export const LoadCurrencySuccess = createAction(
    '[Currency] Load Currency Successfully',
    props<{currencies: Currency[], totalRecords: number}>()
)

export const LoadCurrencyError = createAction(
    '[Currency] Load Currency Error',
    props<{message: string}>()
)

export const UpdateCurrencyStatus = createAction(
    '[Currency] Update Currency Status',
    props<{currencyIDs: number[], status: string}>(),
)

export const UpdateCurrencyStatusSuccess = createAction(
    '[Currency] Update Currency Status Success',
    props<{currencyUpdates: any, message: string}>(),
)

export const UpdateCurrencyStatusError = createAction(
    '[Currency] Update Currency Status Error',
    props<{message: string}>(),
)

export const AddCurrency = createAction(
    '[Currency] Add Currency',
    props<{payload: AddEditCurrencyPayload}>(),
)

export const AddCurrencySuccess = createAction(
    '[Currency] Add Currency Success',
    props<{currency: Currency, message: string}>(),
)

export const AddCurrencyError = createAction(
    '[Currency] Add Currency Error',
    props<{message: string}>(),
)

export const EditCurrency = createAction(
    '[Currency] Edit Currency',
    props<{payload: AddEditCurrencyPayload, subjectId: string}>(),
)

export const EditCurrencySuccess = createAction(
    '[Currency] Edit Currency Success',
    props<{currency: Currency, message: string}>(),
)

export const EditCurrencyError = createAction(
    '[Currency] Edit Currency Error',
    props<{message: string}>(),
)

export const DeleteCurrency = createAction(
    '[Currency] Delete Currency',
    props<{currencyIDs: number[]}>(),
)

export const DeleteCurrencySuccess = createAction(
    '[Currency] Delete Currency Success',
    props<{message: string}>(),
)

export const DeleteCurrencyError = createAction(
    '[Currency] Delete Currency Error',
    props<{message: string}>(),
)