import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { AdminDownloadService } from 'shared/src/services/admin/download/admin-download.service';
import { AdminDownloadActions } from '../actions/index';

@Injectable()
export class AdminDownloadEffects {
  constructor(
    private actions$: Actions,
    private adminDownloadService: AdminDownloadService
  ) {}

  //////////////// add to list ////////////
  AddDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminDownloadActions.AdminAddDownload),
      exhaustMap((action) => {
        return this.adminDownloadService.download(action?.payload).pipe(
          map((response: any) => {
            return AdminDownloadActions.AdminAddDownloadSuccessfully({
              download: response?.body,
              message: response?.message,
            });
          }),
          catchError((error: any) =>
            of(
              AdminDownloadActions.AdminAddDownloadError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// get accession info ////////////
  LoadDownloadList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminDownloadActions.LoadDownloadList),
      exhaustMap((action) => {
        return this.adminDownloadService.getDownloadList(action?.payload).pipe(
          map((response: any) => {
            return AdminDownloadActions.LoadDownloadListSuccessfully({
              downloadList: response?.body.downloadList,
              totalRecords: response?.body?.totalRecords,
              pageNumber: action?.payload?.pageNumber,
            });
          }),
          catchError((error: any) =>
            of(
              AdminDownloadActions.LoadDownloadListError({
                message: error?.message,
              })
            )
          )
        );
      })
    )
  );
  //////////////// edit download item //////////////
  EditDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminDownloadActions.AdminEditDownload),
      exhaustMap((action) => {
        return this.adminDownloadService.editDownload(action?.payload).pipe(
          map((response: any) => {
            return AdminDownloadActions.AdminEditDownloadSuccessfully({
              download: response?.body,
              message: response?.message,
            });
          }),
          catchError((error: any) =>
            of(
              AdminDownloadActions.AdminEditDownloadError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );
  /////////////// update enable/disable status of download items ///////////
  UpdateDownloadStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminDownloadActions.UpdateDownloadStatus),
      exhaustMap((action) => {
        return this.adminDownloadService
          .updateDownloadItemStatus(action?.payload)
          .pipe(
            map((response: any) => {
              return AdminDownloadActions.UpdateDownloadStatusSuccess({
                download: response.body,
                message: response.message,
              });
            }),
            catchError((error: any) =>
              of(
                AdminDownloadActions.UpdateDownloadStatusError({
                  message: error?.error?.error?.message
                    ? error?.error?.error?.message
                    : error?.message,
                })
              )
            )
          );
      })
    )
  );

  ///////////// reorder download //////////////////////
  ReorderDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminDownloadActions.ReorderDownload),
      exhaustMap((action) => {
        return this.adminDownloadService
          .sortDownloadItem(action?.id,action?.toOrder)
          .pipe(
            map((response: any) => {
              return AdminDownloadActions.ReorderDownloadSuccess({ message: response?.message });
            }),
            catchError((error: any) => of(AdminDownloadActions.ReorderDownloadError(error?.error?.error?.message))))

      })
    )
  );
}
