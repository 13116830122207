import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState, Update } from "@ngrx/entity";
import { MetaDataActions } from '../actions';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { MetaData } from 'shared/src/interfaces/metadata.interface';

export interface State extends EntityState<any> {
    payload: GenericListingPayload,
    totalRecords: number,
    isLoading?: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<MetaData>({
    selectId: (e) => e.MetaID
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(MetaDataActions.LoadMetaData, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(MetaDataActions.LoadMetaDataSuccess, (state, action) =>
    ({
        ...adapter.setAll(action.metaData, state),
        totalRecords: action.totalRecords,
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(MetaDataActions.LoadMetaDataError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(MetaDataActions.UpdateMetaDataStatus, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(MetaDataActions.UpdateMetaDataStatusSuccess, (state, action) =>

    ({
        ...adapter.updateMany(action.metaDataUpdates, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(MetaDataActions.UpdateMetaDataStatusError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(MetaDataActions.AddMetaData, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(MetaDataActions.AddMetaDataSuccess, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(MetaDataActions.AddMetaDataError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(MetaDataActions.EditMetaData,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(MetaDataActions.EditMetaDataSuccess,(state, action) =>
     ({
        ...adapter.updateOne({id: action.metaData.MetaID, changes: action.metaData}, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
      })
  ),
  on(MetaDataActions.EditMetaDataError,(state, action) => 
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
  on(MetaDataActions.DeleteMetaData, (state, action) =>
  ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
  })
  ),
  on(MetaDataActions.DeleteMetaDataSuccess, (state, action) =>
  ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action.message
  })
  ),
  on(MetaDataActions.DeleteMetaDataError, (state, action) =>
  ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
  })
  ),
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();