<div class="modal-wrapper">
  <div class="modal_container">
    <div class="closebtn cursor-pointer">
      X
    </div>
    <div class="pt-5 pb-3">
      <div class="semibold text-center heading mb-2">Assign Literature to a Research Topic</div>
      <div class="subheading text-center mb-3">Choose research topics for this literature.</div>
      <div class="px-5 mb-2">
        <select class="form-select sort__filter" aria-label="Default select example">
          <option selected>All Category</option>
          <option value="1">XYZ</option>
        </select>
      </div>
      <div class="text-center"><a class="text-center newlink mx-auto cursor-pointer">
          Create New Research Topic
        </a>
      </div>
      <div class="text-center d-flex mx-auto mt-3 justify-content-center col-gap-12">
        <button class="btn btn__secondary">Cancel</button>
        <button class="btn btn__primary">Save</button>
      </div>
    </div>
  </div>
</div>
