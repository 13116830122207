/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { AdminVideoGalleryActions } from '../actions/index';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { TemplateVideo, Video } from 'shared/src/interfaces/video-gallery.interface';

export interface State extends EntityState<Video> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  totalRecords: number;
  pageNumber : number,
  pageSize: number,
  templateVideos : TemplateVideo[] 
}

export const adapter = createEntityAdapter<Video>({
  selectId: (e) => e?.ID,
});

export const initialState : State = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0,
  pageNumber : 0,
  pageSize: 0,
  templateVideos : []
});

export const reducer = createReducer(
  initialState,

  on(AdminVideoGalleryActions.LoadVideoGallery, (state,action) => ({
    ...state,
    isLoading: true,
    successMessage: '',
    errorMessage: '',
    pageNumber: action.payload?.pageNumber || 1,
    pageSize: action.payload?.pageSize || 0,
  })),

  on(AdminVideoGalleryActions.LoadVideoGallerySuccessfully, (state, action) =>
    adapter.setAll(action?.videoList, {
      ...state,
      totalRecords: action.totalRecords,
      isLoading: false,
    })
  ),

  on(AdminVideoGalleryActions.LoadVideoGalleryError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminVideoGalleryActions.AddVideo, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminVideoGalleryActions.AddVideoSuccessfully, (state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: action?.message
    })
  ),

  on(AdminVideoGalleryActions.AddVideoError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminVideoGalleryActions.UpdateVideo, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminVideoGalleryActions.UpdateVideoSuccessfully, (state, action) => ({
    ...adapter.updateOne(
      { id: action?.video?.ID, changes: action.video },
      state
    ),
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: action?.message,
  })),

  on(AdminVideoGalleryActions.UpdateVideoError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      successMessage: '',
      errorMessage: action?.message,
    };
  }),

  on(AdminVideoGalleryActions.UpdateVideoStatus, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminVideoGalleryActions.UpdateVideoStatusSuccessfully, (state, action) => {
    const updates = action?.video;
    const updatedEntities = adapter.updateMany(
      updates.map((update: Video) => ({
        id: update.ID,
        changes: update,
      })),
      state
    );
    return {
      ...updatedEntities,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action?.message,
    };
  }),

  on(AdminVideoGalleryActions.UpdateVideoStatusError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),

  on(AdminVideoGalleryActions.DeleteVideo, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminVideoGalleryActions.DeleteVideoSuccessfully, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: false,
      successMessage: action?.message,
      errorMessage: '',
    };
  }),

  on(AdminVideoGalleryActions.DeleteVideoError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),

  on(AdminVideoGalleryActions.AddTemplateVideo, (state:any, action:any) => {
    const existingIndex = state.templateVideos.findIndex((video: TemplateVideo) => video.HomepageOrder === action.payload.HomepageOrder);
    if (existingIndex !== -1) {
      const updatedVideos = [...state.templateVideos];
      updatedVideos[existingIndex] = action.payload;
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
        templateVideos: updatedVideos,
      };
    } else if(action?.payload?.length === 0) {
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
        templateVideos: action.payload,
      };
    } else {
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
        templateVideos: [...state.templateVideos, action.payload],
      };
    }
  }),

  on(AdminVideoGalleryActions.UpdateTemplateVideo, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminVideoGalleryActions.UpdateTemplateVideoSuccessfully, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: false,
      successMessage: action?.message,
      errorMessage: '',
    };
  }),

  on(AdminVideoGalleryActions.UpdateTemplateVideoError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),
);

export const { selectAll } = adapter.getSelectors();
