import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { StaffWorkActions } from 'shared/src/store/actions';
import { TaskService } from 'shared/src/services/staff/task.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { StaffWorkSelector } from '../../selectors';

@Injectable()
export class StaffWorkEffects {
  constructor(
    private actions$: Actions, 
    private taskService: TaskService,
    private store: Store<AppState>
  ) {}
  LoadTaskList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffWorkActions.LoadTaskList),
      switchMap(({ payload }) =>
        this.taskService.loadStaffWork(payload).pipe(
          map((response) => {
            return StaffWorkActions.LoadTaskListSuccess({ 
              taskList: response.taskList, 
              totalRecords: response.totalRecords
            })
          }),
          catchError((error: any) => {
            return of(StaffWorkActions.LoadTaskListError({ message: (error.message) }))
          })
        )
      )
    )
  );

  ApproveTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffWorkActions.ApproveTask),
      switchMap(({ PSID }) =>
        this.taskService.approveTask(PSID).pipe(
          map((response) => {
            return StaffWorkActions.ApproveTaskSuccess({ 
              task: response.body.task,
              message: response.message
            })
          }),
          catchError((error: any) => {
            return of(StaffWorkActions.ApproveTaskError({ message: (error.message) }))
          })
        )
      )
    )
  );

  RejectTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffWorkActions.RejectTask),
      switchMap(({ PSID, reason }) =>
        this.taskService.rejectTask(PSID, reason).pipe(
          map((response) => {
            return StaffWorkActions.RejectTaskSuccess({ 
              task: response.body.task,
              message: response.message
            })
          }),
          catchError((error: any) => {
            return of(StaffWorkActions.RejectTaskError({ message: (error.message) }))
          })
        )
      )
    )
  );
  
  OnApproveRejectTaskSuccess = createEffect(() => 
    this.actions$.pipe(
      ofType(StaffWorkActions.ApproveTaskSuccess, StaffWorkActions.RejectTaskSuccess),
      switchMap(() => {
        return this.store.select(StaffWorkSelector.getTaskList).pipe(
          take(1),
          switchMap((tasks) => {
              return this.store.select(StaffWorkSelector.getListingPayload).pipe(
                take(1),
                map(payload => {
                  return StaffWorkActions.LoadTaskList({ payload });
                })
              );
          })
        )
      }),
    )
  )
}
