<section class="d-flex">
  <div class="form">
    <div class="innerpage-banner" style="background: none;">
      <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
        <a class="breadcrumb-link font-regular" href="#">
          <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
          Home
        </a>
        <span class="chevron-arrow font-regular">
          <img src="assets/images/icons/arrow-right.svg" alt="icon">
        </span>
        <a class="breadcrumb-link font-regular" href="/adopt-a-book">
          adopt a book
        </a>
        <span class="chevron-arrow font-regular">
          <img src="assets/images/icons/arrow-right.svg" alt="icon">
        </span>
        <a class="breadcrumb-link font-regular" href="/adopt-a-book/bk-001">
          bk-001
        </a>
      </div>
      <div class="greenBorderText mb-2 heading"><b>ADOPT A BOOK</b> </div>
      <div class="pl-12 text mb-5">Please fill in the required correct information in the form below. After reviewing
        your
        exhibition request
        whatever the decision is, it will be informed to you within a week time. <sup style="color: red">*</sup>
        indicates required fields</div>
      <div class="seperatorLine"></div>
      <div class="pl-12 heading mb-4"><b>Your Selection for adoption below:</b></div>
      <div class="que mb-2 pl-12">What is your status?<sup style="color: red">*</sup></div>
      <div class="pl-12 d-flex col-gap-56 flex-wrap mb-4">
        <div class="text-start form-check">
          <input type="radio" class="form-check-input" value="individual" aria-label="Individual">
          <label for="deactivateAcc" class=" ">Individual</label>
        </div>
        <div class="text-start form-check">
          <input type="radio" class="form-check-input" value="Organization" aria-label="Organization">
          <label for="deactivateAcc" class=" ">Organization</label>
        </div>
      </div>
      <div class="mb-4 d-grid">
        <div class="d-grid eq-grid">
          <div class="d-flex flex-column">
            <div class="que mb-2 pl-12">Name</div>
            <div class="plr-12"><input type="text" class="form-control search__input disabled "
                id="exampleFormControlInput1" placeholder=""></div>
          </div>


          <div class="d-flex flex-column">
            <div class="que mb-2 pl-12">Email</div>
            <div class="plr-12"><input type="text" class="form-control search__input disabled "
                id="exampleFormControlInput1" placeholder=""></div>
          </div>
        </div>

      </div>
      <div class="mb-4">
        <div class="que mb-2 pl-12">City/State/Zip Code</div>
        <div class="plr-12"><input type="text" class="form-control search__input disabled "
            id="exampleFormControlInput1" placeholder=""></div>
      </div>
      <div class="mb-4">
        <div class="que mb-2 pl-12">Country</div>
        <div class="plr-12">
          <select class="form-control form-select search__input sort__filter" aria-label="Default select example">
            <option selected>Country</option>
            <option value="1">XYZ</option>
          </select>
        </div>
      </div>
      <div class="mb-4">
        <div class="que mb-2 pl-12">Remarks</div>
        <div class="plr-12"><input type="text" class="form-control search__input disabled "
            id="exampleFormControlInput1" placeholder=""></div>
      </div>
      <div class="pl-12 d-flex col-gap-12 mb-5 que">
        <input class="form-check-input" type="checkbox" id="switchShow">
        <span>Addess Saved for Later</span>
      </div>
      <div class="d-flex justify-content-end plr-12 mt-5">
        <a href="" class="btn btn__primary">Save for Later</a>
      </div>

    </div>
  </div>
  <div class="cart desktop-only innerpage-banner">
    <div class="cart-wrapper no-scrollbar">
      <!-- // loop through cart itmes here -->
      @for (arr of dummyArr;track arr) {

      <div class="cart-item">
        <div class="d-flex col-gap-12">
          <div class="img"><img class="img" src="/assets/images/adopt-book1.jpg" />
            <div class="bin-icon">
              <img class="bin-icon cursor-pointer" src="/assets/images/icons/bin-green.svg" alt="">
            </div>
          </div>
          <div class="info d-flex flex-column "><span class="semibold mb-2">Rural Rehabilitation in East Punjab and
              farmers
              and the struggles </span>
            <div class="d-flex flex-wrap justify-content-between">
              <div class="meta-btn">
                <span>
                  <img src="/assets/BookReader/icons/book-icon.svg" />
                  Books
                </span>
              </div>
              <div class="d-flex flex-grow-1 align-items-center justify-content-end">
                <img src="/assets/images/icons/pen-ico.svg" /><span class="author_name ">Sodhi Narayan Singh</span>
              </div>
            </div>
          </div>
          <div class="price  flex-grow-1  d-flex justify-content-end">$27 </div>
        </div>
      </div>
      }
      <!-- // loop ends here   -->
      <!-- // total block starts -->
      <div class="total_block">
        <div class="d-flex justify-content-between mb-5">
          <span class="semibold fs-20">Total Amount</span>
          <div class="price  flex-grow-1  d-flex justify-content-end">$27 </div>
        </div>
        <div class=" btn_wrapper ">
          <button class="btn btn__secondary">Reset</button>
          <button class="btn btn__primary">Pay Now</button>
        </div>
      </div>
      <!-- total block ends -->
    </div>
  </div>
</section>
@if (!mobileFullCartView) {
<div class="mob-only mob-cart">
  <div class="d-flex justify-content-center pt-2" (click)="toggleCartView()">
    <img src="/assets/images/icons/chevron-double-up.svg" class="updown" />
  </div>

  <div class="d-flex bottom_cart_overview align-items-center plr-12 justify-content-between">
    <h3 class="semibold">3 items (Total $90)</h3>
    <h3 class="semibold cursor-pointer">Checkout <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
        fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd"
          d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
        <path fill-rule="evenodd"
          d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
      </svg></h3>
  </div>
</div>
} @else {
<!-- //repeated for mobile -->
<div class=" mob-only opened-mobile-cart-wrapper-container">
  <div class="opened-mobile-cart mb-3">
    <div class="d-flex justify-content-center pt-2" (click)="toggleCartView()">
      <img src="/assets/images/icons/chervon-double-down.svg" class="updown" />
    </div>

    <div class="d-flex bottom_cart_overview align-items-center plr-12 justify-content-between">
      <h3 class="semibold">3 items (Total $90)</h3>
      <h3 class="semibold cursor-pointer">Checkout <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
          fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
          <path fill-rule="evenodd"
            d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
        </svg></h3>
    </div>
  </div>
  <div class="cart-wrapper opened-mobile-cart-wrapper no-scrollbar">
    <!-- // loop through cart itmes here -->
    @for (arr of dummyArr;track arr) {
    <div class="cart-item">
      <div class="d-flex col-gap-12">
        <div class="img"><img class="img" src="/assets/images/adopt-book1.jpg" />
          <div class="bin-icon">
            <img class="bin-icon cursor-pointer" src="/assets/images/icons/bin-green.svg" alt="">
          </div>
        </div>
        <div class="info d-flex flex-column "><span class="semibold mb-2">Rural Rehabilitation in East Punjab and
            farmers
            and the struggles </span>
          <div class="">
            <div class="meta-btn">
              <span>
                <img src="/assets/BookReader/icons/book-icon.svg" />
                Books
              </span>
            </div>
            <div class="d-flex flex-grow-1 align-items-center ">
              <img src="/assets/images/icons/pen-ico.svg" /><span class="author_name ">Sodhi Narayan Singh</span>
            </div>
          </div>
        </div>
        <div class="price  flex-grow-1  d-flex justify-content-end">$27 </div>
      </div>
    </div>
    }
    <!-- // loop ends here   -->
    <!-- // total block starts -->
    <div class="total_block">
      <div class="d-flex justify-content-between mb-5">
        <span class="semibold fs-20">Total Amount</span>
        <div class="price  flex-grow-1  d-flex justify-content-end">$27 </div>
      </div>
      <div class=" btn_wrapper ">
        <button class="btn btn__secondary">Reset</button>
        <button class="btn btn__primary">Pay Now</button>
      </div>
    </div>
    <!-- total block ends -->
  </div>
</div>
}
