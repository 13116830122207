import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromAdminVolunteerRequests from "../reducers/admin-volunteer-requests.reducer";

export const selectRequestState =
  createFeatureSelector<fromAdminVolunteerRequests.State>('adminVolunteerRequests');

export const getRequests = createSelector(
  selectRequestState,
  fromAdminVolunteerRequests.selectAll
);

export const getTotalRecords = createSelector(
  selectRequestState,
  (state) => state.totalRecords
);

export const isLoading = createSelector(
  selectRequestState,
  (state) => state.isLoading
);

export const isSaving = createSelector(
  selectRequestState,
  (state) => state.isSaving
);

export const isSaved = createSelector(
  selectRequestState,
  (state) => state.isSaved
);

export const onSave = createSelector(
  selectRequestState,
  (state) => [state.isError, state.errorMessage, state.successMessage, state.isLoading],
);