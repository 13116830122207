import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromAdminWebDocMngr from "../reducers/admin-web-doc-manager.reducer";

export const selectDocumentState =
  createFeatureSelector<fromAdminWebDocMngr.State>('adminWebDocMngr');

export const getDocuments = createSelector(
  selectDocumentState,
  fromAdminWebDocMngr.selectAll
);

export const getTotalRecords = createSelector(
  selectDocumentState,
  (state) => state.totalRecords
);

export const isLoading = createSelector(
  selectDocumentState,
  (state) => state.isLoading
);

export const onSaveDocument = createSelector(
  selectDocumentState,
  (state) => [state.isError, state.errorMessage, state.successMessage, state.isLoading],
);