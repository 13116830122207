/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WebVideosActions } from '../actions';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { VideoService } from 'shared/src/services/website/videos/video.service';

@Injectable()
export class WebVideosEffects {

  constructor(
    private actions$: Actions,
    private videoservice: VideoService
  ) {}
  /////////////////// load video gallery videos ///////////////////
  LoadWebVideos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebVideosActions.LoadWebVideos),
      exhaustMap(action =>
        this.videoservice.loadAllVideos(action?.payload).pipe(
          map((response) => {
            return WebVideosActions.LoadWebVideosSuccess({
              videos: response?.body?.videoList,
              totalRecords : response?.body.totalRecords || 0
            });
          }),
          catchError((error) => of(WebVideosActions.LoadWebVideosError(error))))
      )
    )
  );
}