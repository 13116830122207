/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, CountryActions, CountrySelector } from '@panjab-digi-lib/shared';
import { Update } from '@ngrx/entity';
import { Country } from 'shared/src/interfaces/master-form.interface';
import { CountryService } from 'shared/src/services/admin/master-form/country.service';

@Injectable()
export class CountryEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private countryService: CountryService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadCountry$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountryActions.LoadCountry),
            exhaustMap((action) => {

                return this.countryService.loadCountry(action.payload).pipe(
                    map((response) => {
                        return CountryActions.LoadCountrySuccess({ countries: response.countries, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(CountryActions.LoadCountryError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    LoadActiveCountryList$ = createEffect(() =>
      this.actions$.pipe(
          ofType(CountryActions.LoadActiveCountryList),
          exhaustMap((action) => {

              return this.countryService.loadActiveCountryList(action.payload).pipe(
                  map((response) => {
                      return CountryActions.LoadActiveCountryListSuccess({ countryList: response.countryList, totalRecords: response.totalCount });
                  }),
                  catchError((error: any) => of(CountryActions.LoadCountryError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                  ))
          })
      )
    );
    
    UpdateCountryStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountryActions.UpdateCountryStatus),
            exhaustMap((action) => {

                return this.countryService.updateCountryStatus(action.countryIDs, action.status).pipe(
                    map((response: any) => {
                        const countryUpdates: Update<Country>[] = action.countryIDs.map((id) => {
                            return {
                                id, changes: {
                                   Status : action.status
                                }
                            }
                        })

                        return CountryActions.UpdateCountryStatusSuccess({ countryUpdates, message: response.message });
                    }),
                    catchError((error: any) => of(CountryActions.UpdateCountryStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    AddCountry$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountryActions.AddCountry),
            exhaustMap((action) => {

                return this.countryService.addCountry(action.payload).pipe(
                    map((response) => {

                        return CountryActions.AddCountrySuccess({ country: response.body, message: response.message });
                    }),
                    catchError((error: any) => of(CountryActions.AddCountryError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    EditCountry$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountryActions.EditCountry),
            exhaustMap((action) => {
                
                return this.countryService.editCountry(action.payload, action.subjectId).pipe(
                    map((response) => {

                        this.router.navigateByUrl('/master-forms/manage/country');
                        return CountryActions.EditCountrySuccess({country: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(CountryActions.EditCountryError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );
        
    DeleteCountry$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountryActions.DeleteCountry),
            exhaustMap((action) => {

                return this.countryService.deleteCountry(action.countryIDs).pipe(
                    map((response: any) => {

                        return CountryActions.DeleteCountrySuccess({ message: response.message });
                    }),
                    catchError((error: any) => of(CountryActions.DeleteCountryError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    AddDeleteCountrySuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountryActions.AddCountrySuccess, CountryActions.DeleteCountrySuccess),
            switchMap((action) => combineLatest([
                    of(action.type),
                    this.store.select(CountrySelector.getListingPayload).pipe(take(1))
                ])
            ),
            map(([type, payload]) => {
                
                if(type == '[Country] Add Country Success') {
                    this.router.navigateByUrl('/master-forms/manage/country');
                }
                return CountryActions.LoadCountry({ payload });
            })
        )
    );
}