import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, CurrencyActions, CurrencySelector } from '@panjab-digi-lib/shared';
import { Update } from '@ngrx/entity';
import { Currency } from 'shared/src/interfaces/master-form.interface';
import { CurrencyService } from 'shared/src/services/admin/master-form/currency.service';

@Injectable()
export class CurrencyEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private currencyService: CurrencyService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadCurrency$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CurrencyActions.LoadCurrency),
            exhaustMap((action) => {

                return this.currencyService.loadCurrency(action.payload).pipe(
                    map((response) => {
                        return CurrencyActions.LoadCurrencySuccess({ currencies: response.currencies, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(CurrencyActions.LoadCurrencyError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
    
    UpdateCurrencyStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CurrencyActions.UpdateCurrencyStatus),
            exhaustMap((action) => {

                return this.currencyService.updateCurrencyStatus(action.currencyIDs, action.status).pipe(
                    map((response: any) => {
                        const currencyUpdates: Update<Currency>[] = action.currencyIDs.map((id) => {
                            return {
                                id, changes: {
                                    Status: action.status
                                }
                            }
                        })

                        return CurrencyActions.UpdateCurrencyStatusSuccess({ currencyUpdates, message: response.message });
                    }),
                    catchError((error: any) => of(CurrencyActions.UpdateCurrencyStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    AddCurrency$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CurrencyActions.AddCurrency),
            exhaustMap((action) => {

                return this.currencyService.addCurrency(action.payload).pipe(
                    map((response) => {

                        return CurrencyActions.AddCurrencySuccess({ currency: response.body, message: response.message });
                    }),
                    catchError((error: any) => of(CurrencyActions.AddCurrencyError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    EditCurrency$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CurrencyActions.EditCurrency),
            exhaustMap((action) => {
                
                return this.currencyService.editCurrency(action.payload, action.subjectId).pipe(
                    map((response) => {

                        this.router.navigateByUrl('/master-forms/manage/currency');
                        return CurrencyActions.EditCurrencySuccess({currency: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(CurrencyActions.EditCurrencyError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );
        
    DeleteCurrency$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CurrencyActions.DeleteCurrency),
            exhaustMap((action) => {

                return this.currencyService.deleteCurrency(action.currencyIDs).pipe(
                    map((response: any) => {

                        return CurrencyActions.DeleteCurrencySuccess({ message: response.message });
                    }),
                    catchError((error: any) => of(CurrencyActions.DeleteCurrencyError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    AddDeleteCurrencySuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CurrencyActions.AddCurrencySuccess, CurrencyActions.DeleteCurrencySuccess),
            switchMap((action) => combineLatest([
                    of(action.type),
                    this.store.select(CurrencySelector.getListingPayload).pipe(take(1))
                ])
            ),
            map(([type, payload]) => {
                
                if(type == '[Currency] Add Currency Success') {
                    this.router.navigateByUrl('/master-forms/manage/currency');
                }
                return CurrencyActions.LoadCurrency({ payload });
            })
        )
    );
}