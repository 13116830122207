import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { WebAuthUserActions } from '../actions/index';
import { Router } from '@angular/router';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';

@Injectable()
export class WebAuthUserEffects {
  constructor(
    private actions$: Actions,
    private router: Router, 
    private localStorageService: LocalStorageService,
    private authService: AuthenticationService
  ) { }

  sendWebUserUpdateProfileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebAuthUserActions.updateProfileRequest),
      exhaustMap((action) =>
        this.authService.sendHttpRequest('PUT', 'profile', action.payload).pipe(
          map((res) => {
              if(res.body && Object.keys(res.body).length) {
                this.localStorageService.set('webUser', res.body);
                return WebAuthUserActions.updateProfileSuccess({ successMessage: res.message });
              } else {
                return WebAuthUserActions.updateProfileError({ errorMessage: ('User details not found')});
              }
          }),
          catchError((error: any) => of(WebAuthUserActions.updateProfileError({ errorMessage: (error?.error?.error) })))
        )
      )
    )
  );
}