import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'panjab-digi-lib-contact-us',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent {}
