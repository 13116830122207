import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromMetadataComment from "../reducers/admin-metadata-comment.reducer";

export const selectMetadataCommentState =
  createFeatureSelector<fromMetadataComment.State>('metadataCommentList');

export const getMetadataComments = createSelector(
    selectMetadataCommentState,
    fromMetadataComment.selectAll
);

export const getTotalRecords = createSelector(
  selectMetadataCommentState,
  (state) => state.totalRecords
);

export const getMetadataCommentByID = (props: {id: string}) => createSelector(
  selectMetadataCommentState,
  (state) => state.entities[props.id]
);

export const getListingPayload = createSelector(
  selectMetadataCommentState,
  (state) => state.payload
);

export const onSave = createSelector(
  selectMetadataCommentState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);