/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { AdminWebDocManagerActions } from '../actions';
import { Document } from 'shared/src/interfaces/web-doc-manager.interface';

export interface State extends EntityState<Document> {
  payload: GenericListingPayload;
  totalRecords: number;
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
}

export const adapter = createEntityAdapter<Document>({
  selectId: (e) => e.DocID,
});

export const initialState = adapter.getInitialState({
  totalRecords: 0,
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
});

export const reducer = createReducer(
  initialState,
  on(AdminWebDocManagerActions.LoadDocuments, (state, action) => ({
    ...state,
    payload: action.payload,
    isLoading: true,
    isError: false,
    errorMessage: '',
    successMessage: '',
  })),

  on(AdminWebDocManagerActions.LoadDocumentsSuccess, (state, action) => ({
    ...state,
    ...adapter.setAll(action.documentList, state),
    totalRecords: action.totalRecords,
    isLoading: false,
    isError: false,
    errorMessage: '',
    successMessage: '',
  })),

  on(AdminWebDocManagerActions.LoadDocumentsError, (state, action) => ({
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.message,
    successMessage: '',
  })),
  on(AdminWebDocManagerActions.UpdateDocumentStatus, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(AdminWebDocManagerActions.UpdateDocumentStatusSuccess, (state, action) =>
    ({
        ...adapter.updateMany(action.documentsList, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(AdminWebDocManagerActions.UpdateDocumentStatusError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
  ) 
);

export const { selectAll, selectEntities } = adapter.getSelectors();
