import { createAction, props } from '@ngrx/store';

// web recover/forgot password actions
export const recoverPasswordRequest = createAction(
  '[WebUser] Recover Password Request',
  props<{ email: string, cpt: string }>()
);

export const recoverPasswordRequestSuccess = createAction(
  '[WebUser] Recover Password Success',
  props<{ message: string }>()
);

export const recoverPasswordRequestError = createAction(
  '[WebUser] Recover Password Error',
  props<{ error: any }>()
);

// web recover/forgot password actions
export const validateResetPasswdRequest = createAction(
  '[WebUser] Validate Web User Reset Password Request',
  props<{ token: string }>()
);

export const validateResetPasswdRequestSuccess = createAction(
  '[WebUser] Validate Web User Reset Password Request Success',
  props<{ status: string, message: string }>()
);

export const validateResetPasswdRequestError = createAction(
  '[WebUser] Validate Web User Reset Password Request Error',
  props<{ error: any }>()
);
// web recover/forgot password actions
export const resetAccountPasswd = createAction(
  '[WebUser] Reset Password Request',
  props<{ token: string, password: string }>()
);

export const resetAccountPasswdSuccess = createAction(
  '[WebUser] Reset Password Request Success',
  props<{ message: string }>()
);

export const resetAccountPasswdError = createAction(
  '[WebUser] Reset Password Request Error',
  props<{ error: any }>()
);

// web forgot username actions
export const forgotUsernameRequest = createAction(
  '[WebUser] Forgot Username Request',
  props<{ email: string, cpt: string }>()
);

export const forgotUsernameSuccess = createAction(
  '[WebUser] Forgot Username Success',
  props<{ message: string }>()
);

export const forgotUsernameError = createAction(
  '[WebUser] Forgot Username Error',
  props<{ error: any }>()
);

export const resetState = createAction(
  '[WebUser] Reset User State'
)