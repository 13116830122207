/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { AdminUserServiceActions } from '../actions/index';
import { UserService } from 'shared/src/services/admin/web-user-management/user-service.service';

@Injectable()
export class AdminUserServiceEffects {
  constructor(
    private actions$: Actions,
    private service: UserService
  ) {}

  ///////////////// get users list //////////////////
  LoadUsersList$ = createEffect(() =>
    this.actions$.pipe(
        ofType(AdminUserServiceActions.UsersList),
        exhaustMap((action) => {
          return this.service.loadUserList(action?.payload).pipe(
            map((response) => {
              return AdminUserServiceActions.UsersListSuccess({
                userList: response?.userList,
                totalRecords: response?.totalRecords
              });
            }),
            catchError((error) => {
              return of(
                AdminUserServiceActions.UsersListError({
                  message: error?.error?.error?.message,
                })
              );
            })
          );
        })
      )
  );
}
