import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'panjab-digi-lib-user',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss',
})
export class UserComponent implements OnInit, OnDestroy {
  
  title: string = '';
  private unsubscribe$: Subject<void> = new Subject();
  
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.setPageTitle();
    });
    // set title on component load
    this.setPageTitle();
  }

  private setPageTitle(): void {
    // Access the data from the child route
    this.route.firstChild?.data.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.title = data['title'];
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
