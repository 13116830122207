/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { UserTypes } from 'shared/src/interfaces/master-form.interface';
export const LoadUserTypes = createAction(
  '[UserTypes] Load User Types List',
  props<{ payload: {access: number} }>()
);

export const LoadUserTypesSuccess = createAction(
  '[UserTypes] Load User Types Success',
  props<{ userTypes: UserTypes[] }>()
);

export const LoadUserTypesError = createAction(
  '[UserTypes] Load User Types Error',
  props<{ message: string }>()
);
