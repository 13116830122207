/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { MetadataCommentActions } from '@panjab-digi-lib/shared';
import { Update } from '@ngrx/entity';
import { MetadataComment } from 'shared/src/interfaces/master-form.interface';
import { MetadataCommentsService } from 'shared/src/services/admin/master-form/metadata-comments.service';
import { Router } from '@angular/router';

@Injectable()
export class MetadataCommentEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private router: Router,
        private metadataCommentService: MetadataCommentsService
    ) {
    }

    LoadMetadataComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetadataCommentActions.LoadMetadataComment),
            exhaustMap((action) => {

                return this.metadataCommentService.loadMetadataComment(action.payload).pipe(
                    map((response) => {
                        return MetadataCommentActions.LoadMetadataCommentSuccess({ metadataComments: response.metadataCommentsList, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(MetadataCommentActions.LoadMetadataCommentError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
    
    UpdateMetadataCommentStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetadataCommentActions.UpdateMetadataCommentStatus),
            exhaustMap((action) => {

                return this.metadataCommentService.updateMetadataCommentStatus(action.metadataCommentIDs, action.status, action.comment || '').pipe(
                    map((response: any) => {
                        const metadataCommentUpdates: Update<MetadataComment>[] = action.metadataCommentIDs.map((id: any) => {
                            const changes: any = {Status : action.status}
                            if(action.comment) {
                                changes.Comment = action.comment
                            }
                            return { id, changes }
                        })
                        if (action.metadataCommentIDs.length === 1 && action.comment){
                            this.router.navigateByUrl('/master-forms/comments/metadata-comments');
                        }
                        return MetadataCommentActions.UpdateMetadataCommentStatusSuccess({ metadataCommentUpdates, message: response.message });
                    }),
                    catchError((error: any) => of(MetadataCommentActions.UpdateMetadataCommentStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
}