<div class="modal-wrapper">
  <div class="modal_container">
    <div class="closebtn cursor-pointer" (click)="goback()">
      X
    </div>
    <div class="pt-5 pb-3">
      <div class="semibold text-center heading mb-2">Create New Research Topic</div>
      <div class="subheading text-center mb-3">Name Your Research Topic.</div>
      <div class="px-5 mb-3">
        <input type="text" class="form-control search__input disabled" id="exampleFormControlInput1"
          placeholder="Research Topic">
      </div>
      <div class="px-5 mb-2">
        <textarea class="form-control search__input disabled" id="exampleFormControlInput1" rows="5"
          placeholder="Note"></textarea>
      </div>
      <div class="text-center d-flex mx-auto mt-3 justify-content-center col-gap-12">
        <button class="btn btn__secondary"  (click)="goback()">Cancel</button>
        <button class="btn btn__primary">Create</button>
      </div>
    </div>
  </div>
</div>
