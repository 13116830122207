import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListModalComponent } from '../list-modal/list-modal.component';

@Component({
  selector: 'panjab-digi-lib-common-advance-search',
  standalone: true,
  imports: [CommonModule, ListModalComponent],
  templateUrl: './common-advance-search.component.html',
  styleUrl: './common-advance-search.component.scss',
})
export class CommonAdvanceSearchComponent {
  collapsedMenus: any = [];
  subjects = (() => {
    const subjs = [];
    const rand = Math.random() * 300;
    for (let i = 0; i < rand; i++) {
      const totalChars = Math.random() * 12;
      let subj = '';
      for (let j = 0; j < totalChars; j++) {
        subj =
          subj +
          String.fromCharCode(
            Math.floor(Math.floor(Math.random() * (122 - 97 + 1) + 97))
          );
      }
      subjs.push({
        id: i,
        subject: subj,
      });
    }
    return subjs;
  })();

  modalInfo: any = {
    type: '',
    modalData: [],
  };

  toggleCollapsedMenu = (menu: string) => {
    const index = this.collapsedMenus.indexOf(menu);
    if (index === -1) {
      // Number is not in the array, add it
      this.collapsedMenus.push(menu);
    } else {
      // Number is in the array, remove it
      this.collapsedMenus.splice(index, 1);
    }
  };

  isCollapsed = (menu: string) => this.collapsedMenus.includes(menu);

  getFirstel = (type: string) => {
    if (!(this as any)[type]) {
      return [];
    } else {
      return (this as any)[type].slice(0, 11);
    }
  };

  setModalInfo = (type: string) => {
    this.modalInfo = {
      type,
      modalData: this.subjects,
    };
  };
  clearModalInfo = () =>
    (this.modalInfo = {
      type: '',
      modalData: [],
    });
}
