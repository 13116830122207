import { createAction, props } from '@ngrx/store';

export const AdminAddDownload = createAction(
    '[Downloads] Admin Add Download',
    props<{payload: {data:Array<any>}}>()
);

export const AdminAddDownloadSuccessfully = createAction(
    '[Downloads] Admin Add Download Success',
    props<{ download: any,message: string }>()
);

export const AdminAddDownloadError = createAction(
    '[Downloads] Admin Add Download Error',
    props<{ message: string }>()
);

export const LoadDownloadList = createAction(
    '[Downloads] Load Download List',
    props<{payload: {pageSize:number,pageNumber: number}}>(),
);

export const LoadDownloadListSuccessfully = createAction(
    '[Downloads] Load Download List Success',
    props<{ downloadList: [],totalRecords: number, pageNumber: number }>()
);

export const LoadDownloadListError = createAction(
    '[Downloads] Load Download List Error',
    props<{ message: string }>()
);

export const AdminEditDownload = createAction(
    '[Downloads] Admin Edit Download',
    props<{payload: {data:Array<any>}}>()
);

export const AdminEditDownloadSuccessfully = createAction(
    '[Downloads] Admin Edit Download Success',
    props<{ download: any,message: string }>()
);

export const AdminEditDownloadError = createAction(
    '[Downloads] Admin Edit Download Error',
    props<{ message: string }>()
);

export const UpdateDownloadStatus = createAction(
    '[Downloads] Update Download Status',
    props<{payload: {status:any,id: any}}>()
)

export const UpdateDownloadStatusSuccess = createAction(
    '[Downloads] Update Download Status Success',
    props<{download: any, message: string}>()
)

export const UpdateDownloadStatusError = createAction(
    '[Downloads] Update Download Status Error',
    props<{message: string}>(),
)

// Reorder banners
export const ReorderDownload = createAction(
    '[Downloads] reorder',
    props<{ id: string, toOrder: number }>()
);

export const ReorderDownloadError = createAction(
    '[Downloads] Error',
    props<{ message: string }>()
);

export const ReorderDownloadSuccess = createAction(
    '[Downloads] Action Success',
    props<{ message: string }>()
);
