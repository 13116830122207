<section class="innerpage-banner" style="background: none;">
  @if(videoDetail && videoDetail.body.VideoPath) {
  <video width="100%" height="550px" style="max-height: 820px;" controls #video
  (timeupdate)="onVideoTimeUpdate(videoDetail.body.ID)"
  poster="{{baseUrl}}{{videoDetail.body.ThumbnailPath}}"
  >
    <source src="{{baseUrl}}{{videoDetail.body.VideoPath}}" type="video/mp4">
    Your browser does not support the video tag.
  </video>
}
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
          <a class="breadcrumb-link font-regular" href="#">
            <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
            Home
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular" href="/video-gallery">
            video gallery
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular" href="/video-gallery/{{videoId}}">
            {{videoDetail ? videoDetail.body.Title  :''}}
          </a>
        </div>
      </div>
    </div>

  </div>
</section>
<section>
  <div class="container">
    @if(videoDetail && videoDetail.body.Description) {
    <div class="row" style="row-gap: 2rem;">
      <div class="text-about-page">
        {{'pdl-shared.content.description' | translate}}
      </div>
      <div class="col-12 d-flex align-items-center justify-content-start">
        <div class="">
          <p class="pdl-title-color light-font">{{videoDetail ? videoDetail.body.Description : ''}} </p>
        </div>
      </div>
    </div>
  }
    @if(videoDetail && videoDetail.body.relatedVideos) {
    <div class="col-12 ">
      <div class="d-flex justify-content-start videos-in-grid col-gap-20 no-scrollbar">
         @for(video of videoDetail.body.relatedVideos;track video?.ID) { 
        <a class="videos-card" (click)="loadClickedVideo(video.ID)">
          <div class="block-img cursor-pointer" >
            <img src="{{baseUrl}}{{video?.ThumbnailPath}}" alt="">
          </div>
          <div class="block-text-bar justify-content-between align-items-center">
            <h6 class="pdl-title-color"><span class="text__ellipse">{{video?.Title}} </span>
              <span class="grey-color" triggers="mouseenter:mouseleave" popoverClass="tooltip-custom-class"
                placement="end">{{'pdl-shared.pdlChannelText' |
                translate}}
                <img src='assets/images/icons/verified-channel.png' alt='check' /></span>
            </h6>
            <span class="grey-color d-flex colgap-5 align-items-center">
              <span> {{video?.ViewCount}} views</span>
              <span class="bullet_icon"></span>
              <span> {{video?.PublishedDate}}</span>
            </span>
          </div>
        </a>
        }

 
      </div>
    </div>
  }
  </div>
</section>
