<section class="login-user-form">
    <div class="container login-container">
        <div class="row d-flex">
          <!-- Column 1 - Static Content -->
          <div class="col-md-5 column-1">
            <div class="content fw-normal text-justify">
                <!-- Hide breadcrumbs in mobile view -->
                <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-md-block d-none">
                    <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                </div>
                <h5 class="fw-bold">{{'pdl-website.forms.user.pdl' | translate }}</h5>
                <div class="static-txt" [innerHtml]="'pdl-website.forms.user.pdlText' | translate"></div>
            </div>
          </div>
          <!-- Column 2 - Login Form -->
          <div class="col-md-7 column-2">
            <div class="right-section">
                <div class="top-content">
                    <div class="header-title">
                        <!-- Show breadcrumbs in mobile view -->
                        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-block d-md-none">
                            <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                        </div>
                        <div class="title-txt mt-4">
                            <h5 class="fw-bolder">{{'pdl-website.forms.user.pdlLogin' | translate }}</h5>
                        </div>
                    </div>
                    <div class="header-body">
                        <p class="fw-light" [innerHtml]="'pdl-website.forms.user.requiredFldsText' | translate"></p>
                    </div>
                </div>
                <div class="form-section">
                    <!-- show alert -->
                    <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
                    <!-- /show alert -->
                    <p class="fw-bold">{{'pdl-website.forms.user.formHeader' | translate }}</p>
                    <form [formGroup]="loginUserForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex">
                                    <label for="username" class="form-label reqd" [ngClass]="{'text-danger': loginUserForm.controls['username'].invalid && loginUserForm.controls['username'].touched}">{{'pdl-website.forms.labels.usernameOrEmail' | translate }}</label>
                                    <span class="flex-grow-1"></span>
                                    @if(loginUserForm.controls['username'].touched && loginUserForm.controls['username'].errors && loginUserForm.controls['username']['errors']['required']) {
                                        <span class="text-danger">
                                            <img src="../../../assets/images/icons/error-icon.svg" alt="error" />
                                        </span>
                                    }
                                </div>
                                <input type="text" class="form-control" formControlName="username" [ngClass]="{'border-danger': loginUserForm.controls['username'].invalid && loginUserForm.controls['username'].touched}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex">
                                    <label for="password" class="form-label reqd" [ngClass]="{'text-danger': loginUserForm.controls['password'].invalid && loginUserForm.controls['password'].touched}">{{'pdl-website.forms.labels.password' | translate }}</label>
                                    <span class="flex-grow-1"></span>
                                    @if(loginUserForm.controls['password'].touched && loginUserForm.controls['password'].errors && loginUserForm.controls['password']['errors']['required']) {
                                        <span class="text-danger">
                                            <img src="../../../assets/images/icons/error-icon.svg" alt="error" />
                                        </span>
                                    }
                                </div>
                                <input type="password" class="form-control" formControlName="password" [ngClass]="{'border-danger': loginUserForm.controls['password'].invalid && loginUserForm.controls['password'].touched}">
                            </div>
                        </div>
                        <!-- forgot password -->
                        <div class="row">
                            <div class="col-12 fw-bolder">
                                <a href="javascript:void(0);" [routerLink]="'/forgot-username'" class="forgot-passwd text-decoration-underline">
                                    {{'pdl-shared.content.forgotUsername' | translate }}
                                </a>
                                <a href="javascript:void(0);" [routerLink]="'/recover-password'" class="ps-2 forgot-passwd text-decoration-underline">
                                    {{'pdl-shared.content.forgotPassword' | translate }}
                                </a>
                            </div>
                        </div>
                        <!-- or -->
                        <div class="row">
                            <div class="col-12 or-option text-center fw-normal">-Or-</div>
                        </div>
                        <!-- social media buttons -->
                        <panjab-digi-lib-social-login (handleLoginEvent)="handleSocialLogin($event)" (handleFBLoginEvent)="signInWithFB()"></panjab-digi-lib-social-login>
                        <!-- submit buttons -->
                        <div class="row">
                            <div class="col-12 text-center">
                                <button type="submit" class="btn btn-primary btn__primary">{{'pdl-website.forms.labels.logInBtn' | translate }}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="pdl-user text-center pb-4">
                    <span>{{'pdl-website.forms.user.newToPdl' | translate }} </span>
                    <a href="javascript:void(0);" [routerLink]="'/register-user'" class="fw-bold">{{'pdl-website.forms.labels.signUp' | translate }}</a>
                </div>
            </div>
          </div>
        </div>
      </div>
</section>