import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { UserDataService } from 'shared/src/services/admin/users/user-data.service';
import { UserActions} from '../actions/index';

@Injectable()
export class UsersEffects {

  constructor(
    private actions$: Actions,
    private userDataService: UserDataService
  ) {
  }

  LoadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.LoadUsers),
      exhaustMap(action =>
        this.userDataService.loadUsers().pipe(
          map((users: any) => {
            return UserActions.LoadUsersSuccessfully({users: users.products});
          }),
          catchError((error: any) => of(UserActions.LoadUsersError(error))))
      )
    )
  );
}