import { createAction, props } from '@ngrx/store';

// Load banners
export const LoadBanners = createAction(
    '[Banners] Load Banners',
    props<{ bannerType: string, pageNumber: number, pageSize: number }>()
);

export const LoadBannersSuccessfully = createAction(
    '[Banners] Load Banners Successfully',
    props<{ banners: any[], totalRecords: number }>()
);

export const BannersError = createAction(
    '[Banners] Error',
    props<{ message: string, reload?: boolean }>()
);

export const BannerSuccessMsg = createAction(
    '[Banners] Action Success',
    props<{ message: string, reload: boolean }>()
);

// Reorder banners
export const ReorderBanners = createAction(
    '[Banners] reorder',
    props<{ id: number, toOrder: number }>()
);

// Delete banners
export const DeleteBanners = createAction(
    '[Banners] Delete',
    props<{ id: number }>()
);

// UpdateStatus banners
export const BannersUpdateStatus = createAction(
    '[Banners] UpdateStatus',
    props<{ id: number, status: boolean }>()
);

// Add banners

export const AddBanners = createAction(
    '[Banners] Add Banners',
    props<{ data: FormData }>()
);

// Update banners
export const UpdateBanners = createAction(
    '[Banners] update Banner',
    props<{ data: FormData, id: number }>()
);