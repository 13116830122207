/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { ArticleCommentActions } from '../actions';

export interface State extends EntityState<any> {
    payload: GenericListingPayload;
    totalRecords: number;
    isLoading?: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<any>({
    selectId: (e) => e.pk_iCommentId
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(ArticleCommentActions.LoadArticleComment, (state, action) =>
        ({
            ...state,
            payload: action.payload,
            isLoading: true,
            isError: false,
            errorMessage: '',
            successMessage: ''
        })
    ),
    on(ArticleCommentActions.LoadArticleCommentSuccess, (state, action) =>
        ({
            ...state,
            ...adapter.setAll(action.articleComments, state),
            totalRecords: action.totalRecords,
            isLoading: false,
            isError: false,
            errorMessage: '',
            successMessage: ''
        })
    ),
    on(ArticleCommentActions.LoadArticleCommentError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(ArticleCommentActions.UpdateArticleCommentStatus, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(ArticleCommentActions.UpdateArticleCommentStatusSuccess, (state, action) =>
    ({
        ...adapter.updateMany(action.articleCommentUpdates, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(ArticleCommentActions.UpdateArticleCommentStatusError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    )
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();