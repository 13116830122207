import { createReducer, on } from '@ngrx/store';
import {AdminTrendingActions} from "../actions/index"
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface State extends EntityState<any> {
    isLoading?: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
    totalRecords : number

  }
  
  export const adapter = createEntityAdapter<any>({
    selectId: (e) => e?.keywordID,
  });
  
  export const initialState = adapter.getInitialState( {
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    totalRecords : 0
  });

  export const reducer = createReducer(
    initialState,
    on(AdminTrendingActions.LoadTrendingKeywords, (state, action) => ({
        ...state,
        isLoading: true,
      })),
  
      on(AdminTrendingActions.LoadTrendingKeywordsSuccessfully, (state, action) =>
        adapter.setAll(action.trendingList, {
          ...state,
          totalRecords: action.totalRecords,
          isLoading: false,
        })
      ),
  
      on(AdminTrendingActions.LoadTrendingKeywordsError, (state, action) => ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
      })),

      on(
        AdminTrendingActions.UpdateKeywordShowOnApplicationStatus,
        (state, action) => ({ ...state, isLoading: true })
      ),
  
      on(
        AdminTrendingActions.UpdateKeywordShowOnApplicationStatusSuccessfully,
        (state, action) => ({
          ...adapter.updateOne(
            { id: action?.trendingItem?.keyWordID, changes: action.trendingItem },
            state
          ),
          isLoading: false,
          isSaving: false,
          isError: false,
          errorMessage: '',
          successMessage: action.message,
        })
      ),
      on(
        AdminTrendingActions.UpdateKeywordShowOnApplicationStatusError,
        (state, action) => ({
          ...state,
          isLoading: false,
          isSaving: false,
          isError: true,
          errorMessage: action.message,
          successMessage: '',
        })
      )
  )

  export const {
    selectAll
    } = adapter.getSelectors();