import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromScriptsPerLanguage from "../reducers/scriptsPerLanguage.reducer";

export const selectScriptsPerLanguageState =
  createFeatureSelector<fromScriptsPerLanguage.State>('scriptsPerLanguage');

export const getScriptsPerLanguage = createSelector(
    selectScriptsPerLanguageState,
    fromScriptsPerLanguage.selectAll
);

export const getScriptsPerLanguageByID = (props: {id: number}) => createSelector(
  selectScriptsPerLanguageState,
  (state) => state.entities[props.id]
)

export const getScriptsOfLanguage = createSelector(
    selectScriptsPerLanguageState,
    (state) => state.scriptsOfLanguage,
);

export const getTotalRecords = createSelector(
    selectScriptsPerLanguageState,
    (state) => state.totalRecords,
);

export const onSave = createSelector(
  selectScriptsPerLanguageState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectScriptsPerLanguageState,
  (state) => state.isLoading
);

export const getListingPayload = createSelector(
    selectScriptsPerLanguageState,
    (state) => state.payload
)