import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'panjab-digi-lib-advanced-search',
  standalone: true,
  imports: [CommonModule,RouterLink,TranslateModule],
  templateUrl: './advanced-search.component.html',
  styleUrl: './advanced-search.component.scss',
})
export class AdvancedSearchComponent {
  constructor(private translate: TranslateService) {

  }
}
