import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'panjab-digi-lib-all-exhibitions',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './all-exhibitions.component.html',
  styleUrl: './all-exhibitions.component.scss',
})
export class AllExhibitionsComponent {}
