<section class="innerpage-banner mb-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col">
                <div class="page-title m-auto">
                    <h1 class="heading-dark semibold mb-0">{{title | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container user_content_wrapper">
    <div class="row mb-5 py-4">
        <div class="col">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
