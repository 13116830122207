import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, ScriptsPerLanguageActions, ScriptsPerLanguageSelector } from '@panjab-digi-lib/shared';
import { ScriptsPerLanguageService } from 'shared/src/services/admin/master-form/scriptsPerLanguage.service';

@Injectable()
export class ScriptsPerLanguageEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private scriptsPerLanguageService: ScriptsPerLanguageService,
        private store: Store<AppState>,
    ) {
    }

    LoadScriptsPerLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScriptsPerLanguageActions.LoadScriptsPerLanguage),
            exhaustMap((action) => {

                return this.scriptsPerLanguageService.loadScriptsPerLanguage().pipe(
                    map((response) => {
                        return ScriptsPerLanguageActions.LoadScriptsPerLanguageSuccess({ languages: response.languages });
                    }),
                    catchError((error: any) => of(ScriptsPerLanguageActions.LoadScriptsPerLanguageError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    LoadScriptsOfLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScriptsPerLanguageActions.LoadScriptsOfLanguage),
            exhaustMap((action) => {

                return this.scriptsPerLanguageService.loadScriptsOfLanguage(action.payload, action.LangID).pipe(
                    map((response) => {
                        return ScriptsPerLanguageActions.LoadScriptsOfLanguageSuccess({ scripts: response.scripts, totalCount: response.totalCount, LangID: action.LangID });
                    }),
                    catchError((error: any) => of(ScriptsPerLanguageActions.LoadScriptsOfLanguageError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    SaveAllocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScriptsPerLanguageActions.SaveAllocations),
            exhaustMap((action) => {

                return this.scriptsPerLanguageService.saveAllocations(action.payload, action.LangID).pipe(
                    map((response) => {
                        return ScriptsPerLanguageActions.SaveAllocationsSuccess({ message: response.message, LangID: action.LangID });
                    }),
                    catchError((error: any) => of(ScriptsPerLanguageActions.SaveAllocationsError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    OnSaveAllocationsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScriptsPerLanguageActions.SaveAllocationsSuccess),
            switchMap((action) => combineLatest([
                this.store.select(ScriptsPerLanguageSelector.getListingPayload).pipe(take(1)),
                of(action.LangID),
            ])
            ),
            map(([payload, LangID]) => {
                return ScriptsPerLanguageActions.LoadScriptsOfLanguage({payload, LangID})
            })
        )
    );
    
}