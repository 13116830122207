import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromStaffAuth from "../../reducers/staff/auth.reducer";

export const selectLoginState =
  createFeatureSelector<fromStaffAuth.State>('staffAuth');


export const selectToken = createSelector(
    selectLoginState,
    (state) => state.token
);

export const selectError = createSelector(
    selectLoginState,
    (state) => state.error
);

export const selectIsLoading = createSelector(
    selectLoginState,
    (state) => state.isLoading
);