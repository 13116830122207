<panjab-digi-lib-static-page-head [pageName]="'pdl-website.workingGroups.titleText' | translate"
  pagePath="/working-groups">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <div class="d-flex workingGroup">
      <div class="img"><img src="/assets/images/working-group.png" /></div>
      <div>
        <h1 class="heading mb-3">Working Groups</h1>
        <p class="text">
          DL assembled 15 Working Groups in 2008, drawing specialists from their respective fields. Of these original
          15, eight were made permanent Working Groups. Group members consist of professionals and researchers who work
          and collaborate at an interdisciplinary basis to complete a given project. The functions of a working group
          also include review of existing applications, evaluating goals, effectiveness, usability and design in order
          to provide feedback for further improving presentation and functionality. It is on the professional expertise
          of these working groups that the output and success of PDL depends. The life span of each working group lasts
          anywhere between a few months to several years depending upon specificity and size of the project.
        </p>
        <ul class="text">
          <li>Content Selection Working Group</li>
          <li>Copyright & IPR Issues Working Group</li>
          <li>Digital Data Management Working Group</li>
          <li>Marketing Working Group</li>
          <li>Library Planning Working Group</li>
          <li>Digitization Standards (Review) Working Group</li>
          <li>Metadata Working Group</li>
          <li>Technology, Standards and Architecture Working Group</li>
          <li>Digital Library Metrics Working Group</li>
          <li>Exhibits Working Group</li>
          <li>Products Working Group</li>
          <li>Design Working Group</li>
          <li>Data Migration Working Group</li>
          <li>Back-up Working Group</li>
        </ul>
        <p class="text">
          As PDL spreads its wings, these Working Groups will be made more professional and autonomous. If you have
          expertise and experience in any related field or are interested in joining a Working Group, please write to us
          at <a href="mailto:support@panjabdigilib.org" target="_blank"
            class="text email cursor-pointer">support&#64;panjabdigilib.org</a>
        </p>
      </div>
    </div>
    }
  </div>
</section>
