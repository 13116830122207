import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AppLoadingService {
  public appLoadingStatus$ = new BehaviorSubject<any>('Loading');
  
  constructor(private translate: TranslateService) { }

  initApp() {
  }
}
