/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import {
  DeleteEmployeePayload,
  Employee,
  GetEmployeeListPayload,
  UpdateEmployeeStatusPayload,
  UpdateRoleProcessPayload,
} from 'shared/src/interfaces/employee.interface';
export const LoadEmployees = createAction(
  '[Employee] Load Employees List',
  props<{ payload: GetEmployeeListPayload }>()
);

export const LoadEmployeesSuccessfully = createAction(
  '[Employee] Load Employees Successfully',
  props<{ employeeList: Employee[]; totalRecords: number }>()
);

export const LoadEmployeesError = createAction(
  '[Employee] Load Employees Error',
  props<{ message: string }>()
);

export const AddEmployee = createAction(
  '[Employee] Add Employee to List',
  props<{ payload: any }>()
);

export const AddEmployeeSuccessfully = createAction(
  '[Employee] Employee Added Successfully',
  props<{ employee: Employee; message: string }>()
);

export const AddEmployeeError = createAction(
  '[Employee] Employee Added Error',
  props<{ message: string }>()
);

export const UpdateEmployee = createAction(
  '[Employee] Edit Employee in List',
  props<{ payload: any; id: number }>()
);

export const UpdateEmployeeSuccessfully = createAction(
  '[Employee] Employee Updated Successfully',
  props<{ employee: Employee; message: string }>()
);

export const UpdateEmployeeError = createAction(
  '[Employee] Employee Updated Error',
  props<{ message: string }>()
);

export const UpdateEmployeeStatus = createAction(
  '[Employee] Update Employee Status in List',
  props<{ payload: UpdateEmployeeStatusPayload }>()
);

export const UpdateEmployeeStatusSuccessfully = createAction(
  '[Employee] Employee Status Updated Successfully',
  props<{ employee: Employee[]; message: string }>()
);

export const UpdateEmployeeStatusError = createAction(
  '[Employee] Employee Status Updated Error',
  props<{ message: string }>()
);

export const UpdateRoleEmployee = createAction(
  '[Employee] Update Employee Role',
  props<{ payload: UpdateRoleProcessPayload }>()
);

export const UpdateRoleEmployeeSuccessfully = createAction(
  '[Employee] Update Role Employee Successfully',
  props<{ employee: Employee; message: string }>()
);

export const UpdateRoleEmployeeError = createAction(
  '[Employee] Update Role Employee Error',
  props<{ message: string }>()
);

export const DeleteEmployee = createAction(
  '[Employee] Delete Employee',
  props<{ payload: DeleteEmployeePayload }>()
);

export const DeleteEmployeeSuccess = createAction(
  '[Employee] Delete Employee Success',
  props<{ message: string }>()
);

export const DeleteEmployeeError = createAction(
  '[Employee] Delete Employee Error',
  props<{ message: string }>()
);
