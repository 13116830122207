/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { map } from 'rxjs';
import {
  Videos,
  WebVideoWithRelatedVideos,
  WebVideosPayload,
} from 'shared/src/interfaces/video-gallery.interface';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  ///////////////// load homepage videos /////////////////
  loadAllVideos(payload: WebVideosPayload) {
    let url = `${this.rootURL}${WEB_API_ENDPOINTS.videos.videos}`;
    url = url.replace('{orderBy}', payload?.orderBy ?? 'VideoOrder');
    if (payload?.pageSize) {
      url += `&pageSize=${payload?.pageSize}`;
    }
    if (payload?.pageNumber) {
      url += `&pageNumber=${payload?.pageNumber}`;
    }
    return this.http.get<Videos>(url).pipe(map((e: Videos) => e));
  }

  ///////////////// load homepage videos /////////////////
  loadVideo(videoId: number) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.videos.video}`.replace(
      '{id}',
      videoId.toString()
    );
    return this.http
      .get<WebVideoWithRelatedVideos>(url)
      .pipe(map((e: WebVideoWithRelatedVideos) => e));
  }

}
