import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAdminLanguage from '../reducers/admin-language-reducer';

export const selectState =
  createFeatureSelector<fromAdminLanguage.State>('adminLanguage');

export const loadLanguageList = createSelector(selectState, (state) => ({
  languageList: fromAdminLanguage.selectAll(state),
  totalRecords: state.totalRecords,
  pageSize: state.pageSize,
  pageNumber : state.pageNumber,
  sortBy: state.sortBy
}));

export const getLanguageById = (id: number) => createSelector(
  selectState,
  (state) => state.entities[id]
)

export const onSave = createSelector(
  selectState,
  (state) => [state.isError, state.errorMessage, state.successMessage,state.isLoading]
);