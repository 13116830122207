/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import {
  DeleteSponsorshipPayload,
  Sponsorship,
  SponsorshipItemPayload,
  UpdateSponsorshipStatusPayload,
} from 'shared/src/interfaces/sponsorship.interface';
export const LoadSponsorshipList = createAction(
  '[Sponsorship] Load Sponsorship Items List',
  props<{ payload: SponsorshipItemPayload }>()
);

export const LoadSponsorshipListSuccessfully = createAction(
  '[Sponsorship] Load Sponsorship Items Successfully',
  props<{ sponsorshipList: Sponsorship[]; totalRecords: number }>()
);

export const LoadSponsorshipListError = createAction(
  '[Sponsorship] Load Sponsorship Items Error',
  props<{ message: string }>()
);

export const AddSponsorship = createAction(
  '[Sponsorship] Add Sponsorship to List',
  props<{ payload: any }>()
);

export const AddSponsorshipSuccessfully = createAction(
  '[Sponsorship] Sponsorship Added Successfully',
  props<{ sponsorshipItem: Sponsorship; message: string }>()
);

export const AddSponsorshipError = createAction(
  '[Sponsorship] Sponsorship Added Error',
  props<{ message: string }>()
);

export const UpdateSponsorship = createAction(
  '[Sponsorship] Edit Sponsorship in List',
  props<{ payload: any; id: number }>()
);

export const UpdateSponsorshipSuccessfully = createAction(
  '[Sponsorship] Sponsorship Updated Successfully',
  props<{ sponsorship: Sponsorship; message: string }>()
);

export const UpdateSponsorshipError = createAction(
  '[Sponsorship] Sponsorship Updated Error',
  props<{ message: string }>()
);

export const UpdateSponsorshipStatus = createAction(
  '[Sponsorship] Update Sponsorship Status in List',
  props<{ payload: UpdateSponsorshipStatusPayload }>()
);

export const UpdateSponsorshipStatusSuccessfully = createAction(
  '[Sponsorship] Sponsorship Status Updated Successfully',
  props<{ sponsorship: Sponsorship[]; message: string }>()
);

export const UpdateSponsorshipStatusError = createAction(
  '[Sponsorship] Sponsorship Status Updated Error',
  props<{ message: string }>()
);

export const DeleteSponsorship = createAction(
  '[Sponsorship] Delete Status from List',
  props<{ payload: DeleteSponsorshipPayload }>()
);

export const DeleteSponsorshipSuccessfully = createAction(
  '[Sponsorship] Sponsorship Deleted Successfully',
  props<{ message: string }>()
);

export const DeleteSponsorshipError = createAction(
  '[Sponsorship] Sponsorship Status Updated Error',
  props<{ message: string }>()
);
