import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of, exhaustMap } from 'rxjs';
import { StaffAuthActions } from '../../actions/index';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';

const DEFAULT_REDIRECT_TO = APP_CONSTANTS.DefaultStaffRoute;
@Injectable()
export class StaffAuthEffects {
  constructor(
    private actions$: Actions, 
    private authService: AuthenticationService, 
    private router: Router, 
    private localStorageService: LocalStorageService
  ) {}
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffAuthActions.Login),
      switchMap(({ email, password }) =>
        this.authService.staffLogin(email, password).pipe(
          map((res) => {
            if (res.body.token) {
              localStorage.setItem('staffToken', `"${res.body.token}"`);
              // set refresh token in the localstorage
              localStorage.setItem('staffRefreshToken', `"${res.body.refreshToken}"`);
              // set user profile image
              const user = {
                image: res.body.Image,
                username: res.body.UserName,
                fullname: res.body.LName ? res.body.FName + ' ' + res.body.LName : res.body.FName,
                staffUser: true
              }
              localStorage.setItem('activeStaffUser', JSON.stringify(user));
                // redirect to previous active url
                const redirectTo = localStorage.getItem('redirectTo');
                this.router.navigateByUrl(redirectTo ?? DEFAULT_REDIRECT_TO);
                // delete saved redirect url
                if(redirectTo) {
                  localStorage.removeItem('redirectTo');
                }
              return StaffAuthActions.LoginSuccess({ token: res.body.token, refreshToken: res.body.refreshToken })
            } else {
              return StaffAuthActions.LoginError({ error: "Token not received" })
            }
          }),
          catchError((error: any) => {
            return of(StaffAuthActions.LoginError({ error: (error?.error?.error) }))
          })
        )
      )
    )
  );
}
