/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import {
  addLanguagePayload,
  getLanguagePayload,
  getLanguageResponse,
  addLanguageResponse,
  editLanguagePayload,
  editLanguageResponse,
  updateLanguageStatusPayload,
  updateLanguageStatusResponse,
  deleteLanguagePayload,
  deleteLanguageResponse,
} from 'shared/src/interfaces/language.interface';
import { API_ENDPOINTS } from '../app.endpoints';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.masterForm.language;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }
  ////////// load languages /////////
  loadLanguages(payload: getLanguagePayload) {
    let url = `${this.rootURL}${this.endPointUrl.default}`;
    url = url
      .replace('{pageSize}', (payload?.pageSize ?? 1).toString())
      .replace('{pageNumber}', (payload?.pageNumber ?? 1).toString())
      .replace('{sortBy}', payload?.sortBy ?? 'name');
    return this.http
      .get<getLanguageResponse>(url)
      .pipe(map((e: getLanguageResponse) => e?.body));
  }
  ////////// add language /////////
  addLanguage(payload: addLanguagePayload) {
    const url = `${this.rootURL}${this.endPointUrl.language}`;
    return this.http
      .post<addLanguageResponse>(url, payload)
      .pipe(map((e: addLanguageResponse) => e));
  }

  ////////// update language /////////
  updateLanguage(payload: editLanguagePayload) {
    let url = `${this.rootURL}${this.endPointUrl.update}`;
    url = url.replace('{id}', (payload?.id ?? '').toString());
    return this.http
      .put<editLanguageResponse>(url, { name: payload?.name })
      .pipe(map((e: editLanguageResponse) => e));
  }

  ////////// update language status/////////
  updateLanguageStatus(payload: updateLanguageStatusPayload) {
    const url = `${this.rootURL}${this.endPointUrl.language}`;
    return this.http
      .patch<updateLanguageStatusResponse>(url, payload)
      .pipe(map((e: updateLanguageStatusResponse) => e));
  }

  ////////// delete language/////////
  deleteLanguage(payload: deleteLanguagePayload) {
    const url = `${this.rootURL}${this.endPointUrl.language}`;
    return this.http
      .delete<deleteLanguageResponse>(url, {body:payload})
      .pipe(map((e: deleteLanguageResponse) => e));
  }

    ////////// download language/////////
    download() {
      return this.http.get(`${this.rootURL}${this.endPointUrl.download}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
    }
}
