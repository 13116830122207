import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { AddEditMetaDataPayload, MetaData } from "shared/src/interfaces/metadata.interface";

export const LoadMetaData = createAction(
    '[MetaData] Load MetaData',
    props<{payload: GenericListingPayload}>(),
)

export const LoadMetaDataSuccess = createAction(
    '[MetaData] Load MetaData Successfully',
    props<{metaData: MetaData[], totalRecords: number}>()
)

export const LoadMetaDataError = createAction(
    '[MetaData] Load MetaData Error',
    props<{message: string}>()
)

export const UpdateMetaDataStatus = createAction(
    '[MetaData] Update MetaData Status',
    props<{MetaDataIDs: number[], status: string}>(),
)

export const UpdateMetaDataStatusSuccess = createAction(
    '[MetaData] Update MetaData Status Success',
    props<{metaDataUpdates: any, message: string}>(),
)

export const UpdateMetaDataStatusError = createAction(
    '[MetaData] Update MetaData Status Error',
    props<{message: string}>(),
)

export const AddMetaData = createAction(
    '[MetaData] Add MetaData',
    props<{payload: AddEditMetaDataPayload}>(),
)

export const AddMetaDataSuccess = createAction(
    '[MetaData] Add MetaData Success',
    props<{metaData: MetaData, message: string}>(),
)

export const AddMetaDataError = createAction(
    '[MetaData] Add MetaData Error',
    props<{message: string}>(),
)

export const EditMetaData = createAction(
    '[MetaData] Edit MetaData',
    props<{payload: AddEditMetaDataPayload, metaDataId: string}>(),
)

export const EditMetaDataSuccess = createAction(
    '[MetaData] Edit MetaData Success',
    props<{metaData: MetaData, message: string}>(),
)

export const EditMetaDataError = createAction(
    '[MetaData] Edit MetaData Error',
    props<{message: string}>(),
)

export const DeleteMetaData = createAction(
    '[MetaData] Delete MetaData',
    props<{MetaDataIDs: number[]}>(),
)

export const DeleteMetaDataSuccess = createAction(
    '[MetaData] Delete MetaData Success',
    props<{message: string}>(),
)

export const DeleteMetaDataError = createAction(
    '[MetaData] Delete MetaData Error',
    props<{message: string}>(),
)