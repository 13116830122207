import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromUsers from "../reducers/users.reducer";

export const selectUsersState =
  createFeatureSelector<fromUsers.State>('users');

export const getUsers = createSelector(
    selectUsersState,
    fromUsers.selectAll
);

export const isLoading = createSelector(
  selectUsersState,
  (state) => state.isLoading
);

export const isSaving = createSelector(
    selectUsersState,
  (state) => [state.isSaving, state.isError, state.errorMessage],
);