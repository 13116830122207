import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'panjab-digi-lib-static-page-head',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './staticPageHead.component.html',
  styleUrl: './staticPageHead.component.scss',
})
export class StaticPageHeadComponent {
  @Input() pageName: string = '';
  @Input() pagePath: string = '';
}
