import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { StaticPageHeadComponent } from '../../static/staticPageHead/staticPageHead.component';

@Component({
  selector: 'panjab-digi-lib-exhibitions',
  standalone: true,
  imports: [CommonModule,TranslateModule, StaticPageHeadComponent],
  templateUrl: './exhibitions.component.html',
  styleUrl: './exhibitions.component.scss',
})
export class ExhibitionsComponent {
  constructor(private translate: TranslateService) {

  }
}
