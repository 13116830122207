<panjab-digi-lib-reader></panjab-digi-lib-reader>
<div class="notes container">
    <div class="semibold d-flex justify-content-center">
        Notes
    </div>
    <div class="notesWrapper d-flex mb-3 no-scrollbar">
        <!-- // loop over notes here -->
        @for (item of dummyArr; track item) {
        <div class="note p-2">
            <div class="editbtn d-flex justify-content-end">
                <img src="/assets/images/icons/edit-icon.svg" />
            </div>
            <p class="note_text">Humor in early Punjabi drama is very rare and very often obscene, vulgar and loaded
                with double entendered words. These five one act plays make a departure from this ugly tradition.</p>
        </div>
        }
    </div>
    <div class="bookmarkWrapper d-flex mb-3 no-scrollbar">
        <!-- // loop over bookmarks here here -->
        @for (item of dummyArr; track item) {
        <div class="bookmark p-2">
            <div class="bookmarkbtn d-flex justify-content-end">
                <img src="/assets/images/icons/bookmark-white.svg" />
            </div>
            <div class="bookmark_img"><img src="/assets/images/book-img1.svg" alt=""></div>
        </div>
        }
    </div>
</div>
<section class="innerpage-banner bg-pdl-color no-scrollbar">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
                    <a class="breadcrumb-link font-regular" href="#">
                        <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
                        Home
                    </a>
                    <span class="chevron-arrow font-regular">
                        <img src="assets/images/icons/arrow-right.svg" alt="icon">
                    </span>
                    <a class="breadcrumb-link font-regular" href="#">
                        Books
                    </a>
                    <span class="chevron-arrow font-regular">
                        <img src="assets/images/icons/arrow-right.svg" alt="icon">
                    </span>
                    <a class="breadcrumb-link font-regular" href="#">
                        Rural Rehabilitation in East Punjab
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="bg-pdl-color pt-5 pb-3">
    <div class="container">
        <div class="d-flex wrap-conditional">
            <div class="metabook-img">
                <img alt="image" src="assets/images/rr-book.jpg">
            </div>
            <div class="metadata-book-title">
                <span class="meta-type-span light-font">Books</span>
                <h1 class="pdl-title-color">Rural Rehabilitation in East Punjab</h1>
                <p class="metadata-author"><span class="heading-span">Author:</span><span class="author-span">
                        Unknown</span></p>
                <div class="pdl-btns-row d-flex align-items-center justify-content-between flex-wrap gap-20 pb-3">
                    <div class="btns-aligned-left d-flex flex-wrap gap-20">
                        <a href="#"
                            class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start">Add
                            to my library <img src="assets/images/icons/directions-ico.svg"></a>
                        <div class="d-flex flex-wrap gap-20">
                            <a href="#"
                                class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start">
                                Adopt this book <img src="assets/images/icons/visit-ico.svg"></a>
                            <a href="#"
                                class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start">
                                Print on Demand <img src="assets/images/icons/visit-ico.svg"></a>
                        </div>
                    </div>
                    <div class="btns-aligned-right d-flex gap-20 flex-wrap">
                        <a href="#"
                            class="btn pdl-btn pdl-bg-white medium-font d-flex align-items-center justify-content-start"><img
                                src="assets/images/icons/download-large-ico.svg"></a>
                        <a href="#"
                            class="btn pdl-btn pdl-bg-white medium-font d-flex align-items-center justify-content-start"><img
                                src="assets/images/icons/quote-ico.svg"></a>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="bg-pdl-color-light container lrborder-mob">
        <div class="d-flex meta-info-wrapper pb-5">
            <!-- // for mobile only -->
            <div class="metadata-book-edit overflow-scroll no-scrollbar mob-only ">
                <div
                    class="edit-title d-flex justify-content-start flex-wrap align-items-center justify-content-center">
                    <h6 class="pdl-title-color fullBold mb-3 justify-content-center">Books by the Author</h6>
                </div>
                <div class="list-wrapper d-flex no-scrollbar ">
                    @for (item of getRandomCount(); track item) {
                    <img class="books_img" src="/assets/images/category2.jpg" alt="listitem" />
                    }
                </div>
            </div>
            <!-- end -->
            <div class="meta-info">
                <div class="meta-disc-item">
                    <h6 class="pdl-title-color semibold">Accession Number</h6>
                    <span class="pdl-title-color font-regular">BK-000764</span>
                </div>
                <div class="meta-disc-item">
                    <h6 class="pdl-title-color semibold">Year</h6>
                    <span class="pdl-title-color font-regular">-</span>
                </div>
                <div class="meta-disc-item">
                    <h6 class="pdl-title-color semibold">Script</h6>
                    <span class="pdl-title-color font-regular">Roman</span>
                </div>
                <div class="meta-disc-item">
                    <h6 class="pdl-title-color semibold">Language</h6>
                    <span class="pdl-title-color font-regular">English</span>
                </div>
                <div class="meta-disc-item">
                    <h6 class="pdl-title-color semibold">Publisher</h6>
                    <span class="pdl-title-color font-regular">The Publicity Department, Government of East Punjab,
                        Shimla</span>
                </div>
                <div class="meta-disc-item">
                    <h6 class="pdl-title-color semibold">Custodian</h6>
                    <span class="pdl-title-color font-regular">Kirpal Singh, Dr.</span>
                </div>
                <div class="meta-disc-item">
                    <h6 class="pdl-title-color semibold">Size</h6>
                    <span class="pdl-title-color font-regular">24.5 x 15.9</span>
                </div>
                <div class="meta-disc-item">
                    <h6 class="pdl-title-color semibold">Completion</h6>
                    <span class="pdl-title-color font-regular">Complete</span>
                </div>
                <div class="meta-disc-item">
                    <h6 class="pdl-title-color semibold">Condition</h6>
                    <span class="pdl-title-color font-regular">Good</span>
                </div>
                <div class="meta-disc-item">
                    <h6 class="pdl-title-color semibold">Pages</h6>
                    <span class="pdl-title-color font-regular">40</span>
                </div>
            </div>
            <div class="content_info">
                <div class="metadata-book-edit">
                    <div class="edit-title d-flex justify-content-start flex-wrap align-items-center">
                        <h6 class="pdl-title-color semibold">Keywords</h6> <span
                            class="edit-btn font-14 font-regular">Edit</span>
                    </div>
                    <p class="pdl-title-color font-regular">East Punjab, Provincial Relief and Rehabilitation
                        Programme, Ferozepur, Jullundar, Karnal, Harijan Refugees, M.L. Mehta, Hindus, Sikhs</p>
                </div>
                <div class="metadata-book-edit">
                    <div class="edit-title d-flex justify-content-start flex-wrap align-items-center">
                        <h6 class="pdl-title-color semibold">Description</h6> <span
                            class="edit-btn font-14 font-regular">Edit</span>
                    </div>
                    <p class="pdl-title-color font-regular">Following declaration of independence of India and its
                        simultaneous partition, there was a transfer of population on the basis of religion from the
                        West to the East and from the East to the West Punjab. This large-scale influx of Hindu/Sikh
                        refugees to the East Punjab created a huge problem for the government to settle and rehabilitate
                        then on the evacuee land.</p>

                    <p class="pdl-title-color font-regular">This government publication deals with the settlement and
                        rehabilitation of rural refugees in the rural areas of East Punjab. It included providing them
                        with temporary shelters, land holdings, first on temporary and then on quasi-temporary and
                        permanent basis and starting several welfare schemes to help the displaced persons. The
                        publication provides the statistics about land distribution, allocation of districts and
                        settling the claims. The publication highlights the gravity and magnitude of the problem and the
                        steps taken to deal with the problem</p>
                </div>

                <div class="metadata-book-edit">
                    <div class="edit-title d-flex justify-content-start flex-wrap align-items-center">
                        <h6 class="pdl-title-color semibold">About Author</h6> <span
                            class="edit-btn font-14 font-regular">Edit</span>
                    </div>
                    <p class="pdl-title-color font-regular">Following declaration of independence of India and its
                        simultaneous partition, there was a transfer of population on the basis of religion from the
                        West to the East and from the East to the West Punjab. This large-scale influx of Hindu/Sikh
                        refugees to the East Punjab created a huge problem for the government to settle and rehabilitate
                        then on the evacuee land.</p>
                </div>
                <div class="metadata-book-edit desktop-only">
                    <div class="edit-title d-flex justify-content-start flex-wrap align-items-center">
                        <h6 class="pdl-title-color fullBold mb-5">Books by the Author</h6>
                    </div>
                    <div class="list-wrapper d-flex no-scrollbar">
                        @for (item of getRandomCount(); track item) {
                        <img class="books_img" src="/assets/images/category2.jpg" alt="listitem" />
                        }
                    </div>
                </div>
            </div>

        </div>


    </div>
</section>

<section class="bg-pdl-color pt-5">
    <div class="container">
        <div class="metadata-book-edit">
            <div class="edit-title d-flex justify-content-start flex-wrap align-items-center">
                <h6 class="pdl-title-color fullBold mb-5">Books on the same topic</h6>
            </div>
            <div class="list-wrapper d-flex no-scrollbar pb-3">
                @for (item of getRandomCount(); track item) {
                <div class="d-flex flex-column">
                    <img class="books_img mb-2" src="/assets/images/category2.jpg" alt="listitem" />
                    <span class="text-item-simple">listitem</span>
                </div>
                }
            </div>
        </div>
    </div>
</section>


<section class="bg-pdl-color pt-5  pb-5">
    <div class="container">
        <div class="metadata-book-edit">
            <div class="edit-title d-flex justify-content-start flex-wrap align-items-center">
                <h6 class="pdl-title-color fullBold mb-5">Books published in the same year</h6>
            </div>
            <div class="list-wrapper d-flex no-scrollbar pb-3">

                @for (item of data?.data; track item) {
                <div class="d-flex flex-column same_year_book_wrapper">
                    <div class="d-flex flex-column" style="position: relative;">
                        <img class="books_img_same_year mb-2" src="/assets/images/category2.jpg" alt="listitem" />
                        <div class="text-item"><span>{{item.name}}</span>
                            <div class="stars">
                                @for (star of getRating(item.rating); track star) {
                                @if (star === 1) {
                                <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                                }
                                @if (star === 0.5) {
                                <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                                }
                                @if (star === 0) {
                                <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                                }
                                }
                            </div>
                        </div>
                    </div>
                    <div class="bg-white">
                        <div class="other-info mb-3">
                            <span class="other-info-name">{{item.name}}</span>
                        </div>
                        <div class="other-info-text d-flex justify-content-between">
                            <span>{{item.script}}</span>
                            <span>{{item.pages}} Pages</span>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container pt-5">
        <div class="d-flex justify-content-center">
            <span class="heading mb-3">Curated Collection</span>
        </div>
        <div class="list-wrapper d-flex no-scrollbar pb-3">
            @for (item of getRandomCount(); track item) {
            <div class="d-flex flex-column bg-pdl-color curated_collection">
                <div class="featured"><span>featured</span> </div>
                <img class="curated_image mb-3" src="/assets/images/category2.jpg" alt="listitem" />
                <span class="text-item-dark mb-2">listitem</span>
                <div class="d-flex doc-info align-items-center">
                    <img src="/assets/images/icons/folder-icon.svg" alt="folder icon" /> <span>3 DOCUMETNS</span>
                </div>
                <p class="info mb-5 no-scrollbar">
                    Roman || The Publicity Department, Government of East Punjab, Shimla

                </p>
                <button class="see bg-pdl-color">See Collection</button>
            </div>
            }
        </div>
    </div>
</section>
