import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { SubjectsPerCategory, SubjectsOfCategory, SaveAllocationPayload } from "shared/src/interfaces/master-form.interface";

export const LoadSubjectsPerCategory = createAction(
    '[SubjectsPerCategory] Load Subjects Per Category'
)

export const LoadSubjectsPerCategorySuccess = createAction(
    '[SubjectsPerCategory] Load Subjects Per Category Successfully',
    props<{categories: SubjectsPerCategory[]}>()
)

export const LoadSubjectsPerCategoryError = createAction(
    '[SubjectsPerCategory] Load Subjects Per Category Error',
    props<{message: string}>()
)

export const LoadSubjectsOfCategory = createAction(
    '[SubjectsPerCategory] Load Subjects for single Category',
    props<{payload: GenericListingPayload, categoryID: number}>(),
)

export const LoadSubjectsOfCategorySuccess = createAction(
    '[SubjectsPerCategory] Load Subjects for single Category Successfully',
    props<{subjects: SubjectsOfCategory[], totalCount: number, categoryID: number}>()
)

export const LoadSubjectsOfCategoryError = createAction(
    '[SubjectsPerCategory] Load Subjects for single Category Error',
    props<{message: string}>()
)

export const SaveAllocations = createAction(
    '[SubjectsPerCategory] Save Allocations',
    props<{payload: SaveAllocationPayload, categoryID: number}>(),
)

export const SaveAllocationsSuccess = createAction(
    '[SubjectsPerCategory] Save Allocations Successfully',
    props<{message: string, categoryID: number}>()
)

export const SaveAllocationsError = createAction(
    '[SubjectsPerCategory] Save Allocations Error',
    props<{message: string}>()
)
