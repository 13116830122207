import { createAction, props } from "@ngrx/store";
import { GenericListingPayloadWithEmail } from "shared/src/interfaces/common.interface";
import { Donor } from "shared/src/interfaces/web-management.interface";

export const LoadDonationList = createAction(
    '[Donor] Load View Donation List',
    props<{ payload: GenericListingPayloadWithEmail }>(),
)

export const LoadDonationListSuccess = createAction(
    '[Donor] Load View Donation List Successfully',
    props<{ donors: Donor[], totalRecords: number }>()
)

export const LoadDonationListError = createAction(
    '[Donor] Load View Donation List Error',
    props<{ message: string }>()
)