import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, SubjectActions, SubjectSelector } from '@panjab-digi-lib/shared';
import { MasterFormsService } from 'shared/src/services/admin/master-form/master-forms.service';
import { Update } from '@ngrx/entity';
import { Subjects } from 'shared/src/interfaces/master-form.interface';

@Injectable()
export class SubjectEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private masterFormService: MasterFormsService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadSubject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubjectActions.LoadSubject),
            exhaustMap((action) => {

                return this.masterFormService.loadSubject(action.payload).pipe(
                    map((response) => {
                        return SubjectActions.LoadSubjectSuccess({ subjects: response.subjects, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(SubjectActions.LoadSubjectError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
    
    LoadParentSubject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubjectActions.LoadParentSubject),
            exhaustMap((action) => {

                return this.masterFormService.loadParentSubject(action.subjectID).pipe(
                    map((response) => {
                        return SubjectActions.LoadParentSubjectSuccess({ subjects: response.subjects });
                    }),
                    catchError((error: any) => of(SubjectActions.LoadParentSubjectError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
    
    AddSubject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubjectActions.AddSubject),
            exhaustMap((action) => {

                return this.masterFormService.addSubject(action.payload).pipe(
                    map((response) => {

                        return SubjectActions.AddSubjectSuccess({ subject: response.body, message: response.message });
                    }),
                    catchError((error: any) => of(SubjectActions.AddSubjectError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    EditSubject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubjectActions.EditSubject),
            exhaustMap((action) => {
                
                return this.masterFormService.editSubject(action.payload, action.subjectId).pipe(
                    map((response) => {

                        // this.router.navigateByUrl('/master-forms/manage/subjects');
                        return SubjectActions.EditSubjectSuccess({subject: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(SubjectActions.EditSubjectError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );

    UpdateSubjectStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubjectActions.UpdateSubjectStatus),
            exhaustMap((action) => {

                return this.masterFormService.updateSubjectStatus(action.subjectIDs, action.status).pipe(
                    map((response: any) => {
                        const subjectUpdates: Update<Subjects>[] = action.subjectIDs.map((id) => {
                            return {
                                id, changes: {
                                    Status: action.status
                                }
                            }
                        })

                        return SubjectActions.UpdateSubjectStatusSuccess({ subjectUpdates, message: response.message });
                    }),
                    catchError((error: any) => of(SubjectActions.UpdateSubjectStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    DeleteSubject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubjectActions.DeleteSubject),
            exhaustMap((action) => {

                return this.masterFormService.deleteSubject(action.subjectIDs).pipe(
                    map((response: any) => {

                        return SubjectActions.DeleteSubjectSuccess({ message: response.message });
                    }),
                    catchError((error: any) => of(SubjectActions.DeleteSubjectError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );

    AddDeleteSubjectSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubjectActions.AddSubjectSuccess, SubjectActions.DeleteSubjectSuccess, SubjectActions.EditSubjectSuccess),
            switchMap((action) => combineLatest([
                    of(action.type),
                    this.store.select(SubjectSelector.getListingPayload).pipe(take(1))
                ])
            ),
            map(([type, payload]) => {
                
                if(type == '[Subject] Add Subject Success' || type == '[Subject] Edit Subject Success') {
                    this.router.navigateByUrl('/master-forms/manage/subjects');
                }
                return SubjectActions.LoadSubject({ payload });
            })
        )
    );
}