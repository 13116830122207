import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  selector: 'panjab-digi-lib-curated-collections',
  standalone: true,
  imports: [CommonModule,SlickCarouselModule,TranslateModule],
  templateUrl: './curated-collections.component.html',
  styleUrl: './curated-collections.component.scss',
})
export class CuratedCollectionsComponent {
  constructor(private translate: TranslateService) {

  }

  slides = [
    {
      img: "assets/images/collection1.png",
      heading: "Rural Rehabilitation in East Punjab",
      description: "Roman || The Publicity Department, Government of East Punjab, Shimla",
      number: "3 documents",
      button: "See collection",

    },
    {
      img: "assets/images/collection2.png",
      heading: "Rural Rehabilitation in East Punjab",
      description: "Roman || The Publicity Department, Government of East Punjab, Shimla",
      number: "3 documents",
      button: "See collection",
    },
    {
      img: "assets/images/collection3.png",
      heading: "Rural Rehabilitation in East Punjab",
      description: "Roman || The Publicity Department, Government of East Punjab, Shimla",
      number: "3 documents",
      button: "See collection",
    }
  ];
  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: false
        }
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          dots: false
        }
      }
    ]
  };

  slickInit(e: any) {
    console.log('slick initialized');
  }



}
