import { AbstractControl, FormGroup } from '@angular/forms';

type ValidationErrors = {
  [key: string]: any;
};

export class ResetPasswordValidator {
  static confirmed = (controlName: string, matchControlName: string) => {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get(controlName);
      const confirmPassword = control.get(matchControlName);

      if (confirmPassword?.errors && !confirmPassword.errors['passwordMismatch']) {
        // return if another validator has already found an error on the confirmPassword control
        return null;
      }

      // set error on confirmPassword if validation fails
      if (password?.value !== confirmPassword?.value) {
        confirmPassword?.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
      } else {
        confirmPassword?.setErrors(null);
        return null;
      }
    }
  }

  static verifyStrength = (giveStats: boolean=false) => {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /\d/.test(value);
      const hasSpecialChar = /[$@#^!%*?&_]/.test(value);

      const passwordValid = hasUpperCase && hasNumber && hasSpecialChar;

      if (!passwordValid) {
        return { passwordStrength: true };
      }
      
      return null;
    }
  }
  
  static getPwdStrengthStats(value: string) {
    const hasValidLength = value.length >= 6 && value.length <= 30;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[$@#^!%*?&_]/.test(value);

    return { hasValidLength, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar };
  }
}
