import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromAdoptedBooks from "../reducers/admin-adopted-books.reducer";

export const selectAdoptedBooksState =
  createFeatureSelector<fromAdoptedBooks.State>('adoptedBooksList');

export const getAdoptedBooks = createSelector(
    selectAdoptedBooksState,
    fromAdoptedBooks.selectAll
);

export const getTotalRecords = createSelector(
  selectAdoptedBooksState,
  (state) => state.totalRecords
);


export const getListingPayload = createSelector(
  selectAdoptedBooksState,
  (state) => state.payload
);

export const onSave = createSelector(
  selectAdoptedBooksState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);