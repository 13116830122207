import { createAction, props } from '@ngrx/store';

export const LoadUsers = createAction(
    '[Users] Load Users',
    props<{ id: number }>()
);

export const LoadUsersSuccessfully = createAction(
    '[Users] Load Users Successfully',
    props<{ users: any[] }>()
);

export const LoadUsersError = createAction(
    '[Users] Load Users Error',
    props<{ message: string }>()
);

export const SaveUser = createAction(
    '[Users] Save Users'
);

export const SaveUserSuccessfully = createAction(
    '[Users] Save Users Successfully',
    props<{ users: any[] }>()
);

export const SaveUserError = createAction(
    '[Users] Save Users Error',
    props<{ message: string }>()
);