import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPermission from '../reducers/permission.reducer';

export const selectState =
  createFeatureSelector<fromPermission.State>('userPermission');

export const loadPermission = createSelector(selectState, (state) => ({
  permissionList: fromPermission.selectAll(state),
}));

export const selectPermissionsLoaded = createSelector(
  selectState,
  (state) => state.permissionsLoaded 
);