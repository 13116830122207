import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
@Injectable({
  providedIn: 'root'
})
export class DownloadsService {
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }
  //////// get all downloads based on page number and page size ///////////////
  loadAllDownloads(data:any) {
    return this.http.get(`${this.rootURL}${WEB_API_ENDPOINTS.downloads.allDownloads}`.replace('{pageNumber}',data?.pageNumber).replace('{pageSize}',data?.pageSize).replace('{sortBy}',data?.sortBy));
  }
}
