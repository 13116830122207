/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WebDonationActions } from '../actions/index';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { UserService } from 'shared/src/services/website/user/user.service';

@Injectable()
export class WebDonationEffects {

  constructor(
    private actions$: Actions,
    private userService: UserService
  ) {}
  
  GetDonationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebDonationActions.GetDonationList),
      exhaustMap(action =>
        this.userService.getDonationList(action?.payload).pipe(
          map((response) => {
            return WebDonationActions.GetDonationListSuccess({ 
                donations: response.donations, 
                totalRecords: response.totalCount 
            });
          }),
          catchError((error) => of(WebDonationActions.GetDonationListError(error))))
      )
    )
  );
}