import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'panjab-digi-lib-spinner',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
})
export class SpinnerComponent {
  @Input() name = '';
  @Input() isFullScreen: boolean = true;
}