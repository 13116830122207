/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { TrendingDataService } from 'shared/src/services/admin/trending/trending-data.service';
import { AdminTrendingActions } from '../actions/index';

@Injectable()
export class AdminTrendingEffects {
  constructor(
    private actions$: Actions,
    private trendingDataService: TrendingDataService
  ) {}

  //////////////// load trending keywords ////////////
  LoadTrendingKeywords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminTrendingActions.LoadTrendingKeywords),
      exhaustMap((action) =>
        this.trendingDataService.homePageTrendingKeywords(action?.payload).pipe(
          map((result: any) => {
            return AdminTrendingActions.LoadTrendingKeywordsSuccessfully({
              trendingList: result?.body?.keywords,
              totalRecords: result?.body?.totalCount,
            });
          }),
          catchError((error: any) =>
            of(AdminTrendingActions.LoadTrendingKeywordsError(error))
          )
        )
      )
    )
  );


    //////////////// update status of keywords to show on application or not ////////////
    UpdateKeywordShowOnApplicationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminTrendingActions.UpdateKeywordShowOnApplicationStatus),
      exhaustMap((action) => {
        return this.trendingDataService
          .updateKeywordApplicationStatus(action?.payload)
          .pipe(
            map((response: any) => {
              return AdminTrendingActions.UpdateKeywordShowOnApplicationStatusSuccessfully(
                { trendingItem: response?.body[0], message: response?.message }
              );
            }),
            catchError((error: any) =>
              of(
                AdminTrendingActions.UpdateKeywordShowOnApplicationStatusError({
                  message: error?.message,
                })
              )
            )
          );
      })
    )
  );
}
