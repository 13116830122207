/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap} from 'rxjs/operators';

import { UserTypesActions } from '@panjab-digi-lib/shared';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';

@Injectable()
export class UserTypesEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private authService: AuthenticationService
    ) {
    }

    LoadUserTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserTypesActions.LoadUserTypes),
            switchMap(({payload}) => {
                return this.authService.sendHttpRequest('GET', 'userTypes', {params: {access: payload.access}}, false).pipe(
                    map((response) => {
                        return UserTypesActions.LoadUserTypesSuccess({ userTypes: response.body });
                    }),
                    catchError((error: any) => of(UserTypesActions.LoadUserTypesError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
}