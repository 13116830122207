<!-- change password modal -->
<ng-template #changePasswdModal let-modal>
    <form [formGroup]="changePasswdForm" class="changePasswordForm mt-5">
      <!-- show alert -->
      <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
      <!-- /show alert -->
      <div class="form-group text-start mt-2">
        <div class="d-flex">
          <label class="form-label required" [ngClass]="{'text-danger': changePasswdForm.controls['oldPassword'].invalid && changePasswdForm.controls['oldPassword'].touched}"> {{'pdl-website.forms.labels.changePasswordForm.oldPassword' | translate }}</label>
          <span class="flex-grow-1"></span>
          @if(changePasswdForm.controls['oldPassword'].invalid && changePasswdForm.controls['oldPassword'].touched) {
            <ng-container *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.required' | translate }"></ng-container>
          }
        </div>
        <div class="input__password">
            <input [type]="oldPasswdInputType" formControlName="oldPassword" #oldPassword class="form-control" id="oldPassword" aria-label="Old Password" [ngClass]="{'border-danger': changePasswdForm.controls['oldPassword'].invalid && changePasswdForm.controls['oldPassword'].touched}">    
            <img [src]="oldPasswdInputType === 'password' ? hidePasswordImg : showPasswordImg" (click)="toggleInputType(oldPassword)" alt="eye-icon" />
        </div>
      </div>
      <div class="form-group text-start mt-2">
        <div class="d-flex">
          <label class="form-label required" [ngClass]="{'text-danger': changePasswdForm.controls['newPassword'].invalid && changePasswdForm.controls['newPassword'].touched}"> {{'pdl-website.forms.labels.changePasswordForm.newPassword' | translate }}</label>
          <span class="flex-grow-1"></span>
          @if(changePasswdForm.controls['newPassword'].invalid && changePasswdForm.controls['newPassword'].touched) {
            <ng-container *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.password' | translate }"></ng-container>
          }
        </div>
        <div class="input__password">
            <input [type]="newPasswdInputType" formControlName="newPassword" #newPassword class="form-control" id="newPassword"  aria-label="New Password" [ngClass]="{'border-danger': changePasswdForm.controls['newPassword'].invalid && changePasswdForm.controls['newPassword'].touched}">
            <img [src]="newPasswdInputType === 'password' ? hidePasswordImg : showPasswordImg" (click)="toggleInputType(newPassword)" alt="eye-icon" />
        </div>
      </div>
      <div class="form-group text-start mt-2">
        <div class="d-flex">
          <label class="form-label required" [ngClass]="{'text-danger': changePasswdForm.controls['retypePassword'].invalid && changePasswdForm.controls['retypePassword'].touched}"> {{'pdl-website.forms.labels.changePasswordForm.retypePassword' | translate }}</label>
          <span class="flex-grow-1"></span>
          @if(changePasswdForm.controls['retypePassword'].invalid && changePasswdForm.controls['retypePassword'].touched) {
            <ng-container *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidConfirmPasswd' | translate }"></ng-container>
          }
        </div>
        <div class="input__password">
            <input [type]="retypePasswdInputType" formControlName="retypePassword" #retypePassword class="form-control" id="retypePassword"  aria-label="Retype Password" [ngClass]="{'border-danger': changePasswdForm.controls['retypePassword'].invalid && changePasswdForm.controls['retypePassword'].touched}">
            <img [src]="retypePasswdInputType === 'password' ? hidePasswordImg : showPasswordImg" (click)="toggleInputType(retypePassword)" alt="eye-icon" />
        </div>
      </div>
    </form>
</ng-template>
<!-- /change password modal -->
<!-- Template - invalid field error -->
<ng-template #invalidError let-msg="msg">
    <span class="text-danger">
        <img src="../../../assets/images/icons/error-icon.svg" [ngbTooltip]="tipContent" alt="error" />
        <ng-template #tipContent>{{msg}}</ng-template>
    </span>
</ng-template>
