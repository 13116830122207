<div class="modal-wrapper">
  <div class="modal_container">
    <div class="closebtn cursor-pointer" (click)="goback()">
      X
    </div>
    <div class="pt-5 pb-3">
      <div class="semibold text-center heading mb-2">
        Are you sure you want to delete this
        Research topics ?</div>
      <div class="subheading text-center mb-3">All the saved information will be deleted immediately, you can't undo
        this action.
      </div>
      <div class="text-center d-flex mx-auto mt-3 justify-content-center col-gap-12">
        <button class="btn btn__secondary" (click)="goback()">Cancel</button>
        <button class="btn btn__primary">Create</button>
      </div>
    </div>
  </div>
</div>
