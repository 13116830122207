import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { StaticPageService } from '../StaticPageService';
import { StaticPageHeadComponent } from '../staticPageHead/staticPageHead.component';
import { SafeHtmlPipe } from 'shared/src/pipes/safe-html.pipe';

@Component({
  selector: 'panjab-digi-lib-privacy-policy',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    StaticPageHeadComponent,
    SafeHtmlPipe,
  ],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss',
})
export class PrivacyPolicyComponent {
  content = '';

  constructor(private privacyPolicyService: StaticPageService) {}
  ngOnInit(): void {
    this.privacyPolicyService
      .getStaticContent('en', 'privacy-policy')
      .subscribe((data: any) => {
        this.content = data.body.Content;
      });
  }
}
