import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromAdminTrending from "../reducers/admin-trending.reducer";

export const selectState =
  createFeatureSelector<fromAdminTrending.State>('adminTrending');
  

export const getTodayTrendingList = createSelector(
    selectState,
    (state) => ({
      trendingList: fromAdminTrending.selectAll(state),
      totalRecords: state.totalRecords
  })
);

export const getKeywordDetail = (keywordId: number) => createSelector(
    selectState,
    (state: any) => {
      return state?.entities[keywordId];
    }
  );

export const isLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const onSave = createSelector(
  selectState,
  (state) => [state.isError, state.errorMessage, state.successMessage,state.isLoading]
);

