/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { PermissionActions } from '../actions/index';
import { Permission } from 'shared/src/interfaces/permission.interface';

export interface State extends EntityState<Permission> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  permissionsLoaded?: boolean;
}

export const adapter = createEntityAdapter<Permission>({
  selectId: (e) =>  `${e?.ProcessID}-${e?.AccessType}`,
});
export const initialState = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  permissionsLoaded: false
});

export const reducer = createReducer(
  initialState,
  on(PermissionActions.UserPermission, (state, action) => ({
    ...state,
    isLoading: true,
    isError: false,
    errorMessage: '',
    permissionsLoaded: false
  })),
  
  on(PermissionActions.UserPermissionSuccess, (state, action) =>
    adapter.setAll(action?.permissions, {
      ...state,
      isLoading: false,
      permissionsLoaded: true
    })
  ),

  on(PermissionActions.UserPermissionError, (state, action) => ({
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.message,
    permissionsLoaded: false
  }))
);

export const { selectAll } = adapter.getSelectors();
