import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { environment } from '@panjab-digi-lib/shared'

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  rootURL: string = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadUsers() {
    return this.http.get(this.rootURL+'products').pipe(map((e) => e));
  }
}
