import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, SectionActions, SectionSelector } from '@panjab-digi-lib/shared';
import { Update } from '@ngrx/entity';
import { Section } from 'shared/src/interfaces/master-form.interface';
import { SectionService } from 'shared/src/services/admin/master-form/section.service';

@Injectable()
export class SectionEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private sectionService: SectionService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadSection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SectionActions.LoadSection),
            exhaustMap((action) => {

                return this.sectionService.loadSection(action.payload).pipe(
                    map((response) => {
                        return SectionActions.LoadSectionSuccess({ sections: response.sections, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(SectionActions.LoadSectionError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
    
    UpdateSectionStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SectionActions.UpdateSectionStatus),
            exhaustMap((action) => {

                return this.sectionService.updateSectionStatus(action.SectionIDs, action.status).pipe(
                    map((response: any) => {
                        const sectionUpdates: Update<Section>[] = action.SectionIDs.map((id) => {
                            return {
                                id, changes: {
                                    Status: action.status
                                }
                            }
                        })

                        return SectionActions.UpdateSectionStatusSuccess({ sectionUpdates, message: response.message });
                    }),
                    catchError((error: any) => of(SectionActions.UpdateSectionStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    AddSection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SectionActions.AddSection),
            exhaustMap((action) => {

                return this.sectionService.addSection(action.payload).pipe(
                    map((response) => {

                        return SectionActions.AddSectionSuccess({ Section: response.body, message: response.message });
                    }),
                    catchError((error: any) => of(SectionActions.AddSectionError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    EditSection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SectionActions.EditSection),
            exhaustMap((action) => {
                
                return this.sectionService.editSection(action.payload, action.sectionId).pipe(
                    map((response) => {

                        return SectionActions.EditSectionSuccess({Section: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(SectionActions.EditSectionError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );
        
    DeleteSection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SectionActions.DeleteSection),
            exhaustMap((action) => {

                return this.sectionService.deleteSection(action.SectionIDs).pipe(
                    map((response: any) => {

                        return SectionActions.DeleteSectionSuccess({ message: response.message });
                    }),
                    catchError((error: any) => of(SectionActions.DeleteSectionError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    AddDeleteSectionSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SectionActions.AddSectionSuccess, SectionActions.EditSectionSuccess, SectionActions.DeleteSectionSuccess),
            switchMap((action) => combineLatest([
                    of(action.type),
                    this.store.select(SectionSelector.getListingPayload).pipe(take(1))
                ])
            ),
            map(([type, payload]) => {
                
                if(type == '[Section] Add Section Success' || type == '[Section] Edit Section Success') {
                    this.router.navigateByUrl('/master-forms/manage/sections');
                }
                return SectionActions.LoadSection({ payload });
            })
        )
    );
}