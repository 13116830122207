/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { environment } from 'shared/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AddEditAuthorPayload, AddEditAuthorResponse, DeleteAuthorPayload, DeleteAuthorResponse, GetAuthorPayload, GetAuthorResponse, MergeAuthorPayload, MergeAuthorsResponse, UpdateAuthorStatusPayload, UpdateAuthorStatusResponse } from 'shared/src/interfaces/author.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.masterForm.author;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }
    ////////// load Authors /////////
    loadAuthors(payload: GetAuthorPayload) {
      let url = `${this.rootURL}${this.endPointUrl.default}`;
      url = url
        .replace('{sortBy}', payload?.sortBy ?? 'name')
        .replace('{orderBy}', payload?.orderBy ?? 'asc');
        if(payload?.pageSize) {
        url += `&pageSize=${payload?.pageSize}`
        }
        if(payload?.pageNumber) {
        url += `&pageNumber=${payload?.pageNumber}`
        }
        if(payload?.query) {
        url += `&query=${payload?.query}`
        }
      return this.http
        .get<GetAuthorResponse>(url)
        .pipe(map((e: GetAuthorResponse) => e?.body));
    }
  
    ////////////// Author detail ////////////////
    authorDetail(id:number) {
      const url = `${this.rootURL}${this.endPointUrl.authorById}`.replace('{id}', (id ?? '').toString());
      return this.http.get<AddEditAuthorResponse>(url)
      .pipe(map((e: AddEditAuthorResponse) => e?.body));
    }
  
    ////////// add Author /////////
    addAuthor(payload: AddEditAuthorPayload) {
      const url = `${this.rootURL}${this.endPointUrl.author}`;
      return this.http
        .post<AddEditAuthorResponse>(url, payload)
        .pipe(map((e: AddEditAuthorResponse) => e));
    }
  
    ////////// update Author /////////
    updateAuthor(payload: AddEditAuthorPayload,id:number) {
      let url = `${this.rootURL}${this.endPointUrl.authorById}`;
      url = url.replace('{id}', (id ?? '').toString());
      return this.http
        .put<AddEditAuthorResponse>(url, payload)
        .pipe(map((e: AddEditAuthorResponse) => e));
    }
  
    ////////// update Author status/////////
    updateAuthorStatus(payload: UpdateAuthorStatusPayload) {
      const url = `${this.rootURL}${this.endPointUrl.author}`;
      return this.http
        .patch<UpdateAuthorStatusResponse>(url, payload)
        .pipe(map((e: UpdateAuthorStatusResponse) => e));
    }
  
    ////////// delete Author/////////
    deleteAuthor(payload: DeleteAuthorPayload) {
      const url = `${this.rootURL}${this.endPointUrl.author}`;
      return this.http
        .delete<DeleteAuthorResponse>(url, { body: payload })
        .pipe(map((e: DeleteAuthorResponse) => e));
    }
  
    ////////// merge Authors /////////
    mergeAuthors(payload: MergeAuthorPayload) {
      const url = `${this.rootURL}${this.endPointUrl.merge}`;
      return this.http
        .put<MergeAuthorsResponse>(url, payload)
        .pipe(map((e: MergeAuthorsResponse) => e));
    }
}
