/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminAuthorActions } from '../actions';
import { catchError, exhaustMap, map, of, switchMap, withLatestFrom } from 'rxjs';
import { AuthorService } from 'shared/src/services/admin/master-form/author.service';
import { Store, select } from '@ngrx/store';
import { AdminAuthorSelector, AppState } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';


@Injectable()
export class AdminAuthorEffects {
  constructor(private actions$: Actions, private service: AuthorService, private store: Store<AppState>, private router: Router,) {}

  //////////////// get all Authors ////////////
  LoadAuthorList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminAuthorActions.LoadAuthors),
      exhaustMap((action) => {
        return this.service.loadAuthors(action?.payload).pipe(
          map((response) => {
            return AdminAuthorActions.LoadAuthorSuccessfully({
              authorList: response?.authorList,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              AdminAuthorActions.LoadAuthorError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// add Author ////////////
  AddAuthor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminAuthorActions.AddAuthor),
      exhaustMap((action) => {
        return this.service.addAuthor(action?.payload).pipe(
          map((response) => {
            return AdminAuthorActions.AddAuthorSuccessfully({
              author: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminAuthorActions.AddAuthorError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Author ////////////
  UpdateAuthor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminAuthorActions.UpdateAuthor),
      exhaustMap((action) => {
        return this.service.updateAuthor(action?.payload,action?.id).pipe(
          map((response) => {
            return AdminAuthorActions.UpdateAuthorSuccessfully({
              author: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminAuthorActions.UpdateAuthorError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Author status////////////
  UpdateAuthorStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminAuthorActions.UpdateAuthorStatus),
      exhaustMap((action) => {
        return this.service.updateAuthorStatus(action?.payload).pipe(
          map((response) => {
            return AdminAuthorActions.UpdateAuthorStatusSuccessfully({
              author: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminAuthorActions.UpdateAuthorStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  ///////////////// delete Author ///////////////////////
  DeleteAuthor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminAuthorActions.DeleteAuthor),
      exhaustMap((action) =>
        this.service.deleteAuthor(action?.payload).pipe(
          map((response) => {
            return AdminAuthorActions.DeleteAuthorSuccessfully({
              message: response?.message,
            });
          }),
          catchError((res) =>
            of(
              AdminAuthorActions.DeleteAuthorError({
                message: res.error?.error?.message,
              })
            )
          )
        )
      )
    )
  );

//////////////// add Author ////////////
  MergeAuthor$ = createEffect(() =>
        this.actions$.pipe(
          ofType(AdminAuthorActions.MergeAuthor),
          exhaustMap((action) => {
            return this.service.mergeAuthors(action?.payload).pipe(
              map((response) => {
                return AdminAuthorActions.MergeAuthorSuccessfully({
                  message: response?.message,
                });
              }),
              catchError((error) =>
                of(
                  AdminAuthorActions.AddAuthorError({
                    message: error?.error?.error?.message,
                  })
                )
              )
            );
          })
        )
      );

 ///////////////////// redirect after adding/deleting/merging Author /////////////////////     
  AddDeleteMergeAuthorSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminAuthorActions.AddAuthorSuccessfully, AdminAuthorActions.DeleteAuthorSuccessfully,AdminAuthorActions.MergeAuthorSuccessfully),
      withLatestFrom(this.store.pipe(select(AdminAuthorSelector.loadAuthorList))),
      switchMap(([action, state]) => {
        this.router.navigateByUrl('/master-forms/manage/authors');
        const pageNumber = (action.type === AdminAuthorActions.AddAuthorSuccessfully.type || action.type === AdminAuthorActions.MergeAuthorSuccessfully.type) ? 1 : state?.pageNumber;
        const payload = { pageNumber: pageNumber, pageSize: state?.pageSize, sortBy: state?.sortBy,orderBy: state?.orderBy };
        return of(AdminAuthorActions.LoadAuthors({payload}));
      })
    )
  )
}
