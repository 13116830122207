<section class="form-page">
    <div class="container form-container">
        <div class="row d-flex">
          <!-- Column 1 - Static Content -->
          <div class="col-md-5 column-1">
            <div class="content fw-normal text-justify">
                <!-- Hide breadcrumbs in mobile view -->
                <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-md-block d-none">
                    <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                </div>
                <h5 class="fw-bold">{{'pdl-website.forms.user.pdl' | translate }}</h5>
                <div class="static-txt" [innerHtml]="'pdl-website.forms.user.pdlText' | translate"></div>
            </div>
          </div>
          <!-- Column 2 - Form -->
          <div class="col-md-7 column-2">
            <div class="right-section">
                <div class="top-content">
                    <div class="header-title">
                        <!-- Show breadcrumbs in mobile view -->
                        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-block d-md-none">
                            <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                        </div>
                        <div class="title-txt mt-4">
                            <h5 class="fw-bolder">{{'pdl-website.forms.user.passwdRecovery' | translate }}</h5>
                        </div>
                    </div>
                    <div class="header-body">
                        <p class="fw-light" [innerHtml]="'pdl-website.forms.user.recoverPassHeaderText' | translate"></p>
                    </div>
                </div>
                <div class="form-section">
                    <!-- show alert -->
                    <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
                    <!-- /show alert -->
                    <p class="fw-bold">{{'pdl-website.forms.user.formHeader' | translate }}</p>
                    <form [formGroup]="recoverPasswdForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex">
                                    <label for="email" class="form-label reqd" [ngClass]="{'text-danger': recoverPasswdForm.controls['email'].invalid && recoverPasswdForm.controls['email'].touched}">{{'pdl-website.forms.labels.emailAddress' | translate }}</label>
                                    <span class="flex-grow-1"></span>
                                    @if(recoverPasswdForm.controls['email'].touched && recoverPasswdForm.controls['email'].errors && recoverPasswdForm.controls['email']['errors']['required']) {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                    }
                                    @if(recoverPasswdForm.controls['email'].touched && recoverPasswdForm.controls['email'].errors && recoverPasswdForm.controls['email']['errors']['pattern']) {
                                        <ng-container *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidEmail' | translate }"></ng-container>
                                    }
                                    @if(recoverPasswdForm.controls['email'].touched && recoverPasswdForm.controls['email'].valid) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                    }
                                </div>
                                <input type="text" class="form-control" formControlName="email" [ngClass]="{'border-danger': recoverPasswdForm.controls['email'].invalid && recoverPasswdForm.controls['email'].touched}">
                            </div>
                        </div>
                        <!-- recaptch -->
                        <div class="row mt-4">
                            <div class="col-12">
                                <re-captcha #captchaRef (resolved)="resolveCaptcha($event)" formControlNme="cpt" siteKey="{{sitekey}}" [size]="isMobile ? 'compact' : 'normal'"></re-captcha>
                            </div>
                        </div>
                        <!-- /recaptch -->
                        <!-- submit buttons -->
                        <div class="row">
                            <div class="col-12 text-center">
                                @if(isSubmitted) {
                                    <panjab-digi-lib-spinner-button [name]="'pdl-shared.loaderState.pleaseWait' | translate"></panjab-digi-lib-spinner-button>
                                } @else {
                                    <button type="submit" class="btn btn-primary btn__primary" [disabled]="recoverPasswdForm.invalid">
                                        <span>{{'pdl-website.forms.labels.retrievePasswd' | translate }}</span>
                                    </button>
                                }
                            </div>
                        </div>
                    </form>
                </div>
                <div class="pdl-user text-center pb-4">
                    <span>{{'pdl-website.forms.user.newToPdl' | translate }} </span>
                    <a href="javascript:void(0);" [routerLink]="'/register-user'" class="fw-bold">{{'pdl-website.forms.labels.signUp' | translate }}</a>
                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Template - required field error -->
    <ng-template #requiredError>
        <span class="text-danger">
            <img src="../../../assets/images/icons/error-icon.svg" alt="error" />
        </span>
    </ng-template>
    <!-- Template - invalid field error -->
    <ng-template #invalidError let-msg="msg">
        <span class="text-danger">
            <img src="../../../assets/images/icons/error-icon.svg" [ngbTooltip]="tipContent" alt="error" />
            <ng-template #tipContent>{{msg}}</ng-template>
        </span>
    </ng-template>
    <!-- Template - valid field -->
    <ng-template #validFld>
    <span class="text-success">
        <img src="../../../assets/images/icons/checked-icon.svg" alt="success" />
    </span>
    </ng-template>
</section>