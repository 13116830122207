import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromWebDownloads from '../../reducers/website/web-download.reducer';

export const selectDownloadsState =
  createFeatureSelector<fromWebDownloads.State>('webDownloads');

// export const getDownloads = createSelector(
//   selectDownloadsState,
//   (state) => ({
//     downloads: fromWebDownloads.selectAll(state),
// })
// );

export const getDownloads = createSelector(selectDownloadsState, (state:any) => {
  return {
    downloadImages: fromWebDownloads.selectAll(state),
    totalRecords: state.totalRecords,
  };
});

export const isLoading = createSelector(
  selectDownloadsState,
  (state) => state.isLoading
);