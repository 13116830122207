/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import { WebAdoptedBooksActions } from '../../actions/index';
import { Adopter } from 'shared/src/interfaces/fundraising.interface';


export interface State extends EntityState<Adopter> {
  isLoading: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  totalRecords:number;
}

export const adapter = createEntityAdapter<Adopter>({
  selectId: (e) => e?.AdoptByID
});

export const initialState = adapter.getInitialState( {
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0
});

export const reducer = createReducer(
  initialState,
  on(WebAdoptedBooksActions.GetAdoptedBooksList,(state) =>
    ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebAdoptedBooksActions.GetAdoptedBooksListSuccess,(state, action) =>
  ({
      ...adapter.setAll(action.adoptedBooksList, state),
      totalRecords: action.totalCount,
      isLoading: false,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebAdoptedBooksActions.GetAdoptedBooksListError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    })
  )
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();