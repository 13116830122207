/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { environment } from 'shared/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AddEditContributorPayload, AddEditContributorResponse, DeleteContributorPayload, DeleteContributorResponse, GetContributorPayload, GetContributorResponse, MergeContributorPayload, MergeContributorsResponse, UpdateContributorStatusPayload, UpdateContributorStatusResponse } from 'shared/src/interfaces/contributor.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContributorService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.masterForm.contributor;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }
    ////////// load Contributors /////////
    loadContributors(payload: GetContributorPayload) {
      let url = `${this.rootURL}${this.endPointUrl.default}`;
      url = url
        .replace('{sortBy}', payload?.sortBy ?? 'name')
        .replace('{orderBy}', payload?.orderBy ?? 'asc');
        if(payload?.pageSize) {
        url += `&pageSize=${payload?.pageSize}`
        }
        if(payload?.pageNumber) {
        url += `&pageNumber=${payload?.pageNumber}`
        }
        if(payload?.query) {
        url += `&query=${payload?.query}`
        }
      return this.http
        .get<GetContributorResponse>(url)
        .pipe(map((e: GetContributorResponse) => e?.body));
    }
  
    ////////////// Contributor detail ////////////////
    contributorDetail(id:number) {
      const url = `${this.rootURL}${this.endPointUrl.contributorById}`.replace('{id}', (id ?? '').toString());
      return this.http.get<AddEditContributorResponse>(url)
      .pipe(map((e: AddEditContributorResponse) => e?.body));
    }
  
    ////////// add Contributor /////////
    addContributor(payload: AddEditContributorPayload) {
      const url = `${this.rootURL}${this.endPointUrl.contributor}`;
      return this.http
        .post<AddEditContributorResponse>(url, payload)
        .pipe(map((e: AddEditContributorResponse) => e));
    }
  
    ////////// update Contributor /////////
    updateContributor(payload: AddEditContributorPayload,id:number) {
      let url = `${this.rootURL}${this.endPointUrl.contributorById}`;
      url = url.replace('{id}', (id ?? '').toString());
      return this.http
        .put<AddEditContributorResponse>(url, payload)
        .pipe(map((e: AddEditContributorResponse) => e));
    }
  
    ////////// update Contributor status/////////
    updateContributorStatus(payload: UpdateContributorStatusPayload) {
      const url = `${this.rootURL}${this.endPointUrl.contributor}`;
      return this.http
        .patch<UpdateContributorStatusResponse>(url, payload)
        .pipe(map((e: UpdateContributorStatusResponse) => e));
    }
  
    ////////// delete Contributor/////////
    deleteContributor(payload: DeleteContributorPayload) {
      const url = `${this.rootURL}${this.endPointUrl.contributor}`;
      return this.http
        .delete<DeleteContributorResponse>(url, { body: payload })
        .pipe(map((e: DeleteContributorResponse) => e));
    }
  
    ////////// merge Contributors /////////
    mergeContributors(payload: MergeContributorPayload) {
      const url = `${this.rootURL}${this.endPointUrl.merge}`;
      return this.http
        .put<MergeContributorsResponse>(url, payload)
        .pipe(map((e: MergeContributorsResponse) => e));
    }
}
