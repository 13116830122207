import { createReducer, on } from '@ngrx/store';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {UserActions} from "../actions/index"

export interface State extends EntityState<any> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

export const adapter = createEntityAdapter<any>();

export const initialState = adapter.getInitialState( {
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: ''
});

export const reducer = createReducer(
  initialState,
  on(UserActions.LoadUsers,(state, action) =>
    ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    })
  ),
  on(UserActions.LoadUsersSuccessfully,(state, action) =>
    ({
      ...adapter.setAll(action.users, state),
      isLoading: false,
      isError: false,
      errorMessage: ''
    })
  ),
  on(UserActions.LoadUsersError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    })
  )
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();