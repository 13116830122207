import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromProcess from "../reducers/process.reducer";

export const selectProcessState =
  createFeatureSelector<fromProcess.State>('process');

export const getProcess = createSelector(
    selectProcessState,
    fromProcess.selectAll
);

export const getTotalRecords = createSelector(
  selectProcessState,
  (state) => state.totalRecords
);

export const getProcessByID = (props: {id: string}) => createSelector(
  selectProcessState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectProcessState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectProcessState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);