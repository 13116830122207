
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { Task } from 'shared/src/interfaces/staff/document.interface';

export const LoadTaskList = createAction(
  '[Staff] Load Task List',
  props<{ payload: GenericListingPayload, DocID: string }>()
);

export const LoadTaskListSuccess = createAction(
  '[Staff] Load Task List Success',
  props<{ taskList: Task[]; totalRecords: number }>()
);

export const LoadTaskListError = createAction(
  '[Staff] Load Task List Error',
  props<{ message: string }>()
);

export const ApproveTask = createAction(
  '[Staff] Approve Task',
  props<{ PSID: number }>()
);

export const ApproveTaskSuccess = createAction(
  '[Staff] Approve Task Success',
  props<{ task: Task, message: string }>()
);

export const ApproveTaskError = createAction(
  '[Staff] Approve Task Error',
  props<{ message: string }>()
);

export const BypassTask = createAction(
  '[Staff] Bypass Task',
  props<{ PSID: number, undoBypass: boolean }>()
);

export const BypassTaskSuccess = createAction(
  '[Staff] Bypass Task Success',
  props<{ task: Task, message: string }>()
);

export const BypassTaskError = createAction(
  '[Staff] Bypass Task Error',
  props<{ message: string }>()
);

export const RejectTask = createAction(
  '[Staff] Reject Task',
  props<{ PSID: number, reason: string }>()
);

export const RejectTaskSuccess = createAction(
  '[Staff] Reject Task Success',
  props<{ task: Task, message: string }>()
);

export const RejectTaskError = createAction(
  '[Staff] Reject Task Error',
  props<{ message: string }>()
);