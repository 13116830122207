/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { Video, WebVideosPayload } from 'shared/src/interfaces/video-gallery.interface';

export const LoadWebVideos = createAction(
    '[Videos] Load Website All Videos',
    props<{ payload: WebVideosPayload  }>()
);

export const LoadWebVideosSuccess = createAction(
    '[Videos] Load Website Videos Success',
    props<{ videos: Video[],totalRecords: number  }>()
);

export const LoadWebVideosError = createAction(
    '[Videos] Load Website Videos Error',
    props<{ message: string }>()
);