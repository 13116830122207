import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { AddEditDesignationPayload, Designation } from "shared/src/interfaces/master-form.interface";

export const LoadDesignation = createAction(
    '[Designation] Load Designation',
    props<{payload: GenericListingPayload}>(),
)

export const LoadDesignationSuccess = createAction(
    '[Designation] Load Designation Successfully',
    props<{designations: Designation[], totalRecords: number}>()
)

export const LoadDesignationError = createAction(
    '[Designation] Load Designation Error',
    props<{message: string}>()
)

export const UpdateDesignationStatus = createAction(
    '[Designation] Update Designation Status',
    props<{DesignationIDs: number[], status: string}>(),
)

export const UpdateDesignationStatusSuccess = createAction(
    '[Designation] Update Designation Status Success',
    props<{DesignationUpdates: any, message: string}>(),
)

export const UpdateDesignationStatusError = createAction(
    '[Designation] Update Designation Status Error',
    props<{message: string}>(),
)

export const AddDesignation = createAction(
    '[Designation] Add Designation',
    props<{payload: AddEditDesignationPayload}>(),
)

export const AddDesignationSuccess = createAction(
    '[Designation] Add Designation Success',
    props<{designation: Designation, message: string}>(),
)

export const AddDesignationError = createAction(
    '[Designation] Add Designation Error',
    props<{message: string}>(),
)

export const EditDesignation = createAction(
    '[Designation] Edit Designation',
    props<{payload: AddEditDesignationPayload, designationId: string}>(),
)

export const EditDesignationSuccess = createAction(
    '[Designation] Edit Designation Success',
    props<{designation: Designation, message: string}>(),
)

export const EditDesignationError = createAction(
    '[Designation] Edit Designation Error',
    props<{message: string}>(),
)

export const DeleteDesignation = createAction(
    '[Designation] Delete Designation',
    props<{DesignationIDs: number[]}>(),
)

export const DeleteDesignationSuccess = createAction(
    '[Designation] Delete Designation Success',
    props<{message: string}>(),
)

export const DeleteDesignationError = createAction(
    '[Designation] Delete Designation Error',
    props<{message: string}>(),
)