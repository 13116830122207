export const environment = {
    production: false,
    staging: false,
    development: true,
    rootApiUrl: 'https://devapi.panjabdigilib.org/api/',
    adminApiPrefix: 'admin/',
    staffApiPrefix: 'staff/',
    sharedApiPrefix: 'shared/',
    appApiPrefix: 'app/',
    rootApiPublicUrl: 'https://devapi.panjabdigilib.org/',
    homepageUrl: 'https://dev.panjabdigilib.org/',
    recaptcha: {
        siteKey: '6LdeR9wpAAAAAJdXLaGC7TT53CyF5K71GB2B5W0A'
    },
    social: {
        GOOGLE_CLIENT_ID: '346028492508-tm41lk5f4e12n8qbm7khqffc1eccuf8n.apps.googleusercontent.com',
        FB_APP_ID: '1161989674840271'
    },
    donorBoxConfig: {
        donorBoxJS: 'https://donorbox.org/widget.js',
        donationLink: 'https://donorbox.org/embed/test-donate-12'
    },
    razorPayConfig: {
        razorPayJS: 'https://checkout.razorpay.com/v1/payment-button.js',
        razorPayBtnID: 'pl_OFV2ljkPaFLLTV'
    },
    availableCountriesForDonation: [
        1, 2, 3, 4, 5
    ]
};

// export const environment = {
//     production: false,
//     staging: false,
//     development: true,
//     rootApiUrl: 'https://api-pdl-dev.trantorglobal.com/api/',
//     adminApiPrefix: 'admin/',
//     staffApiPrefix: 'staff/',
//     sharedApiPrefix: 'shared/',
//     appApiPrefix: 'app/',
//     rootApiPublicUrl: 'https://api-pdl-dev.trantorglobal.com/',
//     homepageUrl: 'https://pdl-dev.trantorglobal.com/',
//     recaptcha: {
//         siteKey: '6LdeR9wpAAAAAJdXLaGC7TT53CyF5K71GB2B5W0A'
//     },
//     social: {
//         GOOGLE_CLIENT_ID: '346028492508-tm41lk5f4e12n8qbm7khqffc1eccuf8n.apps.googleusercontent.com',
//         FB_APP_ID: '1161989674840271'
//     },
//     donorBoxConfig: {
//         donorBoxJS: 'https://donorbox.org/widget.js',
//         donationLink: 'https://donorbox.org/embed/test-donate-12'
//     },
//     razorPayConfig: {
//         razorPayJS: 'https://checkout.razorpay.com/v1/payment-button.js',
//         razorPayBtnID: 'pl_OFV2ljkPaFLLTV'
//     },
//     availableCountriesForDonation: [
//         1, 2, 3, 4, 5
//     ]
// };
