import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromWebAdoptedBooks from "../../reducers/website/web-adopted-books.reducer";

export const selectAdoptedBooksState = createFeatureSelector<fromWebAdoptedBooks.State>('webAdoptedBooks');

export const getAdoptedBooks = createSelector(
  selectAdoptedBooksState,
  fromWebAdoptedBooks.selectAll
);

export const getTotalRecords = createSelector(
  selectAdoptedBooksState,
  (state) => state.totalRecords
);

export const isLoading = createSelector(
  selectAdoptedBooksState,
  (state) => state.isLoading
);