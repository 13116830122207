import { StaffListEffects } from './staff-list.effects';
import { DocumentListEffects } from './document-list.effects';
import { TaskEffects } from './task.effects';
import { StaffTaskEffects } from './staff-task.effects';
import { AssignedTaskEffects } from './assigned-tasks.effects';
import { StaffWorkEffects } from './staff-work.effects';

export {
  StaffListEffects,
  DocumentListEffects,
  TaskEffects,
  StaffTaskEffects,
  AssignedTaskEffects,
  StaffWorkEffects,
}