/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { AdminContributorActions } from '../actions/index';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import {Contributor} from 'shared/src/interfaces/contributor.interface';
export interface State extends EntityState<Contributor> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  totalRecords: number;
  pageNumber : number,
  pageSize: number,
  sortBy: string,
  orderBy:string
}

export const adapter = createEntityAdapter<Contributor>({
  selectId: (e) => e?.ContID,
});

export const initialState = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0,
  pageNumber : 0,
  pageSize: 0,
  sortBy: 'DateAdded',
  orderBy: 'desc'
});

export const reducer = createReducer(
  initialState,

  on(AdminContributorActions.LoadContributors, (state,action) => ({
    ...state,
    isLoading: true,
    successMessage: '',
    errorMessage: '',
    pageNumber: action.payload?.pageNumber || 1,
    pageSize: action.payload?.pageSize || 0,
  })),

  on(AdminContributorActions.LoadContributorSuccessfully, (state, action) =>
    adapter.setAll(action?.contributorList, {
      ...state,
      totalRecords: action.totalRecords,
      isLoading: false,
    })
  ),

  on(AdminContributorActions.LoadContributorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminContributorActions.AddContributor, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminContributorActions.AddContributorSuccessfully, (state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: action?.message
    })
  ),

  on(AdminContributorActions.AddContributorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminContributorActions.UpdateContributor, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminContributorActions.UpdateContributorSuccessfully, (state, action) => ({
    ...adapter.updateOne(
      { id: action?.contributor?.ContID, changes: action.contributor },
      state
    ),
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: action?.message,
  })),

  on(AdminContributorActions.UpdateContributorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      successMessage: '',
      errorMessage: action?.message,
    };
  }),

  on(AdminContributorActions.UpdateContributorStatus, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminContributorActions.UpdateContributorStatusSuccessfully, (state, action) => {
    const updates = action?.contributor;
    const updatedEntities = adapter.updateMany(
      updates.map((update: Contributor) => ({
        id: update.ContID,
        changes: update,
      })),
      state
    );
    return {
      ...updatedEntities,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action?.message,
    };
  }),

  on(AdminContributorActions.UpdateContributorStatusError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),

  on(AdminContributorActions.DeleteContributor, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminContributorActions.DeleteContributorSuccessfully, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: false,
      successMessage: action?.message,
      errorMessage: '',
    };
  }),

  on(AdminContributorActions.DeleteContributorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),
  on(AdminContributorActions.MergeContributor, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminContributorActions.MergeContributorSuccessfully, (state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: action?.message
    })
  ),

  on(AdminContributorActions.MergeContributorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),
);

export const { selectAll } = adapter.getSelectors();
