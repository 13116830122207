/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { TrendingDataService } from 'shared/src/services/admin/trending/trending-data.service';
import { AdminKeywordActions } from '../actions/index';

@Injectable()
export class AdminKeywordEffects {
  constructor(
    private actions$: Actions,
    private trendingDataService: TrendingDataService
  ) {}


  //////////////// load all keywords ////////////
  LoadAllTrendingKeywords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminKeywordActions.LoadAllTrendingKeywords),
      exhaustMap((action) =>
        this.trendingDataService.allTrendingKeywords(action?.payload).pipe(
          map((result: any) => {
            return AdminKeywordActions.LoadAllTrendingKeywordsSuccessfully({
              trendingList: result?.body?.keywords,
              totalRecords: result?.body?.totalCount,
            });
          }),
          catchError((error: any) =>
            of(AdminKeywordActions.LoadAllTrendingKeywordsError(error))
          )
        )
      )
    )
  );

    //////////////// update status of keywords to show on homepage of admin or not ////////////
    UpdateKeywordShowOnHomePageStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminKeywordActions.UpdateKeywordShowOnHomePageStatus),
      exhaustMap((action) => {
        return this.trendingDataService
          .updateKeywordHomePageStatus(action?.payload)
          .pipe(
            map((response: any) => {
              return AdminKeywordActions.UpdateKeywordShowOnHomePageSuccessfully(
                { trendingItem: response?.body, message: response?.message }
              );
            }),
            catchError((error: any) =>
              of(
                AdminKeywordActions.UpdateKeywordShowOnHomePageStatusError({
                  message: error?.message,
                })
              )
            )
          );
      })
    )
  );


  //////////////// get keyword details ////////////
  GetKeywordDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminKeywordActions.GetKeywordDetail),
      exhaustMap((action) => {
        return this.trendingDataService.keywordDetail(action?.payload).pipe(
          map((response: any) => {
            return AdminKeywordActions.GetKeywordDetailSuccessfully({
              keywordDetail: response?.body,
              message: response?.message,
            });
          }),
          catchError((error: any) =>
            of(
              AdminKeywordActions.GetKeywordDetailError({
                message: error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// merge the keywords ////////////
  MergeKeywords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminKeywordActions.MergeKeywords),
      exhaustMap((action) => {
        return this.trendingDataService.mergeKeywords(action?.payload).pipe(
          map((response: any) => {
            return AdminKeywordActions.MergeKeywordsSuccessfully({
              keyword: response?.body,
              message: response?.message,
            });
          }),
          catchError((error: any) =>
            of(
              AdminKeywordActions.MergeKeywordsStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );
}
