import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromSubjectsPerCategory from "../reducers/subjectsPerCategory.reducer";

export const selectSubjectsPerCategoryState =
  createFeatureSelector<fromSubjectsPerCategory.State>('subjectsPerCategory');

export const getSubjectsForCategories = createSelector(
    selectSubjectsPerCategoryState,
    fromSubjectsPerCategory.selectAll
);

export const getSubjectsPerCategoryByID = (props: {id: number}) => createSelector(
  selectSubjectsPerCategoryState,
  (state) => state.entities[props.id]
)

export const getSubjectsOfCategory = createSelector(
    selectSubjectsPerCategoryState,
    (state) => state.subjectsOfCategory,
);

export const getTotalRecords = createSelector(
    selectSubjectsPerCategoryState,
    (state) => state.totalRecords,
);

export const onSave = createSelector(
  selectSubjectsPerCategoryState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectSubjectsPerCategoryState,
  (state) => state.isLoading
);

export const getListingPayload = createSelector(
    selectSubjectsPerCategoryState,
    (state) => state.payload
)