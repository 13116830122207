import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, SubjectsPerCategoryActions, SubjectsPerCategorySelector } from '@panjab-digi-lib/shared';
import { SubjectsPerCategoryService } from 'shared/src/services/admin/master-form/subjectsPerCategory.service';

@Injectable()
export class SubjectsPerCategoryEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private subjectsPerCategoryService: SubjectsPerCategoryService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadSubjectsPerCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubjectsPerCategoryActions.LoadSubjectsPerCategory),
            exhaustMap((action) => {

                return this.subjectsPerCategoryService.loadSubjectsPerCategory().pipe(
                    map((response) => {
                        return SubjectsPerCategoryActions.LoadSubjectsPerCategorySuccess({ categories: response.categories });
                    }),
                    catchError((error: any) => of(SubjectsPerCategoryActions.LoadSubjectsPerCategoryError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    LoadSubjectsOfCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubjectsPerCategoryActions.LoadSubjectsOfCategory),
            exhaustMap((action) => {

                return this.subjectsPerCategoryService.loadSubjectsOfCategory(action.payload, action.categoryID).pipe(
                    map((response) => {
                        return SubjectsPerCategoryActions.LoadSubjectsOfCategorySuccess({ subjects: response.subjects, totalCount: response.totalCount, categoryID: action.categoryID });
                    }),
                    catchError((error: any) => of(SubjectsPerCategoryActions.LoadSubjectsOfCategoryError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    SaveAllocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubjectsPerCategoryActions.SaveAllocations),
            exhaustMap((action) => {

                return this.subjectsPerCategoryService.saveAllocations(action.payload, action.categoryID).pipe(
                    map((response) => {
                        return SubjectsPerCategoryActions.SaveAllocationsSuccess({ message: response.message, categoryID: action.categoryID });
                    }),
                    catchError((error: any) => of(SubjectsPerCategoryActions.SaveAllocationsError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    OnSaveAllocationsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubjectsPerCategoryActions.SaveAllocationsSuccess),
            switchMap((action) => combineLatest([
                this.store.select(SubjectsPerCategorySelector.getListingPayload).pipe(take(1)),
                of(action.categoryID),
            ])
            ),
            map(([payload, categoryID]) => {
                return SubjectsPerCategoryActions.LoadSubjectsOfCategory({payload, categoryID})
            })
        )
    );
    
}