/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from 'shared/src/environments/environment';
import { map } from 'rxjs';
import { AddEditVideoPayload, AddEditVideoResponse, DeleteVideoPayload, DeleteVideoResponse, GetVideoGalleryResponse, GetVideoGalleryPayload, UpdateVideoStatusPayload, UpdateVideoStatusResponse, VideoPath, HomepageVideo, UpdateTemplateVideoPayload, UpdateTemplateVideoResponse } from 'shared/src/interfaces/video-gallery.interface';

@Injectable({
  providedIn: 'root',
})
export class VideoGalleryService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.videoGallery;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  ////////// load Video Gallery /////////
  loadVideoGallery(payload: GetVideoGalleryPayload) {
    const url = `${this.rootURL}${this.endPointUrl.default}`
      .replace('{pageSize}', (payload?.pageSize ?? 20).toString())
      .replace('{pageNumber}', (payload?.pageNumber ?? 1).toString())
      .replace('{type}', (payload?.videoType ?? 'all').toString());
    return this.http
      .get<GetVideoGalleryResponse>(url)
      .pipe(map((e: GetVideoGalleryResponse) => e?.body));
  }

  ////////////// Video detail ////////////////
  videoDetail(id: number) {
    const url = `${this.rootURL}${this.endPointUrl.videoById}`.replace(
      '{id}',
      (id ?? '').toString()
    );
    return this.http
      .get<AddEditVideoResponse>(url)
      .pipe(map((e: AddEditVideoResponse) => e?.body));
  }

  ////////// add Video to Gallery /////////
  addVideoGallery(payload: AddEditVideoPayload) {
    const url = `${this.rootURL}${this.endPointUrl.add}`;
    return this.http
      .post<AddEditVideoResponse>(url, payload)
      .pipe(map((e: AddEditVideoResponse) => e));
  }

  ////////// update Video from Gallery /////////
  updateVideoGallery(payload: AddEditVideoPayload, id: number) {
    let url = `${this.rootURL}${this.endPointUrl.videoById}`;
    url = url.replace('{id}', (id ?? '').toString());
    return this.http
      .put<AddEditVideoResponse>(url, payload)
      .pipe(map((e: AddEditVideoResponse) => e));
  }

  ////////// update video status/////////
  updateVideoStatus(payload: UpdateVideoStatusPayload) {
    const url = `${this.rootURL}${this.endPointUrl.video}`;
    return this.http
      .patch<UpdateVideoStatusResponse>(url, payload)
      .pipe(map((e: UpdateVideoStatusResponse) => e));
  }

  ////////// delete video from Gallery/////////
  deleteVideo(payload: DeleteVideoPayload) {
    const url = `${this.rootURL}${this.endPointUrl.video}`;
    return this.http
      .delete<DeleteVideoResponse>(url, { body: payload })
      .pipe(map((e: DeleteVideoResponse) => e));
  }

  ////////// get accession detail //////////////////

  accessionDetail(accessionNo: string) {
    const url = `${this.rootURL}${this.endPointUrl.path}`.replace(
      '{accessionNumber}',
      accessionNo ?? ''
    );
    return this.http.get<VideoPath>(url).pipe(map((e: VideoPath) => e));
  }

  ////////// get homepage videos //////////////////

  loadHomepageVideo() {
    const url = `${this.rootURL}${this.endPointUrl.homepageVideo}`;
    return this.http.get<HomepageVideo>(url).pipe(map((e: HomepageVideo) => e));
  }

  ////////// update homepage videos //////////////////
  updateHomepageVideo(payload: UpdateTemplateVideoPayload[]) {
    const url = `${this.rootURL}${this.endPointUrl.homepageVideo}`;
    return this.http
      .put<UpdateTemplateVideoResponse>(url, payload)
      .pipe(map((e: UpdateTemplateVideoResponse) => e));
  }

   ////////// sort videos //////////////////
   sortVideo(id: number,toOrder: number) {
    const url = `${this.rootURL}${this.endPointUrl.sortVideo}`.replace('{id}', (id ?? '').toString());
    return this.http
      .put(url, {toOrder : toOrder})
      .pipe(map((e) => e));
  }
}
