/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { GenericListingPayload, UserList } from 'shared/src/interfaces/user-services.interface';

export const UsersList = createAction(
  '[PDL User Services] Load Users List',
  props<{payload :GenericListingPayload}>()
);

export const UsersListSuccess = createAction(
  '[PDL User Services] Load Users List Successfully',
  props<{userList: UserList[],totalRecords: number}>()
)

export const UsersListError = createAction(
  '[PDL User Services] Load User List Error',
  props<{message: string}>()
)