import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { AddEditProcessPayload, Process } from "shared/src/interfaces/master-form.interface";

export const LoadProcess = createAction(
    '[Process] Load Process',
    props<{payload: GenericListingPayload}>(),
)

export const LoadProcessSuccess = createAction(
    '[Process] Load Process Successfully',
    props<{processes: Process[], totalRecords: number}>()
)

export const LoadProcessError = createAction(
    '[Process] Load Process Error',
    props<{message: string}>()
)

export const UpdateProcessStatus = createAction(
    '[Process] Update Process Status',
    props<{processIDs: number[], status: string}>(),
)

export const UpdateProcessStatusSuccess = createAction(
    '[Process] Update Process Status Success',
    props<{processUpdates: any, message: string}>(),
)

export const UpdateProcessStatusError = createAction(
    '[Process] Update Process Status Error',
    props<{message: string}>(),
)

export const AddProcess = createAction(
    '[Process] Add Process',
    props<{payload: AddEditProcessPayload}>(),
)

export const AddProcessSuccess = createAction(
    '[Process] Add Process Success',
    props<{process: Process, message: string}>(),
)

export const AddProcessError = createAction(
    '[Process] Add Process Error',
    props<{message: string}>(),
)

export const EditProcess = createAction(
    '[Process] Edit Process',
    props<{payload: AddEditProcessPayload, processId: string}>(),
)

export const EditProcessSuccess = createAction(
    '[Process] Edit Process Success',
    props<{process: Process, message: string}>(),
)

export const EditProcessError = createAction(
    '[Process] Edit Process Error',
    props<{message: string}>(),
)

export const DeleteProcess = createAction(
    '[Process] Delete Process',
    props<{processIDs: number[]}>(),
)

export const DeleteProcessSuccess = createAction(
    '[Process] Delete Process Success',
    props<{message: string}>(),
)

export const DeleteProcessError = createAction(
    '[Process] Delete Process Error',
    props<{message: string}>(),
)