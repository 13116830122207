/* eslint-disable @nx/enforce-module-boundaries */
import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromStaffTask from "shared/src/store/reducers/staff/staff-task.reducer";

export const selectStaffTaskState =
  createFeatureSelector<fromStaffTask.State>('staffTask');

export const getStaffTaskList = createSelector(
    selectStaffTaskState,
    fromStaffTask.selectAll
);

export const getTotalRecords = createSelector(
  selectStaffTaskState,
  (state) => state.totalRecords
);

export const isLoading = createSelector(
  selectStaffTaskState,
  (state) => state.isLoading,
);