import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromWebBanners from "../../reducers/website/web-banners.reducer";

export const selectBannersState =
  createFeatureSelector<fromWebBanners.State>('webBanners');

export const getBanners = createSelector(
    selectBannersState,
    fromWebBanners.selectAll
);

export const isLoading = createSelector(
  selectBannersState,
  (state) => state.isLoading
);

export const isSaving = createSelector(
    selectBannersState,
  (state) => [state.isSaving, state.isError, state.errorMessage],
);