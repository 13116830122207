/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { AdminEditorActions } from '../actions/index';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import {Editor} from 'shared/src/interfaces/editor.interface';
export interface State extends EntityState<Editor> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  totalRecords: number;
  pageNumber : number,
  pageSize: number,
  sortBy: string,
  orderBy:string
}

export const adapter = createEntityAdapter<Editor>({
  selectId: (e) => e?.EditorID,
});

export const initialState = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0,
  pageNumber : 0,
  pageSize: 0,
  sortBy: 'DateAdded',
  orderBy: 'desc'
});

export const reducer = createReducer(
  initialState,

  on(AdminEditorActions.LoadEditors, (state,action) => ({
    ...state,
    isLoading: true,
    successMessage: '',
    errorMessage: '',
    pageNumber: action.payload?.pageNumber || 1,
    pageSize: action.payload?.pageSize || 0,
  })),

  on(AdminEditorActions.LoadEditorSuccessfully, (state, action) =>
    adapter.setAll(action?.editorList, {
      ...state,
      totalRecords: action.totalRecords,
      isLoading: false,
    })
  ),

  on(AdminEditorActions.LoadEditorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminEditorActions.AddEditor, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminEditorActions.AddEditorSuccessfully, (state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: action?.message
    })
  ),

  on(AdminEditorActions.AddEditorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),

  on(AdminEditorActions.UpdateEditor, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminEditorActions.UpdateEditorSuccessfully, (state, action) => ({
    ...adapter.updateOne(
      { id: action?.editor?.EditorID, changes: action.editor },
      state
    ),
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: action?.message,
  })),

  on(AdminEditorActions.UpdateEditorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      successMessage: '',
      errorMessage: action?.message,
    };
  }),

  on(AdminEditorActions.UpdateEditorStatus, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminEditorActions.UpdateEditorStatusSuccessfully, (state, action) => {
    const updates = action?.editor;
    const updatedEntities = adapter.updateMany(
      updates.map((update: Editor) => ({
        id: update.EditorID,
        changes: update,
      })),
      state
    );
    return {
      ...updatedEntities,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action?.message,
    };
  }),

  on(AdminEditorActions.UpdateEditorStatusError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),

  on(AdminEditorActions.DeleteEditor, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminEditorActions.DeleteEditorSuccessfully, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: false,
      successMessage: action?.message,
      errorMessage: '',
    };
  }),

  on(AdminEditorActions.DeleteEditorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action?.message,
    };
  }),
  on(AdminEditorActions.MergeEditor, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminEditorActions.MergeEditorSuccessfully, (state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: action?.message
    })
  ),

  on(AdminEditorActions.MergeEditorError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    };
  }),
);

export const { selectAll } = adapter.getSelectors();
