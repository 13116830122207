import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromToAdopt from "../reducers/admin-to-adopt.reducer";

export const selectToAdoptState =
  createFeatureSelector<fromToAdopt.State>('toAdoptList');

export const getToAdopt = createSelector(
    selectToAdoptState,
    fromToAdopt.selectAll
);

export const getTotalRecords = createSelector(
  selectToAdoptState,
  (state) => state.totalRecords
);

export const getToAdoptByID = (props: {id: string}) => createSelector(
  selectToAdoptState,
  (state) => state.entities[props.id]
);

export const getListingPayload = createSelector(
  selectToAdoptState,
  (state) => state.payload
);

export const onSave = createSelector(
  selectToAdoptState,
  (state) => [state.isError, state.errorMessage, state.successMessage, state.reload],
);