import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { ScriptsPerLanguage, ScriptsOfLanguage, SaveAllocationPayload } from "shared/src/interfaces/master-form.interface";

export const LoadScriptsPerLanguage = createAction(
    '[ScriptsPerLanguage] Load Scripts Per Language'
)

export const LoadScriptsPerLanguageSuccess = createAction(
    '[ScriptsPerLanguage] Load Scripts Per Language Successfully',
    props<{languages: ScriptsPerLanguage[]}>()
)

export const LoadScriptsPerLanguageError = createAction(
    '[ScriptsPerLanguage] Load Scripts Per Language Error',
    props<{message: string}>()
)

export const LoadScriptsOfLanguage = createAction(
    '[ScriptsPerLanguage] Load Scripts for single Language',
    props<{payload: GenericListingPayload, LangID: number}>(),
)

export const LoadScriptsOfLanguageSuccess = createAction(
    '[ScriptsPerLanguage] Load Scripts for single Language Successfully',
    props<{scripts: ScriptsOfLanguage[], totalCount: number, LangID: number}>()
)

export const LoadScriptsOfLanguageError = createAction(
    '[ScriptsPerLanguage] Load Scripts for single Language Error',
    props<{message: string}>()
)

export const SaveAllocations = createAction(
    '[ScriptsPerLanguage] Save Allocations',
    props<{payload: SaveAllocationPayload, LangID: number}>(),
)

export const SaveAllocationsSuccess = createAction(
    '[ScriptsPerLanguage] Save Allocations Successfully',
    props<{message: string, LangID: number}>()
)

export const SaveAllocationsError = createAction(
    '[ScriptsPerLanguage] Save Allocations Error',
    props<{message: string}>()
)
