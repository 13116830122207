/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap, exhaustMap } from 'rxjs/operators';
import { AdminVolunteerRequestActions } from '../actions';
import { VolunteerService } from 'shared/src/services/admin/volunteer/volunteer.service';

@Injectable()
export class AdminVolunteersRequestEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private volunteerService: VolunteerService
    ) {
    }
    
    LoadRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminVolunteerRequestActions.LoadVolunteersRequest),
            switchMap((action) => {
                return this.volunteerService.loadVolunteersRequest(action.payload).pipe(
                    map((response) => {
                        return AdminVolunteerRequestActions.LoadVolunteersRequestSuccess({ requestsList: response.requestsList, totalRecords: response.totalRecords });
                    }),
                    catchError((error: any) => of(AdminVolunteerRequestActions.LoadVolunteersRequestError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                ))
            })
        )
    );

    UpdateRequestStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminVolunteerRequestActions.UpdateVolunteerRequestStatus),
            exhaustMap(({id, status}) => {
                return this.volunteerService.updateVolunteerRequestStatus(id, status).pipe(
                    map((response: any) => {
                        return AdminVolunteerRequestActions.UpdateVolunteerRequestStatusSuccess({ requestsList: response.body, message: response.message });
                    }),
                    catchError((error: any) => of(AdminVolunteerRequestActions.UpdateVolunteerRequestStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message })))
                )
            })
        )
    );
}