import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, environment, WebCategoriesSelector, WebHomePageActions } from '@panjab-digi-lib/shared';
import { HomeCategories } from 'shared/src/interfaces/homepage.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'panjab-digi-lib-categories',
  standalone: true,
  imports: [CommonModule,TranslateModule,SlickCarouselModule,RouterLink],
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.scss',
})
export class CategoriesComponent implements OnInit {
  
  categories$!: Observable<HomeCategories[]>;
  rootURL = environment.rootApiPublicUrl;

  constructor(
    private translate: TranslateService, 
    private store: Store<AppState>) {

  }

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          dots: false
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          dots: false
        }
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          dots: false
        }
      }
    ]
  };

  slickInit(e: any) {
    // console.log('slick initialized');
  }

  ngOnInit(): void {
    
    this.store.dispatch(WebHomePageActions.LoadWebCategories());
    
    this.categories$ = this.store.select(WebCategoriesSelector.getCategories);
  }

}
