import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@panjab-digi-lib/shared';
import { Observable, throwError } from 'rxjs';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { StaffList } from 'shared/src/interfaces/staff/staff.interface';
import { STAFF_API_ENDPOINTS } from './app.endpoints';
import { DocumentList } from 'shared/src/interfaces/staff/document.interface';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  staffApiURL = '';
  constructor(private http: HttpClient) {
    this.staffApiURL = environment.rootApiUrl + environment.staffApiPrefix;
  }

  forgotPasswordRequest(data: {email: string}) {
    return this.http.post<{email: string}>(this.staffApiURL + 'recoverPassword', data).pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  getStaffDetails(StaffID: string) {
    return this.http.get(`${this.staffApiURL}${STAFF_API_ENDPOINTS.staffList.default}/${StaffID}`,{}).pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  validateResetPasswdRequest(data: any) {
    return this.http.post<any>(this.staffApiURL + 'validateResetRequest', data).pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  resetAccountPasswd(payload: {token: string, password: string}) {
    return this.http.patch<any>(`${this.staffApiURL}${STAFF_API_ENDPOINTS.auth.resetPassword}`, payload).pipe(map((e: any) => e));
  }

  updatePassword(password: string) {
    return this.http.patch<any>(`${this.staffApiURL}${STAFF_API_ENDPOINTS.auth.updatePassword}`, {password}).pipe(map((e: any) => e));
  }

  loadStaffList(payload: GenericListingPayload): Observable<{staffList: StaffList[], totalRecords: number}> {
    return this.http.get(`${this.staffApiURL}${STAFF_API_ENDPOINTS.staffList.default}`, {params: {...payload}}).pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }
  
  downloadStaffList() {
    return this.http.get(`${this.staffApiURL}${STAFF_API_ENDPOINTS.staffList.download}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }

  private handleError(error: HttpErrorResponse) {
    let transformedError: HttpErrorResponse;

    if (error.error && error.error.error) {
      transformedError = error.error.error;
    }

    return throwError(() => transformedError);
  }
}
