<section class="adopt-and-donations section-padding">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12 col-12">
                <div class="pdl-ad-block mb-4">
                    <div class="ad-title">
                        <h2 class="heading-style semibold">
                            {{'pdl-website.adoptBook.titleText' | translate }}
                        </h2>
                        <p class="title-description mb-0 pdl-grey"> 
                            {{'pdl-website.adoptDesc.descText' | translate }}
                        </p>
                    </div>
                    <div class="ad-books">
                        <div class="ad-book-item">
                            <div class="ad-book-img">
                                <img src="assets/images/adopt-book1.jpg" alt="book-img">
                            </div>
                            <div class="ad-book-content">
                                <h4 class="pdl-title-color medium-font">Bhaiye Da Janam Din</h4>
                                <div class="ad-book-price">
                                    <img src="assets/images/icons/dollar-icon.svg" alt="icon">
                                    <span>136.00</span>
                                </div>
                            </div>
                        </div>

                        <div class="ad-book-item">
                            <div class="ad-book-img">
                                <img src="assets/images/adopt-book-2.png" alt="book-img">
                            </div>
                            <div class="ad-book-content">
                                <h4 class="pdl-title-color medium-font">Sada Qaumi Panchhi</h4>
                                <div class="ad-book-price">
                                    <img src="assets/images/icons/dollar-icon.svg" alt="icon">
                                    <span>136.00</span>
                                </div>
                            </div>
                        </div>

                        <div class="ad-book-item">
                            <div class="ad-book-img">
                                <img src="assets/images/adopt-book-3.jpg" alt="book-img">
                            </div>
                            <div class="ad-book-content">
                                <h4 class="pdl-title-color medium-font">Soora February 1999</h4>
                                <div class="ad-book-price">
                                    <img src="assets/images/icons/dollar-icon.svg" alt="icon">
                                    <span>136.00</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="centered-btn d-flex align-items-center justify-content-center">
                        <a href="#" class="btn pdl-line-btn">See books for adoption</a>
                    </div>

                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-12">
                <div class="pdl-ad-block mb-4">
                    <div class="ad-title">
                        <img src="assets/images/icons/donate-icon.svg" alt="icon">
                        <h2 class="heading-style semibold">{{'pdl-website.donateTitle.titleText' | translate }}</h2>
                        <p class="title-description pdl-grey">  {{'pdl-website.adoptDesc.descText' | translate }}</p>
                    </div>

                    <div class="centered-btn d-flex align-items-start justify-content-start">
                        <a href="#" [routerLink]="['/donate']" class="btn pdl-line-btn">Donate</a>
                    </div>

                </div>


                <div class="pdl-ad-block">
                    <div class="ad-title">
                        <img src="assets/images/icons/librarian-icon.svg" alt="icon">
                        <h2 class="heading-style semibold">{{'pdl-website.librarianTitle.titleText' | translate }}</h2>
                        <p class="title-description pdl-grey">  {{'pdl-website.adoptDesc.descText' | translate }}</p>
                    </div>

                    <div class="centered-btn d-flex align-items-start justify-content-start">
                        <a href="#" class="btn pdl-line-btn">Chat Now</a>
                    </div>

                </div>

            </div>
        </div>
    </div>
</section>