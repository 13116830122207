import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AdminDownloadActions } from '../actions';

export interface State extends EntityState<any> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  totalRecords?: number;
}
export const adapter = createEntityAdapter<any>({
  selectId: (download) => download.ID,
});

export const initialState = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  totalRecords: 0,
  downloadListsByPage: {},
});

export const reducer = createReducer(
  initialState,

  on(AdminDownloadActions.AdminAddDownload, (state, action) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminDownloadActions.AdminAddDownloadSuccessfully, (state, action) =>
    adapter.addOne(action?.download, {
      ...state,
      totalRecords: state.totalRecords + 1,
      errorMessage: '',
      successMessage: action.message,
      isLoading: false,
      isError: false,
    })
  ),

  on(AdminDownloadActions.AdminAddDownloadError, (state, action) => ({
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action?.message,
    successMessage: '',
  })),

  on(AdminDownloadActions.LoadDownloadList, (state, action) => ({
    ...state,
    isLoading: true,
  })),

  on(AdminDownloadActions.LoadDownloadListSuccessfully, (state, action) =>
    adapter.setAll(action.downloadList, {
      ...state,
      totalRecords: action.totalRecords,
      isLoading: false,
    })
  ),

  // on(AdminDownloadActions.LoadDownloadListSuccessfully, (state:any, action) => {
  //   const download = action.downloadList;
  //   const currentPage = Math.ceil((state.totalRecords) / action?.pageNumber);
  //   const currentList = state.downloadListsByPage[currentPage] || [];
  //   const updatedList = [...currentList, download];
  //   const updatedListsByPage = { ...state.downloadListsByPage, [currentPage]: updatedList };
  //   console.log(updatedListsByPage);
  //   console.log(updatedListsByPage[action?.pageNumber-1]);
  //   return adapter.setAll(download, {
  //     ...state,
  //     totalRecords: action?.totalRecords,
  //     downloadListsByPage: updatedListsByPage[action?.pageNumber-1],
  //     isLoading: false,
  //     isError: false,
  //   });
  // }),

  on(AdminDownloadActions.LoadDownloadListError, (state, action) => ({
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action?.message,
  })),

  on(AdminDownloadActions.AdminEditDownload, (state, action) => ({
    ...state,
    isLoading: true,
  })),

  on(
    AdminDownloadActions.AdminEditDownloadSuccessfully,
    (state: any, action) => {
      const updatedDownload = action.download;
      // const updatedPage: any = {}; // Object to store the updated page

      // for (const pageNumber in state.downloadListsByPage) {
      //   // eslint-disable-next-line no-prototype-builtins
      //   if (state.downloadListsByPage.hasOwnProperty(pageNumber)) {
      //     const pageData = state.downloadListsByPage[pageNumber];
      //     const updatedEntities = pageData.map((download: any) => {
      //       if (download.ID === updatedDownload.ID) {
      //         return download = updatedDownload;
      //       } else {
      //         return download;
      //       }
      //     });
      //     updatedPage[pageNumber] = updatedEntities;
      //   }
      // }
      // const updatedState = {
      //   ...state,
      //   // downloadListsByPage: {
      //   //   ...state.downloadListsByPage,
      //   //   ...updatedPage,
      //   // },
      //   isLoading: false,
      //   isSaving: false,
      //   isError: false,
      //   errorMessage: '',
      //   successMessage: action.message,
      // };
      const updatedState = {
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message,
      };
      return adapter.updateOne(
        { id: updatedDownload.ID, changes: updatedDownload },
        updatedState
      );
    }
  ),

  on(AdminDownloadActions.AdminEditDownloadError, (state, action) => ({
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action?.message,
    successMessage: '',
  })),

  on(AdminDownloadActions.UpdateDownloadStatus, (state, action) => ({
    ...state,
    isLoading: false,
    isSaving: true,
    isError: false,
    errorMessage: '',
    successMessage: '',
  })),
  // on(AdminDownloadActions.UpdateDownloadStatusSuccess, (state: any, action) => {
  //   const updates = action.download;
  //   const updatedPage: any = {}; // Object to store the updated page
  //   for (const pageNumber in state.downloadListsByPage) {
  //     // eslint-disable-next-line no-prototype-builtins
  //     if (state.downloadListsByPage.hasOwnProperty(pageNumber)) {
  //       const pageData = state.downloadListsByPage[pageNumber];
  //       const updatedEntities = pageData.map((download:any) => {
  //         const update =  updates.find((update:any) => {
  //           return update.ID === download.ID
  //         });
  //         return update ? { ...download, ...update } : download;
  //       });
  //       updatedPage[pageNumber] = updatedEntities;
  //     }
  //   }

  //   return {
  //     ...state,
  //     downloadListsByPage: {
  //       ...state.downloadListsByPage,
  //       ...updatedPage,
  //     },
  //     isLoading: false,
  //     isSaving: false,
  //     isError: false,
  //     errorMessage: '',
  //     successMessage: action.message,
  //   };
  // }),

  on(AdminDownloadActions.UpdateDownloadStatusSuccess, (state: any, action) => {
    const updates = action.download;
    // Apply updates to the entities stored in the adapter
    const updatedEntities = adapter.updateMany(
      updates.map((update: any) => ({ id: update.ID, changes: update })),
      state
    );
    return {
      ...updatedEntities,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action.message,
    };
  }),

  on(AdminDownloadActions.UpdateDownloadStatusError, (state, action) => ({
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.message,
    successMessage: '',
  })),


  on(AdminDownloadActions.ReorderDownload, (state, action) => ({
    ...state,
    isLoading: false,
    isSaving: true,
    isError: false,
    errorMessage: '',
    successMessage: '',
  })),

  on(AdminDownloadActions.ReorderDownloadSuccess, (state: any, action) => {
    return {
      ...state,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action.message,
    };
  }),

  on(AdminDownloadActions.ReorderDownloadError, (state, action) => ({
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.message,
    successMessage: '',
  }))

);

export const { selectAll } = adapter.getSelectors();
