import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromCountry from "../reducers/country.reducer";

export const selectCountryState =
  createFeatureSelector<fromCountry.State>('country');

export const getCountries = createSelector(
    selectCountryState,
    fromCountry.selectAll
);

export const getTotalRecords = createSelector(
  selectCountryState,
  (state) => state.totalRecords
);

export const getCountryByID = (props: {id: string}) => createSelector(
  selectCountryState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectCountryState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectCountryState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);