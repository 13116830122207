import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { BannerActions } from '../actions/index';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BannersService } from 'shared/src/services/admin/banners/banner.service';
import { Router } from '@angular/router';

@Injectable()
export class BannersEffects {
  defaultErrMsg = "Something went wrong! Please try again later.";
  constructor(
    private actions$: Actions,
    private bannerService: BannersService,
    private router: Router
  ) {
  }

  LoadBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannerActions.LoadBanners),
      exhaustMap(action =>
        this.bannerService.getAllBanners({ params: { pageNumber: action.pageNumber, pageSize: action.pageSize, type: action.bannerType } }).pipe(
          map((data: any) => {
            return BannerActions.LoadBannersSuccessfully({ banners: data.banners || [], totalRecords: data.totalCount });
          }),
          catchError((error: any) => of(BannerActions.BannersError({ message: error?.message }))))
      )
    )
  );

  ReorderBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannerActions.ReorderBanners),
      exhaustMap(action =>
        this.bannerService.sortBanner(action.id, action.toOrder).pipe(
          map((banner: any) => {
            return BannerActions.BannerSuccessMsg({ message: "Banners reordered successfully!", reload: false });
          }),
          catchError((error: any) => of(BannerActions.BannersError({ message: error?.message || this.defaultErrMsg }))))
      )
    )
  );

  DeleteBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannerActions.DeleteBanners),
      exhaustMap(action =>
        this.bannerService.deleteBanners(action.id).pipe(
          map((banner: any) => {
            return BannerActions.BannerSuccessMsg({ message: "Banner deleted successfully!", reload: true });
          }),
          catchError((res: any) => of(BannerActions.BannersError({ message: res.error?.error?.message || this.defaultErrMsg }))))
      )
    )
  );

  UpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannerActions.BannersUpdateStatus),
      exhaustMap(action =>
        this.bannerService.updateStatusBanner(action.id, action.status).pipe(
          map((banner: any) => {
            return BannerActions.BannerSuccessMsg({ message: "Banners status updated successfully!", reload: true });
          }),
          catchError((res: any) => of(BannerActions.BannersError({ message: res.error?.error?.message || this.defaultErrMsg }))))
      )
    )
  );

  AddBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannerActions.AddBanners),
      exhaustMap(action =>
        this.bannerService.addBanners(action.data).pipe(
          map((banner: any) => {
            this.router.navigateByUrl('/home/banners');
            return BannerActions.BannerSuccessMsg({ message: "Banner added successfully!", reload: true });
          }),
          catchError((error: any) => of(BannerActions.BannersError({ message: error?.message || this.defaultErrMsg }))))
      )
    )
  );


  UpdateBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannerActions.UpdateBanners),
      exhaustMap(action =>
        this.bannerService.updateBanner(action.id, action.data).pipe(
          map((banner: any) => {
            this.router.navigateByUrl('/home/banners');
            return BannerActions.BannerSuccessMsg({ message: "Banner updated successfully!", reload: true });
          }),
          catchError((error: any) => of(BannerActions.BannersError({ message: error?.message || this.defaultErrMsg, reload: true }))))
      )
    )
  );
}