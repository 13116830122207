import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromAdminKeyword from "../reducers/admin-keywords.reducer";

export const selectState =
  createFeatureSelector<fromAdminKeyword.State>('adminKeyword');
  
export const getAllTrendingList = createSelector(
  selectState,
  (state) => ({
    trendingList: fromAdminKeyword.selectAll(state),
    totalRecords: state.totalRecords
})
);

export const isLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const onSave = createSelector(
  selectState,
  (state) => [state.isError, state.errorMessage, state.successMessage,state.isLoading]
);

