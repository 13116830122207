import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { AddEditSectionPayload, Section } from "shared/src/interfaces/master-form.interface";

export const LoadSection = createAction(
    '[Section] Load Section',
    props<{payload: GenericListingPayload}>(),
)

export const LoadSectionSuccess = createAction(
    '[Section] Load Section Successfully',
    props<{sections: Section[], totalRecords: number}>()
)

export const LoadSectionError = createAction(
    '[Section] Load Section Error',
    props<{message: string}>()
)

export const UpdateSectionStatus = createAction(
    '[Section] Update Section Status',
    props<{SectionIDs: number[], status: string}>(),
)

export const UpdateSectionStatusSuccess = createAction(
    '[Section] Update Section Status Success',
    props<{sectionUpdates: any, message: string}>(),
)

export const UpdateSectionStatusError = createAction(
    '[Section] Update Section Status Error',
    props<{message: string}>(),
)

export const AddSection = createAction(
    '[Section] Add Section',
    props<{payload: AddEditSectionPayload}>(),
)

export const AddSectionSuccess = createAction(
    '[Section] Add Section Success',
    props<{Section: Section, message: string}>(),
)

export const AddSectionError = createAction(
    '[Section] Add Section Error',
    props<{message: string}>(),
)

export const EditSection = createAction(
    '[Section] Edit Section',
    props<{payload: AddEditSectionPayload, sectionId: string}>(),
)

export const EditSectionSuccess = createAction(
    '[Section] Edit Section Success',
    props<{Section: Section, message: string}>(),
)

export const EditSectionError = createAction(
    '[Section] Edit Section Error',
    props<{message: string}>(),
)

export const DeleteSection = createAction(
    '[Section] Delete Section',
    props<{SectionIDs: number[]}>(),
)

export const DeleteSectionSuccess = createAction(
    '[Section] Delete Section Success',
    props<{message: string}>(),
)

export const DeleteSectionError = createAction(
    '[Section] Delete Section Error',
    props<{message: string}>(),
)