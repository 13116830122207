import { createReducer, on } from '@ngrx/store';
import {createEntityAdapter, EntityState, Update} from "@ngrx/entity";
import { Service } from 'shared/src/interfaces/services.interface';
import { ServiceActions } from '../actions';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';

export interface State extends EntityState<any> {
  thumbnails?: string[];
  payload: GenericListingPayload,
  totalRecords: number,
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
}

export const adapter = createEntityAdapter<Service>({
  selectId: (e) => e.ServiceID
});

export const initialState = adapter.getInitialState( {
  thumbnails: [''],
  totalRecords: 0,
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  successMessage: ''
});

export const reducer = createReducer(
  initialState,
  on(ServiceActions.LoadServices,(state, action) =>
    ({
      ...state,
      payload: action.payload,
      isLoading: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(ServiceActions.LoadServicesSuccess,(state, action) =>
    ({
      ...adapter.setAll(action.services, state),
      totalRecords: action.totalRecords,
      isLoading: false,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(ServiceActions.LoadServicesError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
      successMessage: '',
    })
  ),
  on(ServiceActions.AddService,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(ServiceActions.AddServiceSuccess,(state, action) =>
     ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
      })
  ),
  on(ServiceActions.AddServiceError,(state, action) => 
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
  on(ServiceActions.EditService,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(ServiceActions.EditServiceSuccess,(state, action) =>
     ({
        ...adapter.updateOne({id: action.service.ServiceID, changes: action.service}, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
      })
  ),
  on(ServiceActions.EditServiceError,(state, action) => 
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
  on(ServiceActions.UpdateServicesStatus,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(ServiceActions.UpdateServicesStatusSuccess,(state, action) =>
  
    ({
      ...adapter.updateMany(action.serviceUpdates, state),
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action.message
    })
  ),
  on(ServiceActions.UpdateServicesStatusError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
  on(ServiceActions.DeleteServices,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(ServiceActions.DeleteServicesSuccess,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action.message
    })
  ),
  on(ServiceActions.DeleteServicesError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();