/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState, Update } from "@ngrx/entity";
import { ProcessPerCategoryActions } from '../actions';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { ProcessesPerCategory, ProcessesOfCategory } from "shared/src/interfaces/master-form.interface";

export interface State extends EntityState<any> {
    payload: GenericListingPayload,
    processesOfCategory: ProcessesOfCategory[],
    totalRecords: number,
    isLoading: boolean;
    loadingForSingleCategory: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<ProcessesPerCategory>({
    selectId: (e) => e.CategoryID
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: false,
    loadingForSingleCategory: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(ProcessPerCategoryActions.LoadProcessPerCategory, (state, action) =>
    ({
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(ProcessPerCategoryActions.LoadProcessPerCategorySuccess, (state, action) =>
    ({
        ...adapter.setAll(action.category, state),
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(ProcessPerCategoryActions.LoadProcessPerCategoryError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(ProcessPerCategoryActions.LoadprocessesOfCategory, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        loadingForSingleCategory: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(ProcessPerCategoryActions.LoadprocessesOfCategorySuccess, (state, action) =>
    ({
        ...state,
        processesOfCategory: action.processes,
        totalRecords: action.totalCount,
        loadingForSingleCategory: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(ProcessPerCategoryActions.LoadprocessesOfCategoryError, (state, action) =>
    ({
        ...state,
        loadingForSingleCategory: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(ProcessPerCategoryActions.SaveAllocations, (state, action) =>
    ({
        ...state,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(ProcessPerCategoryActions.SaveAllocationsSuccess, (state, action) =>
    ({
        ...state,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(ProcessPerCategoryActions.SaveAllocationsError, (state, action) =>
    ({
        ...state,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();