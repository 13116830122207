import { createReducer, on } from '@ngrx/store';
import { StaffAuthActions } from '../../actions/index';

export interface State {
  token: string | null;
  refreshToken: string | null;
  error: string;
  isLoading: boolean;
  user: any;
}

const initialState: State = {
  token: null,
  refreshToken: null,
  error: '',
  isLoading: false,
  user: null
};

export const reducer = createReducer(initialState,
  on(StaffAuthActions.Login, state => ({ 
    ...state, 
    isLoading: true 
  })),
  on(StaffAuthActions.LoginSuccess, (state, { token, refreshToken }) => ({ 
    ...state, 
    token,
    refreshToken, 
    error: '',
    isLoading: false 
  })),
  on(StaffAuthActions.LoginError, (state, { error: message }) => ({ 
    ...state, 
    error: message, 
    isLoading: false 
  })),
  on(StaffAuthActions.ResetLoginError, (state) => ({ 
    ...state, 
    error: ''
  })),
  on(StaffAuthActions.tokenExpired, (state) => ({ 
    ...state, 
    token: null, 
    isLoading: false 
  })),
  on(StaffAuthActions.logout, (state) => ({ 
    ...state, 
    token: null, 
    error: '', 
    isLoading: false 
  })),
);