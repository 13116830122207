/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { Volunteer } from "shared/src/interfaces/volunteer.interface";

export const LoadVolunteer = createAction(
    '[volunteer] Load volunteer',
    props<{payload: GenericListingPayload}>(),
)

export const LoadVolunteerSuccess = createAction(
    '[volunteer] Load volunteer Success',
    props<{volunteerList: Volunteer[], totalRecords: number}>()
)

export const LoadVolunteerError = createAction(
    '[volunteer] Load volunteer Error',
    props<{message: string}>()
)

export const AddVolunteer = createAction(
    '[volunteer] Add Volunteer',
    props<{data: Volunteer }>(),
)

export const AddVolunteerSuccess = createAction(
    '[volunteer] Add Volunteer Success',
    props<{volunteer: Volunteer, message: string}>(),
)

export const AddVolunteerError = createAction(
    '[volunteer] Add Volunteer Error',
    props<{message: string}>(),
)

export const EditVolunteer = createAction(
    '[volunteer] Update Volunteer',
    props<{id: number, data: Volunteer }>(),
)

export const EditVolunteerSuccess = createAction(
    '[volunteer] Update Volunteer Success',
    props<{volunteer: Volunteer, message: string}>(),
)

export const EditVolunteerError = createAction(
    '[volunteer] Update Volunteer Error',
    props<{message: string}>(),
)

export const UpdateVolunteerStatus = createAction(
    '[Volunteer] Update Volunteer Status',
    props<{volunteerIDs: number[], status: boolean}>(),
)

export const UpdateVolunteerStatusSuccess = createAction(
    '[Volunteer] Update Volunteer Status Success',
    props<{volunteerUpdates: any, message: string}>(),
)

export const UpdateVolunteerStatusError = createAction(
    '[Volunteer] Update Volunteer Status Error',
    props<{message: string}>(),
)