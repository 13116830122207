<panjab-digi-lib-static-page-head [pageName]="'pdl-website.preservation.titleText' | translate"
  pagePath="/preservation">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <div class="d-flex workingGroup">
      <div class="img"><img src="/assets/images/data-mining.png" /></div>
      <div>
        <h1 class="heading mb-3">Panjab Digital Library Preservation</h1>

        <p class="text">Time is a great leveler. With the passage of time, objects, animate or inanimate, tend to decay
          and
          perish. But certain things need to be preserved and conserved for all they represent historically and all they
          have to continue to teach us in the future. It is through the human effort of preservation that frontiers of
          human
          knowledge and human civilizations have been extended. In an eternal race of human memory against time, it is
          through the endeavor of preservation and perpetuation of achievements that the continuity of human
          civilizations
          has been maintained. Thus, the successful preservation of history is the bedrock of current human information,
          knowledge and civilization. Without this attention and effort, elements of nature such as heat, light,
          humidity
          and natural decay, natural calamities such as earthquakes and floods, and manmade disasters such as wars and
          acts
          of vengeance and vandalism will, singly or jointly, wipe out irreplaceable foundations of the past.
          Establishment
          of archives, museums, libraries and the latest technique of digitization are some of the prevalent
          preservation
          techniques employed today.</p>

        <p class="text">
          Besides our focus on digitations and Web access, the PDL wishes also to start a preservation lab, which would
          be
          the first of its kind in North India initiated by a Non-Governmental Organization.
        </p>
        <p class="text"> If you have ideas for this endeavor, or you wish to help in planning and establishing this lab
          please write to <a href="mailto:support@panjabdigilib.org" target="_blank"
            class="cursor-pointer text email">support&#64;panjabdigilib.org</a>
        </p>
      </div>
    </div>

    }
  </div>
</section>
