import { createAction, props } from '@ngrx/store';

export const LoadWebDownloads = createAction(
    '[Downloads] Load Website All Downloads',
    props<{ payload: any  }>()
);

export const LoadWebDownloadsSuccess = createAction(
    '[Downloads] Load Website Downloads Success',
    props<{ downloads: any,totalRecords: number  }>()
);

export const LoadWebDownloadsError = createAction(
    '[Downloads] Load Website Downloads Error',
    props<{ message: string }>()
);