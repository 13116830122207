@if((categories$ | async)?.length) {
<section class="categories section-padding padding-top-md-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="heading-style semibold text-center mb-lg-40">{{'pdl-website.categoriesSection.sectionTitle' |
                    translate }}</h2>
            </div>
            <div class="col-12 hidden-after-767">
                <div class="categories-in-grid">
                    <div class="row justify-content-center">
                        @for(category of categories$ | async; track category.HomeCategoriesOrder) {
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a href="#" class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0"> {{category.Name}}</h6>
                                    <span class="number-of-users mb-0">
                                        {{category.totalBooks}}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <img [src]="rootURL+category.CategoryThumbnail">
                                </div>
                            </a>
                        </div>
                        }
                    </div>
                </div>
            </div>

            <div class="col-12 visible-below-767">
                <div class="categories-slider">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                        (init)="slickInit($event)">
                        @for(category of categories$ | async; track category.HomeCategoriesOrder) {
                        <div ngxSlickItem class="slide category-slide pe-4">
                            <div class="category-card">
                                <div class="block-text-bar">
                                    <h6 class="mb-0">{{category.Name}}</h6>
                                    <span class="number-of-users mb-0">
                                        {{category.totalBooks}}
                                    </span>
                                </div>
                                <div class="block-img">
                                    <!-- <img src="assets/images/category8.jpg"> -->
                                    <img class="category-img" [src]="rootURL+category.CategoryThumbnail" alt="" width="100%">
                                </div>
                            </div>
                        </div>
                        }
                    </ngx-slick-carousel>
                </div>
            </div>

            <div class="col-12">
                <div class="centered-btn d-flex align-items-center justify-content-center">
                    <a href="javascript:void(0);" [routerLink]="'/all-categories'" class="btn pdl-btn">{{'pdl-website.categoriesButton.buttonText' | translate }}</a>
                </div>
            </div>

        </div>
    </div>
</section>
}