/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { CategoryWithAdoptPrice } from "shared/src/interfaces/fundraising.interface";

export const LoadCategoryWithPriceList = createAction(
    '[Set Adopt Price] Load Category With Price List',
    props<{payload: GenericListingPayload}>(),
)

export const LoadCategoryWithPriceListSuccess = createAction(
    '[Set Adopt Price] Load Category With Price List Sucess',
    props<{categoryList: CategoryWithAdoptPrice[], totalRecords: number}>()
)

export const LoadCategoryWithPriceListError = createAction(
    '[Set Adopt Price] Load Category With Price List Error',
    props<{message: string}>()
)

export const UpdateAdoptPrice = createAction(
    '[Set Adopt Price] Update Adopt Price List',
    props<{id: number, price: string }>(),
)

export const UpdateAdoptPriceSuccess = createAction(
    '[Set Adopt Price] Update Adopt Price List Success',
    props<{category: CategoryWithAdoptPrice, message: string}>(),
)

export const UpdateAdoptPriceError = createAction(
    '[Set Adopt Price] Update Adopt Price List Error',
    props<{message: string}>(),
)