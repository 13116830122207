import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, DesignationActions, DesignationSelector } from '@panjab-digi-lib/shared';
import { Update } from '@ngrx/entity';
import { Designation } from 'shared/src/interfaces/master-form.interface';
import { DesignationService } from 'shared/src/services/admin/master-form/designation.service';

@Injectable()
export class DesignationEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private DesignationService: DesignationService,
        private router: Router,
        private store: Store<AppState>,
    ) {
    }

    LoadDesignation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DesignationActions.LoadDesignation),
            exhaustMap((action) => {

                return this.DesignationService.loadDesignation(action.payload).pipe(
                    map((response) => {
                        return DesignationActions.LoadDesignationSuccess({ designations: response.designations, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(DesignationActions.LoadDesignationError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
    
    UpdateDesignationStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DesignationActions.UpdateDesignationStatus),
            exhaustMap((action) => {

                return this.DesignationService.updateDesignationStatus(action.DesignationIDs, action.status).pipe(
                    map((response: any) => {
                        const DesignationUpdates: Update<Designation>[] = action.DesignationIDs.map((id) => {
                            return {
                                id, changes: {
                                    Status: action.status
                                }
                            }
                        })

                        return DesignationActions.UpdateDesignationStatusSuccess({ DesignationUpdates, message: response.message });
                    }),
                    catchError((error: any) => of(DesignationActions.UpdateDesignationStatusError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    AddDesignation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DesignationActions.AddDesignation),
            exhaustMap((action) => {

                return this.DesignationService.addDesignation(action.payload).pipe(
                    map((response) => {

                        return DesignationActions.AddDesignationSuccess({ designation: response.body, message: response.message });
                    }),
                    catchError((error: any) => of(DesignationActions.AddDesignationError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );

    EditDesignation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DesignationActions.EditDesignation),
            exhaustMap((action) => {
                
                return this.DesignationService.editDesignation(action.payload, action.designationId).pipe(
                    map((response) => {

                        this.router.navigateByUrl('/master-forms/manage/designations');
                        return DesignationActions.EditDesignationSuccess({designation: response.body, message: response.message});
                    }),
                    catchError((error: any) => of(DesignationActions.EditDesignationError({message: error?.error?.error?.message || this.defaultErrMsg}))
                    ))
            })
        )
    );
        
    DeleteDesignation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DesignationActions.DeleteDesignation),
            exhaustMap((action) => {

                return this.DesignationService.deleteDesignation(action.DesignationIDs).pipe(
                    map((response: any) => {

                        return DesignationActions.DeleteDesignationSuccess({ message: response.message });
                    }),
                    catchError((error: any) => of(DesignationActions.DeleteDesignationError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    AddDeleteDesignationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DesignationActions.AddDesignationSuccess, DesignationActions.DeleteDesignationSuccess),
            switchMap((action) => combineLatest([
                    of(action.type),
                    this.store.select(DesignationSelector.getListingPayload).pipe(take(1))
                ])
            ),
            map(([type, payload]) => {
                
                if(type == '[Designation] Add Designation Success') {
                    this.router.navigateByUrl('/master-forms/manage/designations');
                }
                return DesignationActions.LoadDesignation({ payload });
            })
        )
    );
}