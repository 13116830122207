import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core'
import { Store } from '@ngrx/store';
import { AuthActions, StaffAuthActions } from 'shared/src/store/actions';
import { Router } from '@angular/router';
import { environment } from '@panjab-digi-lib/shared';

@Component({
  selector: 'panjab-digi-lib-header',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input() title: string = '';
  baseUrl = environment.rootApiPublicUrl;
  activeUser: any;
  constructor(private translate: TranslateService, private store: Store, private router: Router) {

  }

  ngOnInit() {
    if(localStorage.getItem('activeStaffUser')) {
      const activeUsr = localStorage.getItem('activeStaffUser') ?? '{}';
      this.activeUser = JSON.parse(activeUsr);
    }
  }

  signOut() {
    
    if(localStorage.getItem('staffToken')) {
      
      localStorage.removeItem('staffToken');
      localStorage.removeItem('staffRefreshToken');
      localStorage.removeItem('activeStaffUser');
      this.router.navigateByUrl('/login')
    } else if(localStorage.getItem('token')) {

      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      this.store.dispatch(AuthActions.logout());
      this.router.navigateByUrl('/')
    }
  }
}
