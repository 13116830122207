<section class="innerpage-banner">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
          <a class="breadcrumb-link font-regular" href="#">
            <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
            Home
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular" href="#">
            All Exhibitions
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="page-title">
          <h1 class="heading-dark semibold mb-0">All Exhibitions</h1>
        </div>
      </div>
    </div>

  </div>
</section>
<section class="advancesearch-section section-padding ">
  <div class="container">
    <div class="col mb-3">
      <div class="results-and-filters d-flex align-items-center justify-content-between">
        <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
          <!-- <span class="category-name semibold">Manuscripts</span> -->
          <span>1</span>
          <span>to</span>
          <span>40</span>
          <span>of about</span>
          <span>1963</span>
          <span>records</span>
        </div>
        <div class="category-action d-flex align-items-center justify-content-end">
          <div class="page-filters-dropdown">
            <div class="dropdown page-filter-dropdown">
              <a class="page-filter-action btn btn-secondary dropdown-toggle" href="#" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <img src="assets/images/icons/filters-ico.svg" alt="icon"> Filters
              </a>

              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </div>
          </div>
          <div class="list-styles">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                  type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">

                  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.125 5.8125C3.125 5.30469 3.51562 4.875 4.0625 4.875H5.9375C6.44531 4.875 6.875 5.30469 6.875 5.8125V7.6875C6.875 8.23438 6.44531 8.625 5.9375 8.625H4.0625C3.51562 8.625 3.125 8.23438 3.125 7.6875V5.8125ZM3.125 12.0625C3.125 11.5547 3.51562 11.125 4.0625 11.125H5.9375C6.44531 11.125 6.875 11.5547 6.875 12.0625V13.9375C6.875 14.4844 6.44531 14.875 5.9375 14.875H4.0625C3.51562 14.875 3.125 14.4844 3.125 13.9375V12.0625ZM5.9375 17.375C6.44531 17.375 6.875 17.8047 6.875 18.3125V20.1875C6.875 20.7344 6.44531 21.125 5.9375 21.125H4.0625C3.51562 21.125 3.125 20.7344 3.125 20.1875V18.3125C3.125 17.8047 3.51562 17.375 4.0625 17.375H5.9375Z"
                      fill="#7A8699" />
                    <path opacity="0.4"
                      d="M8.75 6.75C8.75 6.08594 9.29688 5.5 10 5.5H21.25C21.9141 5.5 22.5 6.08594 22.5 6.75C22.5 7.45312 21.9141 8 21.25 8H10C9.29688 8 8.75 7.45312 8.75 6.75ZM8.75 13C8.75 12.3359 9.29688 11.75 10 11.75H21.25C21.9141 11.75 22.5 12.3359 22.5 13C22.5 13.7031 21.9141 14.25 21.25 14.25H10C9.29688 14.25 8.75 13.7031 8.75 13ZM21.25 18C21.9141 18 22.5 18.5859 22.5 19.25C22.5 19.9531 21.9141 20.5 21.25 20.5H10C9.29688 20.5 8.75 19.9531 8.75 19.25C8.75 18.5859 9.29688 18 10 18H21.25Z"
                      fill="#7A8699" />
                  </svg>

                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                  type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">

                  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.25 9.875C11.25 10.9297 10.3906 11.75 9.375 11.75H5.625C4.57031 11.75 3.75 10.9297 3.75 9.875V6.125C3.75 5.10938 4.57031 4.25 5.625 4.25H9.375C10.3906 4.25 11.25 5.10938 11.25 6.125V9.875ZM21.25 19.875C21.25 20.9297 20.3906 21.75 19.375 21.75H15.625C14.5703 21.75 13.75 20.9297 13.75 19.875V16.125C13.75 15.1094 14.5703 14.25 15.625 14.25H19.375C20.3906 14.25 21.25 15.1094 21.25 16.125V19.875Z"
                      fill="#7A8699" />
                    <path opacity="0.4"
                      d="M21.25 10.1875C21.25 11.0859 20.5469 11.75 19.6875 11.75H15.3125C14.4141 11.75 13.75 11.0859 13.75 10.1875V5.8125C13.75 4.95312 14.4141 4.25 15.3125 4.25H19.6875C20.5469 4.25 21.25 4.95312 21.25 5.8125V10.1875ZM11.25 20.1875C11.25 21.0859 10.5469 21.75 9.6875 21.75H5.3125C4.41406 21.75 3.75 21.0859 3.75 20.1875V15.8125C3.75 14.9531 4.41406 14.25 5.3125 14.25H9.6875C10.5469 14.25 11.25 14.9531 11.25 15.8125V20.1875Z"
                      fill="#7A8699" />
                  </svg>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
            tabindex="0">
            <div class="searched-list mt-2">
              <div class="row">
                <div class="col-12">
                  <a href="#" class="list-search-card odd-card d-flex align-items-top justify-content-start">
                    <div class="list-thumbnail">
                      <img src="assets/images/list-thumb1.jpg" alt="image">
                    </div>
                    <div class="thumb-content">
                      <!-- <span class="category-tag medium-font">
                        {{'pdl-website.categoryTag1.tagText' | translate }}
                      </span> -->
                      <h5 class="semibold heading pdl-title-color">
                        pdlRural Rehabilitation in East Punjab and farmers and the struggles
                      </h5>
                      <div class="small-tags">
                        <span class="small-tag">
                          <img src="assets/images/icons/calendar-ico.svg">
                          {{'pdl-website.exhibitionDate1.dateText' | translate }}
                        </span>
                        <span class="small-tag">
                          <img src="assets/images/icons/location-ico.svg">
                          {{'pdl-website.exhibitionLocation1.locationText' | translate }}
                        </span>
                      </div>
                      <p class="description-text">
                        {{'pdl-website.exhibitionDescription1.descriptionText' | translate }}
                      </p>
                    </div>
                  </a>
                </div>
                <div class="col-12">
                  <a href="#" class="list-search-card even-card d-flex align-items-top justify-content-start">
                    <div class="list-thumbnail">
                      <img src="assets/images/list-thumb1.jpg" alt="image">
                    </div>
                    <div class="thumb-content">
                      <!-- <span class="category-tag medium-font">
                        {{'pdl-website.categoryTag1.tagText' | translate }}
                      </span> -->
                      <h5 class="semibold heading pdl-title-color">
                        pdlRural Rehabilitation in East Punjab and farmers and the struggles
                      </h5>
                      <div class="small-tags">
                        <span class="small-tag">
                          <img src="assets/images/icons/calendar-ico.svg">
                          {{'pdl-website.exhibitionDate1.dateText' | translate }}
                        </span>
                        <span class="small-tag">
                          <img src="assets/images/icons/location-ico.svg">
                          {{'pdl-website.exhibitionLocation1.locationText' | translate }}
                        </span>
                      </div>
                      <p class="description-text">
                        {{'pdl-website.exhibitionDescription1.descriptionText' | translate }}
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
            <div class="searches-in-grid mt-2">
              <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a href="#" class="search-card mb-4">
                    <div class="block-img">
                      <div class="image-overlay">
                        <span class="category-title">
                          Manuscripts
                        </span>
                        <span class="ratings">
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                        </span>
                      </div>
                      <img class="thumb-imb" src="assets/images/search-thumb.jpg">
                    </div>
                    <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                      <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card1.titleText' |
                        translate }}
                      </h6>
                      <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                        <span class="font-10 d-flex">Persian</span>
                        <span class="font-10 d-flex">16 Pages</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a href="#" class="search-card mb-4">

                    <div class="block-img">
                      <div class="image-overlay">
                        <span class="category-title">
                          Manuscripts
                        </span>
                        <span class="ratings">
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                        </span>
                      </div>
                      <img class="thumb-imb" src="assets/images/search-thumb.jpg">
                    </div>
                    <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                      <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card2.titleText' |
                        translate }}
                      </h6>
                      <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                        <span class="font-10 d-flex">Persian</span>
                        <span class="font-10 d-flex">16 Pages</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a href="#" class="search-card mb-4">

                    <div class="block-img">
                      <div class="image-overlay">
                        <span class="category-title">
                          Manuscripts
                        </span>
                        <span class="ratings">
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                        </span>
                      </div>
                      <img class="thumb-imb" src="assets/images/search-thumb.jpg">
                    </div>
                    <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                      <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card3.titleText' |
                        translate }}
                      </h6>
                      <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                        <span class="font-10 d-flex">Persian</span>
                        <span class="font-10 d-flex">16 Pages</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a href="#" class="search-card mb-4">

                    <div class="block-img">
                      <div class="image-overlay">
                        <span class="category-title">
                          Manuscripts
                        </span>
                        <span class="ratings">
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                        </span>
                      </div>
                      <img class="thumb-imb" src="assets/images/search-thumb.jpg">
                    </div>
                    <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                      <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card4.titleText' |
                        translate }}
                      </h6>
                      <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                        <span class="font-10 d-flex">Persian</span>
                        <span class="font-10 d-flex">16 Pages</span>
                      </div>
                    </div>
                  </a>
                </div>


                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a href="#" class="search-card mb-4">

                    <div class="block-img">
                      <div class="image-overlay">
                        <span class="category-title">
                          Manuscripts
                        </span>
                        <span class="ratings">
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                        </span>
                      </div>
                      <img class="thumb-imb" src="assets/images/search-thumb.jpg">
                    </div>
                    <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                      <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card1.titleText' |
                        translate }}
                      </h6>
                      <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                        <span class="font-10 d-flex">Persian</span>
                        <span class="font-10 d-flex">16 Pages</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a href="#" class="search-card mb-4">

                    <div class="block-img">
                      <div class="image-overlay">
                        <span class="category-title">
                          Manuscripts
                        </span>
                        <span class="ratings">
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                        </span>
                      </div>
                      <img class="thumb-imb" src="assets/images/search-thumb.jpg">
                    </div>
                    <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                      <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card2.titleText' |
                        translate }}
                      </h6>
                      <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                        <span class="font-10 d-flex">Persian</span>
                        <span class="font-10 d-flex">16 Pages</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a href="#" class="search-card mb-4">

                    <div class="block-img">
                      <div class="image-overlay">
                        <span class="category-title">
                          Manuscripts
                        </span>
                        <span class="ratings">
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                        </span>
                      </div>
                      <img class="thumb-imb" src="assets/images/search-thumb.jpg">
                    </div>
                    <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                      <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card3.titleText' |
                        translate }}
                      </h6>
                      <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                        <span class="font-10 d-flex">Persian</span>
                        <span class="font-10 d-flex">16 Pages</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a href="#" class="search-card mb-4">

                    <div class="block-img">
                      <div class="image-overlay">
                        <span class="category-title">
                          Manuscripts
                        </span>
                        <span class="ratings">
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/full-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/half-star.svg" alt="icon"></span>
                          <span><img src="assets/images/icons/no-star.svg" alt="icon"></span>
                        </span>
                      </div>
                      <img class="thumb-imb" src="assets/images/search-thumb.jpg" alt="thumbnail">
                    </div>
                    <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                      <h6 class="pdl-title-color medium-font font-14">{{'pdl-website.searchrow1Card4.titleText' |
                        translate }}
                      </h6>
                      <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                        <span class="font-10 d-flex">Persian</span>
                        <span class="font-10 d-flex">16 Pages</span>
                      </div>
                    </div>
                  </a>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>
    </div>
  </div>
</section>
