import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromCurrency from "../reducers/currency.reducer";

export const selectCurrencyState =
  createFeatureSelector<fromCurrency.State>('currency');

export const getCurrencies = createSelector(
    selectCurrencyState,
    fromCurrency.selectAll
);

export const getActiveCurrencies = createSelector(
  getCurrencies,
  (currencies) => currencies.filter(currency => currency.Status == 'E')
);

export const getTotalRecords = createSelector(
  selectCurrencyState,
  (state) => state.totalRecords
);

export const getCurrencyByID = (props: {id: string}) => createSelector(
  selectCurrencyState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectCurrencyState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectCurrencyState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);