import { createAction, props } from '@ngrx/store';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { Adopter } from 'shared/src/interfaces/fundraising.interface';

export const GetAdoptedBooksList = createAction(
  '[WebAdoptedBooks] Adopted Books List Request',
  props<{ payload: GenericListingPayload }>()
);

export const GetAdoptedBooksListSuccess = createAction(
  '[WebAdoptedBooks] Adopted Books List Request Success',
  props<{ adoptedBooksList: Adopter[], totalCount: number }>()
);

export const GetAdoptedBooksListError = createAction(
  '[WebAdoptedBooks] Adopted Books List Request Error',
  props<{ message: string }>()
);