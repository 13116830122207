/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { AdminUserServiceActions } from '../actions/index';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { UserList } from 'shared/src/interfaces/user-services.interface';

export interface State extends EntityState<UserList> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  order: string;
  orderBy: string;
}

export const adapter = createEntityAdapter<UserList>({
  selectId: (e) => e?.UserID,
});

export const initialState = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
  totalRecords: 0,
  pageNumber: 0,
  pageSize: 0,
  order: 'TotalSearchCount',
  orderBy: 'desc',
});

export const reducer = createReducer(
  initialState,

  on(AdminUserServiceActions.UsersList, (state, action) => ({
    ...state,
    isLoading: true,
    successMessage: '',
    errorMessage: '',
    pageNumber: action.payload?.pageNumber || 1,
    pageSize: action.payload?.pageSize || 0,
  })),

  on(AdminUserServiceActions.UsersListSuccess, (state, action) =>
    adapter.setAll(action?.userList, {
      ...state,
      totalRecords: action.totalRecords,
      isLoading: false,
      isError: false,
    })
  ),

  on(AdminUserServiceActions.UsersListError, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
      successMessage: '',
    };
  })
);

export const { selectAll } = adapter.getSelectors();
