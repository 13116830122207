/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from 'shared/src/environments/environment';
import { map } from 'rxjs';
import {
  AddEditEmployeePayload,
  AddEditEmployeeResponse,
  EmployeeDetail,
  GetEmployeeListPayload,
  GetEmployeeListResponse,
  UpdateRoleProcessPayload,
  UpdateRoleStatusPayload,
  GenericMessageResponse,
  UpdateRoleServiceResponse,
  UpdateEmployeeStatusPayload,
  UpdateEmployeeStatusResponse,
  GetEmployeeInfoResponse,
  DeleteEmployeePayload,
} from 'shared/src/interfaces/employee.interface';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.employee;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  ////////// load employee list /////////
  loadEmployeeList(payload: GetEmployeeListPayload) {
    const url = `${this.rootURL}${this.endPointUrl.default}`
      .replace('{pageSize}', (payload?.pageSize ?? 20).toString())
      .replace('{pageNumber}', (payload?.pageNumber ?? 1).toString())
      .replace('{type}', (payload?.type ?? 'E').toString());
    return this.http
      .get<GetEmployeeListResponse>(url)
      .pipe(map((e: GetEmployeeListResponse) => e?.body));
  }

  //////////// add employee /////////
  addEmployee(payload: AddEditEmployeePayload) {
    const url = `${this.rootURL}${this.endPointUrl.add}`;
    return this.http
      .post<AddEditEmployeeResponse>(url, payload)
      .pipe(map((e: AddEditEmployeeResponse) => e));
  }

  /////////////// edit employee /////////////
  updateSponsorship(payload: AddEditEmployeePayload, id: number) {
    const url = `${this.rootURL}${this.endPointUrl.info}`.replace(
      '{id}',
      (id ?? '').toString()
    );
    return this.http
      .put<AddEditEmployeeResponse>(url, payload)
      .pipe(map((e: AddEditEmployeeResponse) => e));
  }

  ////////// update Publisher status/////////
  updateEmployeeStatus(payload: UpdateEmployeeStatusPayload) {
    const url = `${this.rootURL}${this.endPointUrl.status}`;
    return this.http
      .patch<UpdateEmployeeStatusResponse>(url, payload)
      .pipe(map((e: UpdateEmployeeStatusResponse) => e));
  }

  ////////// update role status/////////
  updateRoleStatus(payload: UpdateRoleStatusPayload) {
    const url = `${this.rootURL}${this.endPointUrl.roleStatus}`;
    return this.http
      .patch<GenericMessageResponse>(url, payload)
      .pipe(map((e: GenericMessageResponse) => e));
  }

  ////////// get employee detail along with roles //////////////////
  employeeDetail(employeeId: number) {
    const url = `${this.rootURL}${this.endPointUrl.get}`.replace(
      '{id}',
      employeeId.toString() ?? ''
    );
    return this.http
      .get<EmployeeDetail>(url)
      .pipe(map((e: EmployeeDetail) => e?.body));
  }

  //////////////// employee info without roles /////////////////
  employeeInfo(employeeId: number) {
    const url = `${this.rootURL}${this.endPointUrl.info}`.replace(
      '{id}',
      employeeId.toString() ?? ''
    );
    return this.http
      .get<GetEmployeeInfoResponse>(url)
      .pipe(map((e: GetEmployeeInfoResponse) => e?.body));
  }

  ////////// update role services //////////////////
  updateRoleServices(payload: UpdateRoleProcessPayload) {
    const url = `${this.rootURL}${this.endPointUrl.assignService}`;
    return this.http
      .put<UpdateRoleServiceResponse>(url, payload)
      .pipe(map((e: UpdateRoleServiceResponse) => e));
  }

  ////////// send login credentials to staff //////////////////
  sendCredentials(staffId: number) {
    const url = `${this.rootURL}${this.endPointUrl.credential}`;
    return this.http
      .put<GenericMessageResponse>(url, { staffId })
      .pipe(map((e: GenericMessageResponse) => e));
  }

  ////////// download script as excel /////////
  download(type: string) {
    return this.http
      .get(
        `${this.rootURL}${this.endPointUrl.download}`.replace('{type}', type),
        {
          responseType: 'blob',
        }
      )
      .pipe(map((data: Blob) => data));
  }

  ////////// delete Employee/////////
  deleteEmployee(payload: DeleteEmployeePayload) {
    const url = `${this.rootURL}${this.endPointUrl.delete}`;
    return this.http
      .delete<GenericMessageResponse>(url, { body: payload })
      .pipe(map((e: GenericMessageResponse) => e));
  }
}
