@if(allDownloads?.Images?.length > 0) {
<section class="downloads section-padding pdl-bg section-lg-mt-4 section-sm-mt-25 section-lg-mb-4 section-sm-mb-25">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="heading-style semibold text-center mb-4">{{'pdl-website.downloadsSection.titleText' | translate }}</h2>
                <p class="title-description text-center mb-lg-40 pdl-grey">
                    {{'pdl-website.downloadsDescription.descriptionText' | translate }}
                </p>
            </div>
            <div class="col-12 hidden-after-767">
                <div class="downloads-in-grid pb-4">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <!-- <img src="assets/images/downloads-placeholder.png"> -->
                            @if(allDownloads?.TemplateType === 1) {
                                <!-- TEMPLATE 1 -->
                                <div class="template__wrapper_first justify-content-center">
                                    @for(download of allDownloads?.Images; track download.ID; let i = $index) {
                                    <!-- loop through below list -->
                                    <div class="item-{{i}}">
                                        <img src="{{imageBaseUrl}}{{download.ImagePath}}">
                                    </div>
                                    }
                                </div>
                            } @else {
                                <!-- TEMPLATE 2 -->
                                <div class="template__wrapper_second justify-content-center">
                                    @for(download of allDownloads?.Images; track download.ID; let i = $index) {
                                        <div class="grid-{{i}}">
                                            <img src="{{imageBaseUrl}}{{download.ImagePath}}">
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 visible-below-767">
                <div class="downloads-in-slider">
                    <ngx-slick-carousel class="carousel" 
                                        #slickModal="slick-carousel" 
                                        [config]="slideConfig" 
                                        (init)="slickInit($event)">
                        @for (download of allDownloads?.Images; track download.ID; let i = $index) {
                            <div ngxSlickItem class="slide collection-slide pe-4 pb-4">
                                <div class="downloads-card">
                                    <div class="block-img">
                                        <img class="collection-img" src="{{baseUrl}}{{download.ImagePath}}" alt="">
                                    </div>
                                </div> 
                            </div>
                        }
                    </ngx-slick-carousel>
                </div>
            </div>
            <div class="col-12">
                <div class="centered-btn d-flex align-items-center justify-content-center">
                    <a href="javascript:void(0);" [routerLink]="'/all-downloads'" class="btn pdl-btn">{{'pdl-website.downloadsButton.buttonText' | translate }}</a>
                </div>
            </div>
            
        </div>
    </div>
</section>
}