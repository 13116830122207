<panjab-digi-lib-static-page-head [pageName]="'pdl-website.listOfCustodians.titleText' | translate"
  pagePath="/list-of-custodians">
</panjab-digi-lib-static-page-head>

<section class="static-section section-padding ">
  <div class="mt-4 mb-4 table__top__section container">
    <div class="d-flex justify-content-between">
      <div>
        <div class="d-flex">
          <select class="form-select sort__filter select__grey" aria-label="Default select example">
            <!-- <img src="/assets/images/icons/arrow-up-down-up-highlighted.png" /> -->
            <option selected>Sort by: Recent</option>
            <option value="1">Sort by: Old</option>
          </select>
        </div>
      </div>
      <div class="d-flex">
        <div class="position-relative search-autocomplete inner__search">
          <label for="serachtags" class="search-label"><img src="assets/images/icons/search-grey.svg"></label>
          <input type="text" class="form-control search__input" id="serachtags"
            placeholder="{{'pdl-shared.content.searchByName' | translate}}">
          <img class="cross-icon" src="assets/images/icons/cross-close-icon.svg">
        </div>
        <div>
        </div>
        <span class="btn btn-default btn__secondary p-0 ms-3">
          <select class="form-select sort__filter" aria-label="Default select example">
            <option selected>All Category</option>
            <option value="1">XYZ</option>
          </select>
        </span>
      </div>
    </div>

    <div class="table-responsive mt-5 ">
      <table class="static_table table">
        <thead class="text">
          <tr class="text">
            <th>Name</th>
            <th>City</th>
            <th>Number Of Records</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          <!-- row 1 -->
          <tr class="text">
            <td>Harnam Singh Shan, Dr. (3697)</td>
            <td>Chandigarh, India</td>
            <td>12</td>
            <td>
              <div class="centered-btn d-flex align-items-center align-items-center p-6-12">
                <a href="/assets/documents/Digitization Training Form.dot"
                  class="btn pdl-btn f-12 d-flex align-items-center">View Collection</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
