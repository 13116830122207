<panjab-digi-lib-static-page-head [pageName]="'All Literature'" pagePath="/all-literature">
</panjab-digi-lib-static-page-head>
<section class="search_compnent_section">
  <div class="container">
    <div class="d-flex search-header-wrapper mb-5 mt-3 no-scrollbar ">
      @for (category of allCategories; track category) {
      <span class="search-item" [ngClass]="{'active-item' : category.id === activeKey.id}">
        <span (click)="setActiveKey(category.id)" class="cursor-pointer">
          {{category.categoryName}}
        </span>
      </span>
      }
    </div>
    @if (!showSearch) {
    <div class="d-flex data-search-wrapper mb-5">
      <div class="search_field_wrapper align-items-center d-flex">
        <div class="p-0-10 d-flex cursor-pointer" (click)="toggleSearch()">
          <img src="/assets/images/icons/open-search.svg" />
          <span>ADVANCE SEARCH</span>
        </div>
      </div>
      <div class="tool_wrapper align-items-center">
        <div class="page-filters-dropdown">
          <div class="dropdown page-filter-dropdown">
            <a class="page-filter-action btn  dropdown-toggle" style="border: none" href="#" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img src="assets/images/icons/filters-ico.svg" alt="icon"> Filters
            </a>

            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </div>
        </div>
        <div class="list-styles d-flex">
          <button class="nav-link" [ngClass]="{'active' : listingType === 'list'}" id="home-tab" data-bs-toggle="tab"
            data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"
            (click)="setLising('list')">
            <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.125 5.8125C3.125 5.30469 3.51562 4.875 4.0625 4.875H5.9375C6.44531 4.875 6.875 5.30469 6.875 5.8125V7.6875C6.875 8.23438 6.44531 8.625 5.9375 8.625H4.0625C3.51562 8.625 3.125 8.23438 3.125 7.6875V5.8125ZM3.125 12.0625C3.125 11.5547 3.51562 11.125 4.0625 11.125H5.9375C6.44531 11.125 6.875 11.5547 6.875 12.0625V13.9375C6.875 14.4844 6.44531 14.875 5.9375 14.875H4.0625C3.51562 14.875 3.125 14.4844 3.125 13.9375V12.0625ZM5.9375 17.375C6.44531 17.375 6.875 17.8047 6.875 18.3125V20.1875C6.875 20.7344 6.44531 21.125 5.9375 21.125H4.0625C3.51562 21.125 3.125 20.7344 3.125 20.1875V18.3125C3.125 17.8047 3.51562 17.375 4.0625 17.375H5.9375Z"
                [attr.fill]="getFillColor('list')" />
              <path opacity="0.4"
                d="M8.75 6.75C8.75 6.08594 9.29688 5.5 10 5.5H21.25C21.9141 5.5 22.5 6.08594 22.5 6.75C22.5 7.45312 21.9141 8 21.25 8H10C9.29688 8 8.75 7.45312 8.75 6.75ZM8.75 13C8.75 12.3359 9.29688 11.75 10 11.75H21.25C21.9141 11.75 22.5 12.3359 22.5 13C22.5 13.7031 21.9141 14.25 21.25 14.25H10C9.29688 14.25 8.75 13.7031 8.75 13ZM21.25 18C21.9141 18 22.5 18.5859 22.5 19.25C22.5 19.9531 21.9141 20.5 21.25 20.5H10C9.29688 20.5 8.75 19.9531 8.75 19.25C8.75 18.5859 9.29688 18 10 18H21.25Z"
                [attr.fill]="getFillColor('list')" />
            </svg>
          </button>
          <button class="nav-link" [ngClass]="{'active' : listingType === 'card'}" id="profile-tab" data-bs-toggle="tab"
            data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane"
            aria-selected="false" (click)="setLising('card')">
            <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.25 9.875C11.25 10.9297 10.3906 11.75 9.375 11.75H5.625C4.57031 11.75 3.75 10.9297 3.75 9.875V6.125C3.75 5.10938 4.57031 4.25 5.625 4.25H9.375C10.3906 4.25 11.25 5.10938 11.25 6.125V9.875ZM21.25 19.875C21.25 20.9297 20.3906 21.75 19.375 21.75H15.625C14.5703 21.75 13.75 20.9297 13.75 19.875V16.125C13.75 15.1094 14.5703 14.25 15.625 14.25H19.375C20.3906 14.25 21.25 15.1094 21.25 16.125V19.875Z"
                [attr.fill]="getFillColor('card')" />
              <path opacity="0.4"
                d="M21.25 10.1875C21.25 11.0859 20.5469 11.75 19.6875 11.75H15.3125C14.4141 11.75 13.75 11.0859 13.75 10.1875V5.8125C13.75 4.95312 14.4141 4.25 15.3125 4.25H19.6875C20.5469 4.25 21.25 4.95312 21.25 5.8125V10.1875ZM11.25 20.1875C11.25 21.0859 10.5469 21.75 9.6875 21.75H5.3125C4.41406 21.75 3.75 21.0859 3.75 20.1875V15.8125C3.75 14.9531 4.41406 14.25 5.3125 14.25H9.6875C10.5469 14.25 11.25 14.9531 11.25 15.8125V20.1875Z"
                [attr.fill]="getFillColor('card')" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    } @else {
    @if (showSearch){
    <div class="mobileOnly">
      <panjab-digi-lib-common-advance-search></panjab-digi-lib-common-advance-search>
    </div>
    }
    <div class="d-flex data-search-wrapper mb-5">
      <div class="search_field_wrapper">
        <div class="p-0-10">
          <img src="/assets/images/icons/open-search.svg" (click)="toggleSearch()" />
        </div>
        @if (showSearch) {
        <div class="desktopOnly">
          <panjab-digi-lib-common-advance-search></panjab-digi-lib-common-advance-search>
        </div>
        }
      </div>
      <div class="content_field_wrapper">
        <div class="tool_wrapper align-items-center">
          <div class="page-filters-dropdown">
            <div class="dropdown page-filter-dropdown">
              <a class="page-filter-action btn  dropdown-toggle" style="border: none" href="#" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <img src="assets/images/icons/filters-ico.svg" alt="icon"> Filters
              </a>

              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </div>
          </div>
          <div class="list-styles d-flex">
            <button class="nav-link" [ngClass]="{'active' : listingType === 'list'}" id="home-tab" data-bs-toggle="tab"
              data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane"
              aria-selected="true" (click)="setLising('list')">

              <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.125 5.8125C3.125 5.30469 3.51562 4.875 4.0625 4.875H5.9375C6.44531 4.875 6.875 5.30469 6.875 5.8125V7.6875C6.875 8.23438 6.44531 8.625 5.9375 8.625H4.0625C3.51562 8.625 3.125 8.23438 3.125 7.6875V5.8125ZM3.125 12.0625C3.125 11.5547 3.51562 11.125 4.0625 11.125H5.9375C6.44531 11.125 6.875 11.5547 6.875 12.0625V13.9375C6.875 14.4844 6.44531 14.875 5.9375 14.875H4.0625C3.51562 14.875 3.125 14.4844 3.125 13.9375V12.0625ZM5.9375 17.375C6.44531 17.375 6.875 17.8047 6.875 18.3125V20.1875C6.875 20.7344 6.44531 21.125 5.9375 21.125H4.0625C3.51562 21.125 3.125 20.7344 3.125 20.1875V18.3125C3.125 17.8047 3.51562 17.375 4.0625 17.375H5.9375Z"
                  [attr.fill]="getFillColor('list')" />
                <path opacity="0.4"
                  d="M8.75 6.75C8.75 6.08594 9.29688 5.5 10 5.5H21.25C21.9141 5.5 22.5 6.08594 22.5 6.75C22.5 7.45312 21.9141 8 21.25 8H10C9.29688 8 8.75 7.45312 8.75 6.75ZM8.75 13C8.75 12.3359 9.29688 11.75 10 11.75H21.25C21.9141 11.75 22.5 12.3359 22.5 13C22.5 13.7031 21.9141 14.25 21.25 14.25H10C9.29688 14.25 8.75 13.7031 8.75 13ZM21.25 18C21.9141 18 22.5 18.5859 22.5 19.25C22.5 19.9531 21.9141 20.5 21.25 20.5H10C9.29688 20.5 8.75 19.9531 8.75 19.25C8.75 18.5859 9.29688 18 10 18H21.25Z"
                  [attr.fill]="getFillColor('list')" />
              </svg>
            </button>
            <button class="nav-link" [ngClass]="{'active' : listingType === 'card'}" id="profile-tab"
              data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab"
              aria-controls="profile-tab-pane" aria-selected="false" (click)="setLising('card')">

              <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.25 9.875C11.25 10.9297 10.3906 11.75 9.375 11.75H5.625C4.57031 11.75 3.75 10.9297 3.75 9.875V6.125C3.75 5.10938 4.57031 4.25 5.625 4.25H9.375C10.3906 4.25 11.25 5.10938 11.25 6.125V9.875ZM21.25 19.875C21.25 20.9297 20.3906 21.75 19.375 21.75H15.625C14.5703 21.75 13.75 20.9297 13.75 19.875V16.125C13.75 15.1094 14.5703 14.25 15.625 14.25H19.375C20.3906 14.25 21.25 15.1094 21.25 16.125V19.875Z"
                  [attr.fill]="getFillColor('card')" />
                <path opacity="0.4"
                  d="M21.25 10.1875C21.25 11.0859 20.5469 11.75 19.6875 11.75H15.3125C14.4141 11.75 13.75 11.0859 13.75 10.1875V5.8125C13.75 4.95312 14.4141 4.25 15.3125 4.25H19.6875C20.5469 4.25 21.25 4.95312 21.25 5.8125V10.1875ZM11.25 20.1875C11.25 21.0859 10.5469 21.75 9.6875 21.75H5.3125C4.41406 21.75 3.75 21.0859 3.75 20.1875V15.8125C3.75 14.9531 4.41406 14.25 5.3125 14.25H9.6875C10.5469 14.25 11.25 14.9531 11.25 15.8125V20.1875Z"
                  [attr.fill]="getFillColor('card')" />
              </svg>
            </button>
          </div>
        </div>
        <panjab-digi-lib-common-listing [data]="data" [listType]="listingType"></panjab-digi-lib-common-listing>
      </div>
    </div>

    }
    @if(!showSearch){
    <panjab-digi-lib-common-listing [data]="data" [listType]="listingType"></panjab-digi-lib-common-listing>
    }
  </div>
</section>
