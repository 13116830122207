/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { AdminVolunteerRequestActions } from '../actions';
import { VolunteerRequests } from 'shared/src/interfaces/volunteer.interface';

export interface State extends EntityState<VolunteerRequests> {
  payload: GenericListingPayload;
  totalRecords: number;
  isLoading?: boolean;
  isSaving?: boolean;
  isSaved?: boolean;
  isError?: boolean;
  errorMessage?: string;
  successMessage?: string;
}

export const adapter = createEntityAdapter<VolunteerRequests>({
  selectId: (e) => e.vappID,
});

export const initialState = adapter.getInitialState({
  totalRecords: 0,
  isLoading: false,
  isSaving: false,
  isSaved: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
});

export const reducer = createReducer(
  initialState,
  on(AdminVolunteerRequestActions.LoadVolunteersRequest, (state, action) => ({
    ...state,
    payload: action.payload,
    isLoading: true,
    isSaving: false,
    isSaved: false,
    isError: false,
    errorMessage: '',
    successMessage: '',
  })),

  on(AdminVolunteerRequestActions.LoadVolunteersRequestSuccess, (state, action) => ({
    ...state,
    ...adapter.setAll(action.requestsList, state),
    totalRecords: action.totalRecords,
    isLoading: false,
    isSaving: false,
    isSaved: false,
    isError: false,
    errorMessage: '',
    successMessage: '',
  })),

  on(AdminVolunteerRequestActions.LoadVolunteersRequestError, (state, action) => ({
    ...state,
    isLoading: false,
    isError: true,
    isSaving: false,
    isSaved: false,
    errorMessage: action.message,
    successMessage: '',
  })),
  on(AdminVolunteerRequestActions.UpdateVolunteerRequestStatus, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isSaved: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
  ),
  on(AdminVolunteerRequestActions.UpdateVolunteerRequestStatusSuccess, (state, action) =>
    {
        const updates = action?.requestsList;
        const updatedEntities = adapter.updateMany(
          updates.map((update: VolunteerRequests) => ({
            id: update.vappID,
            changes: update,
          })),
          state
        );
        return {
          ...updatedEntities,
          isLoading: false,
          isSaving: false,
          isSaved: true,
          isError: false,
          errorMessage: '',
          successMessage: action.message
        }
    }
  ),
  on(AdminVolunteerRequestActions.UpdateVolunteerRequestStatusError, (state, action) =>
  ({
      ...state,
      isLoading: false,
      isSaving: false,
      isSaved: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
  })
  )
);

export const { selectAll, selectEntities } = adapter.getSelectors();
