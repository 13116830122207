import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageHeadComponent } from '../static/staticPageHead/staticPageHead.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonListingComponent } from '../common-listing/common-listing.component';
import { CommonAdvanceSearchComponent } from '../common-listing/search/common-advance-search.component';

@Component({
  selector: 'panjab-digi-lib-all-literature',
  standalone: true,
  imports: [
    CommonModule,
    StaticPageHeadComponent,
    TranslateModule,
    CommonListingComponent,
    CommonAdvanceSearchComponent
  ],
  templateUrl: './all-literature.component.html',
  styleUrl: './all-literature.component.scss',
})
export class AllLiteratureComponent {
  constructor() {}
  allCategories: any = [
    { id: 0, categoryName: 'everything' },
    {
      id: 1,
      categoryName: 'Manuscripts',
    },
    {
      id: 2,
      categoryName: 'Books',
    },
    {
      id: 3,
      categoryName: 'Magzines',
    },
  ];
  activeKey: any = { id: 0, categoryName: 'everything' };
  data = null;
  itemsPerPage = 20;
  showSearch = true;
  listingType: 'card' | 'list' = 'list';

  ngOnInit() {
    this.data = this.getDataCorrespondingToCategory(this.activeKey);
  }

  getDataCorrespondingToCategory = (currentKey: any): any => {
    const data = [];
    const types = ['Manuscripts', 'Books', 'Magzines'];
    const stars = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
    for (let i = 0; i < this.itemsPerPage; i++) {
      data.push({
        id: 1,
        type:
          currentKey.id === 0
            ? types[Math.ceil(Math.random() * 3) - 1]
            : currentKey.categoryName,
        rating: stars[Math.ceil(Math.random() * 10) - 1],
        name: 'Item ' + Math.ceil(Math.random() * 38374),
        script: 'Gurmukhi',
        pages: Math.ceil(Math.random() * 10000),
        thumbnail: 'assets/images/list-thumb1.jpg',
        author: 'Author ' + Math.ceil(Math.random() * 38374),
        subject: 'Subject ' + Math.ceil(Math.random() * 38374),
        description:
          'Following declaration of independence of India and its simultaneous partition, there was a transfer of population on the basis of religion from the asdasda'.substring(0,150),
      });
    }
    return { data, total: data.length + Math.ceil(Math.random() * 500) };
  };
  setActiveKey = (key: string) => {
    this.activeKey = this.allCategories.find((cat: any) => cat.id === key);
    this.data = this.getDataCorrespondingToCategory(this.activeKey);
  };

  toggleSearch = () => {
    this.showSearch = !this.showSearch;
  };
  setLising = (type: 'card' | 'list') => {
    this.listingType = type;
  };
  getFillColor = (type: string) => {
    if (this.listingType === type) {
      return '#FFFFFF';
    }
    return '#7A8699';
  };
}
